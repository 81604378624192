import React from 'react'
import { COLORS } from '../../../../constants'
import { PartialRecordDataType } from '../../../../types/helpers'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import CustomLegend from '../Common/CustomLegend'
import GraphLineBarWithTimelineView from '../GraphLineBar/GraphLineBarWithTimeline/GraphLineBarWithTimelineView'

interface Props {
  data: Array<PartialRecordDataType<'recipe'>>
  recipeNames: Record<string, string>
  isLoading: boolean
  isError: boolean
}

const _RecipeTimeline = (props: Props) => {
  const { data, isError, isLoading, recipeNames } = props
  if (isLoading) {
    return <></>
  }

  if (isError) {
    return <CommonErrorMessage />
  }

  if (data && data.length === 0) {
    return <CommonNoDataAvailable />
  }

  return (
    <>
      <GraphLineBarWithTimelineView
        topMargin={true}
        entry={'recipe'}
        colorsId={'pressRecipe'}
        filteredData={data}
        i18nTitle={''}
        lineHeight={60}
        hideTitle={true}
      />
      <CustomLegend
        // stayCenter={false}
        dataToShow={Object.entries(recipeNames).map(([key, value]) => ({
          label: `${key}: ${value}`,
          color: COLORS.pressRecipe[key],
          unitOfMeasure: '',
        }))}
      />
    </>
  )
}

export default _RecipeTimeline
