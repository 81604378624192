import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useComponentsTranslation } from '../../../../../inplant-components-fe/services/translation'
import Wrapper from '../../../../../inplant-components-fe/ui/components/Layout/Wrapper'
import Button from '../../../../../inplant-components-fe/ui/components/MVButtons/Button'
import Input from '../../../../../inplant-components-fe/ui/components/MVInput/Input'
import { sendRecoveryPassword } from '../../../../redux/actions/recoveryPassword'
import { popupNotification } from '../../../../../inplant-coreadapter-fe/functions/notification'
import Alert from '../../../../../inplant-components-fe/ui/components/MVAlert/Alert'
import Flex, { Direction } from '../../../../../inplant-components-fe/ui/components/MVFlex/Flex'

interface FormData {
  username: string
}

const RecoveryPassword = () => {
  const { t } = useComponentsTranslation()
  const [formData, setFormData] = useState<FormData>({ username: '' })
  const [fetching, setFetching] = useState<boolean>(false)
  // const [error, setError] = useState<string>()
  const base = 'user.recoveryPassword'
  const dispatch = useDispatch()
  const history = useHistory()

  async function handleRecoveryPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setFetching(true)
    sendRecoveryPassword(formData.username)(dispatch)
      .then(() => {
        popupNotification({
          type: 'success',
          title: t(`${base}.actionSuccessTitle`),
          layout: 'topRight',
          content: t(`${base}.actionSuccess`),
        })
        history.push('/')
      })
      .catch(error => {
        if (error.statusCode === 404) {
          popupNotification({
            type: 'error',
            content: t(`${base}.actionSuccess`),
          })
        }
      })
      .finally(() => {
        setFetching(false)
        // history.push('/')
      })
  }

  return (
    <div className="inplant-login">
      <Wrapper customType="loginForm">
        <form onSubmit={handleRecoveryPassword}>
          <img src={'/logo-login.png'} className="img-fluid mb-4" alt={''} />
          <Flex direction={Direction.column} spaceSize={'lg'}>
            <Alert text={t(`${base}.info`)} variant={'info'} />
            <Flex direction={Direction.column}>
              <Input
                label={t(`${base}.username`)}
                type={'text'}
                kind={'input'}
                placeholder={t(`${base}.usernamePlaceholder`)}
                onChange={value => setFormData(prev => ({ username: value }))}
                required={true}
              />
              <Button type={'submit'} semantic={'primary'} isLoading={fetching} label={t(`${base}.action`)} />
            </Flex>
          </Flex>
        </form>
      </Wrapper>
    </div>
  )
}

export default RecoveryPassword
