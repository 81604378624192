import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { API } from '../../../../../redux/actions'
import { GeneralData } from '../../../../../types/measure'
import { Workshift } from '../../../../../types/workshift'
import { PieData } from '../../charts/EventsPie/EventsPieView'
import { COLORS } from '../../../../../constants'
import { /* HHHmmssFromMilliseconds, */ Loader } from '../../../../../functions/shared'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { parseData } from '../../../../../functions/series'
import moment from 'moment'
import GraphLineBarWithTimeline from '../../GraphLineBar/GraphLineBarWithTimeline/GraphLineBarWithTimelineView'
import Column from '../../../../../../inplant-components-fe/ui/components/Grid/Column'
import {
  composeData,
  fillTime,
  populateSingleDataFrom,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/seriesV2'

export type BufferStateType = 'bufferState'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: BufferStateType[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
}

interface OwnState {
  bufferState: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataBufferState: PieData | null
  fetching: boolean
  error: boolean
  tooltipData: any
  showTooltip: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
})

class BufferStateView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      bufferState: {},
      fetching: false,
      error: false,
      dataBufferState: null,
      showTooltip: true,
      tooltipData: null,
      data: null,
      filteredData: [],
      mergedData: [],
      keyCollection: [],
    }
    this.getData = this.getData.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    if (this.props.workShift) {
      this.getData(this.props.processing)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const curThrustPresser = curData.thrustPresser !== undefined ? curData.thrustPresser : '--'
        const curAbsorption = curData.absorption !== undefined ? curData.absorption : '--'
        const curSpeedPercent = curData.speedPercent !== undefined ? curData.speedPercent : '--'
        this.setState({
          showTooltip: true,
          tooltipData: {
            time: curData.time,
            thrustPresser: curThrustPresser,
            absorption: curAbsorption,
            speedPercent: curSpeedPercent,
            thrustPresserColor: COLORS.vecoplant.generalGraph.thrustPresser,
            absorptionColor: COLORS.vecoplant.generalGraph.absorption,
            speedPercentColor: COLORS.vecoplant.generalGraph.speedPercent,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }


  public render() {
    const {
      t,
      hiddenCharts,
    } = this.props
    const { fetching, error } = this.state

    return (
      <div className='halts-graphs'>
        {!fetching && error && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferStateTitle')}</h3>
            <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
              {t('plantAnalysis.fetchErrors')}
            </div>
          </>
        )}
        {!fetching && !error && ((this.state.data && this.state.data.length === 0) || !this.state.data) && (
          <>
            <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferStateTitle')}</h3>
            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
          </>
        )}
        {!error && (
          <React.Fragment>
            {(!hiddenCharts || !hiddenCharts.includes('bufferState')) &&
              (this.state.fetching ? (
                <Row>
                  <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferStateTitle')}</h3>
                  <Loader />
                </Row>
              ) : (
                this.state.data && (
                  <>
                    <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferStateTitle')}</h3>
                    <Column sm={12} lg={12} md={12}>
                      <Row>
                        <div
                          className={`col-md-12 col-sm-12 col-lg-12 page-break-after-always print-full-width halts-graphs halts-graphs__vertical`}
                        >
                          {this.state.data.buffer1NoData ? (
                            <>
                              <h5 className={'ml-5'}>{t('plantAnalysis.vecoplant.bufferState.buffers.buffer1')}</h5>
                              <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                                {t('plantAnalysis.noDataAvailable')}
                              </div>
                            </>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer1'}
                              colorsId={'bufferState'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.vecoplant.bufferState.buffers.buffer1'}
                              lineHeight={60}
                            />
                          )}
                          {this.state.data.buffer2NoData ? (
                            <>
                              <h5 className={'ml-5'}>{t('plantAnalysis.vecoplant.bufferState.buffers.buffer2')}</h5>
                              <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                                {t('plantAnalysis.noDataAvailable')}
                              </div>
                            </>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer2'}
                              colorsId={'bufferState'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.vecoplant.bufferState.buffers.buffer2'}
                              lineHeight={60}
                            />
                          )}
                          {this.state.data.buffer5NoData ? (
                            <>
                              <h5 className={'ml-5'}>{t('plantAnalysis.vecoplant.bufferState.buffers.buffer5')}</h5>
                              <div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                                {t('plantAnalysis.noDataAvailable')}
                              </div>
                            </>
                          ) : (
                            <GraphLineBarWithTimeline
                              topMargin={true}
                              entry={'buffer5'}
                              colorsId={'bufferState'}
                              filteredData={this.state.filteredData}
                              i18nTitle={'plantAnalysis.vecoplant.bufferState.buffers.buffer5'}
                              lineHeight={60}
                            />
                          )}
                        </div>
                        {/* <div className={`col-sm-2 col-lg-2 col-md-2 chart-events-pie--data`}>
                          <table
                            className={
                              'table table-borderless table-sm w-100 mw-300 font-size-12 table-first-column-color'
                            }
                          >
                            <thead>
                            <tr>
                              <th />
                              <th className='text-right'>
                                {t('plantAnalysis.vecoplant.chartLabel.timeUnderMinThreshold')}
                              </th>
                              <th className='text-right'>
                                {t('plantAnalysis.vecoplant.chartLabel.timeOverMinThreshold')}
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.data?.bufferRecap &&
                              this.state.keyCollection.map((bufferId, bufferIndex) => {
                                const overValue = Object.entries(this.state.data?.bufferRecap[0]).filter(
                                  ([b, value]) => b.includes(bufferId) && b.includes('threshold') && b.includes('over'),
                                )[0][1]
                                const underValue = Object.entries(this.state.data?.bufferRecap[0]).filter(
                                  ([b, value]) => b.includes(bufferId) && b.includes('threshold') && b.includes('under'),
                                )[0][1]
                                return (
                                  <tr key={bufferIndex}>
                                    <td>
                                      <span
                                        style={{
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {this.getTraductionFromBufferId(bufferId)}
                                      </span>
                                    </td>
                                    <td className='text-right'>
                                      {HHHmmssFromMilliseconds((overValue as number) * 1000, false, true, false)}
                                    </td>
                                    <td className='text-right'>
                                      {HHHmmssFromMilliseconds((underValue as number) * 1000, false, true, false)}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div> */}
                      </Row>
                      <Row>
                        <div className='d-flex d-print-inline-flex'>
                          <Row verticalAlignment='center'>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className='label-color-square'
                          style={{ backgroundColor: COLORS.vecoplant.bufferState.loadable }}
                        />
                        <span>{t('plantAnalysis.vecoplant.bufferState.loadable')}</span>
                        </span>
                          </Row>
                          <Row verticalAlignment='center'>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className='label-color-square'
                          style={{ backgroundColor: COLORS.vecoplant.bufferState.notLoadable }}
                        />
                        <span>{t('plantAnalysis.vecoplant.bufferState.notLoadable')}</span>
                        </span>
                          </Row>
                          <Row verticalAlignment='center'>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className='label-color-square'
                          style={{ backgroundColor: COLORS.vecoplant.bufferState.emptying }}
                        />
                        <span>{t('plantAnalysis.vecoplant.bufferState.emptying')}</span>
                        </span>
                          </Row>
                        </div>
                      </Row>
                    </Column>
                  </>
                )
              ))}
          </React.Fragment>
        )}
      </div>
    )
  }

  private constructData() {
    try {
      const buffer1 = populateSingleDataFrom(this.state.data?.buffer1?.data, false, true)
      const buffer2 = populateSingleDataFrom(this.state.data?.buffer2?.data, false, true)
      const buffer5 = populateSingleDataFrom(this.state.data?.buffer5?.data, false, true)


      let mergedData = composeData({
        buffer1,
        buffer2,
        buffer5,
      })

      const start = moment(this.props.workShift.start.toString()).unix()
      const end = moment(this.props.workShift.end.toString()).unix()

      mergedData = fillTime(
        mergedData,
        start,
        end,
      )

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

/*   private getTraductionFromBufferId(key: string) {
    return this.props.t(`plantAnalysis.vecoplant.bufferState.buffers.${key}`)
  } */

  // private generateKey(data: Record<string, any>) {
  //   const keys = Object.keys(data)
  //   const truncateKey = keys.map(k => k.match('[a-z-A-Z-0-9]{1,}_[a-z-A-Z-0-9]{1,}'))
  //   const splitKey = truncateKey.map(k => k && k[0]).filter(k => k !== null) as string[]
  //   this.setState({
  //     keyCollection: splitKey.filter((v, i, a) => a.indexOf(v) === i),
  //   })
  // }

  // private formatData(data: any) {
  //   const resultData = data.columns
  //     .map((value: any, index: number) => {
  //       if (value !== 'time' && value !== 'shift') {
  //         return {
  //           [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
  //         }
  //       }
  //     })
  //     .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {})
  //   this.generateKey(resultData)
  //   this.setState({
  //     data: {
  //       ...this.state.data,
  //       bufferRecap: [resultData],
  //     },
  //   })
  // }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workShift

    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''

      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const startOfShift = workshift?.start
        ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss',
      )
      const endShift = workshift?.end
        ? mvDate.getDateFromString(workshift.end.toString()).toISOString()
        : endOfDay

      // const queryEnd = ` WHERE shift = ${
      //   workshift.value !== 99 ? workshift.value : 0
      // } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`
      const queryEndWithShift = ` WHERE ${
        workshift && workshift.value !== 99 ? `shift = ${workshift.value} AND ` : ''
      } time >= '${startOfShift}' AND time <= '${endShift}'`

      try {

        const queryStart = `SELECT * FROM `
        const buffer1Label = `vBufferF017LoadState`
        const buffer2Label = `vBufferF026LoadState`
        const buffer5Label = `vBufferF055LoadState`
        // const bufferRecap = `vStateBuffersThresholdsResults`

        const buffer1Query = queryStart + buffer1Label + queryEndWithShift
        const buffer2Query = queryStart + buffer2Label + queryEndWithShift
        const buffer5Query = queryStart + buffer5Label + queryEndWithShift
        // const bufferRecapQuery = queryStart + bufferRecap + queryEnd

        const dataBuffer1Query = API().request(`/query?${plantQueryString}q=` + buffer1Query, { signal: this.abortController.signal })
        const dataBuffer2Query = API().request(`/query?${plantQueryString}q=` + buffer2Query, { signal: this.abortController.signal })
        const dataBuffer5Query = API().request(`/query?${plantQueryString}q=` + buffer5Query, { signal: this.abortController.signal })
        // const dataBufferRecapQuery = API().request(`/query?${plantQueryString}q=` + bufferRecapQuery, { signal: this.abortController.signal })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([/* dataBufferRecapQuery, */ dataBuffer1Query, dataBuffer2Query, dataBuffer5Query]).then(
          ([/* dataBufferRecapResult, */ dataBuffer1QueryResult, dataBuffer2QueryResult, dataBuffer5QueryResult]) => {
            if (this.mounted) {
              const parsedDataBuffer1QueryResult =
                typeof dataBuffer1QueryResult === 'string' ? JSON.parse(dataBuffer1QueryResult) : dataBuffer1QueryResult
              const parsedDataBuffer2QueryResult =
                typeof dataBuffer2QueryResult === 'string' ? JSON.parse(dataBuffer2QueryResult) : dataBuffer2QueryResult
              const parsedDataBuffer5QueryResult =
                typeof dataBuffer5QueryResult === 'string' ? JSON.parse(dataBuffer5QueryResult) : dataBuffer5QueryResult
              // const dataBufferRecapResultObject =
              //   typeof dataBufferRecapResult === 'string' ? JSON.parse(dataBufferRecapResult) : dataBufferRecapResult
              const parsedDataBuffer1 = parseData(parsedDataBuffer1QueryResult)
              const parsedDataBuffer2 = parseData(parsedDataBuffer2QueryResult)
              const parsedDataBuffer5 = parseData(parsedDataBuffer5QueryResult)
              try {
                this.setState({
                  data: Object.assign({}, this.state.data, {
                    buffer1NoData: parsedDataBuffer1.length === 0,
                    buffer1: { data: parsedDataBuffer1 },
                    buffer2NoData: parsedDataBuffer2.length === 0,
                    buffer2: { data: parsedDataBuffer2 },
                    buffer5NoData: parsedDataBuffer5.length === 0,
                    buffer5: { data: parsedDataBuffer5 },
                  }),
                  fetching: false,
                  error: false,
                }, () => {
                  this.constructData()
                })
              } catch (error) {
                this.setState({
                  filteredData: [],
                  fetching: false,
                  error: false,
                })
              }
              // try {
              //   this.formatData(dataBufferRecapResultObject.results[0].series[0])
              // } catch (error) {
              //   this.setState({
              //     bufferState: {},
              //     fetching: false,
              //     error: false,
              //     data: null,
              //   })
              // }

            }
          },
        )
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          bufferState: {},
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(BufferStateView))
