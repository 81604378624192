import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ResponsiveContainer, CartesianGrid, ComposedChart, Line, LineChart, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import { TableRowFullWrapper } from '../../PrintUtilities/TableRowWrapper'
import EngineTorqueDensifier from './_EngineSpeedDensifier'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'
import { DEFAULT_STATE } from './_constants'
import { reduceWarnAreas } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/sharedV2'
import { HHHmmssFromMilliseconds } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import moment from 'moment'
import { EngineSpeedDensifierProps, EngineSpeedDensifierState } from './_types'

class EngineSpeedDensifierPrint extends EngineTorqueDensifier<EngineSpeedDensifierProps, EngineSpeedDensifierState> {
  constructor(props: EngineSpeedDensifierProps) {
    super(props, () => DEFAULT_STATE)
  }

  public renderContent(): JSX.Element {
    const { t } = this.props
    const highlightedAxisKey = 'highlighted'

    const line1TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line1, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })
    const line2TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line2, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })
    const line3TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line3, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })
    const line4TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line4, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })
    const line5TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line5, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })

    const line1ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line1, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })
    const line2ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line2, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })
    const line3ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line3, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })
    const line4ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line4, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })
    const line5ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line5, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })

    return (
      <>
        <TableRowFullWrapper>
          <h3 className={'w-100'}>{this.props.t(this.chartTranslationKeyTitle)}</h3>
        </TableRowFullWrapper>
        <TableRowFullWrapper columnClassName={'py-3'}>
          <h5 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine1')}</h5>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={this.state.filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                key={'line1'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />
              <CartesianGrid stroke="#f5f5f5" />
              <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
              {line1TorqueFrom25To78PercentArea}
              {line1ZeroTorqueArea}
              <YAxis
                domain={[0, 100]}
                orientation={'left'}
                yAxisId={'meters'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />
              <YAxis
                domain={[0, 100]}
                orientation={'right'}
                yAxisId={'rotation'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />
              <Line
                dataKey="line1Threshold"
                stroke={COLORS.lines.torque.thr}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line1Values"
                stroke={COLORS.lines.torque.line1}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line1SpeedValues"
                stroke={COLORS.lines.torque.line1Speed}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'rotation'}
              />
            </LineChart>
          </ResponsiveContainer>
          <div className="d-none d-print-inline-flex">
            <span style={{ whiteSpace: 'nowrap' }}>
              <span
                className="label-color-square mx-3"
                style={{
                  backgroundColor: COLORS.lines.torque.line1,
                }}
              />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Values')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span
                className="label-color-square mx-3"
                style={{
                  backgroundColor: COLORS.lines.torque.thr,
                }}
              />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Threshold')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span
                className="label-color-square mx-3"
                style={{
                  backgroundColor: COLORS.lines.torque.line1Speed,
                }}
              />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1SpeedValues')}</span>
            </span>
          </div>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine1') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line1')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine1') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line1')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine1') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line1')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line1?.counts['10']}
            </span>
          </div>
        </TableRowFullWrapper>
        <TableRowFullWrapper columnClassName={'py-3'}>
          <h5 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine2')}</h5>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={700}
              height={400}
              data={this.state.filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                key={'line2'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />
              <CartesianGrid stroke="#f5f5f5" />
              <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
              {line2TorqueFrom25To78PercentArea}
              {line2ZeroTorqueArea}
              <YAxis
                domain={[0, 100]}
                orientation={'left'}
                yAxisId={'meters'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />
              <YAxis
                domain={[0, 100]}
                orientation={'right'}
                yAxisId={'rotation'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />
              <Line
                dataKey="line2Threshold"
                stroke={COLORS.lines.torque.thr}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line2Values"
                stroke={COLORS.lines.torque.line2}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line2SpeedValues"
                stroke={COLORS.lines.torque.line2Speed}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'rotation'}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div className="d-none d-print-inline-flex">
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.torque.line2 }} />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line2Values')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.torque.thr }} />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line2Threshold')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span
                className="label-color-square mx-3"
                style={{
                  backgroundColor: COLORS.lines.torque.line2Speed,
                }}
              />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line2SpeedValues')}</span>
            </span>
          </div>
          <div className={'col-lg-12 col-md-12 col-sm-12'}>
            <div>
              <span className="mr-5">
                <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine2') + ': '}</b>
                {this.state.data &&
                  parseFloat(
                    this.state.data?.[this.getKeyFromLine('line2')].find((v: { key: string; value: number }) =>
                      v.key.includes('percent')
                    ).value
                  ).toFixed(2)}
              </span>
              <span className="mr-5">
                <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine2') + ': '}</b>
                {this.state.data &&
                  parseFloat(
                    this.state.data?.[this.getKeyFromLine('line2')].find((v: { key: string; value: number }) =>
                      v.key.includes('count')
                    )?.value
                  ).toFixed(0)}
              </span>
              <span className="mr-5">
                <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine2') + ': '}</b>
                {this.state.data &&
                  HHHmmssFromMilliseconds(
                    this.state.data?.[this.getKeyFromLine('line2')].find((v: { key: string; value: number }) =>
                      v.key.includes('seconds')
                    )?.value * 1000,
                    false,
                    true,
                    false
                  )}
              </span>
              <span className="mr-5">
                <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
                {this.state.highlightInfoData.line2?.counts['10']}
              </span>
            </div>
          </div>
        </TableRowFullWrapper>
        <TableRowFullWrapper columnClassName={'py-3'}>
          <h5 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine5')}</h5>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={700}
              height={400}
              data={this.state.filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                key={'line5'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />
              <CartesianGrid stroke="#f5f5f5" />
              <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
              {line5TorqueFrom25To78PercentArea}
              {line5ZeroTorqueArea}
              <YAxis
                domain={[0, 100]}
                orientation={'left'}
                yAxisId={'meters'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />
              <YAxis
                domain={[0, 100]}
                orientation={'right'}
                yAxisId={'rotation'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />

              <Line
                dataKey="line5Threshold"
                stroke={COLORS.lines.torque.thr}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line5Values"
                stroke={COLORS.lines.torque.line5}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line5SpeedValues"
                stroke={COLORS.lines.torque.line5Speed}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'rotation'}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div className="d-none d-print-inline-flex">
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.torque.line5 }} />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line5Values')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.torque.thr }} />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line5Threshold')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span
                className="label-color-square mx-3"
                style={{
                  backgroundColor: COLORS.lines.torque.line5Speed,
                }}
              />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line5SpeedValues')}</span>
            </span>
          </div>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine5') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line5')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine5') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line5')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine5') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line5')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line5?.counts['10']}
            </span>
          </div>
          <hr />
        </TableRowFullWrapper>
        <TableRowFullWrapper columnClassName={'py-3'}>
          <h5 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine3')}</h5>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={700}
              height={400}
              data={this.state.filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                key={'line3'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />
              <CartesianGrid stroke="#f5f5f5" />
              <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
              {line3TorqueFrom25To78PercentArea}
              {line3ZeroTorqueArea}
              <YAxis
                domain={[0, 100]}
                orientation={'left'}
                yAxisId={'meters'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />
              <YAxis
                domain={[0, 100]}
                orientation={'right'}
                yAxisId={'rotation'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />

              <Line
                dataKey="line3Threshold"
                stroke={COLORS.lines.torque.thr}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line3Values"
                stroke={COLORS.lines.torque.line3}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line3SpeedValues"
                stroke={COLORS.lines.torque.line3Speed}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'rotation'}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div className="d-none d-print-inline-flex">
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.torque.line3 }} />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line3Values')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.torque.thr }} />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line3Threshold')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span
                className="label-color-square mx-3"
                style={{
                  backgroundColor: COLORS.lines.torque.line3Speed,
                }}
              />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line3SpeedValues')}</span>
            </span>
          </div>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine3') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line3')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine3') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line3')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine3') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line3')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line3?.counts['10']}
            </span>
          </div>
          <hr />
        </TableRowFullWrapper>
        <TableRowFullWrapper columnClassName={'py-3'}>
          <h5 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine4')}</h5>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={700}
              height={400}
              data={this.state.filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                key={'line4'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />
              <CartesianGrid stroke="#f5f5f5" />
              <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
              {line4TorqueFrom25To78PercentArea}
              {line4ZeroTorqueArea}
              <YAxis
                domain={[0, 100]}
                orientation={'left'}
                yAxisId={'meters'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />
              <YAxis
                domain={[0, 100]}
                orientation={'right'}
                yAxisId={'rotation'}
                axisLine={false}
                tickFormatter={val => parseInt(val, 10)}
              />

              <Line
                dataKey="line4Threshold"
                stroke={COLORS.lines.torque.thr}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line4Values"
                stroke={COLORS.lines.torque.line4}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meters'}
              />
              <Line
                dataKey="line4SpeedValues"
                stroke={COLORS.lines.torque.line4Speed}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'rotation'}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div className="d-none d-print-inline-flex">
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.torque.line4 }} />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line4Values')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.torque.thr }} />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line4Threshold')}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span
                className="label-color-square mx-3"
                style={{
                  backgroundColor: COLORS.lines.torque.line4Speed,
                }}
              />
              <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line4SpeedValues')}</span>
            </span>
          </div>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine4') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line4')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine4') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line4')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine4') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line4')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line4?.counts['10']}
            </span>
          </div>
          <hr />
        </TableRowFullWrapper>
      </>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(EngineSpeedDensifierPrint))
