import { API } from "."
import { Dispatch } from 'redux'

export interface UserRoleWithId {
  id: string
  name: string
}

export const getUserRoles = () => async (
  dispatch: Dispatch
): Promise<UserRoleWithId[]> => {
  return API()
    .request(`/manage/auth/roles/full`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
    .then((result:any) => result)
}