import React, { useEffect, useState } from 'react'
import useGenericFetchHook, { useLazyGenericFetchHook } from '../../../../../inplant-components-fe/mvfunctions/hooks/useGenericFetchHook'
import { useComponentsTranslation } from '../../../../../inplant-components-fe/services/translation'
import { getResetPassword } from '../../../../redux/actions/resetPassword'
import { getUserRoles, UserRoleWithId } from '../../../../redux/actions/userRoles'
import { getPlants } from '../../../../redux/actions/getPlant'
import { getPasswordControlByConfiguration } from '../../../../functions/controlsHelpers'
import { CenterLoader, Checkbox, Flex, StretchColumn } from '../../../../../mvlabs-components-fe/ui/components'
import Button from '../../../../../inplant-components-fe/ui/components/MVButtons/Button'
import Input, { MultiSelect } from '../../../../../inplant-components-fe/ui/components/MVInput/Input'
import { useStateValidity } from '../../../../../inplant-components-fe/mvfunctions/hooks/useGenericInputHook'
import { BreadcrumbsTitle } from '../../../../../inplant-core-fe'
import { Direction } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import VidimWrapper from '../../../../../inplant-core-fe/ui/components/widgets/VidimWrapper/VidimWrapperHOC'
import { manageRequestErrors } from '../../../../../inplant-core-fe/functions'
import { showNotification } from '../../../../../mvlabs-components-fe/ui/components/Notification/Notification'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DetailUser, getUser, updateUser } from '../../../../redux/actions/user'
import { getSlugByIndex } from '../../../../../mvlabs-components-fe/functions/getSlug'
import { PasswordOptionType, PlantOptionType } from '../../../../types/options'
import { consoleLog } from '../../../../../mvlabs-components-fe/functions/logs'

export interface UserUpdateFormData {
  name?: string
  surname?: string
  email?: string
  roles: string[]
  plants: string[]
  enabled: boolean
  password?: string
  confirmPassword?: string
  username: string
  displayName: string
}

const ManageUserPageView = () => {
  const { t } = useComponentsTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const base = 'user.createUser'
  const baseF = 'user.createUser.form'

  const [isLoading, setIsLoading] = useState(false)

  const [dataValidity, addDataValidity, isValid] = useStateValidity({})
  const [formData, setFormData] = useState<UserUpdateFormData>({
    confirmPassword: '',
    password: undefined,
    username: '',
    email: undefined,
    name: undefined,
    plants: [],
    roles: [],
    surname: undefined,
    displayName: '',
    enabled: false,
  })

  const [errorToShow, setErrorToShow] = useState<{ error: string | undefined }>({ error: undefined })
  useEffect(() => {
    if (formData.password !== formData.confirmPassword) {
      setErrorToShow({
        error: t(`user.noMatchConfirmPassword`),
      })
    } else {
      setErrorToShow({
        error: undefined,
      })
    }
  }, [formData.password, formData.confirmPassword])

  const [updateUserHook,] = useLazyGenericFetchHook(updateUser, 
    data => {
      setIsLoading(false)
      showNotification({
        type: 'success',
        title: t(`${baseF}.updateSuccess`)
      })
      history.push('/user/list')
    }, 
    error => {
      setIsLoading(false)
      manageRequestErrors(error, dispatch)
  })

  const passwordOptionHook = useGenericFetchHook<PasswordOptionType>(getResetPassword)

  const userRolesHook = useGenericFetchHook<UserRoleWithId[]>(getUserRoles)

  const plantsHook = useGenericFetchHook<PlantOptionType[]>(getPlants)
  
  const getUserHook = useGenericFetchHook<DetailUser, [string]>((id) => getUser(id), true, getSlugByIndex(3)) 

  useEffect(() => {
    if(getUserHook.state.kind === 'success') {
      setFormData({
        surname: getUserHook.state.data.surname,
        displayName: getUserHook.state.data.displayName,
        enabled: getUserHook.state.data.enabled,
        plants: getUserHook.state.data.plants.map(p => p.id),
        roles: getUserHook.state.data.roles.map(r => r.id),
        username: getUserHook.state.data.username,
        email: getUserHook.state.data.email,
        name: getUserHook.state.data.name
      })
    }
  }, [getUserHook.state.kind])


  function submitUser(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setIsLoading(true)
    updateUserHook(getSlugByIndex(3),formData)
  }

  function renderSuccessContent(passwordOption: PasswordOptionType, userRoles: UserRoleWithId[], plant: PlantOptionType[], user: DetailUser) {
    consoleLog('dataValidity',dataValidity)
    return (
      <div className="inplant-user inplant-user-form">
        <StretchColumn>
          <BreadcrumbsTitle level={1} title={t(`${base}.updateTitle`)} />
          <form onSubmit={submitUser}>
            <StretchColumn>
              {/* <Alert text={t(`${base}.info`)} variant={'info'} /> */}
              <Flex spaceSize={'lg'} >
                <Input
                  label={t(`${baseF}.name`)}
                  initialValue={user.name}
                  type={'text'}
                  kind={'input'}
                  onChange={value => setFormData(prev => ({ ...prev, name: value }))}
                  onChangeState={state => addDataValidity('name', state)}
                  grow={1}
                />
                <Input
                  initialValue={user.surname}
                  label={t(`${baseF}.surname`)}
                  type={'text'}
                  kind={'input'}
                  onChange={value => setFormData(prev => ({ ...prev, surname: value }))}
                  onChangeState={state => addDataValidity('surname', state)}
                  grow={1}
                />
              </Flex>
              <Flex spaceSize={'lg'} >
                <Input
                  initialValue={user.displayName}
                  label={t(`${baseF}.displayName`)}
                  type={'text'}
                  kind={'input'}
                  onChange={value => setFormData(prev => ({ ...prev, displayName: value }))}
                  required={true}
                  onChangeState={state => addDataValidity('displayName', state)}
                  grow={1}
                />
                <Input
                  initialValue={user.email}
                  label={t(`${baseF}.email`)}
                  type={'text'}
                  kind={'input'}
                  required={true}
                  onChange={value => setFormData(prev => ({ ...prev, email: value }))}
                  onChangeState={state => addDataValidity('email', state)}
                  grow={1}
                />
              </Flex>
              <Flex spaceSize={'lg'} >
                <Input
                  label={t(`${baseF}.username`)}
                  initialValue={user.username}
                  type={'text'}
                  kind={'input'}
                  autocomplete={'off'}
                  onChange={value => setFormData(prev => ({ ...prev, username: value }))}
                  required={true}
                  onChangeState={state => addDataValidity('username', state)}
                  grow={1}
                />
                <Input
                  grow={1}
                  kind={'input'}
                  type={'password'}
                  autocomplete={'off'}
                  id={'field2'}
                  label={t(`${baseF}.password`)}
                  controls={getPasswordControlByConfiguration(passwordOption.controls, t, false, formData.password)}
                  onChangeState={state => addDataValidity('newPassord', state)}
                  onChange={value => setFormData(prev => ({ ...prev, password: value }))}
                />
              </Flex>
              <Flex spaceSize={'lg'} >
                <Input
                  shrink={0}
                  grow={0}
                  basis={50}
                  kind={'input'}
                  autocomplete={'off'}
                  type={'password'}
                  label={t(`${baseF}.passwordConfirm`)}
                  onChangeState={state => addDataValidity('newPassworConfirm', state)}
                  onChange={value => setFormData(prev => ({ ...prev, confirmPassword: value }))}
                  error={errorToShow.error}
                />
                <Checkbox
                  items={[
                    {
                      value: 'enable',
                      label: t(`${baseF}.enable`),
                      initialSelected: formData.enabled
                    },
                  ]}
                  itemsDirection={Direction.row}
                  onChange={value => setFormData(prev => ({...prev, enabled: value[0].selected}))}
                />
              </Flex>
              <Flex spaceSize={'lg'} >
                <MultiSelect 
                  options={{
                    items: userRoles.map(r => ({label: r.name, value: r.id, selected: user.roles.find(role => role.id === r.id) ? true : undefined}))
                  }}
                  required={true}
                  grow={1}
                  label={t(`${baseF}.roles`)}
                  onChange={value => setFormData(prev => ({...prev, roles: value.filter(v => v.selected).map(v => v.value)}))}
                  onChangeState={state => addDataValidity('newPassworConfirm', state)}
                />
                <MultiSelect
                  options={{
                    items: plant.map(p => ({label: p.name, value: p.id, selected: user.plants.find(pl => pl.id === p.id) ? true : undefined}) )
                  }}
                  required={true}
                  grow={1}
                  label={t(`${baseF}.plants`)}
                  onChange={value => {
                    setFormData(prev => ({...prev, plants: value.filter(v => v.selected).map(v => v.value)}))}
                  }
                  onChangeState={state => addDataValidity('plants', state)}
                />
              </Flex>
              <Button
                isDisable={isLoading || !isValid || errorToShow.error !== undefined}
                isLoading={isLoading}
                type={'submit'}
                semantic={'primary'}
                alignSelf={AlignSelf.end}
                shrink={1}
                label={t(`${baseF}.update`)}
              />
            </StretchColumn>
          </form>
        </StretchColumn>
      </div>
    )
  }

  function renderContent() {
    if (
      passwordOptionHook.state.kind === 'success' &&
      userRolesHook.state.kind === 'success' &&
      getUserHook.state.kind === 'success' &&
      plantsHook.state.kind === 'success' 
    ) {
      return renderSuccessContent(passwordOptionHook.state.data, userRolesHook.state.data, plantsHook.state.data, getUserHook.state.data)
    }
    if (
      passwordOptionHook.state.kind === 'error' ||
      userRolesHook.state.kind === 'error' ||
      plantsHook.state.kind === 'error' || 
      getUserHook.state.kind === 'error'
    ) {
      return null
    }
    return <CenterLoader />
  }

  return renderContent()
}

export default VidimWrapper(ManageUserPageView) 
