import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faChartBar } from '@fortawesome/free-solid-svg-icons'
import { ReferenceArea } from 'recharts'
import { AreaSection } from '../types/measure'
import * as moment from 'moment'
import { isMoment } from 'moment'
import PieSmallView from '../ui/components/widgets/charts/PieSmall/PieSmallView'
import { DurationInputArg2 } from 'moment'
import { TFunction } from 'i18next'
import { TableRowFullWrapper } from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import { TableColumnWrapperWithCondition } from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'

export const Loader = () => (
  <div key={Date.now()} className="float-right data-loading">
    <FontAwesomeIcon icon={faCircleNotch} size="2x" spin={true} />
  </div>
)

export const reduceWarnAreas = (warnPoints: AreaSection[], yAxisId?: string, color?: string, opacity?: number) => {
  const warnSegments: any[] = []

  if (warnPoints && warnPoints.length > 0) {
    warnPoints.forEach((e: any) => {
      if (warnSegments.length < 1 || warnSegments[warnSegments.length - 1].x2 !== e.x1) {
        warnSegments.push(e)
      } else {
        warnSegments[warnSegments.length - 1].x2 = e.x2
      }
    })

    return warnSegments.map((e: any) => {
      return (
        <ReferenceArea
          key={e.x1}
          x1={e.x1}
          x2={e.x2}
          stroke={color || '#FF9893'}
          fill={color || '#FF9893'}
          strokeWidth={1}
          fillOpacity={opacity || 0.5}
          strokeOpacity={opacity || 0.5}
          yAxisId={yAxisId}
        />
      )
    })
  }

  return null
}

export const getAreaSections = (data: any, key: string) => {
  const out: AreaSection[] = []
  let prevKey: null | string = null
  let lastTime: null | string = null

  data.map((e: any) => {
    if (e.hasOwnProperty(key)) {
      if (prevKey === null) {
        out.push({ x1: e.time, x2: null, y1: 0, y2: 100, value: e[key] })
      } else {
        out[out.length - 1].x2 = e.time
        out.push({ x1: e.time, x2: null, y1: 0, y2: 100, value: e[key] })
      }

      prevKey = e[key]
      lastTime = e.time
    }
  })

  if (out.length > 0 && out[out.length - 1].x2 === null) {
    out[out.length - 1].x2 = lastTime ? lastTime + 60 : 60
  }

  return out
}

export const getAreaPauses = (data: Array<{ start: number; end: number }>) => {
  const out: AreaSection[] = []

  data.map((e: any) => {
    out.push({ x1: e.start, x2: e.end, y1: 0, y2: 100, value: 'pause' })
  })

  return out
}

export const getGeneralArea = (data: Array<{ start: number; end: number }>,valueKey: string = 'pause', timeBound?: [number, number]) => {
  const out: AreaSection[] = []

  data.map((e: any) => {
    const finalStart = timeBound?.[0] && e.start < timeBound?.[0] ? timeBound?.[0] : e.start 
    const finalEnd = timeBound?.[1] && e.end > timeBound?.[1] ? timeBound?.[1] : e.end
    // const finalStart = e.start 
    // const finalEnd = e.end
    out.push({ x1: finalStart, x2: finalEnd, y1: 0, y2: 100, value: valueKey })
  })

  return out
}


export const RowLegend = (title: string, value?: string | number, supTitle?: any, subTitle?: any) => {
  return (
    <div className={'row-text-legend'}>
      {supTitle ? <div className={'sup-title'}>{supTitle}</div> : null}
      <div className={'title'}>
        {title}
        <br />
        {value && <span className="big">{value}</span>}
      </div>
      {subTitle ? <div className={'sub-title'}>{subTitle}</div> : null}
    </div>
  )
}

export const toggleArrayItem = (id: string, currentActive: string[]) => {
  const exists = currentActive && currentActive.indexOf(id) > -1

  if (exists) {
    currentActive.splice(currentActive.indexOf(id), 1)
  } else {
    currentActive.push(id)
  }
}

export const getWorkingMode = (man: number, auto: number, loc: number): number | undefined => {
  if (loc === 1) {
    return 2
  } else {
    if (man === 1) {
      return 0
    } else if (auto === 1) {
      return 1
    }

    if (man === 0 && auto === 0 && loc === 0) {
      // sezionato
      return 3
    }

    return undefined
  }
}

export function getForwardBackward(forward: number, backward: number): number | undefined {
  if (forward) {
    return 1
  }
  if (backward) {
    return 0
  }
  return undefined
}

export const populateSingleData = (key: string, state: any, isBoolean: boolean = false, multiplier?: number) => {
  const stateData = { ...state }

  if (stateData && stateData[key] && stateData[key].data) {
    return stateData[key].data.map((datum: any) => {
      const time = moment(datum[0]).unix()

      if (stateData[key].min === 0 || time < stateData[key].min) {
        stateData[key].min = time
      }
      if (time > stateData[key].max) {
        stateData[key].max = time
      }

      return {
        x: time,
        y: isBoolean ? (datum[1] ? 1 : 0) : multiplier ? datum[1] * multiplier : datum[1],
        h100: 100,
      }
    })
  }

  return [{}]
}

export const NullPie = () => (
  <div style={{ opacity: 0.3 }}>
    <PieSmallView
      data={[
        { name: 'true', value: 0 },
        { name: 'false', value: 100 },
      ]}
      colors={[
        { id: 'true', color: '#ddd' },
        { id: 'false', color: '#ddd' },
      ]}
    />
  </div>
)

export const getInfluxPercents = (dataSrc: any, isBoolean: boolean = true) => {
  if (dataSrc && dataSrc.values) {
    const data = restoreTimeShift(dataSrc.values, isBoolean)
    // UNUSED const out = { true: 0, false: 0 }

    if (isBoolean) {
      const out = { true: 0, false: 0 }

      data.map((e: any) => {
        if (e[2] === true) {
          out.true += e[1]
        } else if (e[2] === false) {
          out.false += e[1]
        }
      })

      if (out.true + out.false > 0) {
        out.true = (out.true / (out.true + out.false)) * 100
        out.false = 100 - out.true
      } else {
        return false
      }

      return out
    } else {
      const out = { true: 0, false: 0 }

      data.map((e: any) => {
        if (e[2] > 0) {
          out.true += e[1]
        } else if (e[2] === 0) {
          out.false += e[1]
        }
      })

      if (out.true + out.false > 0) {
        out.true = (out.true / (out.true + out.false)) * 100
        out.false = 100 - out.true
      } else {
        return false
      }

      return out
    }
  }

  return false
}

export const restoreTimeShift = (data: any, isBoolean: boolean) => {
  const dataCopy: Array<{ [k: string]: number }> = JSON.parse(JSON.stringify(data))

  data.map((item: any, index: number) => {
    if (index > 0) {
      dataCopy[index - 1][2] = item[2]
    }
  })

  if (dataCopy.length > 0) {
    dataCopy.pop()

    return dataCopy
  }

  return []
}

export const parseInfluxResponseData = (dataSrc: any) => {
  const data = JSON.parse(dataSrc)

  if (data && data && data.results && data.results[0] && data.results[0].series) {
    return data.results[0].series
  }

  return []
}

export const getInfluxSeries = (data: any, name: string) => {
  if (data) {
    return data.filter((s: any) => s.name.indexOf(name) === 0)
  }

  return []
}

export const getInfluxMultiSeries = (data: any, name: string | string[]) => {
  const out: any[] = []
  if (data && data.length > 0) {
    data.forEach((serie: any) => {
      if (serie && serie[0]) {
        if (serie[0].columns.includes(name)) {
          // column exists in this serie
          const index = serie[0].columns.findIndex((c: string) => c === name)
          if (index !== -1) {
            const summedValues = serie[0].values.reduce((acc: any, cur: any) => {
              return acc + cur[index]
            }, 0)
            const duration = serie[0].values.reduce((acc: any, cur: any) => {
              return acc + cur[index + 1]
            }, 0)
            const remainingTime = serie[0].values.reduce((acc: any, cur: any) => {
              return acc + cur[index + 2]
            }, 0)
            out.push({
              name: serie[0].name,
              value: summedValues, // serie[0].values[0][index],
              duration,
              remainingTime,
            })
          }
        }
      }
    })
  }

  return out
}

export const isJSON = (str: any): boolean => {
  if (typeof str === 'object') {
    return str
  }

  try {
    return JSON.parse(str) && !!str
  } catch (e) {
    return false
  }
}

export const timeToSeconds = (timeString: string): number => {
  if (!timeString) {
    return 0
  }
  const timeData = timeString.split(':')

  return (
    (timeData[0] ? +timeData[0] : 0) * 60 * 60 +
    (timeData[1] ? +timeData[1] : 0) * 60 +
    (timeData[2] ? +timeData[2] : 0)
  )
}

export const momentRoundTime = (
  value: string | number | moment.Moment,
  to: 'second' | 'minute' | 'hour'
): moment.Moment => {
  let subUnit: DurationInputArg2 = 'minutes'

  const timeMoment: moment.Moment =
    typeof value === 'string' ? moment(value) : typeof value === 'number' ? moment.utc(value) : value

  switch (to) {
    case 'hour':
      subUnit = 'minutes'
      break

    case 'minute':
      subUnit = 'seconds'
      break

    case 'second':
      subUnit = 'milliseconds'
      break

    default:
      subUnit = 'seconds'
      break
  }

  return timeMoment.add(30, subUnit).startOf(to)
}

export const secondsDiff = (a: number, b: number, roundToMinutes: boolean = false): number => {
  return (
    Math.round(a / (roundToMinutes ? 60 : 1)) * (roundToMinutes ? 60 : 1) -
    Math.round(b / (roundToMinutes ? 60 : 1)) * (roundToMinutes ? 60 : 1)
  )
}

export const secondsToHMS = (
  secNumSrc: number | moment.Moment,
  hideSecond = false,
  zeroPadding: boolean = true
): string => {
  if (undefined === secNumSrc) {
    return ''
  }

  const momentObj = typeof secNumSrc === 'number' ? moment.utc(Math.abs(secNumSrc) * 1000) : secNumSrc

  return (
    (secNumSrc < 0 ? '-' : '') +
    (hideSecond ? momentObj.add(30, 'seconds').startOf('minute') : momentObj).format(
      `${zeroPadding ? 'HH' : 'H'}:${zeroPadding ? 'mm' : 'm'}${hideSecond ? '' : ':' + (zeroPadding ? 'ss' : 's')}`
    )
  )
}

export const getCutoff = (
  cutoff: string,
  startDateSrc: string,
  utc?: boolean
): false | { startDate: string; endDate: string } => {
  if (cutoff) {
    const cutOffHourParts = cutoff.split(':')
    const cutOffStartH = parseInt(cutOffHourParts[0], 10)
    const cutOffStartM = parseInt(cutOffHourParts[1], 10)

    const startDateObj = moment(startDateSrc)
    startDateObj.set({ hour: cutOffStartH, minute: cutOffStartM, second: 0, millisecond: 0 })

    const endDateObj = startDateObj
      .clone()
      .add(1, 'days')
      .subtract(1, 'minute')

    if (utc) {
      startDateObj.utc()
      endDateObj.utc()
    }

    const startDate = startDateObj.format('YYYY-MM-DD HH:mm:ss').toString()
    const endDate = endDateObj.format('YYYY-MM-DD HH:mm:ss').toString()

    return {
      startDate,
      endDate,
    }
  }

  return false
}

export const ChartPlaceholder = (title: string) => (
  <TableRowFullWrapper>
    <div className="card text-center chart-invalid-date-selection" title={title}>
      <div className="card-body">
        <div className="alert alert-secondary w-100 mx-auto alert-local">
          <FontAwesomeIcon icon={faChartBar} />
          <p>{title}</p>
        </div>
      </div>
    </div>
  </TableRowFullWrapper>
)

export const getMoment = (value: string | moment.Moment) => {
  if (moment.isMoment(value)) {
    return value
  } else {
    return moment(value) // @todo utc?
  }
}
/**
 * Get formatted string HHH:mm:ss from milliseconds diff
 * Reason: MomentJs resets HH after 24
 */
export const HHHmmssFromMilliseconds = (value: number, showLabels = false, showSeconds = false, padStart = false) => {
  const sign = value >= 0 ? '' : '-'
  const d = moment.duration(Math.abs(value))
  const m = moment.utc(Math.abs(value))

  return (
    sign +
    String(Math.floor(d.asHours())).padStart(padStart ? 2 : 0, '0') +
    (showLabels ? 'h' : '') +
    m.format(':mm') +
    (showLabels ? 'm' : '') +
    (showSeconds ? m.format(':ss') + (showLabels ? 's' : '') : '')
  )
}

export const HoursFromMilliseconds = (value: number) => {
  const d = moment.duration(value)
  const hours = d.asHours()
  return hours
}

interface HaltsCalculatedData {
  key: string
  events: number
  duration: number
  perc: number
}

export const getHaltsData = (
  data: { values: Array<Array<number | string>>; columns: string[] },
  days = 1,
  hideZero = false
): HaltsCalculatedData[] => {
  const values: HaltsCalculatedData[] = []
  let multipleDays = false

  data.values.forEach(row => {
    const columnsInserted: string[] = []
    data.columns.forEach(column => {
      if (column !== 'time' && column !== 'dummy' && column.indexOf('_') > -1) {
        const key = column.substr(0, column.lastIndexOf('_'))

        if (!columnsInserted.includes(key)) {
          const valueIndex = values.findIndex(f => {
            return f.key === key
          })

          if (!hideZero || row[data.columns.indexOf(key + '_events')]) {
            if (valueIndex === -1) {
              // not existing, push
              values.push({
                key,
                events: row[data.columns.indexOf(key + '_events')] as number,
                duration: row[data.columns.indexOf(key + '_duration')] as number,
                perc: (row[data.columns.indexOf(key + '_perc')] as number) * 100,
              })
            } else {
              // existing, sum
              multipleDays = true
              values[valueIndex] = {
                ...values[valueIndex],
                events: (values[valueIndex].events as number) + (row[data.columns.indexOf(key + '_events')] as number),
                duration:
                  (values[valueIndex].duration as number) + (row[data.columns.indexOf(key + '_duration')] as number),
                perc: 0, // resetting perc, we will recalc later
              }
            }
          }

          columnsInserted.push(key)
        }
      }
    })
  })

  /**
   * If there are multiple entries we need to recalculate percs (we cannot use sum o mean values)
   */
  if (multipleDays) {
    const totalDuration = values.reduce((acc, curr) => acc + curr.duration, 0)
    values.forEach(v => {
      v.perc = (100 * v.duration) / totalDuration
    })
  }

  return values
}

export const getHaltsDataRecord = (
  data: { values: Array<Array<number | string>>; columns: string[]; name: string },
  days = 1,
  hideZero = false
): Record<string, HaltsCalculatedData[]> => {
  const values: Record<string, HaltsCalculatedData[]> = {}
  let multipleDays = false

  data.values.forEach(row => {
    const columnsInserted: string[] = []
    data.columns.forEach(column => {
      if (column !== 'time' && column !== 'dummy' && column.indexOf('_') > -1) {
        const key = column.substr(0, column.lastIndexOf('_'))

        if (!columnsInserted.includes(key)) {
          const valueIndex = Object.entries(values).find(([objectKey, value]) => value.find(v => v.key === key))
          // const valueIndex = values.findIndex(f => {
          //   return f.key === key
          // })

          if (!hideZero || row[data.columns.indexOf(key + '_events')]) {
            if (valueIndex === undefined) {
              // not existing, push
              values[data.name].push({
                key,
                events: row[data.columns.indexOf(key + '_events')] as number,
                duration: row[data.columns.indexOf(key + '_duration')] as number,
                perc: (row[data.columns.indexOf(key + '_perc')] as number) * 100,
              })
            } else {
              // existing, sum
              const eventsCount =
                values[valueIndex?.[0]].reduce((acc, curr) => acc + curr.events, 0) +
                (row[data.columns.indexOf(key + '_events')] as number)
              const durationCount =
                values[valueIndex?.[0]].reduce((acc, curr) => acc + curr.duration, 0) +
                (row[data.columns.indexOf(key + '_duration')] as number)
              multipleDays = true
              values[valueIndex?.[0]] = [
                {
                  ...values[valueIndex?.[0]][0],
                  events: eventsCount,
                  duration: durationCount,
                  perc: 0, // resetting perc, we will recalc later
                },
              ]
            }
          }

          columnsInserted.push(key)
        }
      }
    })
  })

  /**
   * If there are multiple entries we need to recalculate percs (we cannot use sum o mean values)
   */
  if (multipleDays) {
    const totalDuration = Object.entries(values).reduce((acc, curr) => acc + curr[1][0].duration, 0)
    Object.entries(values).forEach(([key, value]) => {
      values[key][0].perc = (100 * value[0].duration) / totalDuration
    })
  }

  return values
}

export const startOfTheDay = (m: moment.Moment | string) => {
  return (isMoment(m) ? m : moment(m))
    .utc()
    .set({ h: 0, m: 0, s: 0 })
    .format('YYYY-MM-DD HH:mm:ss')
    .toString()
}

export const endOfTheDay = (m: moment.Moment | string) => {
  return (isMoment(m) ? m : moment(m))
    .utc()
    .set({ h: 23, m: 59, s: 59 })
    .format('YYYY-MM-DD HH:mm:ss')
    .toString()
}

export const parseInfluxResponse = (response: string): Array<{ name: string; data: any[] }> => {
  const data: false | InfluxResponse = isJSON(response) && JSON.parse(response)
  if (data && data.results && data.results[0].series) {
    return data.results[0].series.map((s, si) => {
      return {
        name: s.name,
        data: s.values.map(sv => {
          const out = {}
          s.columns.map((c, ci) => {
            out[c] = sv[ci]
          })
          return out
        }),
      }
    })
  }

  return []
}

export interface InfluxResponse {
  results: Array<{
    statement_id: number
    series?: InfluxSeries[]
  }>
}

export interface InfluxSeries {
  name: string
  columns: string[]
  values: Array<string | number>
}

export const ChartStatusBadges = (
  id: string,
  isLoading: boolean,
  fetchErrors: boolean,
  noData: boolean,
  t: TFunction
) => {
  return (
    <>
      {noData && (
        <div className={'alert alert-warning w-100 col-sm-6 mx-auto alert-local ' + id}>
          {t('plantAnalysis.noDataAvailable')}
        </div>
      )}
      {isLoading && (
        <div className={'alert alert-secondary w-100 col-sm-6 mx-auto alert-local ' + id}>
          {t('plantAnalysis.loading')}
          <Loader />
        </div>
      )}
      {fetchErrors && (
        <div className={'alert alert-danger w-100 col-sm-6 mx-auto alert-local ' + id}>
          {t('plantAnalysis.fetchErrors')}
        </div>
      )}
    </>
  )
}
export const ChartStatusBadgesPrint = (
  id: string,
  isLoading: boolean,
  fetchErrors: boolean,
  noData: boolean,
  t: TFunction
) => {
  return (
    <>
      <TableColumnWrapperWithCondition col={'full'} condition={noData}>
        <div className={'alert alert-warning w-100 col-sm-6 mx-auto alert-local ' + id}>
          {t('plantAnalysis.noDataAvailable')}
        </div>
      </TableColumnWrapperWithCondition>
      <TableColumnWrapperWithCondition col={'full'} condition={isLoading}>
        <div className={'alert alert-secondary w-100 col-sm-6 mx-auto alert-local ' + id}>
          {t('plantAnalysis.loading')}
          <Loader />
        </div>
      </TableColumnWrapperWithCondition>
      <TableColumnWrapperWithCondition col={'full'} condition={fetchErrors}>
        <div className={'alert alert-danger w-100 col-sm-6 mx-auto alert-local ' + id}>
          {t('plantAnalysis.fetchErrors')}
        </div>
      </TableColumnWrapperWithCondition>
    </>
  )
}
