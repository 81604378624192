import {
  InfluxResponseToParse,
  parseInfluxResponse,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx'
import { IInfluxResponse, InfluxMetricQueryParams } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/influx'

const vecoplanMeasuresToFetch: Array<InfluxMetricQueryParams & InfluxResponseToParse> = [
  {
    type: 'standard',
    id: 'IBSGN5PHK0L9NEFM_RAM_FEED_PERCENT',
    aggregate: true,
    slug: "thrustPresser",
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'standard' }),
  },
  {
    type: 'standard',
    id: 'IBSGN5PHK0L9NEFM_DRIVE1_SPEED_PERCENT_calc',
    aggregate: true,
    slug: "speedPercent",
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'standard' }),
  },
  {
    type: 'summary',
    id: 'vVecoplan',
    slug: "recapData",
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'summary' }),
  },
]

export { vecoplanMeasuresToFetch }
