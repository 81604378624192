import * as React from 'react'
import { Redirect, Route, withRouter, RouteComponentProps, RouteProps } from 'react-router'
import { connect } from 'react-redux'

interface StateProps {
  loggedIn: boolean
}

interface OwnProps extends RouteComponentProps<any> {
  failPath?: string
}

const mapStateToProps = (state: any): StateProps => ({
  loggedIn: state.auth.loggedIn,
})

type Props = RouteProps & StateProps & OwnProps

/**
 * Protect access to unauthenticated user.
 *
 * If not logged in the app redirect you on login saving this location inside search
 *
 * @param {Props} props
 * @returns {JSX.Element} jsxElement
 */
function PrivateRoute(props: Props): JSX.Element {
  if (props.loggedIn) {
    return <Route {...props} />
  }
  return (
    <Redirect
      to={{
        pathname: props.failPath || '/',
        search: `redirect=${props.location.pathname}${props.location.search}`,
        state: { from: props.location },
      }}
    />
  )
}

export default withRouter(connect(mapStateToProps)(PrivateRoute))
