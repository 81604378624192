import {
  AssetDetail, AssetDocumentsValidationPayload,
  AssetForm, AssetFormStatus, AssetSubsititutionForm, AssetTask,
  AssetUpdate,
  AssetValidation, AssetValidateActionAvailabilty, RequestDisableAssetForm, AssetTaskForCopy, CopyTasksBody,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { API } from '@mv-submodules/inplant-asset-manager-fe/redux/actions/index'
import {
  FetchConfigurationTasksStatusResponse, FetchUploadDocumentSuccess,
} from '@mv-submodules/inplant-asset-manager-fe/types/fetchData'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'

export const fetchCreateAsset = (asset: AssetForm) => {
  const query = '/assets/create'
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(asset),
  })
}

export const fetchDetailAsset = async (assetId: string) => {
  const query = `/assets/${assetId}`
  const result: AssetDetail = await API().request(query, {
    method: 'GET',
  })
  return result
}

export const fetchAssetTask = async (assetId: string): Promise<AssetTask[]> => {
  const query = `/assets/tasks/${assetId}`
  const result: AssetTask[] = await API().request(query, {
    method: 'GET',
  })
  return result
}

export const fetchAssetsTaskForCopy = (assetId: string): Promise<AssetTaskForCopy[]> => {
  const query = `/assets/tasks-for-copy/${assetId}`
  return API().request(query, {
    method: 'GET',
  })
    .then((data: AssetTaskForCopy[]) => data)
    .catch((error: FetchError) => {
      throw error
    })
}

export const copyTasksToAssets = (assetId: string, data: CopyTasksBody): Promise<void> => {
  const query = `/assets/copy-tasks-to-assets/${assetId}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const fetchAssetConfigurationTaskStatus = async (assetId: string): Promise<FetchConfigurationTasksStatusResponse> => {
  const query = `/assets/configuration-tasks/${assetId}`
  const result: FetchConfigurationTasksStatusResponse = await API().request(query)
  return result
}

export const fetchValidateAsset = (asset: AssetValidation, id: string) => {
  const query = `/assets/validate-creation/${id}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(asset),
  })
}

export const validateAssetDocuments = (asset: AssetDocumentsValidationPayload, id: string) => {
  const query = `/assets/validate-documents/${id}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(asset),
  })
}

export const deactivateAsset = (assetId: string, data: RequestDisableAssetForm): Promise<void> => {
  const query = `/assets/deactivate/${assetId}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const validateAssetDeactivation = (assetId: string, note: string): Promise<void> => {
  const query = `/assets/validate-deactivation/${assetId}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ note }),
  })
}

export const fetchUpdateAsset = (asset: AssetUpdate, id: string) => {
  const query = `/assets/update/${id}`
  return API().request(query, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(asset),
  })
}

export const fetchUploadFIle = async (file: File) => {
  const query = '/files/upload'
  const formData: FormData = new FormData()
  formData.append('file', file)
  const result: FetchUploadDocumentSuccess = await API().request(query, {
    method: 'POST',
    body: formData,
  })
  return result
}

export const addNoteToAsset = (text: string, id: string) => {
  const query = `/assets/add-note/${id}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text }),
  })
}

export const updateAssetMachineCode = (machineCode: string, id: string) => {
  const query = `/assets/update-asset-code/${id}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ assetCode: machineCode }),
  })
}

export const replaceAsset = (assetSubstitutionData: AssetSubsititutionForm, id: string) => {
  const query = `/assets/replace/${id}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(assetSubstitutionData),
  })
}

export const validateReplaceAsset = (asset: AssetValidation, id: string) => {
  const query = `/assets/validate-replace/${id}`
  return API().request(query, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(asset),
  })
}

export const checkDeactivationAvailability = (id: string): Promise<AssetValidateActionAvailabilty> => {
  const query = `/assets/check-deactivation-availability/${id}`
  return API().request(query)
}

export const checkActivationAvailability = (id: string): Promise<AssetValidateActionAvailabilty> => {
  const query = `/assets/check-activation-availability/${id}`
  return API().request(query)
}

export const getAssetFormRules = (name: string, action: string, status: AssetFormStatus): boolean | undefined => {
  const inputRules: {} = {
    temporaryPlantCode: {
      create: {
        visible: true,
      },
    },
    assetCode: {
      create: {
        disabled: true,
        readOnly: true,
        required: false,
      },
      validate: {
        disabled: false,
        readOnly: false,
        required: true,
      },
      'validate-replacing-with-deactivated-asset': {
        disabled: true,
        readOnly: true,
        required: true,
      },
      'validate-replacing-with-new-asset': {
        disabled: false,
        readOnly: false,
        required: true,
      },
      update: {
        disabled: true,
        readOnly: true,
        required: false,
      },
      'substitution-new-asset': {
        disabled: true,
        readOnly: true,
        required: false,
      },
      'substitution-with-deactivated': {
        disabled: true,
        readOnly: true,
        required: false,
      },
      'documents-to-validate': {
        disabled: true,
        readOnly: true,
        required: false,
      },
    },
    siteCode: {
      create: {
        disabled: true,
      },
      validate: {
        disabled: true,
      },
      'validate-replacing-with-deactivated-asset': {
        disabled: true,
      },
      'validate-replacing-with-new-asset': {
        disabled: true,
      },
      update: {
        disabled: true,
      },
      'substitution-new-asset': {
        disabled: true,
      },
      'substitution-with-deactivated': {
        disabled: true,
      },
      'documents-to-validate': {
        disabled: true,
      },
    },
    activationDate: {
      validate: {
        disabled: true,
        readOnly: true,
      },
      'validate-replacing-with-deactivated-asset': {
        disabled: true,
        readOnly: true,
      },
      'validate-replacing-with-new-asset': {
        disabled: true,
        readOnly: true,
      },
      update: {
        disabled: true,
        readOnly: true,
      },
      'documents-to-validate': {
        disabled: true,
        readOnly: true,
      },
    },
    positionCode: {
      create: {
        adviseKey: 'assetManager.forms.temporaryPlantCodeAdviseCreation'
      },
      validate: {
        disabled: false,
        readOnly: false,
        adviseKey: 'assetManager.forms.temporaryPlantCodeAdviseValidation'
      },
      'validate-replacing-with-deactivated-asset': {
        disabled: true,
        readOnly: true,
      },
      'validate-replacing-with-new-asset': {
        disabled: true,
        readOnly: true,
      },
      update: {
        disabled: true,
        readOnly: true,
      },
      'substitution-new-asset': {
        disabled: true,
        readOnly: true,
      },
      'substitution-with-deactivated': {
        disabled: true,
        readOnly: true,
      },
      'documents-to-validate': {
        disabled: true,
        readOnly: true,
      },
    },
    modules: {
      validate: {
        disabled: false,
        'allow-only-news': true,
      },
      'validate-replacing-with-deactivated-asset': {
        disabled: false,
        'allow-only-news': true,
      },
      'validate-replacing-with-new-asset': {
        disabled: false,
        'allow-only-news': true,
      },
      update: {
        disabled: false,
        'allow-only-news': true,
      },
      'substitution-with-deactivated': {
        disabled: false,
      },
      'substitution-new-asset': {
        disabled: false,
      },
      'documents-to-validate': {
        disabled: false,
        'allow-only-news': true,
      },
    },
    plantCode: {
      validate: {
        disabled: true,
      },
      'validate-replacing-with-deactivated-asset': {
        disabled: true,
      },
      'validate-replacing-with-new-asset': {
        disabled: true,
      },
      update: {
        disabled: true,
      },
      'substitution-new-asset': {
        disabled: true,
      },
      'substitution-with-deactivated': {
        disabled: true,
      },
      'documents-to-validate': {
        disabled: true,
      },
    },
    costCenterCode: {
      validate: {
        disabled: true,
      },
      'validate-replacing-with-deactivated-asset': {
        disabled: true,
      },
      'validate-replacing-with-new-asset': {
        disabled: true,
      },
      update: {
        disabled: true,
      },
      'substitution-new-asset': {
        disabled: true,
      },
      'substitution-with-deactivated': {
        disabled: true,
      },
    },
    groups: {
      'substitution-with-deactivated': {
        hide: true,
      },
      'substitution-new-asset': {
        hide: true,
      },
    },
    documents: {
      'substitution-with-deactivated': {
        hide: true,
      },
      'substitution-new-asset': {
        hide: true,
      },
    },
  }
  return inputRules[name] && inputRules[name][status] && inputRules[name][status][action] || undefined
}

export const assetDocumentTypes: string[] = ['other','ce', 'maintenance', 'electric_scheme', 'selling_scrapping', 'drawing','manual', 'ddt_delivery']