import { API } from '../../../../redux/actions'
import moment from 'moment'
import { hydrateData, hydrateTimeData, parseResponseData } from '../../../../functions/series'
import { SingleDataValue } from '../../../../types/singleDataValue'
import React, { useMemo } from 'react'
import { useChartState } from '../../../../hook/useChartState'
import { useChartFetching } from '../../../../hook/useChartFetching'
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { withTranslation } from 'react-i18next'
import Row from '../../../../../inplant-components-fe/ui/components/Grid/Row'
import CustomLegend from '../Common/CustomLegend'
import CustomTooltip from '../Common/CustomTooltip'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import { COLORS } from '../../../../constants'
import { GeneralChartProps } from './generalChartProps'
import { simplePopulateSingleData } from '../../../../functions/simplePopulateSingleData'
import { PartialRecordDataType, PartialRecordStringType } from '../../../../types/helpers'
import { isJSON } from '../../../../functions/shared'

type DataType = typeof DATA_TYPE[number]

const itemsColors: PartialRecordStringType<DataType> = {
  cartPressure: COLORS.press.generalCartPressure,
  channelPressure: COLORS.press.generalChannelPressure,
  oilTemperature: COLORS.press.generalOilTemp,
} as const

const itemsUnitOfMeasure: PartialRecordStringType<DataType> = {
  cartPressure: 'bar',
  channelPressure: 'bar',
  oilTemperature: '°C',
} as const

interface CompleteData {
  data: Array<PartialRecordDataType<DataType>>
}

const DATA_TYPE = ['oilTemperature', 'channelPressure', 'cartPressure'] as const
const oliTemperatureMeasure = '_OIL_TEMP' as const
const channelPressureMeasure = '_CHAN_PRES' as const
const cartPressureMeasure = '_CARR_PRES' as const

const _PressGeneralInfoLineChart = (props: GeneralChartProps) => {
  const { startDateTime, endDateTime, abortController, measure, plantString, t } = props
  const dataContentLabel: PartialRecordStringType<DataType> = useMemo(
    () => ({
      cartPressure: `${t('plantAnalysis.pressDetail.generalCartPressure')}`,
      channelPressure: `${t('plantAnalysis.pressDetail.generalChannelPressure')}`,
      oilTemperature: `${t('plantAnalysis.pressDetail.generalOilTemperature')}`,
    }),
    []
  )
  const { actions, data, isError, isLoading, tooltip } = useChartState<CompleteData, DataType>({
    keys: [...DATA_TYPE],
    tooltipContentInfo: {
      cartPressure_color: itemsColors.cartPressure,
      cartPressure_label: dataContentLabel.cartPressure,
      channelPressure_color: itemsColors.channelPressure,
      channelPressure_label: dataContentLabel.channelPressure,
      oilTemperature_color: itemsColors.oilTemperature,
      oilTemperature_label: dataContentLabel.oilTemperature,
      cartPressure_unitOfMeasure: itemsUnitOfMeasure.cartPressure,
      channelPressure_unitOfMeasure: itemsUnitOfMeasure.channelPressure,
      oilTemperature_unitOfMeasure: itemsUnitOfMeasure.oilTemperature,
    },
  })
  const { queryEndSection, queryStartSection, uriRequestStart } = useChartFetching({
    callback: fetchAllData,
    endDateTime,
    startDateTime,
    plantString,
    type: 'normal',
  })

  function fetchAllData() {
    actions.setLoading()
    const oilTempCompleteMeasureName = `${measure}${oliTemperatureMeasure}`
    const channelPressureCompleteMeasureName = `${measure}${channelPressureMeasure}`
    const cartPressureMeasureName = `${measure}${cartPressureMeasure}`

    const oilTemperatureRequest = API().request(
      `${uriRequestStart}${queryStartSection}${oilTempCompleteMeasureName}${queryEndSection}`,
      { signal: abortController.signal }
    )
    const channelPressureRequest = API().request(
      `${uriRequestStart}${queryStartSection}${channelPressureCompleteMeasureName}${queryEndSection}`,
      { signal: abortController.signal }
    )
    const cartPressureRequest = API().request(
      `${uriRequestStart}${queryStartSection}${cartPressureMeasureName}${queryEndSection}`,
      { signal: abortController.signal }
    )
    Promise.all([oilTemperatureRequest, channelPressureRequest, cartPressureRequest])
      .then(([oilTemperatureResponse, channelPressureResponse, cartPressureResponse]) => {
        actions.setData({
          data: parseAndPrepareData({
            oilTemperature: oilTemperatureResponse,
            channelPressure: channelPressureResponse,
            cartPressure: cartPressureResponse,
          }),
        })
      })
      .catch(_ => {
        actions.setError()
      })
  }

  function parseAndPrepareData(requestDataSet: Record<DataType, any>): Array<PartialRecordDataType<DataType>> {
    const populatedData: Record<DataType, SingleDataValue[]> = Object.entries(requestDataSet).reduce(
      (acc, [currKey, currValue]) => {
        const dataToParse = isJSON(currValue)
          ? parseResponseData(currValue)
          : parseResponseData(JSON.stringify(currValue))
        return {
          ...acc,
          [currKey]: simplePopulateSingleData(dataToParse),
        }
      },
      {}
    ) as Record<DataType, SingleDataValue[]>
    const mergedData: any[] = []
    hydrateData(populatedData, mergedData)
    hydrateTimeData([...DATA_TYPE], mergedData, { startDate: startDateTime, endDate: endDateTime })
    return (mergedData as unknown) as Array<PartialRecordDataType<DataType>>
  }

  function handleMouseEnter() {
    actions.setTooltipContent({})
  }

  function handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      const currentData = data?.data.find((e: any) => e.time === args.activeLabel)
      // tslint:disable-next-line: variable-name
      const oilTemperature_data = currentData?.oilTemperature ?? '--'
      // tslint:disable-next-line: variable-name
      const channelPressure_data = currentData?.channelPressure ?? '--'
      // tslint:disable-next-line: variable-name
      const cartPressure_data = currentData?.cartPressure ?? '--'
      actions.setTooltipContent({
        oilTemperature_data,
        channelPressure_data,
        cartPressure_data,
        time: currentData?.time,
      })
    }
  }

  function handleMouseLeave() {
    actions.hideTooltip()
  }

  function nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  if (isLoading) {
    return <></>
  }

  if (isError) {
    return <CommonErrorMessage />
  }

  if (data && data.data.length === 0) {
    return <CommonNoDataAvailable />
  }

  return (
    <React.Fragment>
      <Row>
        <div className="col-md-10">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={700}
              height={400}
              data={data?.data}
              margin={{ top: 40, right: 0, bottom: 0, left: 0 }}
              onMouseEnter={handleMouseEnter}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                key={'line1'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />

              <YAxis domain={[0, 'auto']} axisLine={false} yAxisId={'bar'} orientation={'left'}>
                <Label value={itemsUnitOfMeasure.cartPressure} position={'top'} offset={10}  />
              </YAxis>
              <YAxis domain={[0, 'auto']} axisLine={true} yAxisId={'temperature'} orientation={'right'}>
                <Label value={itemsUnitOfMeasure.oilTemperature} position={'top'} offset={10}  />
              </YAxis>
              <CartesianGrid stroke="#f5f5f5" />
              <Tooltip content={nullTooltipContent()} />
              <Line
                dataKey="channelPressure"
                stroke={itemsColors.channelPressure}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'bar'}
              />
              <Line
                dataKey="oilTemperature"
                stroke={itemsColors.oilTemperature}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'temperature'}
              />
              <Line
                dataKey="cartPressure"
                stroke={itemsColors.cartPressure}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'bar'}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <CustomTooltip tooltip={tooltip} />
      </Row>
      <CustomLegend
        dataToShow={DATA_TYPE.map(dataType => ({
          label: dataContentLabel[dataType] ?? '',
          color: itemsColors[dataType] ?? '',
          unitOfMeasure: itemsUnitOfMeasure[dataType] ?? '',
        }))}
      />
    </React.Fragment>
  )
}

export default withTranslation()(_PressGeneralInfoLineChart)
