import React, { useEffect, useMemo, useState } from 'react'
import { useChartFetching } from '../../../../hook/useChartFetching'
import { useChartState } from '../../../../hook/useChartState'
import { API } from '../../../../redux/actions'
import { PartialRecordDataType } from '../../../../types/helpers'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import { GeneralChartProps } from './generalChartProps'
import _PressBaleDetailChart from './_PressBalesDetailChart'
import _PressRecapProductionTime from './_PressRecapProductionTime'
import _ProductionAndConsumptionChart from './_ProductionAndConsumptionChart'
import _RecipeRecapTable from './_RecipeRecapTable'
import _RecipeSelection from './_RecipeSelection'
import _RecipeTimeline from './_RecipeTimeline'

const recipeNameMeasure = '_REC_NAME_' as const

type DataType = 'recipeNames'

interface CompleteData {
  data: Record<string, string> | null
}

interface Props {
  uniqueRecipeList: string[]
  recipes: Array<PartialRecordDataType<'recipe'>>
}

const _RecipeNameWrapper = (props: Props & GeneralChartProps) => {
  const { abortController, endDateTime, plantString, startDateTime, measure, uniqueRecipeList, recipes, t } = props

  const recipeNameList = useMemo(() => {
    return uniqueRecipeList.map(recipeNumber => `${measure}${recipeNameMeasure}${recipeNumber}`)
  }, [uniqueRecipeList.join()])

  const { actions, data, isError, isLoading } = useChartState<CompleteData, DataType>({
    keys: [],
    tooltipContentInfo: {},
  })
  const { queryOnlyLastElementSection, queryStartSection, uriRequestStart } = useChartFetching({
    callback: fetchAllData,
    endDateTime,
    startDateTime,
    plantString,
    type: 'onlyLastElement',
  })

  const [selectedRecipe, setSelectedRecipe] = useState<string>('')

  useEffect(() => {
    setSelectedRecipe(Object.keys(data?.data ?? {})?.[0] ?? '')
  }, [Object.keys(data?.data ?? {}).join()])

  function fetchAllData() {
    actions.setLoading()
    const recipeNameListQuery = recipeNameList.join('", "')
    const recipeNameRequest = API().request(
      `${uriRequestStart}${queryStartSection}${recipeNameListQuery}${queryOnlyLastElementSection}`,
      { signal: abortController.signal }
    )

    Promise.all([recipeNameRequest])
      .then(([recipeNameResponse]) => {
        actions.setData({
          data: parseResponseData(recipeNameResponse),
        })
      })
      .catch(_e => {
        actions.setError()
      })
  }

  function parseResponseData(responseData: any) {
    const dataParsed = JSON.parse(responseData)
    let recipeNameRecord: Record<string, string> | null = null
    if (dataParsed.results[0]?.series) {
      const resultDataParsed = dataParsed.results[0]
      const tempRecipeNameRecord: Record<string, string> = {}
      uniqueRecipeList.forEach(name => {
        const recipeFound = resultDataParsed.series.find((s: any) => s.name === `${measure}${recipeNameMeasure}${name}`)
        tempRecipeNameRecord[name] = ''
        if (recipeFound && recipeFound.values[0]) {
          tempRecipeNameRecord[name] = recipeFound.values[0][1]
        }
      })
      recipeNameRecord = { ...tempRecipeNameRecord }
    }
    // else {
    //   const tempRecipeNameRecord: Record<string, string> = {}
    //   uniqueRecipeList.forEach(name => {
    //     tempRecipeNameRecord[name] = ''
    //   })
    //   recipeNameRecord = { ...tempRecipeNameRecord }
    // }
    return recipeNameRecord
  }

  function renderRecipeTimeline() {
    return (
      <_RecipeTimeline
        {...props}
        data={recipes ?? []}
        isError={isError}
        isLoading={isLoading}
        recipeNames={data?.data ?? {}}
      />
    )
  }

  if (isLoading) {
    return <></>
  }

  if (isError) {
    return <CommonErrorMessage />
  }

  if (data === null || data.data === null) {
    return <CommonNoDataAvailable />
  }

  return (
    <>
      {renderRecipeTimeline()}
      <div className={'d-flex flex-column mt-5'}>
        <_RecipeSelection items={data.data ?? {}} onChange={setSelectedRecipe} selectedRecipe={selectedRecipe} />
        <h3 className={'w-100 mt-5'}>{t('plantAnalysis.pressDetail.productionAndConsumption').toUpperCase()}</h3>
        <_RecipeRecapTable {...props} currentSelectedRecipe={selectedRecipe} />
        <_ProductionAndConsumptionChart {...props} currentSelectedRecipe={selectedRecipe} />
        <_PressBaleDetailChart {...props} currentSelectedRecipe={selectedRecipe} />
        <_PressRecapProductionTime {...props} currentSelectedRecipe={selectedRecipe} />
      </div>
    </>
  )
}

export default _RecipeNameWrapper
