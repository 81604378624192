export const COLORS = {
  waterFlow: '#59D8FC',
  materialTemperature: '#FED283',
  screwRotSpeed: '#6F38B1',
  beltSpeed: "#2680EB",
  u2Phase2: {
    U350_airFlow: '#FED283',
    U350_powder: '#A1A1F7',
    U352_airPressureIn: '#87C287',
    U352_airPressureOut: '#ffa84C',
    U357_airFlow: '#FED283',
    U357_powder: '#A1A1F7',
    U358_airPressure: '#87C287',
    U359_airPressure: '#FE8175',
    U357_airFlowThreshold: '#FF0000',
    U350_airFlowThreshold: '#FF0000',
  },
  press: {
    workingTime: '#87C287',
    notWorkingTime: '#FF99CC',
    absorption: '#FED283',
    tons: '#87C287',
    generalOilTemp: '#FED283',
    generalChannelPressure: '#A1A1F7',
    generalCartPressure: '#59D8FC',
  },
  pressRecipe: {
    1: '#5151D3',
    2: '#E68619',
    3: '#D83790',
    4: '#2680EB',
    5: '#9BEC54',
    6: '#268E6C',
    7: '#DFBF00',
    8: '#6F38B1',
    9: '#9089FA',
    10: '#CB6F10',
    11: '#47E26F',
    12: '#b0e8e9',
    13: '#caccf3',
    14: '#f8ddb3',
    15: '#ecb9d8',
    16: '#badbfe',
    17: '#DFF9C7',
    18: '#B6E1D3',
    19: '#26C0C7',
    20: '#f2e7ad',
  },
  recipeRecap: {
    halt: '#ffa84C',
    1: '#59D8FC',
    2: '#87C287',
    3: '#FE8175',
    4: '#FF99CC',
    5: '#FFD789',
    6: '#4650ff',
    7: '#3f7f30',
    8: '#974e3b',
    9: '#d271cf',
    10: '#A1A1F7',
    11: '#14f706',
    12: '#f72438',
    13: '#8712e1',
    noConnection: '#FF0000',
    null: '#BBB',
  },
  lines: {
    loading: {
      from_zero_to_twenty_five_percent: '#060079',
      from_twenty_five_to_fifty_percent: '#0e4d83',
      from_fifty_to_seventy_five_percent: '#1D8DB8',
      from_seventy_five_to_one_hundred_percent: '#2eb81b',
      over_one_hundred_percent: '#d8a819',
      // from_zero_to_seventy_five_percent: '#FC786D',
      // from_seventy_five_to_eighty_five_percent: '#BBB',
      // from_eighty_five_to_hundred_percent: '#3F46FB'
    },
    torque: {
      line1: '#3F46FB',
      line1Speed: '#59D8FC',
      line2: '#3F46FB',
      line2Speed: '#59D8FC',
      line3: '#3F46FB',
      line3Speed: '#59D8FC',
      line4: '#3F46FB',
      line4Speed: '#59D8FC',
      line5: '#3F46FB',
      line5Speed: '#59D8FC',
      thr: '#FF0000',
      from_one_point_five_to_sixty_eight_percent: '#0e4d83',
      from_sixty_eight_to_seventy_eight_percent: '#1D8DB8',
      from_seventy_eight_to_eighty_eight_percent: '#2eb81b',
      from_eighty_eight_to_hundred_percent: '#d8a819',
    },
    waterDrainValve: {
      valve1: '#3F46FB',
      valve2: '#FE90C5',
    },
    mills: {
      thr: '#FF0000',
      mill: '#A1A1F7',
    },
  },
  scrubber: {
    literHourTrend: {
      valve1: '#88C94D',
      valve2: '#7B17D9',
      valve1Thr: '#FF0000',
      valve2Thr: '#FF0000',
    },
    scrubberThrTime: {
      over: '#88C94D',
      under: '#E26326',
    },
    scrubberStatus: {
      0: '#E26326',
      1: '#88C94D',
      active: '#88C94D',
      inactive: '#E26326',
    },
  },
  activeStatus: {
    0: '#E26326',
    1: '#88C94D',
  },
  fillStatus: {
    0: '#7B17D9',
    1: '#53D2F9',
  },
  densifierTorqueStatus: {
    0: '#FED283',
    1: '#3f7f30',
    2: '#F55A62',
  },
  vecoplant: {
    generalGraph: {
      thrustPresser: '#A1A1F7',
      absorption: '#FFD789',
      speedPercent: '#59D8FC',
    },
    fillBuffer: {
      buffer1: '#00B0F0',
      buffer2: '#FF99CC',
      buffer3: '#7B17D9',
      buffer4: '#FED283',
      buffer5: '#87C287',
      limit: '#FF0000',
    },
    bufferState: {
      emptying: '#FED283',
      loadable: '#3f7f30',
      notLoadable: '#F55A62',
      0: '#FED283',
      1: '#3f7f30',
      2: '#F55A62',
    },
    bufferLine: {
      active: '#88C94D',
      inactive: '#E26326',
      full: '#53D2F9',
      empty: '#7B17D9',
    },
  },
  bufferState: {
    0: '#FED283',
    1: '#3f7f30',
    2: '#F55A62',
  },
  manAutoLocSec: {
    0: '#FF99CC', // manuale
    1: '#92D050', // automatico
    2: '#6290C3', // locale
    3: '#FFD789', // sezionato
  },
  avind: {
    0: '#F55A62', // in
    1: '#00B0F0', // av
  },
  running: {
    0: '#F55A62', // stoped
    1: '#87C287', // running
  },
  aspi: {
    SI: '#87C287',
    NO: '#F55A62',
  },
  invdir: {
    0: '#87C287', // inverter
    1: '#FFD789', // diretta
  },
  driveBypass: {
    0: '#87C287', // drive
    1: '#FFD789', // bypass
  },
  deviazione: {
    under: '#fbc106',
    over: '#dde9e9',
  },
  nopienoPieno: {
    0: '#00B0F0', // no pieno
    1: '#E76C19', // pieno
  },
  marciaFermo: {
    0: '#FF0000', // marcia
    1: '#87C287', // fermo
  },
  recipe: ['#59D8FC', '#FE8175', '#FFD789', '#A1A1F7'],
  globalRicetta: {
    0: '#ffa84C',
    1: '#59D8FC',
    2: '#87C287',
    3: '#FE8175',
    4: '#FF99CC',
    5: '#FFD789',
    6: '#4650ff',
    7: '#3f7f30',
    8: '#974e3b',
    9: '#d271cf',
    10: '#A1A1F7',
    11: '#14f706',
    12: '#f72438',
    13: '#8712e1',
  },
  plantHaltsTimes: {
    0: '#59D8FC',
    1: '#87C287',
    2: '#FE8175',
    10: '#FF99CC',
    11: '#FFD789',
    12: '#4650ff',
    13: '#3f7f30',
    14: '#a15f4e',
    15: '#fbc106',
    16: '#E76C19',
    17: '#A1A1F7',
    18: '#8712e1',
  },
  zonaAspirato: {
    0: '#FE8175',
    1: '#87C287',
  },
  cicliNum: {
    line: '#87C287',
  },
  performanceNum: {
    line: '#F55A62',
  },
  bagBreaker: {
    line: '#A19FF9',
  },
  locali: {
    0: '#FE8175',
    1: '#87C287',
  },
  servizio: {
    0: '#FE8175',
    1: '#87C287',
  },
  aspiredZone: {
    BeltOnAspOnPerc: '#C7F4C2',
    BeltOnAspOffPerc: '#FF7D77',
    BeltOffPerc: '#FFB55A',
  },
  assorbimento: '#A1A1F7',
  pressure: '#00B0F0',
  assorbimentoMinimo: '#FFC000',
  assorbimentoMassimo: '#A5A5A5',
  setVelocita: '#59D8FC',
  temperatura: '#87C287',
  cicli: '#87C287',
  ricetta: '#59D8FC',
  performance: '#FFC000',
  warnArea: {
    0: '#FE8175',
    100: 'transparent',
  },
  pauses: '#4cff8d',
  power: '#ffa84C',
  tempMelt: '#59D8FC',
  torque: '#87C287',
  position: '#FE8175',
  highlighted: '#FFC000',
  danger: '#FE8175',
}

export const THRESHOLDS = {
  'densifierTorqueUpperThresholdPercent': 78,
  'densifierTorqueZeroThresholdPercent': 25,
}