import moment from "moment"
import { SingleDataValue } from "../types/singleDataValue"

function simplePopulateSingleData(newData: any): SingleDataValue[] {
  if (newData && Array.isArray(newData)) {
    return newData.map(datum => {
      const time = moment(datum[0]).unix()
      return {
        x: time,
        y: datum[1],
        h100: 100,
      }
    })
  }
  return []
}

export { simplePopulateSingleData }