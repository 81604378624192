import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import moment from 'moment'
import { composeData, fillTime } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/seriesV2'
import { ChartFetch } from '../../ChartRenderUtilities'
import { LineBufferProps, LineBufferState } from './_types'
import { InfluxMeasureFetcher } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx'
import { lineBufferMeasuresToFetch } from './_measures'

abstract class LineBuffer<Props extends LineBufferProps, State extends LineBufferState> extends ChartFetch<
  Props,
  State
> {
  private mounted = false
  private abortController: AbortController = new AbortController()
  protected chartTranslationKeyTitle: string = 'plantAnalysis.lines.linesStatusTitle'

  constructor(props: Props, generateState: (props: Props) => State) {
    super(props)
    this.state = generateState(props)
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    if (this.props.workShift) {
      this.getData()
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData()
      }
    }
  }

  private constructData() {
    try {
      let mergedData = composeData(this.state.data)
      const start = moment(this.props.workShift.start.toString()).unix()
      const end = moment(this.props.workShift.end.toString()).unix()
      mergedData = fillTime(mergedData, start, end)

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private async getData() {
    const workshift = this.props.workShift
    if (workshift) {
      try {
        this.setState({
          fetching: true,
          error: false,
        })

        const InfluxFetcher = new InfluxMeasureFetcher(
          this.props.plant.plant,
          this.props.workshift,
          this.props.date.toString(),
          this.abortController
        )

        const promises = InfluxFetcher.request(lineBufferMeasuresToFetch)
        const promisesResolved = await Promise.all(promises)

        const promisesParsed = promisesResolved.reduce((acc, value, index) => {
          const measureSlug = lineBufferMeasuresToFetch[index].slug
          const parseFn = lineBufferMeasuresToFetch[index].parseFn
          const dataParsed = parseFn(value)
          return {
            ...acc,
            [measureSlug]: dataParsed,
          }
        }, {})

        if (this.mounted) {
          this.setState(
            {
              data: Object.assign({}, this.state.data, promisesParsed),
              fetching: false,
              error: false,
            },
            () => {
              this.constructData()
            }
          )
        }
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  protected abstract renderContent(): JSX.Element
  public render() {
    const { fetching, error } = this.state

    if (fetching) {
      return this.renderLoading()
    }

    if (error) {
      return this.renderError()
    }

    if (!this.state.data || (this.state.filteredData && this.state.filteredData.length === 0)) {
      return this.renderNoData()
    }

    return this.renderContent()
  }
}

export default LineBuffer
