import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageHeader } from '../../../../../inplant-components-fe'
import useGenericFetchHook from '../../../../../inplant-components-fe/mvfunctions/hooks/useGenericFetchHook'
import { useStateValidity } from '../../../../../inplant-components-fe/mvfunctions/hooks/useGenericInputHook'
import { useComponentsTranslation } from '../../../../../inplant-components-fe/services/translation'
import Button from '../../../../../inplant-components-fe/ui/components/MVButtons/Button'
import Flex, { StretchColumn } from '../../../../../inplant-components-fe/ui/components/MVFlex/Flex'
import { AlignSelf } from '../../../../../inplant-components-fe/ui/components/MVFlex/FlexItem'
import Input from '../../../../../inplant-components-fe/ui/components/MVInput/Input'
import { popupNotification } from '../../../../../inplant-coreadapter-fe/functions/notification'
import { getPasswordControlByConfiguration} from '../../../../functions/controlsHelpers'
import { fetchUpdatePassword } from '../../../../redux/actions/changePassword'
import { getResetPassword } from '../../../../redux/actions/resetPassword'
import { PasswordOptionType } from '../../../../types/options'

interface FormData {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const ChangePassword = () => {
  const userId: string | undefined = useSelector((state: any) => state?.auth?.user?.uuid)

  const { t } = useComponentsTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const base = 'user.changePassword'

  const [isLoading, setIsLoading] = useState(false)
  const [, addDataValidity, isValid] = useStateValidity({})

  const [formData, setFormData] = useState<FormData>({ confirmPassword: '', newPassword: '', oldPassword: '' })
  
  const passwordOption = useGenericFetchHook<PasswordOptionType>(getResetPassword)
  
  const [errorToShow, setErrorToShow] = useState<{ error: string | undefined }>({ error: undefined })
  useEffect(() => {
    if (formData.newPassword !== formData.confirmPassword) {
      setErrorToShow({
        error: t(`user.noMatchConfirmPassword`),
      })
    } else {
      setErrorToShow({
        error: undefined,
      })
    }
  }, [formData.newPassword, formData.confirmPassword])

  const updatePassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    fetchUpdatePassword(
      userId || '',
      formData.oldPassword,
      formData.newPassword,
      formData.confirmPassword
    )(dispatch)
      .then(() => {
        history.goBack()
      })
      .catch(e => {
        if(e.name === 'FetchError' && e.statusCode === 422) {
          popupNotification({
            type: 'error',
            content: t(`${base}.${e.message}`),
            title: t(`${base}.validationError`)
          })
          return 
        }
        popupNotification({
          title: t('api.errors.error'),
          content: t(`api.errors.error_${e.statusCode}`),
          type: 'error',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!userId) {
    return <></>
  }

  return (
    <div className="inplant-user">
      <PageHeader title={t(`${base}.title`)} />
      {passwordOption.state.kind === 'success' && (
        <form onSubmit={updatePassword}>
          <StretchColumn className="content">
            <Flex>
              <Input
                required={true}
                grow={1}
                kind={'input'}
                type={'password'}
                label={t(`${base}.currentPassword.label`)}
                placeholder={t(`${base}.currentPassword.placeholder`)}
                initialValue={formData.oldPassword}
                onChangeState={state => addDataValidity('oldPassword', state)}
                onChange={value => setFormData(prev => ({ ...prev, oldPassword: value }))}
              />
              <Input
                required={true}
                grow={1}
                kind={'input'}
                type={'password'}
                label={t(`${base}.newPassword.label`)}
                placeholder={t(`${base}.newPassword.placeholder`)}
                controls={getPasswordControlByConfiguration(passwordOption.state.data.controls, t)}
                onChangeState={state => addDataValidity('newPassord', state)}
                initialValue={formData.newPassword}
                onChange={value => setFormData(prev => ({ ...prev, newPassword: value }))}
              />
              <Input
                required={true}
                grow={1}
                kind={'input'}
                type={'password'}
                label={t(`${base}.newPassword.labelConfirm`)}
                placeholder={t(`${base}.newPassword.placeholderConfirm`)}
                initialValue={formData.confirmPassword}
                onChangeState={(state) => addDataValidity('newPassworConfirm', state)}
                error={errorToShow.error}
                onChange={value => setFormData(prev => ({ ...prev, confirmPassword: value }))}
              />
            </Flex>
            <Button
              isDisable={isLoading || !isValid || errorToShow.error !== undefined}
              isLoading={isLoading}
              type={'submit'}
              semantic={'primary'}
              label={t(`${base}.save`)}
              alignSelf={AlignSelf.end}
            />
          </StretchColumn>
        </form>
      )}
    </div>
  )
}

export default ChangePassword
