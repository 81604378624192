import { Dispatch } from 'redux'
import { API } from '.'
import { PasswordOptionType } from '../../types/options';

export const sendResetPassword = (token: string, request: { password: string; confirmPassword: string }) => async (
  dispatch: Dispatch
): Promise<void> => {
  return API().request(`/auth/reset/${token}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(request),
  },
  false, true)
}

export const getResetPassword = () => async (
  dispatch: Dispatch
): Promise<PasswordOptionType> => {
  return API()
    .request(`/auth/config/password`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
    .then((result:any) => result)
}
