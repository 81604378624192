import * as React from 'react'
import { ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { getAreaPauses } from '../../../../../functions/shared'
import { TFunction } from 'i18next'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { getAreaSections, reduceWarnAreas } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/sharedV2'

export interface OwnProps {
  filteredData: any
  i18nTitle: string
  i18nLabelPrefix?: string
  tooltip?: boolean
  t: TFunction
  topMargin?: boolean
  entry: string
  colorsId: string
  paddingRight?: number
  pauses?: Array<{ start: number; end: number }>
  forcedColors?: { [k: string]: string }
  lineHeight?: number
  hideTitle?: boolean
  hideTimeline?: boolean
}

type Props = WithTranslation & OwnProps

class GraphLineBarWithTimelineView extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)

    this.renderTooltip = this.renderTooltip.bind(this)
  }

  public render() {
    try {
      const { pauses, forcedColors } = this.props
      const colors = forcedColors ? forcedColors : COLORS[this.props.colorsId]

      const dataAreas = reduceWarnAreas(getAreaSections(this.props.filteredData, this.props.entry), {colorMap: colors, xAxisId: "1"})
      const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), {color: '#ff00ff', xAxisId: "1"})

      return (
        <div className={'row ' + (this.props.topMargin ? '-under' : '')}>
          <div className="col" style={this.props.paddingRight ? { paddingRight: this.props.paddingRight } : {}}>
            {!this.props.hideTitle && <h5>{this.props.t(this.props.i18nTitle)}</h5>}
            <ResponsiveContainer width="100%" height={this.props.lineHeight ? this.props.lineHeight : 20}>
              <ComposedChart
                /* width={700} */ height={180}
                data={this.props.filteredData}
                barGap={0}
                barCategoryGap={0}
              >
                <YAxis hide={true} label={''} domain={[0, 100]} tick={false} axisLine={false} tickLine={false} />
                <XAxis
                  xAxisId={'1'}
                  dataKey="time"
                  hide={true}
                  domain={['dataMin', 'dataMax']}
                  type={'number'}
                  interval={0}
                />
                {dataAreas}
                {warnPauses}
                {!this.props.hideTimeline && (
                  <XAxis
                    height={20}
                    dataKey="time"
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={timeStr => moment.unix(timeStr).format('HH:mm')}
                    domain={['dataMin', 'dataMax']}
                    type={'number'}
                    interval={'preserveStartEnd'}
                    tickCount={21}
                    allowDataOverflow={false}
                    xAxisId={'2'}
                  />
                )}
                {this.props.tooltip && (
                  <Tooltip wrapperStyle={{ zIndex: 100 }} content={this.renderTooltip} isAnimationActive={false} />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    } catch (error) {
      consoleLog('error', error)
      return <></>
    }
  }

  private renderTooltip(a: any) {
    const current = this.props.filteredData.find((e: any) => e.time === a.label)
    if (a.active) {
      return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
          <div
            className="recharts-default-tooltip"
            style={{
              margin: 0,
              padding: 10,
              backgroundColor: 'rgb(255, 255, 255)',
              border: '1px solid rgb(204, 204, 204)',
              whiteSpace: 'nowrap',
            }}
          >
            <p className="recharts-tooltip-label time-label" style={{ margin: 0 }}>
              {moment.unix(a.label).format('DD/MM/YYYY HH:mm:ss')}
            </p>
            {current && current.hasOwnProperty(this.props.entry) && this.props.i18nLabelPrefix
              ? current[this.props.entry] +
                ' - ' +
                this.props.t(this.props.i18nLabelPrefix + current[this.props.entry], { defaultValue: 'Ricetta' })
              : null}{' '}
            {
              // current[this.props.entry] @todo check
            }
          </div>
        </div>
      )
    }

    return null
  }
}

export default withTranslation()(GraphLineBarWithTimelineView)
