import React, { useMemo } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { COLORS } from '../../../../constants'
import { HHHmmssFromMilliseconds } from '../../../../functions/shared'
import { useChartFetching } from '../../../../hook/useChartFetching'
import { useChartState } from '../../../../hook/useChartState'
import { API } from '../../../../redux/actions'
import { PartialRecordStringType } from '../../../../types/helpers'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import { GeneralChartProps } from './generalChartProps'

type DataType = typeof DATA_TYPE[number]

const itemsColors: PartialRecordStringType<DataType> = {
  notWorking: COLORS.press.notWorkingTime,
  working: COLORS.press.workingTime,
}

type DataAggregateType = Array<{ value: number; key: DataType }>

interface CompleteData {
  data: DataAggregateType
}

const DATA_TYPE = ['notWorking', 'working'] as const
const measureMiddleSection = '_PROD_'
const workingTimeMeasure = '_WORK_TIME' as const
const stoppingTimeMeasure = '_STP_TIME' as const

const MEASURE_TYPE = [workingTimeMeasure, stoppingTimeMeasure] as const
type measureType = typeof MEASURE_TYPE[number]

const mapperMeasureToDataType: Record<measureType, DataType> = {
  _STP_TIME: 'notWorking',
  _WORK_TIME: 'working',
}

interface Props {
  currentSelectedRecipe: string
}

const _PressRecapProductionTime = (props: GeneralChartProps & Props) => {
  const { startDateTime, endDateTime, abortController, measure, plantString, currentSelectedRecipe, t } = props

  const baseStartMeasure = useMemo(() => {
    return `${measure}${measureMiddleSection}`
  }, [measure, measureMiddleSection])
  const workingTimeMeasureName = useMemo(() => {
    return `${baseStartMeasure}${currentSelectedRecipe}${workingTimeMeasure}`
  }, [baseStartMeasure, currentSelectedRecipe, workingTimeMeasure])
  const stoppingTimeMeasureName = useMemo(() => {
    return `${baseStartMeasure}${currentSelectedRecipe}${stoppingTimeMeasure}`
  }, [baseStartMeasure, currentSelectedRecipe, stoppingTimeMeasure])

  const dataContentLabel: PartialRecordStringType<DataType> = useMemo(
    () => ({
      working: `${t('plantAnalysis.pressDetail.workingTime')}`,
      notWorking: `${t('plantAnalysis.pressDetail.notWorkingTime')}`,
    }),
    []
  )

  const { actions, data, isError, isLoading } = useChartState<CompleteData, DataType>({
    keys: [...DATA_TYPE],
    tooltipContentInfo: {},
  })

  const { uriRequestStart, queryEndSection, queryStartSection, queryEndLastElement } = useChartFetching({
    callback: fetchAllData,
    endDateTime,
    startDateTime,
    plantString,
    type: 'withLast',
    deps: [currentSelectedRecipe],
  })

  function fetchAllData() {
    if (currentSelectedRecipe.length === 0) {
      return
    }
    actions.setLoading()
    const measureNameList = [workingTimeMeasureName, stoppingTimeMeasureName].join('", "')

    const workingStoppedTimeRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureNameList}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )
    const workingStoppedTimeLastRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureNameList}${queryEndLastElement}`,
      {
        signal: abortController.signal,
      }
    )

    Promise.all([workingStoppedTimeRequest, workingStoppedTimeLastRequest]).then(
      ([workingStoppedTimeResult, workingStoppedTimeLastResult]) => {
        actions.setData({
          data: parseAndPrepareData(workingStoppedTimeResult, workingStoppedTimeLastResult),
        })
      }
    )
  }

  function getValueFromSeries(responseData: any) {
    const dataParsed = JSON.parse(responseData)
    const recipeNameRecord: Record<string, string[]> = {}
    if (dataParsed.results[0]) {
      const resultDataParsed = dataParsed.results[0]
      MEASURE_TYPE.forEach(name => {
        if (resultDataParsed?.series) {
          const recipeFound = resultDataParsed.series.find((s: any) => s.name.includes(name))
          recipeNameRecord[name] = []
          if (recipeFound && recipeFound.values.length > 0) {
            recipeNameRecord[name] = recipeFound.values.map((v: any) => v[1])
          }
        }
      })
    }
    return recipeNameRecord
  }

  function parseAndPrepareData(responseData: any, lastElement: any): DataAggregateType {
    const getValueDataResult = getValueFromSeries(responseData)
    const getValuesDataLastResult = getValueFromSeries(lastElement)

    const result: Record<DataType, number> = MEASURE_TYPE.reduce(
      (acc, curr) => {
        const baseArray = [...(getValuesDataLastResult[curr] ?? []), ...(getValueDataResult[curr] ?? [])]

        const res = baseArray.reduceRight((difference, currentValue, index, array) => {
          if (array[index - 1] === undefined) {
            return difference
          }
          if (parseFloat(currentValue) - parseFloat(array[index - 1]) <= 0) {
            return difference
          }
          return difference + (parseFloat(currentValue) - parseFloat(array[index - 1]))
        }, 0)

        return { ...acc, [mapperMeasureToDataType[curr]]: res }
      },
      { notWorking: 0, working: 0 }
    )

    return [
      {
        key: 'notWorking',
        value: result.notWorking,
      },
      {
        key: 'working',
        value: result.working,
      },
    ]
  }

  function renderChart() {
    if (isLoading) {
      return <></>
    }

    if (isError) {
      return <CommonErrorMessage />
    }

    if ((data && !data.data) || data?.data.every(element => element.value === 0)) {
      return <CommonNoDataAvailable />
    }

    return (
      <div className={'d-flex'}>
        <div className={'d-flex align-items-center'}>
          <ResponsiveContainer className={'mx-2'} width={250} height={250}>
            <PieChart height={250}>
              <Pie
                nameKey={'key'}
                dataKey={'value'}
                data={data?.data}
                cx={130}
                cy={125}
                isAnimationActive={false}
                outerRadius={100}
                labelLine={false}
              >
                {DATA_TYPE.map(key => (
                  <Cell key={key} fill={itemsColors[key]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className={'d-flex flex-column'}>
          <table className={'table table-borderless w-25'}>
            <thead>
              <tr>
                <th />
                <th>{t('plantAnalysis.commonTime')}</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.map(d => (
                <tr key={d.key}>
                  <td>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      <span className="label-color-square" style={{ backgroundColor: itemsColors[d.key] }} />
                      <span>{dataContentLabel[d.key]}</span>
                    </span>
                  </td>
                  <td scope="col">
                    <b className={'ml-3'}>{HHHmmssFromMilliseconds(d.value, true, true, true) ?? 0}</b>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <div className={'d-flex flex-column'}>
      <h3 className={'w-100'}>{t('plantAnalysis.pressDetail.times').toUpperCase()}</h3>
      {renderChart()}
    </div>
  )
}

export default _PressRecapProductionTime
