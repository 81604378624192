import * as React from 'react'
import { Line } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'

import { GeneralStore } from '../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { DateRangeProps, Workshift } from '../../../../types/workshift'
import { GeneralData, Measure } from '../../../../types/measure'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Loader } from '../../../../functions/shared'
import BottomLegend from '../BottomLegend/BottomLegend/BottomLegendView'
import {
  fillWarnArea, getValueFromBinaryStatus,
  hydrateData,
  hydrateTimeData,
  interpolateTime,
  parseResponseData,
  parseResponseSingleData,
  populateManAutoLocSec,
} from '../../../../functions/series'
import BeltGraphMain  from './BeltGraphMain'
import GraphLineBarTime from '../GraphLineBar/GraphLineBarTime/GraphLineBarTimeView'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import GraphBrush from '../GraphBrush/GraphBrush'
import { BinaryMappingConfiguration, PlantAnalysisDetailsConfiguration } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/settings'
import { isJSON } from '@mv-submodules/inplant-coreadapter-fe/functions'
import { debounce } from '../../../../../inplant-components-fe/mvfunctions/helpers'
import { SingleDataValue } from '../../../../types/singleDataValue'
import GenericLineGraph from './GenericLineGraph'

interface OwnState {
  brush1End: number
  brush1EndInd: number
  brush1Start: number
  brush1StartInd: number
  data: { [key: string]: Measure }
  dayEnd: string | null
  dayStart: string | null
  endDate: string | number
  fetchErrors: boolean
  filteredData: any[]
  isCollapsed: boolean
  isLoading: boolean
  last: any
  lastAfter: any
  mergedData: undefined | any[]
  startDate: string | number
}

interface OwnProps {
  active: string
  component: any
  inverter: boolean
  measureActCurrentName?: string
  mono?: boolean
  toggleCollapse: (id: string) => void
  binaryConfiguration: BinaryMappingConfiguration
}

interface StateProps {
  aspirato: GeneralStore
  detailsConfiguration: PlantAnalysisDetailsConfiguration | null
  fullDay: null | Workshift
  model: null | GeneralData
  plant: string | null
  ricetta: GeneralStore
  workshift: Workshift

}

const mapStateToProps = (state: any): StateProps & DateRangeProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  days: state.plantAnalysis.common.days,
  detailsConfiguration: state.config?.plantAnalysis?.detailsConfiguration &&
  isJSON(state.config.plantAnalysis.detailsConfiguration) ?
    JSON.parse(state.config.plantAnalysis.detailsConfiguration) : undefined,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  isDateFilterRange: state.plantAnalysis.common.isDateFilterRange,
  model: state.plantAnalysis.model,
  plant: state.plantSelector.plant,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
})

type Props = StateProps & OwnProps & WithTranslation & DateRangeProps

const cleanState = {
  brush1End: 0,
  brush1EndInd: 0,
  brush1Start: 0,
  brush1StartInd: 0,
  data: {
    aspirato: {
      data: [],
      min: 0,
      max: 0,
    },
    ricetta: {
      data: [],
      min: 0,
      max: 0,
    },
    assorbimento: {
      data: [],
      min: 0,
      max: 0,
    },
    avantiIndietro: {
      data: [],
      min: 0,
      max: 0,
    },
    inverterDiretta: {
      data: [],
      min: 0,
      max: 0,
    },
    manuale: {
      data: [],
      min: 0,
      max: 0,
    },
    automatico: {
      data: [],
      min: 0,
      max: 0,
    },
    setVelocita: {
      data: [],
      min: 0,
      max: 0,
    },
    status: {
      data: [],
      min: 0,
      max: 0,
    },
    localeSezionato: {
      data: [],
      min: 0,
      max: 0,
    },
    position: {
      data: [],
      min: 0,
      max: 0,
    },
    power: {
      data: [],
      min: 0,
      max: 0,
    },
    tempMelt: {
      data: [],
      min: 0,
      max: 0,
    },
    torque: {
      data: [],
      min: 0,
      max: 0,
    },
    screwRotSpeed: {
      data: [],
      min: 0,
      max: 0,
    },
    // waterFlow: {
    //   data: [],
    //   min: 0,
    //   max: 0,
    // },
    running: {
      data: [],
      min: 0,
      max: 0,
    },
    beltSpeed: {
      data: [],
      min: 0,
      max: 0,
    },
  },
  dayEnd: null,
  dayStart: null,
  endDate: 'auto',
  fetchErrors: false,
  filteredData: [],
  isCollapsed: false,
  isLoading: false,
  last: null,
  lastAfter: null,
  mergedData: [],
  startDate: 'auto',
}

class BeltBinarySrcGenGraphs extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = cleanState

    this.exportData = this.exportData.bind(this)
    this.handleBrush1 = debounce(this.handleBrush1.bind(this),10)
  }

  public componentDidMount() {
    const date = moment.isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    if (this.props.dateFilterStart && moment.isMoment(date) && this.props.workshift) {
      this.getData(this.props)
    }
  }

  public componentWillUnmount() {
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const date = moment.isMoment(nextProps.dateFilterStart)
      ? nextProps.dateFilterStart
      : moment(nextProps.dateFilterStart)
    if (
      (this.props.dateFilterStart !== nextProps.dateFilterStart ||
        this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.component.id) &&
      nextProps.dateFilterStart &&
      moment.isMoment(date) &&
      nextProps.workshift
    ) {
      this.getData(nextProps)
    } else {
      const cleanStateWithouFetch = (({ isLoading, fetchErrors, ...others }) => ({ ...others }))(cleanState)
      this.setState(cleanStateWithouFetch)
    }
  }

  public render() {
    const { workshift } = this.props
    const isReady = this.state.mergedData !== undefined && this.state.mergedData.length > 0 && !this.state.isLoading
    const noData = this.state.mergedData !== undefined && this.state.mergedData.length === 0 && !this.state.isLoading

    return (
      <React.Fragment>
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.state.isLoading && (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {this.state.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto belt-graph-fetch-errors">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}

          {isReady && this.props.inverter && (
            <BeltGraphMain
              additionalChartLines={[{
                measure: 'power',
                label: 'Power',
                type: 'value',
                unit: 'kW'
              }]}
              hideSpeed={true}
              filteredData={this.state.filteredData}
              data={this.state.mergedData}
              pauses={(workshift && workshift.pauses) || []}
            />
          )}
        </div>

        {isReady && (
          <div className="row mt-4 mb-4">
            <GenericLineGraph 
              additionalChartLines={[
                {
                  measure: 'position',
                  label: 'Position',
                  type: 'perc',
                  unit: '%'
                },
                {
                  measure: 'tempMelt',
                  label: 'Temp Melt',
                  type: 'value',
                  unit: '°C'
                },
                {
                  measure: 'torque',
                  label: 'Torque',
                  type: 'perc',
                  unit: '%'
                },
                {
                  measure: 'screwRotSpeed',
                  label: 'Screw Rotation Speed',
                  type: 'value',
                  unit: 'Rpm'
                },
                {
                  measure: 'beltSpeed',
                  label: 'Belt Speed',
                  type: 'value',
                  unit: '%'
                },
                // {
                //   measure: 'waterFlow',
                //   label: 'Water Flow',
                //   type: 'value',
                //   unit: 'l/h'
                // },
              ]}
              filteredData={this.state.filteredData}
              data={this.state.mergedData}
            />
          </div>
        )}

        {isReady && (
          <GraphLineBarTime
            topMargin={true}
            entry={'manAutoLocSec'}
            colorsId={'manAutoLocSec'}
            filteredData={this.state.filteredData}
            i18nTitle={'plantAnalysis.labels.manAutoLocSec'}
            paddingRight={70}
          />
        )}

        {isReady && (
          <GraphLineBarTime
            topMargin={true}
            entry={'running'}
            colorsId={'running'}
            filteredData={this.state.filteredData}
            i18nTitle={'plantAnalysis.labels.running'}
            paddingRight={70}
          />
        )}

        {isReady && <GraphTimelineView data={this.state.filteredData} xAxisNumber={true} paddingRight={60} />}

        {isReady && (
          <BottomLegend
            labels={['manAutoLocSec', 'running']}
          />
        )}

        {isReady && this.props.inverter && (
          <>
            <GraphBrush data={this.state.mergedData} brushOnChange={this.handleBrush1} paddingRight={70}>
              <Line dataKey="assorbimento" type="step" stroke="#A19FF9" dot={false} isAnimationActive={false} />
            </GraphBrush>
            <GraphTimelineView data={this.state.mergedData} xAxisNumber={false} paddingRight={60} />
          </>
        )}
      </React.Fragment>
    )
  }

  private populateSingleData(key: string, isBoolean: boolean = false, multiplier?: number) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : multiplier ? datum[1] * multiplier : datum[1],
          h100: 100,
        }
      })
    }

    return [{}]
  }

  private async getData(props: Props) {
    const {measureActCurrentName} = props
    try {
      this.setState({ isLoading: true })

      const startDateObj = moment(props.workshift.start)
      const startDate = startDateObj
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()
      const endDateObj = moment(props.workshift.end)
      const endDate = endDateObj
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()

      const measureLabel = props.component.id

      const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

      const queryStart = `SELECT "measure" FROM "`
      const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
      const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`


      const queryStatus = queryStart + measureLabel + '_WORD01_INFO' + queryEnd
      const queryLastStatus = queryLastStart + measureLabel + '_WORD01_INFO' + queryLastEnd

      const requestDataStatusSrc = API().request(`/query?${plantQuery}q=` + queryStatus)
      const requestDataLastStatusSrc = API().request(`/query?${plantQuery}q=` + queryLastStatus)

      const speedSubstring = '_SPD_ACT'
      const positionSubstring = '_MT_DEC_ACT_POS'
      const powerSubstring = '_MT_DEC_POWER'
      const tempMeltSubstring = '_MT_DEC_TEMP_MELT'
      const torqueSubstring = '_MT_DEC_TORQUE'
      const screwRotSpeedSubstring = '_SPEED_VITI_DEC'
      const beltSpeedSubstring = '_SPEED_NASTRO'
      // const waterFlowSubstring = '_MT_DEC_WATER_FLOW'

      const queryAssorbimento = queryStart + measureLabel + (measureActCurrentName || '_ACT_CUR') + queryEnd
      const querySetVelocita = queryStart + measureLabel + speedSubstring + queryEnd
      const queryPosition = queryStart + measureLabel + positionSubstring + queryEnd
      const queryPower = queryStart + measureLabel + powerSubstring + queryEnd
      const queryTempMelt = queryStart + measureLabel + tempMeltSubstring + queryEnd
      const queryTorque = queryStart + measureLabel + torqueSubstring + queryEnd
      const queryScrewRotSpeed = queryStart + measureLabel + screwRotSpeedSubstring + queryEnd
      const queryBeltSpeed = queryStart + measureLabel + beltSpeedSubstring + queryEnd
      // const queryWaterFlow = queryStart + measureLabel + waterFlowSubstring + queryEnd

      const queryLastAssorbimento = queryLastStart + measureLabel + (measureActCurrentName || '_ACT_CUR') + queryLastEnd
      const queryLastSetVelocita = queryLastStart + measureLabel + speedSubstring + queryLastEnd
      const queryLastPosition = queryLastStart + measureLabel + positionSubstring + queryLastEnd
      const queryLastPower = queryLastStart + measureLabel + powerSubstring + queryLastEnd
      const queryLastTempMelt = queryLastStart + measureLabel + tempMeltSubstring + queryLastEnd
      const queryLastTorque = queryLastStart + measureLabel + torqueSubstring + queryLastEnd
      const queryLastScrewRotSpeed = queryLastStart + measureLabel + screwRotSpeedSubstring + queryLastEnd
      const queryLastBeltSpeed = queryLastStart + measureLabel + beltSpeedSubstring + queryLastEnd
      // const queryLastWaterFlow = queryLastStart + measureLabel + waterFlowSubstring + queryLastEnd

      const requestDataAssorbimentoSrc = API().request(`/query?${plantQuery}&q=` + queryAssorbimento)
      const requestDataSetVelocitaSrc = API().request(`/query?${plantQuery}&q=` + querySetVelocita)
      const requestDataPositionSrc = API().request(`/query?${plantQuery}&q=` + queryPosition)
      const requestDataPowerSrc = API().request(`/query?${plantQuery}&q=` + queryPower)
      const requestDataTempMeltSrc = API().request(`/query?${plantQuery}&q=` + queryTempMelt)
      const requestDataTorqueSrc = API().request(`/query?${plantQuery}&q=` + queryTorque)
      const requestDataScrewRotSpeed = API().request(`/query?${plantQuery}&q=` + queryScrewRotSpeed)
      const requestDataBeltSpeed = API().request(`/query?${plantQuery}&q=` + queryBeltSpeed)
      // const requestDataWaterFlow = API().request(`/query?${plantQuery}&q=` + queryWaterFlow)

      const requestDataLastAssorbimentoSrc = API().request(`/query?${plantQuery}&q=` + queryLastAssorbimento)
      const requestDataLastSetVelocitaSrc = API().request(`/query?${plantQuery}&q=` + queryLastSetVelocita)
      const requestDataLastPositionSrc = API().request(`/query?${plantQuery}&q=` + queryLastPosition)
      const requestDataLastPowerSrc = API().request(`/query?${plantQuery}&q=` + queryLastPower)
      const requestDataLastTempMeltSrc = API().request(`/query?${plantQuery}&q=` + queryLastTempMelt)
      const requestDataLastTorqueSrc = API().request(`/query?${plantQuery}&q=` + queryLastTorque)
      const requestDataLastScrewRotSpeed = API().request(`/query?${plantQuery}&q=` + queryLastScrewRotSpeed)
      const requestDataLastBeltSpeed = API().request(`/query?${plantQuery}&q=` + queryLastBeltSpeed)
      // const requestDataLastWaterFlow  = API().request(`/query?${plantQuery}&q=` + queryLastWaterFlow)

      Promise.all([
        requestDataAssorbimentoSrc,
        requestDataStatusSrc,
        requestDataSetVelocitaSrc,
        requestDataPositionSrc,
        requestDataPowerSrc,
        requestDataTempMeltSrc,
        requestDataTorqueSrc,
        requestDataScrewRotSpeed,
        requestDataBeltSpeed,
        // requestDataWaterFlow,
        requestDataLastAssorbimentoSrc,
        requestDataLastStatusSrc,
        requestDataLastSetVelocitaSrc,
        requestDataLastPositionSrc,
        requestDataLastPowerSrc,
        requestDataLastTempMeltSrc,
        requestDataLastTorqueSrc,
        requestDataLastScrewRotSpeed,
        requestDataLastBeltSpeed
        // requestDataLastWaterFlow
      ]).then(([
        requestDataAssorbimentoSrcResult,
        requestDataStatusSrcResult,
        requestDataSetVelocitaSrcResult,
        requestDataPositionSrcResult,
        requestDataPowerSrcResult,
        requestDataTempMeltSrcResult,
        requestDataTorqueSrcResult,
        requestDataScrewRotSpeedResult,
        requestDataBeltSpeedResult,
        // requestDataWaterFlowResult,
        requestDataLastAssorbimentoSrcResult,
        requestDataLastStatusSrcResult,
        requestDataLastSetVelocitaSrcResult,
        requestDataLastPositionSrcResult,
        requestDataLastPowerSrcResult,
        requestDataLastTempMeltSrcResult,
        requestDataLastTorqueSrcResult,
        requestDataLastScrewRotSpeedResult,
        requestDataLastBeltSpeedResult,
        // requestDataLastWaterFlowResult,
      ]) => {
        this.setState({
          data: Object.assign({}, this.state.data, {
            assorbimento: { data: parseResponseData(requestDataAssorbimentoSrcResult) },
            status: { data: parseResponseData(requestDataStatusSrcResult) },
            setVelocita: { data: parseResponseData(requestDataSetVelocitaSrcResult) },
            position: { data: parseResponseData(requestDataPositionSrcResult) },
            power: { data: parseResponseData(requestDataPowerSrcResult) },
            tempMelt: { data: parseResponseData(requestDataTempMeltSrcResult) },
            torque: { data: parseResponseData(requestDataTorqueSrcResult) },
            screwRotSpeed: { data: parseResponseData(requestDataScrewRotSpeedResult) },
            beltSpeed: { data: parseResponseData(requestDataBeltSpeedResult) },
            // waterFlow : { data: parseResponseData(requestDataWaterFlowResult) },
          }),
          last: Object.assign({}, this.state.data, {
            assorbimento: parseResponseSingleData(requestDataLastAssorbimentoSrcResult),
            status: parseResponseSingleData(requestDataLastStatusSrcResult),
            setVelocita: parseResponseSingleData(requestDataLastSetVelocitaSrcResult),
            position: parseResponseSingleData(requestDataLastPositionSrcResult),
            power: parseResponseSingleData(requestDataLastPowerSrcResult),
            tempMelt: parseResponseSingleData(requestDataLastTempMeltSrcResult),
            torque: parseResponseSingleData(requestDataLastTorqueSrcResult),
            screwRotSpeed: parseResponseSingleData(requestDataLastScrewRotSpeedResult),
            beltSpeed: parseResponseSingleData(requestDataLastBeltSpeedResult),
            // waterFlow: parseResponseSingleData(requestDataLastWaterFlowResult),
          }),
          startDate: startDateObj.unix(),
          endDate: endDateObj.unix(),
          isLoading: false,
          fetchErrors: false,
        })

        this.prepareData()
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private getBinaryMapping() {
    // const {detailsConfiguration, plant} = this.props
    // return plant && detailsConfiguration?.hasOwnProperty(plant) && detailsConfiguration[plant].binaryMapping || undefined
    return this.props.binaryConfiguration
  }

  private populateSingleDataFromBinaryStatus(key: string): SingleDataValue[] {
    const stateData = { ...this.state.data }
    const stateDataLast = { ...this.state.last }
    const detailsConfiguration = this.getBinaryMapping()

    const result: SingleDataValue[] = []

    if (stateDataLast?.status && detailsConfiguration) {
      const time = moment(this.props.workshift.start).unix()

      result.push({
        x: time,
        y: getValueFromBinaryStatus(key, stateDataLast?.status[1], detailsConfiguration),
        h100: 100,
      })
    }

    if (stateData?.status?.data && detailsConfiguration) {
      stateData.status.data.forEach((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }
        result.push({
          x: time,
          y: getValueFromBinaryStatus(key, datum[1], detailsConfiguration),
          h100: 100,
        })
      })
      return result
    }

    // TODO Controlla che sia corretto => [{}]
    return result
  }

  private prepareData() {
    try {
      const mergedData: any[] = []

      if (this.state.data) {
        // let aspirato: any = []
        // let ricetta: any = []
        let avantiIndietro: any = []
        let inverterDiretta: any = []
        let assorbimento: any = []
        let manuale: any = []
        let automatico: any = []
        let status: any[] = []
        let setVelocita: any = []
        let localeSezionato: any[] = []
        let position: any[] = []
        let power: any[] = []
        let tempMelt: any[] = []
        let torque: any[] = []
        let running: any = []
        let screwRotSpeed:  any[] = []
        let beltSpeed:  any[] = []
        // let waterFlow :  any[] = []

        // needed by populateSingleDataFromBinaryStatus
        status = this.populateSingleData('status', false)
        assorbimento = this.populateSingleData('assorbimento', false)
        setVelocita = this.populateSingleData('setVelocita')
        position = this.populateSingleData('position')
        power = this.populateSingleData('power')
        tempMelt = this.populateSingleData('tempMelt')
        torque = this.populateSingleData('torque')
        screwRotSpeed = this.populateSingleData('screwRotSpeed')
        beltSpeed = this.populateSingleData('beltSpeed')
        // waterFlow = this.populateSingleData('waterFlow')


        avantiIndietro = this.populateSingleDataFromBinaryStatus('avantiIndietro')
        inverterDiretta = this.populateSingleDataFromBinaryStatus('inverterDiretta')
        manuale = this.populateSingleDataFromBinaryStatus('manuale')
        automatico = this.populateSingleDataFromBinaryStatus('automatico')
        localeSezionato = this.populateSingleDataFromBinaryStatus('localeSezionato')
        running = this.populateSingleDataFromBinaryStatus('running')

        if(!(
          status.length === 0 &&
          assorbimento.length === 0 &&
          setVelocita.length === 0 &&
          avantiIndietro.length === 0 &&
          inverterDiretta.length === 0 &&
          manuale.length === 0 &&
          automatico.length === 0 &&
          localeSezionato.length === 0 &&
          position.length === 0 &&
          power.length === 0 &&
          tempMelt.length === 0 &&
          torque.length === 0 &&
          screwRotSpeed.length === 0 &&
          beltSpeed.length === 0 &&
          // waterFlow.length === 0
          running === 0
        )) {

          hydrateData(
            {
              // aspirato,
              // ricetta,
              assorbimento,
              avantiIndietro,
              inverterDiretta,
              manuale,
              automatico,
              setVelocita,
              localeSezionato,
              status,
              position,
              power,
              tempMelt,
              torque,
              screwRotSpeed,
              beltSpeed,
              // waterFlow,
              running,
            },
            mergedData,
            'assorbimento',
            0,
          )
          mergedData.sort(( a, b ) => {
            if (a.time < b.time) {
              return -1
            }
            if (a.time > b.time) {
              return 1
            }
            return 0
          })

          hydrateTimeData(
            [
              // 'aspirato',
              // 'ricetta',
              'assorbimento',
              'avantiIndietro',
              'inverterDiretta',
              'manuale',
              'automatico',
              'setVelocita',
              'localeSezionato',
              'warnArea',
              'status',
              'position',
              'power',
              'tempMelt',
              'torque',
              'screwRotSpeed',
              'beltSpeed',
              // 'waterFlow',
              "running",
            ],
            mergedData,
            this.state,
            undefined,
            undefined,
            undefined,
            this.getBinaryMapping()
          )

          populateManAutoLocSec(mergedData)
          fillWarnArea(mergedData, 'assorbimento', 0)
          interpolateTime(mergedData, moment(this.props.workshift.start).unix(), moment(this.props.workshift.end).unix())
        }
        this.setState({
          brush1StartInd: 0,
          brush1EndInd: mergedData.length,
          mergedData,
          filteredData: mergedData,
        })
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private handleBrush1(args: any) {
    this.setState({
      brush1StartInd: args.startIndex,
      brush1EndInd: args.endIndex,
      filteredData: this.state.mergedData ? this.state.mergedData.slice(args.startIndex, args.endIndex + 1) : [],
    })
  }

  private exportData(e: React.MouseEvent) {
    e.preventDefault()
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(BeltBinarySrcGenGraphs)))
