import { useCallback, useState } from 'react'
import { Tooltip, TooltipColor, TooltipData, TooltipLabel, TooltipUnitOfMeasure } from '../ui/components/widgets/Common/CustomTooltip'

// type Tooltip = { show: false } | { show: true, tooltipContentData: Record<string, any> }

interface Props<T extends string> {
  tooltipContentInfo: TooltipLabel<T> & TooltipColor<T> & TooltipUnitOfMeasure<T>
  keys: T[]
}

const useChartState = <DataType, TooltipType extends string>(props: Props<TooltipType>) => {
  const { keys, tooltipContentInfo } = props
  const [tooltip, setTooltip] = useState<Tooltip<TooltipType>>({ show: false, tooltipContentInfo, keys })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [data, _setData] = useState<DataType | null>(null)

  const setData = useCallback((newData: DataType) => {
    _setData(newData)
    setIsError(false)
    setIsLoading(false)
  }, [])

  const setLoading = useCallback(() => {
    _setData(null)
    setIsError(false)
    setIsLoading(true)
  }, [])

  const setError = useCallback(() => {
    _setData(null)
    setIsError(true)
    setIsLoading(false)
  }, [])

  const setTooltipContent = useCallback((tooltipContent: TooltipData<TooltipType>) => {
    setTooltip(prev => ({ ...prev, show: true, tooltipContentData: { ...tooltipContent } }))
  }, [])

  const hideTooltip = useCallback(() => {
    setTooltip(prev => ({ show: false, keys: prev.keys, tooltipContentInfo: { ...prev.tooltipContentInfo } }))
  }, [])

  return {
    isLoading,
    isError,
    data,
    tooltip,
    actions: { setData, setLoading, setError, hideTooltip, setTooltipContent },
  }
}

export { useChartState }
