import { API } from "."
import { Dispatch } from 'redux'
import { PlantOptionType } from "../../types/options"

export const getPlants = () => async (
  dispatch: Dispatch
): Promise<PlantOptionType[]> => {
  return API()
    .request(`/manage/auth/plants/full`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
    .then((result:any) => result)
}