import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import moment from 'moment'
import { HHHmmssFromMilliseconds } from '../../../../../functions/shared'
import TableRowWrapper, {
  TableRowFullWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import { MillsProps, MillsState } from './_types'
import Mills from './_Mills'
import { DEFAULT_STATE, MILLS } from './_constants'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'

class MillsPrint extends Mills<MillsProps, MillsState> {
  constructor(props: MillsProps) {
    super(props, () => DEFAULT_STATE)
  }

  public renderContent(): JSX.Element {
    const { t } = this.props
    return (
      <>
        <TableRowFullWrapper>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.millsTitle')}</h3>
        </TableRowFullWrapper>
        {MILLS.map(mill => {
          return (
            <TableRowWrapper>
              <TableColumnWrapper className={'py-2'} col={10}>
                <h3 className={'w-100'}>{this.props.t(`plantAnalysis.lines.mills.${mill.id}`)}</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    height={400}
                    data={this.state.filteredData}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    barGap={0}
                    barCategoryGap={0}
                  >
                    <XAxis
                      key={'line1'}
                      hide={false}
                      dataKey="time"
                      label={''}
                      height={30}
                      axisLine={true}
                      tickLine={false}
                      tick={true}
                      interval={'preserveStartEnd'}
                      minTickGap={50}
                      domain={['dataMin', 'dataMax']}
                      tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                      type={'category'}
                    />
                    <CartesianGrid stroke="#f5f5f5" />
                    <YAxis
                      orientation={'left'}
                      yAxisId={'A'}
                      axisLine={false}
                      tickFormatter={val => parseInt(val, 10)}
                    />
                    <Line
                      dataKey={`${mill.id}Thr1`}
                      stroke={COLORS.lines.mills.thr}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'A'}
                    />
                    <Line
                      dataKey={`${mill.id}Thr2`}
                      stroke={COLORS.lines.mills.thr}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'A'}
                    />
                    <Line
                      dataKey={`${mill.id}Thr3`}
                      stroke={COLORS.lines.mills.thr}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'A'}
                    />
                    <Line
                      dataKey={`${mill.id}Absorption`}
                      stroke={COLORS.lines.mills.mill}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'A'}
                    />
                  </LineChart>
                </ResponsiveContainer>
                <div className="d-print-inline-flex">
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span className="label-color-square mx-3" style={{ backgroundColor: COLORS.lines.mills.mill }} />
                    <span>{t(`plantAnalysis.lines.mills.label.${mill.id}Absorption`)}</span>
                  </span>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className="label-color-square mx-3"
                      style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                    />
                    <span>{t(`plantAnalysis.lines.mills.label.${mill.id}Thr1`)}</span>
                  </span>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className="label-color-square mx-3"
                      style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                    />
                    <span>{t(`plantAnalysis.lines.mills.label.${mill.id}Thr2`)}</span>
                  </span>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    <span
                      className="label-color-square mx-3"
                      style={{ backgroundColor: COLORS.vecoplant.fillBuffer.limit }}
                    />
                    <span>{t(`plantAnalysis.lines.mills.label.${mill.id}Thr3`)}</span>
                  </span>
                </div>
              </TableColumnWrapper>
              <TableColumnWrapper className={'py-2'} col={2}>
                <table className={'table table-borderless table-sm w-50 mw-300 font-size-12 table-first-column-color'}>
                  <thead>
                    <tr>
                      <th />
                      <th className="text-right">{t('plantAnalysis.lines.mills.table.overValue')}</th>
                      <th className="text-right">{t('plantAnalysis.lines.mills.table.overTimeValue')}</th>
                    </tr>
                  </thead>
                  {this.state.data?.mills && (
                    <tbody>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr1')}</td>
                        <td className="text-right">
                          {parseInt(this.state.data?.mills[0]?.[`mill_${mill.assetId}_count_over_first_threshold`], 10)}
                        </td>
                        <td className="text-right">
                          {HHHmmssFromMilliseconds(
                            this.state.data?.mills[0]?.[`mill_${mill.assetId}_seconds_over_first_threshold`] * 1000,
                            false,
                            true,
                            false
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr2')}</td>
                        <td className="text-right">
                          {parseInt(
                            this.state.data?.mills[0]?.[`mill_${mill.assetId}_count_over_second_threshold`],
                            10
                          )}
                        </td>
                        <td className="text-right">
                          {HHHmmssFromMilliseconds(
                            this.state.data?.mills[0]?.[`mill_${mill.assetId}_seconds_over_second_threshold`] * 1000, // tslint:disable-line
                            false,
                            true,
                            false
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr3')}</td>
                        <td className="text-right">
                          {parseInt(this.state.data?.mills[0]?.[`mill_${mill.assetId}_count_over_third_threshold`], 10)}
                        </td>
                        <td className="text-right">
                          {HHHmmssFromMilliseconds(
                            this.state.data?.mills[0]?.[`mill_${mill.assetId}_seconds_over_third_threshold`] * 1000,
                            false,
                            true,
                            false
                          )}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </TableColumnWrapper>
            </TableRowWrapper>
          )
        })}
      </>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(MillsPrint))
