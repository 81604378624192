import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { extractResponseWithTagsData, hydrateData, hydrateTimeData } from '../../../../../functions/series'
import { API } from '../../../../../redux/actions'
import { THRESHOLDS } from '../../../../../constants'
import { getGeneralArea } from '../../../../../functions/shared'
import { extractResponseData } from '../../../../../functions/series'
import moment from 'moment'
import { stringify } from 'qs'
import {
  getAreaSections,
  joinSubsequentAreaSections,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/sharedV2'
import {
  EngineSpeedDensifierProps,
  EngineSpeedDensifierState,
  HighlightInfoDataType,
  HighlightedTimesType,
} from './_types'
import { ChartFetch } from '../../ChartRenderUtilities'
import { measuresToFetch, rifColumnName } from './_constants'

abstract class EngineTorqueDensifier<
  Props extends EngineSpeedDensifierProps,
  State extends EngineSpeedDensifierState
> extends ChartFetch<Props, State> {
  private mounted = false
  private abortController: AbortController = new AbortController()
  protected chartTranslationKeyTitle: string = 'plantAnalysis.lines.engineSpeedDensifierTitle'

  constructor(props: Props, generateState: (props: Props) => State) {
    super(props)
    this.state = generateState(props)
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.dataDensifier }
    const startWorkshiftUnix = moment(this.props.workShift.start).unix()

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const datumTime = moment(datum[0]).unix()
        const time = datumTime >= startWorkshiftUnix ? datumTime : startWorkshiftUnix

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private alignTimeOfHighlightedTimesValuesToAggregationPoints(
    mergedData: any[],
    highlightInfo: HighlightInfoDataType
  ) {
    const newHighlight = {}
    Object.entries(highlightInfo).forEach(([line, data]) => {
      const newLineData = {}
      data.highlightedTimes.forEach((area, index) => {
        if (!(area.end <= moment(this.props.workShift.start).unix())) {
          const startGreaterPointIndex = mergedData.findIndex(obj => obj.time >= area.start)
          const newStart = mergedData[startGreaterPointIndex - 1]?.time ?? moment(this.props.workShift.start).unix()
          const endGreaterPointIndex = mergedData.findIndex(obj => obj.time >= area.end)
          const newEnd = mergedData[endGreaterPointIndex - 1]?.time ?? moment(this.props.workShift.end).unix()
          newLineData[index] = { start: newStart, end: newEnd }
        }
      })
      newHighlight[line] = { ...highlightInfo[line], highlightedTimes: newLineData }
    })
    return newHighlight
  }

  private constructHighlightedData(mergedData: any[], highlightInfo: HighlightInfoDataType) {
    const highlightedAxisKey = 'highlighted'
    const [workshiftStart, workshiftEnd] = this.getShiftDateUnix(
      this.props.date,
      this.props.date,
      this.props.workshift?.start,
      this.props.workshift?.end
    )

    const dangerArea1Points = joinSubsequentAreaSections(
      getAreaSections(mergedData, 'line1Values')
        .map(d => (d.value < THRESHOLDS.densifierTorqueZeroThresholdPercent ? { ...d, value: 0 } : { ...d, value: 1 }))
        .filter(d => d.value === 0)
    )
    const dangerArea2Points = joinSubsequentAreaSections(
      getAreaSections(mergedData, 'line2Values')
        .map(d => (d.value < THRESHOLDS.densifierTorqueZeroThresholdPercent ? { ...d, value: 0 } : { ...d, value: 1 }))
        .filter(d => d.value === 0)
    )
    const dangerArea3Points = joinSubsequentAreaSections(
      getAreaSections(mergedData, 'line3Values')
        .map(d => (d.value < THRESHOLDS.densifierTorqueZeroThresholdPercent ? { ...d, value: 0 } : { ...d, value: 1 }))
        .filter(d => d.value === 0)
    )
    const dangerArea4Points = joinSubsequentAreaSections(
      getAreaSections(mergedData, 'line4Values')
        .map(d => (d.value < THRESHOLDS.densifierTorqueZeroThresholdPercent ? { ...d, value: 0 } : { ...d, value: 1 }))
        .filter(d => d.value === 0)
    )
    const dangerArea5Points = joinSubsequentAreaSections(
      getAreaSections(mergedData, 'line5Values')
        .map(d => (d.value < THRESHOLDS.densifierTorqueZeroThresholdPercent ? { ...d, value: 0 } : { ...d, value: 1 }))
        .filter(d => d.value === 0)
    )

    const genArea1 = getGeneralArea(
      Object.values(highlightInfo.line1?.highlightedTimes ?? {}).map(v => v),
      highlightedAxisKey,
      [workshiftStart, workshiftEnd]
    )
    const genArea2 = getGeneralArea(
      Object.values(highlightInfo.line2?.highlightedTimes ?? {}).map(v => v),
      highlightedAxisKey,
      [workshiftStart, workshiftEnd]
    )
    const genArea3 = getGeneralArea(
      Object.values(highlightInfo.line3?.highlightedTimes ?? {}).map(v => v),
      highlightedAxisKey,
      [workshiftStart, workshiftEnd]
    )
    const genArea4 = getGeneralArea(
      Object.values(highlightInfo.line4?.highlightedTimes ?? {}).map(v => v),
      highlightedAxisKey,
      [workshiftStart, workshiftEnd]
    )
    const genArea5 = getGeneralArea(
      Object.values(highlightInfo.line5?.highlightedTimes ?? {}).map(v => v),
      highlightedAxisKey,
      [workshiftStart, workshiftEnd]
    )

    return {
      zeroThrs: {
        line1: dangerArea1Points,
        line2: dangerArea2Points,
        line3: dangerArea3Points,
        line4: dangerArea4Points,
        line5: dangerArea5Points,
      },
      fromZeroThrsToPlcThrs: {
        line1: genArea1,
        line2: genArea2,
        line3: genArea3,
        line4: genArea4,
        line5: genArea5,
      },
    }
  }
  private constructData() {
    try {
      let line1Threshold: any = []
      let line1Values: any = []
      let line1SpeedValues: any = []
      let line2Threshold: any = []
      let line2Values: any = []
      let line2SpeedValues: any = []
      let line3Threshold: any = []
      let line3Values: any = []
      let line3SpeedValues: any = []
      let line4Threshold: any = []
      let line4Values: any = []
      let line4SpeedValues: any = []
      let line5Threshold: any = []
      let line5Values: any = []
      let line5SpeedValues: any = []
      const mergedData: any[] = []
      if (this.state.dataDensifier) {
        line1Threshold = this.populateSingleData('line1Threshold')
        line1Values = this.populateSingleData('line1Values')
        line1SpeedValues = this.populateSingleData('line1SpeedValues')
        line2Threshold = this.populateSingleData('line2Threshold')
        line2Values = this.populateSingleData('line2Values')
        line2SpeedValues = this.populateSingleData('line2SpeedValues')
        line3Threshold = this.populateSingleData('line3Threshold')
        line3Values = this.populateSingleData('line3Values')
        line3SpeedValues = this.populateSingleData('line3SpeedValues')
        line4Threshold = this.populateSingleData('line4Threshold')
        line4Values = this.populateSingleData('line4Values')
        line4SpeedValues = this.populateSingleData('line4SpeedValues')
        line5Threshold = this.populateSingleData('line5Threshold')
        line5Values = this.populateSingleData('line5Values')
        line5SpeedValues = this.populateSingleData('line5SpeedValues')
      }

      hydrateData(
        {
          line1Threshold,
          line1Values,
          line1SpeedValues,
          line2Threshold,
          line2Values,
          line2SpeedValues,
          line3Threshold,
          line3Values,
          line3SpeedValues,
          line4Threshold,
          line4Values,
          line4SpeedValues,
          line5Threshold,
          line5Values,
          line5SpeedValues,
        },
        mergedData,
        undefined,
        0
      )

      mergedData.sort((a, b) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
      })

      hydrateTimeData(
        [
          'line1Threshold',
          'line1Values',
          'line1SpeedValues',
          'line2Threshold',
          'line2Values',
          'line2SpeedValues',
          'line3Threshold',
          'line3Values',
          'line3SpeedValues',
          'line4Threshold',
          'line4Values',
          'line4SpeedValues',
          'line5Threshold',
          'line5Values',
          'line5SpeedValues',
        ],
        mergedData,
        this.state
      )

      const updatedHighlightedData = this.alignTimeOfHighlightedTimesValuesToAggregationPoints(
        mergedData,
        this.state.highlightInfoData
      )
      const warnAreasPoints = this.constructHighlightedData(mergedData, updatedHighlightedData)
      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
          warnAreas: warnAreasPoints,
          fetching: false,
          errorDensifier: false,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          errorDensifier: false,
          data: null,
        })
      }
    }
  }

  private formatData(data: any) {
    const resultData = [
      data.columns
        .map((value: any, index: number) => {
          if (value !== 'time' && value !== 'shift') {
            return {
              [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
            }
          }
        })
        .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {}),
    ]
    this.generateKey(resultData[0])
  }

  private generateKey(data: Record<string, any>) {
    try {
      const keys = Object.keys(data)
      const truncateKey = keys.map(k => k.match('[a-z-A-Z-0-9]{1,}_[a-z-A-Z-0-9]{1,}'))
      const splitKey = truncateKey.map(k => k && k[0]).filter(k => k !== null) as string[]
      this.setState({
        keyCollection: splitKey.filter((v, i, a) => a.indexOf(v) === i),
        data: splitKey
          .filter((v, i, a) => a.indexOf(v) === i)
          .map(key => ({
            [key]: Object.entries(data)
              .filter(([keyFilter, value]) => keyFilter.includes(key))
              .map(([keyMap, value]) => {
                return {
                  value,
                  key: keyMap,
                }
              }),
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      })
    } catch (error) {
      this.setState({
        data: null,
        fetching: false,
        error: false,
      })
    }
  }

  private getShiftDate(startDate: string | number, endDate: string | number, workShiftStart: any, workShiftEnd: any) {
    const startOfDay = mvDate.format(
      mvDate.startOfDay(mvDate.getDateFromString(startDate.toString())),
      'yyyy-MM-dd HH:mm:ss'
    )

    const startDateResult = workShiftStart
      ? mvDate.getDateFromString(workShiftStart.toString()).toISOString()
      : startOfDay

    const endOfDay = mvDate.format(mvDate.endOfDay(mvDate.getDateFromString(endDate.toString())), 'yyyy-MM-dd HH:mm:ss')
    const endDateResult = workShiftEnd ? mvDate.getDateFromString(workShiftEnd.toString()).toISOString() : endOfDay

    return [startDateResult, endDateResult]
  }

  private getShiftDateUnix(
    startDate: string | number,
    endDate: string | number,
    workShiftStart: any,
    workShiftEnd: any
  ) {
    const [start, end] = this.getShiftDate(startDate, endDate, workShiftStart, workShiftEnd)
    return [moment(start).unix(), moment(end).unix()]
  }

  private getShiftDateUnixMilliseconds(
    startDate: string | number,
    endDate: string | number,
    workShiftStart: any,
    workShiftEnd: any
  ) {
    const [start, end] = this.getShiftDateUnix(startDate, endDate, workShiftStart, workShiftEnd)

    return [start * 1000, end * 1000]
  }

  protected getKeyFromLine(line: 'line1' | 'line2' | 'line3' | 'line4' | 'line5') {
    switch (line) {
      case 'line1':
        return 'dec_f019'
      case 'line2':
        return 'dec_f028'
      case 'line3':
        return 'dec_f076'
      case 'line4':
        return 'dec_f086'
      case 'line5':
        return 'dec_f057'
      default:
        return ''
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift

    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )

      const [startDate, endDate] = this.getShiftDate(this.props.date, this.props.date, workshift.start, workshift?.end)
      const [startDateUnix, endDateUnix] = this.getShiftDateUnixMilliseconds(
        this.props.date,
        this.props.date,
        workshift.start,
        workshift?.end
      )

      const queryStartGrouped = `SELECT PERCENTILE(*, 95) FROM `
      const queryEndGrouped = ` WHERE time >= '${startDate}' AND time <= '${endDate}' GROUP BY time(1m) fill(linear)`
      const queryEndShift = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`

      try {
        const line1Label = 'IBSGN5PHK0N0DCJP'
        const line2Label = 'IBSGN5PHK0OBEPNL'
        const line3Label = 'IBSGN5PP2MAITHBU'
        const line4Label = 'IBSGN5PP2MBA6KED'
        const line5Label = 'IBSGN5PKA065B3AF'

        const highlightedTimeTableBaseLine1 = `vDecF019Events`
        const highlightedTimeTableBaseLine2 = `vDecF028Events`
        const highlightedTimeTableBaseLine3 = `vDecF076Events`
        const highlightedTimeTableBaseLine4 = `vDecF086Events`
        const highlightedTimeTableBaseLine5 = `vDecF057Events`

        const rifColumnTableName = `"${rifColumnName}"`
        const startColumnTableName = `"start"`
        const endColumnTableName = `"stop"`
        const highlightColumnTableName = `"highlight"`
        const highlightCountQueryStart = `SELECT COUNT(${startColumnTableName}) FROM `
        const highlightTimesQueryStart = `SELECT ${startColumnTableName}, ${endColumnTableName} FROM `
        const customQueryStart = `SELECT ${measuresToFetch.map((m, index) => `"${m}"`)} FROM `
        const query = customQueryStart + `vDecSpeedTorque` + queryEndShift

        const queryEndHighlightTime = ` WHERE ((${endColumnTableName} >= ${startDateUnix} AND ${endColumnTableName} <= ${endDateUnix}) OR (${startColumnTableName} >= ${startDateUnix} AND ${startColumnTableName} <= ${endDateUnix}))`
        const queryEndHighlightCount = ` WHERE ((${endColumnTableName} >= ${startDateUnix} AND ${endColumnTableName} <= ${endDateUnix}) OR (${startColumnTableName} >= ${startDateUnix} AND ${startColumnTableName} <= ${endDateUnix})) AND ${rifColumnTableName} != '0'`

        const highlightCountQueryEnd = ` ${queryEndHighlightCount} GROUP BY ${rifColumnTableName}`
        const highlightTimeQueryEnd = ` ${queryEndHighlightTime} AND ${highlightColumnTableName} = true`

        const highlighCountLine1 = `${highlightCountQueryStart}${highlightedTimeTableBaseLine1}${highlightCountQueryEnd}`
        const highlighCountLine2 = `${highlightCountQueryStart}${highlightedTimeTableBaseLine2}${highlightCountQueryEnd}`
        const highlighCountLine3 = `${highlightCountQueryStart}${highlightedTimeTableBaseLine3}${highlightCountQueryEnd}`
        const highlighCountLine4 = `${highlightCountQueryStart}${highlightedTimeTableBaseLine4}${highlightCountQueryEnd}`
        const highlighCountLine5 = `${highlightCountQueryStart}${highlightedTimeTableBaseLine5}${highlightCountQueryEnd}`

        const highlighLine1Time = `${highlightTimesQueryStart}${highlightedTimeTableBaseLine1}${highlightTimeQueryEnd}`
        const highlighLine2Time = `${highlightTimesQueryStart}${highlightedTimeTableBaseLine2}${highlightTimeQueryEnd}`
        const highlighLine3Time = `${highlightTimesQueryStart}${highlightedTimeTableBaseLine3}${highlightTimeQueryEnd}`
        const highlighLine4Time = `${highlightTimesQueryStart}${highlightedTimeTableBaseLine4}${highlightTimeQueryEnd}`
        const highlighLine5Time = `${highlightTimesQueryStart}${highlightedTimeTableBaseLine5}${highlightTimeQueryEnd}`

        const line1VAlues = queryStartGrouped + `"${line1Label}_MT_DEC_TORQUE"` + queryEndGrouped
        const line1SpeedValues = queryStartGrouped + `"${line1Label}_SPEED_VITI_DEC"` + queryEndGrouped

        const line2VAlues = queryStartGrouped + `"${line2Label}_MT_DEC_TORQUE"` + queryEndGrouped
        const line2SpeedValues = queryStartGrouped + `"${line2Label}_SPEED_VITI_DEC"` + queryEndGrouped

        const line3VAlues = queryStartGrouped + `"${line3Label}_MT_DEC_TORQUE"` + queryEndGrouped
        const line3SpeedValues = queryStartGrouped + `"${line3Label}_SPEED_VITI_DEC"` + queryEndGrouped

        const line4VAlues = queryStartGrouped + `"${line4Label}_MT_DEC_TORQUE"` + queryEndGrouped
        const line4SpeedValues = queryStartGrouped + `"${line4Label}_SPEED_VITI_DEC"` + queryEndGrouped

        const line5VAlues = queryStartGrouped + `"${line5Label}_MT_DEC_TORQUE"` + queryEndGrouped
        const line5SpeedValues = queryStartGrouped + `"${line5Label}_SPEED_VITI_DEC"` + queryEndGrouped

        const dataTorqueBelt = API().request(`/query?${plantQueryString}q=` + query, {
          signal: this.abortController.signal,
        })

        const dataLine1VAlues = API().request(`/query?${plantQueryString}q=` + line1VAlues, {
          signal: this.abortController.signal,
        })
        const dataLine1SpeedValues = API().request(`/query?${plantQueryString}q=` + line1SpeedValues, {
          signal: this.abortController.signal,
        })

        const dataLine2VAlues = API().request(`/query?${plantQueryString}q=` + line2VAlues, {
          signal: this.abortController.signal,
        })
        const dataLine2SpeedValues = API().request(`/query?${plantQueryString}q=` + line2SpeedValues, {
          signal: this.abortController.signal,
        })

        const dataLine3VAlues = API().request(`/query?${plantQueryString}q=` + line3VAlues, {
          signal: this.abortController.signal,
        })
        const dataLine3SpeedValues = API().request(`/query?${plantQueryString}q=` + line3SpeedValues, {
          signal: this.abortController.signal,
        })

        const dataLine4VAlues = API().request(`/query?${plantQueryString}q=` + line4VAlues, {
          signal: this.abortController.signal,
        })
        const dataLine4SpeedValues = API().request(`/query?${plantQueryString}q=` + line4SpeedValues, {
          signal: this.abortController.signal,
        })

        const dataLine5VAlues = API().request(`/query?${plantQueryString}q=` + line5VAlues, {
          signal: this.abortController.signal,
        })
        const dataLine5SpeedValues = API().request(`/query?${plantQueryString}q=` + line5SpeedValues, {
          signal: this.abortController.signal,
        })

        const dataLine1HighlightedCountValues = API().request(`/query?${plantQueryString}q=` + highlighCountLine1, {
          signal: this.abortController.signal,
        })
        const dataLine2HighlightedCountValues = API().request(`/query?${plantQueryString}q=` + highlighCountLine2, {
          signal: this.abortController.signal,
        })
        const dataLine3HighlightedCountValues = API().request(`/query?${plantQueryString}q=` + highlighCountLine3, {
          signal: this.abortController.signal,
        })
        const dataLine4HighlightedCountValues = API().request(`/query?${plantQueryString}q=` + highlighCountLine4, {
          signal: this.abortController.signal,
        })
        const dataLine5HighlightedCountValues = API().request(`/query?${plantQueryString}q=` + highlighCountLine5, {
          signal: this.abortController.signal,
        })

        const dataLine1HighlightedTimeValues = API().request(
          `/query?${plantQueryString}${stringify({
            q: highlighLine1Time,
          })}`,
          {
            signal: this.abortController.signal,
          }
        )
        const dataLine2HighlightedTimeValues = API().request(
          `/query?${plantQueryString}${stringify({
            q: highlighLine2Time,
          })}`,
          {
            signal: this.abortController.signal,
          }
        )
        const dataLine3HighlightedTimeValues = API().request(
          `/query?${plantQueryString}${stringify({
            q: highlighLine3Time,
          })}`,
          {
            signal: this.abortController.signal,
          }
        )
        const dataLine4HighlightedTimeValues = API().request(
          `/query?${plantQueryString}${stringify({
            q: highlighLine4Time,
          })}`,
          {
            signal: this.abortController.signal,
          }
        )
        const dataLine5HighlightedTimeValues = API().request(
          `/query?${plantQueryString}${stringify({
            q: highlighLine5Time,
          })}`,
          {
            signal: this.abortController.signal,
          }
        )

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([
          dataTorqueBelt,
          dataLine1VAlues,
          dataLine2VAlues,
          dataLine3VAlues,
          dataLine4VAlues,
          dataLine5VAlues,
          dataLine1SpeedValues,
          dataLine2SpeedValues,
          dataLine3SpeedValues,
          dataLine4SpeedValues,
          dataLine5SpeedValues,
          dataLine1HighlightedCountValues,
          dataLine2HighlightedCountValues,
          dataLine3HighlightedCountValues,
          dataLine4HighlightedCountValues,
          dataLine5HighlightedCountValues,
          dataLine1HighlightedTimeValues,
          dataLine2HighlightedTimeValues,
          dataLine3HighlightedTimeValues,
          dataLine4HighlightedTimeValues,
          dataLine5HighlightedTimeValues,
        ])
          .then(
            ([
              dataTorqueBeltResult,
              dataLine1VAluesResult,
              dataLine2VAluesResult,
              dataLine3VAluesResult,
              dataLine4VAluesResult,
              dataLine5VAluesResult,
              dataLine1SpeedValuesResult,
              dataLine2SpeedValuesResult,
              dataLine3SpeedValuesResult,
              dataLine4SpeedValuesResult,
              dataLine5SpeedValuesResult,
              dataLine1HighlightedCountValuesResult,
              dataLine2HighlightedCountValuesResult,
              dataLine3HighlightedCountValuesResult,
              dataLine4HighlightedCountValuesResult,
              dataLine5HighlightedCountValuesResult,
              dataLine1HighlightedTimeValuesResult,
              dataLine2HighlightedTimeValuesResult,
              dataLine3HighlightedTimeValuesResult,
              dataLine4HighlightedTimeValuesResult,
              dataLine5HighlightedTimeValuesResult,
            ]) => {
              const dataTorqueBeltResultValue =
                typeof dataTorqueBeltResult === 'string' ? JSON.parse(dataTorqueBeltResult) : dataTorqueBeltResult
              if (this.mounted) {
                try {
                  const line1ThresholdAgrValue = [
                    [startDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                    [endDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                  ]
                  const line2ThresholdAgrValue = [
                    [startDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                    [endDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                  ]
                  const line3ThresholdAgrValue = [
                    [startDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                    [endDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                  ]
                  const line4ThresholdAgrValue = [
                    [startDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                    [endDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                  ]
                  const line5ThresholdAgrValue = [
                    [startDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                    [endDate, THRESHOLDS.densifierTorqueUpperThresholdPercent],
                  ]

                  const line1HighlightCountArgValue = extractResponseWithTagsData(dataLine1HighlightedCountValuesResult)
                  const line1HighlightTimeArgValue = extractResponseData(dataLine1HighlightedTimeValuesResult)
                  const line2HighlightCountArgValue = extractResponseWithTagsData(dataLine2HighlightedCountValuesResult)
                  const line2HighlightTimeArgValue = extractResponseData(dataLine2HighlightedTimeValuesResult)
                  const line3HighlightCountArgValue = extractResponseWithTagsData(dataLine3HighlightedCountValuesResult)
                  const line3HighlightTimeArgValue = extractResponseData(dataLine3HighlightedTimeValuesResult)
                  const line4HighlightCountArgValue = extractResponseWithTagsData(dataLine4HighlightedCountValuesResult)
                  const line4HighlightTimeArgValue = extractResponseData(dataLine4HighlightedTimeValuesResult)
                  const line5HighlightCountArgValue = extractResponseWithTagsData(dataLine5HighlightedCountValuesResult)
                  const line5HighlightTimeArgValue = extractResponseData(dataLine5HighlightedTimeValuesResult)
                  this.setState(
                    {
                      dataDensifier: Object.assign({}, this.state.dataDensifier, {
                        line1Threshold: { data: line1ThresholdAgrValue },
                        line1Values: { data: extractResponseData(dataLine1VAluesResult) },
                        line1SpeedValues: { data: extractResponseData(dataLine1SpeedValuesResult) },
                        line2Threshold: { data: line2ThresholdAgrValue },
                        line2Values: { data: extractResponseData(dataLine2VAluesResult) },
                        line2SpeedValues: { data: extractResponseData(dataLine2SpeedValuesResult) },
                        line3Threshold: { data: line3ThresholdAgrValue },
                        line3Values: { data: extractResponseData(dataLine3VAluesResult) },
                        line3SpeedValues: { data: extractResponseData(dataLine3SpeedValuesResult) },
                        line4Threshold: { data: line4ThresholdAgrValue },
                        line4Values: { data: extractResponseData(dataLine4VAluesResult) },
                        line4SpeedValues: { data: extractResponseData(dataLine4SpeedValuesResult) },
                        line5Threshold: { data: line5ThresholdAgrValue },
                        line5Values: { data: extractResponseData(dataLine5VAluesResult) },
                        line5SpeedValues: { data: extractResponseData(dataLine5SpeedValuesResult) },
                      }),
                      highlightInfoData: {
                        line1: {
                          counts: {
                            ...line1HighlightCountArgValue.reduce(
                              (acc, curr) => ({ ...acc, [curr.tags[rifColumnName]]: curr.values[1] }),
                              {}
                            ),
                          },
                          highlightedTimes: line1HighlightTimeArgValue.map(
                            (v: any) =>
                              ({ end: moment(v[2]).unix(), start: moment(v[1]).unix() } as HighlightedTimesType)
                          ),
                        },
                        line2: {
                          counts: {
                            ...line2HighlightCountArgValue.reduce(
                              (acc, curr) => ({ ...acc, [curr.tags[rifColumnName]]: curr.values[1] }),
                              {}
                            ),
                          },
                          highlightedTimes: line2HighlightTimeArgValue.map(
                            (v: any) =>
                              ({ end: moment(v[2]).unix(), start: moment(v[1]).unix() } as HighlightedTimesType)
                          ),
                        },
                        line3: {
                          counts: {
                            ...line3HighlightCountArgValue.reduce(
                              (acc, curr) => ({ ...acc, [curr.tags[rifColumnName]]: curr.values[1] }),
                              {}
                            ),
                          },
                          highlightedTimes: line3HighlightTimeArgValue.map(
                            (v: any) =>
                              ({ end: moment(v[2]).unix(), start: moment(v[1]).unix() } as HighlightedTimesType)
                          ),
                        },
                        line4: {
                          counts: {
                            ...line4HighlightCountArgValue.reduce(
                              (acc, curr) => ({ ...acc, [curr.tags[rifColumnName]]: curr.values[1] }),
                              {}
                            ),
                          },
                          highlightedTimes: line4HighlightTimeArgValue.map(
                            (v: any) =>
                              ({ end: moment(v[2]).unix(), start: moment(v[1]).unix() } as HighlightedTimesType)
                          ),
                        },
                        line5: {
                          counts: {
                            ...line5HighlightCountArgValue.reduce(
                              (acc, curr) => ({ ...acc, [curr.tags[rifColumnName]]: curr.values[1] }),
                              {}
                            ),
                          },
                          highlightedTimes: line5HighlightTimeArgValue.map(
                            (v: any) =>
                              ({ end: moment(v[2]).unix(), start: moment(v[1]).unix() } as HighlightedTimesType)
                          ),
                        },
                      },
                      fetching: false,
                      error: false,
                    },
                    () => {
                      this.constructData()
                    }
                  )
                } catch (error) {
                  this.setState({
                    fetching: false,
                    data: null,
                    errorDensifier: false,
                  })
                }
                try {
                  this.formatData(dataTorqueBeltResultValue.results[0].series[0])
                } catch (error) {
                  this.setState({
                    fetching: false,
                    error: false,
                    data: null,
                  })
                }
              }
              if (dataTorqueBeltResultValue.results[0].error) {
                this.setState({
                  data: null,
                  fetching: false,
                  error: true,
                })
              }
            }
          )
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              fetching: false,
              error: true,
              errorDensifier: true,
            })
          })
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetching: false,
          error: true,
          errorDensifier: true,
        })
      }
    }
  }

  protected abstract renderContent(): JSX.Element
  public render() {
    const { fetching, error } = this.state

    if (fetching) {
      return this.renderLoading()
    }

    if (error) {
      return this.renderError()
    }

    if (!this.state.data || this.state.filteredData && this.state.filteredData.length === 0) {
      return this.renderNoData()
    }


    return this.renderContent()
  }
}

export default EngineTorqueDensifier
