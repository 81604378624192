const ON_OFF = {
  0: '#F55A62', // off
  1: '#92D050', // on
} as const

const OPEN_CLOSE = {
  0: '#92D050', // open
  1: '#F55A62', // close
} as const

const COMMAND = {
  0: '#FF99CC', // manuale
  1: '#92D050', // automatico
  2: '#6290C3', // locale
  3: '#FFD789', // sezionato
} as const

const PROGRAM = {
  1: '#FF99CC', // P1
  2: '#92D050', // P2
  3: '#6290C3', // P3
  4: '#FFD789', // P4
} as const

const BUFFER_LOADED = {
  0: '#EEE',
  1: '#FF99CC', // P1
  2: '#92D050', // P2
  3: '#6290C3', // P3
  4: '#FFD789', // P4
  5: '#BE5A38',
} as const

const DIRECTION = {
  0: '#F55A62', // in
  1: '#00B0F0', // av
}

const DENSIFIER_BELT_STATE = {
  0: '#F55A62', // off
  1: '#92D050', // on
  2: '#FF99CC', // allarme
  3: '#FFD789', // sezionato
  4: '#6290C3', // pronto
}

export const COLORS: Record<string, Record<number, string>> = {
  command: COMMAND,
  direction: DIRECTION,
  running: ON_OFF,
  program: PROGRAM,
  sync: ON_OFF,
  valvePosition: OPEN_CLOSE,
  key1Inserted: ON_OFF,
  key2Inserted: ON_OFF,
  key3Inserted: ON_OFF,
  key4Inserted: ON_OFF,
  key5Inserted: ON_OFF,
  key6Inserted: ON_OFF,
  key7Inserted: ON_OFF,
  key8Inserted: ON_OFF,
  key9Inserted: ON_OFF,
  key10Inserted: ON_OFF,
  key11Inserted: ON_OFF,
  key12Inserted: ON_OFF,
  key13Inserted: ON_OFF,
  key14Inserted: ON_OFF,
  bufferLoadedWithF015: BUFFER_LOADED,
  bufferLoadedWithF070: BUFFER_LOADED,
  densifierBeltState: DENSIFIER_BELT_STATE,
} as const
