import { detect } from 'detect-browser'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import routes from '../../../../../inplant-config/config/routes'
import initServices from '../../../../../inplant-core-fe/services/initServices'
import Main from '../Main/Main'
import SideBar from '../SideBar/SideBar'
import TopBar from '../TopBar/TopBar'
import SidebarBottomWidget from '../SidebarBottomWidget/SidebarBottomWidget'
import ReactNotification from '../../../../../inplant-components-fe/ui/components/MVNotification/Notification'
import { ContainerProvider } from '../../../../../inplant-components-fe/services/binding'

const browser = detect()
const container = initServices()

interface StateProps {
  loginSuccess: boolean
  loginSuccessPath: string
  userRoles: string[]
}

export type Props = StateProps & WithTranslation

export const mapStateToProps = (state: any): StateProps => ({
  loginSuccess: state.auth.loggedIn,
  loginSuccessPath: state.config.generic.loginSuccessPath,
  userRoles: state.auth.user ? state.auth.user.roles : [],
})

class Layout extends React.Component<Props> {
  public render() {
    if (browser && browser.name !== 'ie') {
      const userRoutes = routes(this.props.userRoles)

      return (
        <ContainerProvider container={container}>
          <Router>
            <div id="inplant">
              {this.props.loginSuccess && (
                <SideBar loginSuccessPath={this.props.loginSuccessPath} routes={userRoutes} />
              )}
              <TopBar routes={userRoutes} />
              <ReactNotification className={'notifications-component--core'} />
              <Main routes={userRoutes} />
            </div>
            {this.props.loginSuccess && <SidebarBottomWidget />}
          </Router>
        </ContainerProvider>
      )
    } else {
      return (
        <div className="container">
          <div className="alert alert-warning mt-5" role="alert">
            <h4 className="alert-heading">{this.props.t('ieDetected.title')}</h4>
            <p>{this.props.t('ieDetected.subTitle')}</p>
            <hr />
            <p className="mb-0">
              {this.props.t('ieDetected.description')}{' '}
              <a href="https://www.mozilla.org/it/firefox/new/" target="_blank">
                Mozilla Firefox
              </a>{' '}
              {this.props.t('ieDetected.or')}{' '}
              <a href="https://www.google.com/intl/it/chrome/" target="_blank">
                Google Chrome
              </a>
              .
            </p>
          </div>
        </div>
      )
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(Layout))
