import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { API } from '../../../../../redux/actions'
import { LineLoadingBeltProps, LineLoadingBeltState } from './_types'
import { measuresToFetch } from './_costants'
import { ChartFetch } from '../../ChartRenderUtilities'

abstract class LineLoadingBelt<
  Props extends LineLoadingBeltProps,
  State extends LineLoadingBeltState
> extends ChartFetch<Props, State> {
  private mounted = false
  private abortController: AbortController = new AbortController()
  protected chartTranslationKeyTitle: string = 'plantAnalysis.lines.loadingBeltTitle'

  constructor(props: Props, generateState: (props: Props) => State) {
    super(props)

    this.state = generateState(props)
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<LineLoadingBeltProps>) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private jsonifyInfluxData(data: any) {
    return data.columns
    .map((value: any, index: number) => {
      if (value !== 'time' && value !== 'shift') {
        return {
          [value]: data.values[0][index],
        }
      }
    })
    .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {})
  }

  private formatData(data: any) {
    try {
      const jsonizedData = this.jsonifyInfluxData(data)
      const dataGrouped = Object.entries(jsonizedData).reduce((acc, [key, value]) => {
        const match = key.match(/(buffer_f\d+)_([\w_]+)/);
        if (match) {
          const [ , bufferKey, metricKey ] = match;
          (acc[bufferKey] ??= {})[metricKey] = value;
        }
        return acc;
      }, {});
      
      const filteredDataGrouped =  Object.entries(jsonizedData).reduce((acc, [key, value]) => {
        const match = key.match(/(buffer_f\d+)_([\w_]+)/);
        if (match) {
          const [ , bufferKey, metricKey ] = match;
          (acc[bufferKey] ??= []).push({name: metricKey, value});
        }
        return acc;
      }, {});

      this.setState({
        data: dataGrouped,
        filteredData: filteredDataGrouped
      })
    } catch (error) {
      this.setState({
        data: null,
        fetching: false,
        error: true,
      })
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift
    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const queryEnd = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`

      try {
        const queryStart = `SELECT ${measuresToFetch.map((m, index) => `"${m}"`)} FROM `
        const bufferStateQuery = queryStart + `vLoadingBeltsSpeed` + queryEnd

        const dataBufferState = API().request(`/query?${plantQueryString}q=` + bufferStateQuery, {
          signal: this.abortController.signal,
        })

        Promise.all([dataBufferState])
          .then(([dataBufferStateResult]) => {
            try {
              const dataBufferStateResultValue =
                typeof dataBufferStateResult === 'string' ? JSON.parse(dataBufferStateResult) : dataBufferStateResult
              if (this.mounted && dataBufferStateResultValue.results[0].series[0]) {
                this.formatData(dataBufferStateResultValue.results[0].series[0])
                this.setState({
                  fetching: false,
                  error: false,
                })
              }
              if (dataBufferStateResultValue.results[0].error) {
                this.setState({
                  data: null,
                  fetching: false,
                  error: false,
                })
              }
            } catch (error) {
              this.setState({
                data: null,
                fetching: false,
                error: false,
              })
            }
          })
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              fetching: false,
              error: true,
            })
          })
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  protected abstract renderContent(): JSX.Element
  public render() {
    const { fetching, error } = this.state

    if (fetching) {
      return this.renderLoading()
    }

    if (error) {
      return this.renderError()
    }

    if (!this.state.data) {
      return this.renderNoData()
    }

    return this.renderContent()
  }
}

export default LineLoadingBelt
