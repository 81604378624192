const it = {
  general: {
    mv4iot: 'MV4IoT',
    userLogout: 'Logout',
  },
  additionalRoutes: {
    emergency: 'Squadra Emergenza',
    maintenance: 'Manutenzioni',
  },
  ieDetected: {
    title: 'Browser non supportato',
    subTitle: 'Il portale al momento non supporta questa versione del browser.',
    description: "Per usufruire di tutte le potenzialità del portale si consiglia di utilizzare l'ultima versione di",
    or: 'o di',
  },
  plantSelectorWidget: {
    options: {
      disabled: 'Modulo non attivo per questo impianto',
      null: 'Select plant',
      cd: 'Codroipo',
      cm: 'Cairo Montenotte',
      go: 'Godega',
      'plant-name': 'plant-name',
      re: 'Reggio Emilia',
      ri: "Rive d'Arcano",
      ro: 'Rovigo',
      sg: 'San Giorgio di Nogaro',
      visor: 'visor',
    },
    plantChangedToAllowed: "I dati per l'impianto di {{fromPlant}} non sono disponibili.\nVengono visualizzati i dati dell'impianto di {{toPlant}}"
  },
}

export default it
