import { RouteProps } from '@mv-submodules/inplant-core-fe/types'
// import maintenanceRoutes from '@mv-submodules/inplant-maintenance-fe/routes'
// import { routes as checklistRoutes } from '@mv-submodules/inplant-checklist-fe-iblu'
// import { routes as mcsRoutes } from '@mv-submodules/inplant-mcs-fe-iblu'
// import { faStopCircle } from "@fortawesome/free-solid-svg-icons/faStopCircle";
// import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
// import { config } from './reducers'
// // import { ScheduledJobDetailComponent, TargetComponent } from '@mv-submodules/inplant-maintenance-fe'
import userRoutes from '@mv-submodules/inplant-user-fe/ui/routes'
import { generateRoutes } from '@mv-submodules/inplant-chronoframe-fe'
import PlantHaltList from '@mv-submodules/inplant-fermiimpianto-fe-iblu/ui/components/views/PlantHaltList/PlantHaltListPageView'
// import { filterRoutes } from '@mv-submodules/inplant-core-fe/ui/routes'
import { routes as titechRoutes } from '@mv-submodules/inplant-titech-fe-iblu'
import { routes as plantAnalysisRoutes } from '@mv-submodules/inplant-plantanalysis-fe-iblu'
// import { faStopCircle } from '@fortawesome/free-solid-svg-icons'
import { routes as plantStatusRoutes } from '@mv-submodules/inplant-plantstatus-fe'
// import { faStopCircle } from '@fortawesome/free-solid-svg-icons/faStopCircle'
import { RoutePropsCoreWithLegacy, RoutePropsLegacy } from '../../inplant-coreadapter-fe/types/routes'
import { mapRoutePropsLegacyToRoutePropsCoreWithLegacy } from '../../inplant-coreadapter-fe/functions/routeAdapter'
import { routes as assetManagerRoutes } from '@mv-submodules/inplant-asset-manager-fe'
import attachInplantWrapperTo from '../../inplant-coreadapter-fe/ui/components/widgets/InplantWrapper/InplantWrapperHOC'

const userCan = (roles: string[] | boolean, userRoles: string[]) => {
  if (typeof roles === 'boolean') {
    return roles
  }
  return userRoles.filter(value => -1 !== roles.indexOf(value)).length > 0
}

const composeRoutes = (moduleRoutes: RoutePropsLegacy[], userRoles: string[]) => {
  return moduleRoutes.map(routeGroup => {
    const aclRoute = routeGroup
    switch (routeGroup.path) {
      case '/checklist/models-assets':
        aclRoute.visible = userCan(true, userRoles)
        break
      case '/mcs/today':
        aclRoute.visible = userCan(
          ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAG'],
          userRoles,
        )
        break
      case '/maintenance/calendar':
        aclRoute.visible = userCan(
          ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'MAN', 'VRPROD'],
          userRoles,
        )
        break
      case '/scadenziario/manager':
        aclRoute.visible = userCan(['DID', 'ASPP', 'REA', 'RA'], userRoles)
        break
      case 'http://sg.inplant.hubme.in/manutenzioni-beni/da-fare/':
        aclRoute.visible = userCan(
          ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'MAN'],
          userRoles,
        )
        break
    }

    // root's childs visibility, should include all child acls
    if (routeGroup.children) {
      aclRoute.children = routeGroup.children.map((childRoute: RoutePropsLegacy) => {
        switch (childRoute.path) {
          case '/checklist/models-assets':
            childRoute.visible = userCan(true, userRoles)
            break

          case '/checklist/models':
            childRoute.visible = userCan(true, userRoles)
            break

          case '/checklist/archive':
            childRoute.visible = userCan(
              [
                'RCP',
                'RPROD',
                'VRPROD',
                'RMAG',
                'DID',
                'REA',
                'RSPP',
                'ASPP',
                'CI',
                'ASQ',
                'RSACP',
                'UTM',
                'RMAN',
                'SACP',
                'CSS', 'PRE', 'SEL','ALI'
              ],
              userRoles,
            )
            break

          case '/checklist/personal-archive':
            childRoute.visible = userCan(
              [
                'RCP',
                'RPROD',
                'VRPROD',
                'RMAG',
                'DID',
                'REA',
                'RSPP',
                'ASPP',
                'CI',
                'ASQ',
                'RSACP',
                'UTM',
                'RMAN',
                'SACP',
                'CSS', 'PRE', 'SEL','ALI'
              ],
              userRoles,
            )
            break

          case '/checklist/validations':
            childRoute.visible = userCan(['RCP', 'RPROD', 'MAG', 'RMAG', 'ASPP', 'ASQ'], userRoles)
            break

          case '/checklist/reset-asset-hours':
            childRoute.visible = userCan(['RSACP', 'SACP', 'RCP', 'UTM', 'PRE', 'SEL','ALI'], userRoles)
            break

          case '/checklist/gia':
            childRoute.visible = userCan(['RMAG', 'RPROD', 'VRPROD', 'SACP', 'RSACP', 'DID', 'REA', 'MAG'], userRoles)
            break

          case '/mcs/today':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAG'],
              userRoles,
            )
            break

          case '/maintenance/calendar':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'VRPROD'],
              userRoles,
            )
            break

          case '/maintenance/calendarize':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'VRPROD'],
              userRoles,
            )
            break

          case '/maintenance/overdue-anomalies':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'MAN', 'VRPROD'],
              userRoles,
            )
            break

          case '/maintenance/anomalies':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'MAN', 'VRPROD'],
              userRoles,
            )
            break

          case '/maintenance/archive':
            childRoute.visible = userCan(
              ['RSACP', 'SACP', 'RCP', 'DID', 'REA', 'RSPP', 'ASPP', 'CI', 'ASQ', 'UTM', 'RMAN', 'VRPROD'],
              userRoles,
            )
            break
          case '/scadenziario/manager':
            childRoute.visible = userCan(['RQ', 'RA', 'ASPP', 'RSCOMM', 'SCOMM', 'SVA', 'UTM'], userRoles)
            break

          // Hide titech setup route in Visor
          case '/titech/setup':
            childRoute.visible = false
          case '/titech':
            childRoute.visible = false
        }
        return childRoute
      })
    }
    return aclRoute
  })
}

/*
const emergencyRoute: RouteProps = {
  path: 'http://sg.inplant.hubme.in/squadra-emergenza/index?v2=true',
  icon: faCaretRight,
  i18nkey: 'additionalRoutes.emergency',
  visible: true,
  external: true,
}

const maintenanceRoute: RouteProps = {
  path: 'http://sg.inplant.hubme.in/manutenzioni-beni/da-fare/',
  icon: faCaretRight,
  i18nkey: 'additionalRoutes.maintenance',
  visible: true,
  external: true,
}
*/

const plantHaltsRoutes: RouteProps = {
  path: '/plant-halts',
  i18nkey: 'plantHalts.navigation.plantHalts',
  icon: 'stop-circle',
  visible: true,
  component: PlantHaltList,
  /* children: [
    {
      path: '/plant-halts',
      i18nkey: 'plantHalts.navigation.plantHaltsList',
      exact: true,
      component: PlantHaltList,
      visible: true,
    },
  ], */
}

export const aclRoutes = (sroutes: RoutePropsCoreWithLegacy[], forbiddenActions: string[], loginSuccessPath: string) => {
  return sroutes.filter(r => r)/* .map(mapRoutePropsLegacyToRoutePropsCoreWithLegacy) */
}

export const aclRedirect = (loginSuccessPath: string) => loginSuccessPath
// consoleLog('userRoutes', userRoutes)

const routes: (userRoles: string[]) => RoutePropsCoreWithLegacy[] = userRoles => [
  ...composeRoutes(
    [
      attachInplantWrapperTo(plantAnalysisRoutes),
      attachInplantWrapperTo(titechRoutes),
      attachInplantWrapperTo(plantStatusRoutes),
      attachInplantWrapperTo(generateRoutes('scadenziario', ['ongoing', 'overdue', 'user-tasks', 'to-validate', 'archive', 'rejected', 'manager'])),
      attachInplantWrapperTo(generateRoutes('anomalie', ['anomalie-ongoing', 'anomalie-overdue', 'anomalie-archive','anomalie-rejected'])),
      attachInplantWrapperTo(plantHaltsRoutes),
      attachInplantWrapperTo(assetManagerRoutes),
      attachInplantWrapperTo(userRoutes),
      // checklistRoutes,
      // mcsRoutes,
      // maintenanceRoutes,
      // emergencyRoute,
      // maintenanceRoute,
    ],
    userRoles,
  ).map(mapRoutePropsLegacyToRoutePropsCoreWithLegacy)
]

export default routes
