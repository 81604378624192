import { HHHmmssFromMilliseconds, NullPie, RowLegend } from '../../../../../functions/shared'
import PieSmallView from '../../charts/PieSmall/PieSmallView'
import * as React from 'react'
import { COLORS } from '../../../../../constants'
import { withTranslation, WithTranslation } from 'react-i18next'
import Bars from '../../charts/Bars/BarsView'

interface OwnProps {
  data: any
  days: number
  name: string
  id: string
  currentOverrun: any
  currentLimit: null | string
}

type Props = OwnProps & WithTranslation

class BeltGraphRowItemView extends React.PureComponent<Props> {

  public render() {
    const { days } = this.props
    const dataArrestoMarcia: any = this.props.data.find((e: any) => e.name === 'vBeltStop')
    const dataAvantiIndietro: any = this.props.data.find((e: any) => e.name === 'vBeltForward')

    const dataAuto: any = this.props.data.find((e: any) => e.name === 'vBeltAuto')
    const dataMan: any = this.props.data.find((e: any) => e.name === 'vBeltManual')
    const dataLoc: any = this.props.data.find((e: any) => e.name === 'vBeltLocal')
    const dataSez: any = this.props.data.find((e: any) => e.name === 'vBeltSectioned')

    const currentOverrun = this.props.currentOverrun ? parseInt(this.props.currentOverrun, 10) : null
    const currentLimit = this.props.currentLimit ? parseInt(this.props.currentLimit, 10) : null

    const currentValue =
      currentOverrun && currentLimit && currentOverrun > currentLimit
        ? (((currentOverrun - currentLimit) / currentLimit) * 100).toFixed(2) + '%'
        : 0

    const formatTime = (value: number) => HHHmmssFromMilliseconds(value, false, true, true)

    return (
      <div className="belt-row-item col-12 mt-3 page-break-inside-avoid">
        <div className="row pt-1">
          <div className="col-12">
            <h3>{RowLegend(this.props.name)}</h3>
          </div>
        </div>
        <div className="row">
          <div className={`col-6 ${currentValue !== 0 ? 'col-md-3' : 'col-md-4'}`}>
            {dataArrestoMarcia !== undefined && (
              <div className={'row chart-events-pie'}>
                <div className={'col-xxl-3 col-md-6 chart-events-pie--chart'}>
                  <PieSmallView
                    data={[
                      { name: 'true', value: dataArrestoMarcia.value / days },
                      { name: 'false', value: 100 - dataArrestoMarcia.value / days },
                    ]}
                    colors={[
                      { id: 'false', color: COLORS.marciaFermo[1] },
                      { id: 'true', color: COLORS.marciaFermo[0] },
                    ]}
                    width={150}
                  />
                </div>
                <div className={'col-md-6 col-xxl-6'}>
                  <table className={''}>
                    <thead>
                      <tr>
                        <th />
                        <th className="text-right">{this.props.t('plantAnalysis.labels.time')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <span className="label-color-square mr-3" style={{ backgroundColor: COLORS.marciaFermo[0] }} />
                        </td>
                        <td className="text-right"> 
                          {dataArrestoMarcia.duration !== undefined && formatTime(dataArrestoMarcia.duration * 1000)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="label-color-square mr-3" style={{ backgroundColor: COLORS.marciaFermo[1] }} />
                        </td>
                        <td className="text-right">
                          {dataArrestoMarcia.remainingTime !== undefined && formatTime(dataArrestoMarcia.remainingTime * 1000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {dataArrestoMarcia === undefined && <NullPie />}
          </div>
          <div className={`col-6 ${currentValue !== 0 ? 'col-md-3' : 'col-md-4'} `}>
            {dataAvantiIndietro !== undefined && (
              <div className={'row chart-events-pie'}>
                <div className={'col-xxl-3 col-md-6 chart-events-pie--chart'}>
                  <PieSmallView
                    data={[
                      { name: 'true', value: dataAvantiIndietro.value / days },
                      { name: 'false', value: 100 - dataAvantiIndietro.value / days },
                    ]}
                    colors={[
                      { id: 'false', color: COLORS.avind[0] },
                      { id: 'true', color: COLORS.avind[1] },
                    ]}
                    width={150}
                  />
                </div>
                <div className={'col-md-6 col-xxl-6'}>
                  <table className={''}>
                    <thead>
                      <tr>
                        <th />
                        <th className="text-right">{this.props.t('plantAnalysis.labels.time')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <span className="label-color-square mr-3" style={{ backgroundColor: COLORS.avind[0] }} />
                        </td>
                        <td className="text-right">
                          {dataAvantiIndietro.remainingTime !== undefined && formatTime(dataAvantiIndietro.remainingTime * 1000)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="label-color-square mr-3" style={{ backgroundColor: COLORS.avind[1] }} />
                        </td>
                        <td className="text-right">
                          {dataAvantiIndietro.duration !== undefined && formatTime(dataAvantiIndietro.duration * 1000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {dataAvantiIndietro === undefined && <NullPie />}
          </div>
          <div className={`mt-5 mt-md-0 ${currentValue !== 0 ? 'col-md-3' : 'col-md-4'}`}>
            {dataAuto !== undefined && dataMan !== undefined && dataLoc !== undefined && (
              <Bars
                data={[
                  {
                    man: dataMan.value ? parseFloat(dataMan.value.toFixed(2)) / days : 0,
                    auto: dataAuto.value ? parseFloat(dataAuto.value.toFixed(2)) / days : 0,
                    loc: dataLoc.value ? parseFloat(dataLoc.value.toFixed(2)) / days : 0,
                    sec: dataSez.value ? parseFloat(dataSez.value.toFixed(2)) / days : 0,
                  },
                ]}
                colors={{
                  man: COLORS.manAutoLocSec[0],
                  auto: COLORS.manAutoLocSec[1],
                  loc: COLORS.manAutoLocSec[2],
                  sec: COLORS.manAutoLocSec[3],
                }}
              />
            )}
          </div>
          {currentValue !== 0 && (
            <div className="col-md-2 pt-4">
              {RowLegend(
                'Sforamento Assorbimento',
                currentValue,
                null,
                <React.Fragment>
                  <strong>{this.props.t('plantAnalysis.labels.mean')}</strong>: {currentOverrun}
                  <br />
                  <strong>{this.props.t('plantAnalysis.labels.limit')}</strong>: {currentLimit}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation()(BeltGraphRowItemView)
