import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import * as moment from 'moment'
import * as React from 'react'
// import { AreaSection } from '../../../../types/measure'
// import { /* getAreaPauses, */ getAreaSections, reduceWarnAreas */ } from '../../../../functions/shared'
import { reduceWarnAreas, getAreaSections } from '../../../../../../functions/shared'
import { withTranslation, WithTranslation } from 'react-i18next'
import { AreaSection } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/measure'
import { WarnAreasData } from '../../utils/hydrate'

export interface AdditionalChartLineData {
  measure: string
  type: AdditionalChartLineDataType
  unit?: string
  color?: string
  label?: string
}

export type AdditionalChartLineDataType = 'perc' | 'value' | 'byte'

export interface OwnProps {
  additionalChartLines: AdditionalChartLineData[]
  warnAreasData: WarnAreasData[]
  filteredData: any
}

export interface OwnState {
  showTooltip: boolean
  tooltipData: any
}
export const COLORS = {
  0: '#26c0c7',
  1: '#5151d3',
  2: '#e68619',
  3: '#d83790',
  4: '#2680eb',
  5: '#9bec54',
  6: '#268e6c',
  7: '#dfbf00',
  8: '#6f38b1',
  9: '#9089fa',
  10: '#47e26f',
  11: '#cb6f10',
}
type Props = OwnProps & WithTranslation

class GenericLineGraph extends React.PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showTooltip: false,
      tooltipData: null,
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  private getLineColor(index: number): string {
    return COLORS[index as keyof typeof COLORS] || '#000000'
  }

  private static nullTooltipContent({ active }: { active: boolean }) {
    if (active) {
      return <div className="null-tooltip-content" />
    }

    return null
  }

  public render() {
    const { additionalChartLines, filteredData } = this.props
    const uniqueUnits = [...new Set(this.props.additionalChartLines.map(obj => obj.unit))]
    const showYAxis = uniqueUnits.length <= 2
    const warnAreas = this.props.warnAreasData.reduce((acc: JSX.Element[], value) => {
      const warnPoints: AreaSection[] | undefined = getAreaSections(this.props.filteredData, value.slug).filter(
        e => e.value === 100
      )
      const warnArea = reduceWarnAreas(warnPoints, 'axis_perc', value.color)
      if (warnArea) {
        return [...acc, ...warnArea]
      }
      return acc
    }, [])

    return (
      <React.Fragment>
        <div className="col-md-10" style={{ padding: !showYAxis ? '0 80px' : '0 20px' }}>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={700}
              height={400}
              data={filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              onMouseEnter={this.handleMouseEnter}
              onMouseMove={this.handleMouseMove}
              onMouseLeave={this.handleMouseLeave}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                hide={true}
                dataKey="time"
                label={{ value: '', position: 'insideBottomRight', offset: 0 }}
                height={30}
                axisLine={false}
                tickLine={false}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'number'}
              />

              {showYAxis && (
                <YAxis
                  label={uniqueUnits[0] ? `(${uniqueUnits[0]})` : ''}
                  domain={[0, 'auto']}
                  axisLine={true}
                  yAxisId={'axis_perc'}
                  orientation={'left'}
                />
              )}
              {showYAxis && (
                <YAxis
                  label={uniqueUnits[1] ? `(${uniqueUnits[1]})` : ''}
                  domain={[0, 'auto']}
                  axisLine={true}
                  yAxisId={'axis_value'}
                  orientation={'right'}
                />
              )}

              <CartesianGrid stroke="#f5f5f5" />

              {warnAreas}

              <Tooltip content={GenericLineGraph.nullTooltipContent} />
              {additionalChartLines &&
                additionalChartLines.map((ac, index) => (
                  <Line
                    key={ac.measure}
                    isAnimationActive={false}
                    dataKey={ac.measure}
                    stroke={ac.color ?? this.getLineColor(index)}
                    dot={false}
                    strokeWidth={2}
                    type="step"
                    yAxisId={ac.unit === uniqueUnits[0] ? 'axis_perc' : 'axis_value'}
                    strokeOpacity={0.8}
                  />
                ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="col-md-2">
          {this.state.showTooltip && this.state.tooltipData !== null && (
            <ul className="side-tooltip">
              <li>
                <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                <br />
                <span>{moment(this.state.tooltipData.time, 'X').format('HH:mm')}</span>
              </li>

              {additionalChartLines &&
                additionalChartLines.map((ac, index) => {
                  const label = ac.label ?? this.props.t('plantAnalysis.labels.' + ac.measure)
                  const color = ac.color ?? this.getLineColor(index)
                  return (
                    <li key={ac.measure}>
                      <strong>{label}</strong>
                      <br />
                      <span className="label-color label-color-line" style={{ backgroundColor: color }} />
                      <span>
                        {this.state.tooltipData[ac.measure]} {ac.unit}
                      </span>
                    </li>
                  )
                })}
            </ul>
          )}
        </div>
      </React.Fragment>
    )
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      let curData = this.props.filteredData && this.props.filteredData.find((e: any) => e.time === args.activeLabel)

      if (curData) {
        this.setState({
          showTooltip: true,
          tooltipData: {
            time: curData.time,
            ...this.props.additionalChartLines?.reduce(
              (acc, curr) => ({
                ...acc,
                [curr.measure]: curData[curr.measure] ? curData[curr.measure].toFixed(2) : '--',
              }),
              {}
            ),
          },
        })
      }

      curData = null
    }
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }
}

export default withTranslation()(GenericLineGraph)
