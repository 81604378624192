// * -------------------------------- NPM --------------------------------------
import { useEffect, useState } from 'react'
import { IDispatch, useComponentDispatch } from '../../services/stateManager'
import { useHasMounted } from './hooksHelper'
import { logger } from '../logs'

export type ErrorWithAddition<T = {}> = Error & T

export type FetchPromise<T, P extends any[] = []> = (...params: P) => (dispatch: IDispatch) => Promise<T>

export interface GenericFetchHookReturnType<ReturnType, Parameters extends any[] = []> {
  state: StateType<ReturnType>
  retryCall: (silentRetry?: boolean, ...params: Parameters) => void
  updateData: (data: ReturnType) => void
}

/**
 * @template T the type of data returned from the success state callback
 *
 * @template P array of type of dispatched function
 *
 * @param promise promise to fire
 *
 * @param fireAtInit il false state will be `waiting` and the promise will not start until a retryCall()
 *
 * @param initialParams set the initial params for the dispatched function
 *
 * @returns
 *  `state` The state of the fetch: success, fetching, error.
 *  Every state has an optional prevData param to manage the previous data.
 *  Only `success` state have the data:T param
 *
 *  `retryCall` the callback retryCall that fire another time the promise.
 *   retryCall(true) for silent retry (state `fetching` will be skipped)
 *
 * @example
 * Use the `renderHook` snippet to create the pageView boilerplate. (./snippet.json)
 */
const useGenericFetchHook = <ReturnType, Parameters extends any[] = []>(
  promise: FetchPromise<ReturnType, Parameters>,
  fireAtInit: boolean = true,
  ...initialParams: Parameters
): GenericFetchHookReturnType<ReturnType, Parameters> => {
  const dispatch = useComponentDispatch()

  const [state, setState] = useState<StateType<ReturnType>>({
    kind: fireAtInit ? 'fetching' : 'waiting',
  })
  const hasMount = useHasMounted()

  //  if retryCount is odd then promise doesn't fire IsFetching state
  const [retryCount, setRetryCount] = useState<{ value: number; params: Parameters }>({
    value: 2,
    params: initialParams,
  })

  // deferred a promise that will abort the promise if the hook is unmount
  const [deferred, setDeferred] = useState<any>()

  function log() {
    logger('useGenericFetchHook', promise.toString(), '')
  }

  useEffect(() => {
    if (!hasMount && !fireAtInit) {
      return
    }
    if (retryCount.value % 2 === 0) {
      setState(prev => ({ kind: 'fetching', prevData: prev.kind === 'success' ? prev.data : prev.prevData }))
    } else {
      setState(prev => {
        if (prev.kind === 'success') {
          return { ...prev, isLoadingNewData: true }
        }
        return prev
      })
    }

    const p = new Promise((resolve, reject) => {
      setDeferred({ resolve, reject })
    })

    Promise.race([promise(...retryCount.params)(dispatch), p])
      .then(value => {
        if (value instanceof Error) {
          log()
          setState(prev => ({
            kind: 'error',
            error: value,
            prevData: prev.kind === 'success' ? prev.data : prev.prevData,
          }))
          return
        }
        // deferred promise doesn't return an object
        if (typeof value === 'object') {
          const result = (value as any) as ReturnType
          setState(prev => ({
            kind: 'success',
            data: result,
            prevData: prev.kind === 'success' ? prev.data : prev.prevData,
            isLoadingNewData: false,
          }))
        }
      })
      .catch(error => {
        log()
        setState(prev => ({ kind: 'error', error, prevData: prev.kind === 'success' ? prev.data : prev.prevData }))
      })
  }, [retryCount])

  // unmount logic
  useEffect(() => {
    return () => {
      if (deferred) {
        deferred.resolve('abort')
      }
    }
  }, [deferred])

  return {
    state,
    retryCall: (silentRetry: boolean = false, ...params: Parameters) =>
      setRetryCount(prev => {
        let value = silentRetry ? prev.value * 2 + 1 : prev.value * 2
        if (value > 20) {
          value -= 20
        }
        return { value, params }
      }),
    updateData: (data: ReturnType) => {
      setState({ kind: 'success', data, prevData: state.prevData })
    },
  }
}

export interface IsFetching<T> {
  kind: 'fetching'
  prevData?: T
}

/**
 * @param isLoadingNewData: retryCall can be silent so no fetching state will be called. This boolean return true if we are in success state when is called a silently retry call.
 */
export interface IsSuccess<T> {
  kind: 'success'
  data: T
  prevData?: T
  isLoadingNewData?: boolean
}

export interface IsError<T> {
  kind: 'error'
  error: ErrorWithAddition
  prevData?: T
}

export interface Waiting<T> {
  kind: 'waiting'
  prevData?: T
}

export type StateType<T> = IsFetching<T> | IsSuccess<T> | IsError<T> | Waiting<T>

export default useGenericFetchHook

// * ------------------------------------------
// * Helper Components that pre-fill some props
// * ------------------------------------------
const useSimpleGenericFetchHook = <T, P extends any[] = []>(
  promise: FetchPromise<T, P>,
  fireAtInit: boolean = true,
  ...initialParams: P
) => {
  const fetchHook = useGenericFetchHook<T, P>(promise, fireAtInit, ...initialParams)

  const getData = () => {
    if (fetchHook.state.kind === 'success') {
      return fetchHook.state.data
    }
    return undefined
  }

  return {
    state: {
      data: getData(),
      loading: fetchHook.state.kind === 'fetching',
      error: fetchHook.state.kind === 'error' ? fetchHook.state.error : undefined,
    },
    retryCall: fetchHook.retryCall,
    updateData: fetchHook.updateData,
  }
}

/**
 * @template T the type of data returned from the success state callback
 *
 * @template P array of type of dispatched function
 *
 * @template K type then parameters
 *
 * @param promise promise to fire
 *
 * @param then callback called when the promise is finished
 *
 * @returns 1) a method to execute the promise 2) an object thant contains data,loading,error
 */
export const useLazyGenericFetchHook: <
  ReturnType,
  ParametersType extends any[] = [],
  AdditionalThenParams extends any[] = [],
  Errors = {}
>(
  promise: FetchPromise<ReturnType, ParametersType>,
  then?: (data: ReturnType, ...thenParams: AdditionalThenParams) => void,
  onError?: (error: ErrorWithAddition<Errors>) => void,
  ...thenParams: AdditionalThenParams
) => [
  (...params: ParametersType) => void,
  { data: ReturnType | undefined; loading: boolean; error: ErrorWithAddition<Errors> | undefined }
] = <ReturnType, ParametersType extends any[] = [], AdditionalThenParams extends any[] = [], Errors = {}>(
  promise: FetchPromise<ReturnType, ParametersType>,
  then?: (data: ReturnType, ...thenParams: AdditionalThenParams) => void,
  onError?: (error: ErrorWithAddition<Errors>) => void,
  ...thenParams: AdditionalThenParams
) => {
  const { state, retryCall } = useSimpleGenericFetchHook<ReturnType, ParametersType>(
    promise,
    false,
    ...({} as ParametersType)
  )

  const execute: (...params: ParametersType) => void = (...params: ParametersType) => retryCall(false, ...params)

  useEffect(() => {
    if (!state.loading && state.data) {
      then?.(state.data, ...thenParams)
    }
    if (!state.loading && state.error) {
      onError?.(state.error as ErrorWithAddition<Errors>)
    }
  }, [state.loading])

  return [execute, { data: state.data, loading: state.loading, error: state.error as ErrorWithAddition<Errors> }]
}
