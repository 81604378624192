import { API } from '.'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
// UNUSED import { Moment } from 'moment'
import { Workshift } from '../../types/workshift'
import * as moment from 'moment'
// UNUSED import { logdata } from '../../functions/debug'
import { plantQuery } from '../../functions/model'

export const generalStatusIsFetching = (): AnyAction => {
  return {
    type: 'PLANTANALYSIS_GENERAL_STATUS_IS_FETCHING',
  }
}

export const generalStatusFetchSuccess = (dataLavorazioneSrc: any, dataRicettaSrc: any): AnyAction => {
  const dataLavorazione = JSON.parse(dataLavorazioneSrc)
  const dataRicetta = JSON.parse(dataRicettaSrc)

  return {
    type: 'PLANTANALYSIS_GENERAL_STATUS_FETCH_SUCCESS',
    payload: {
      lavorazione:
        dataLavorazione.results[0] && dataLavorazione.results[0].series ? dataLavorazione.results[0].series[0] : null,
      ricetta: dataRicetta.results[0] && dataRicetta.results[0].series ? dataRicetta.results[0].series[0] : null,
    },
  }
}

export const generalStatusFetchError = (error: Error): AnyAction => {
  return {
    type: 'PLANTANALYSIS_GENERAL_STATUS_FETCH_ERROR',
    payload: error,
  }
}

export const generalFetchData = (
  workshift: Workshift | null
): ((dispatch: Dispatch, getState: any) => Promise<any>) => {
  if (workshift) {
    return async (dispatch, getState) => {
      dispatch(generalStatusIsFetching())
      const plantQueryString = plantQuery(getState)

      try {
        const endDate = moment(workshift.end)
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()

        const queryLavorazione =
          `SELECT "measure" FROM "CURRENT_JOB_ACT"` + ` WHERE time <= '${endDate}' GROUP BY * ORDER BY time DESC LIMIT 1`
        const queryRicetta =
          `SELECT "measure" FROM "CURRENT_REC_ACT"` + ` WHERE time <= '${endDate}' GROUP BY * ORDER BY time DESC LIMIT 1`

        const dataLavorazioneJson = await API().request(`/query?${plantQueryString}q=` + queryLavorazione)
        const dataRicettaJson = await API().request(`/query?${plantQueryString}q=` + queryRicetta)

        dispatch(generalStatusFetchSuccess(dataLavorazioneJson, dataRicettaJson))

        return Promise.all([dataLavorazioneJson, dataRicettaJson])
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        dispatch(generalStatusFetchError(error))
        throw error
      }
    }
  }
  return Promise.resolve
}
