import { logoutUser } from '../../../../../inplant-core-fe/redux/actions'
import { fillWarnArea, hydrateData, hydrateTimeData, populateManAutoLocSec } from '../../../../functions/series'
import { UnthaProps, UnthaState } from './_types'
import { ChartFetch } from '../ChartRenderUtilities'

import { unthaMeasuresToFetch } from './_measures'
import { InfluxMeasureFetcher } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx'

abstract class Untha<Props extends UnthaProps, State extends UnthaState> extends ChartFetch<Props, State> {
  private mounted = false
  private abortController: AbortController = new AbortController()
  protected chartTranslationKeyTitle: string = 'plantAnalysis.untha.generalGraph.title'

  constructor(props: Props, generateState: (props: Props) => State) {
    super(props)
    this.state = generateState(props)
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData()
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData()
      }
    }
  }

  private constructData() {
    try {
      const mergedData: any[] = []
      hydrateData(
        {
          speedPercent: this.state.data.speedPercent,
        },
        mergedData,
        undefined,
        0
      )
      mergedData.sort((a, b) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
      })
      hydrateTimeData(['speedPercent'], mergedData, this.state)
      populateManAutoLocSec(mergedData)
      fillWarnArea(mergedData, '', 0)

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
          fetching: false,
          error: false,
        })
      }
    } catch (error) {
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private async getData() {
    const workshift = this.props.workShift
    if (workshift) {
      try {
        const InfluxFetcher = new InfluxMeasureFetcher(
          this.props.plant.plant,
          this.props.workshift,
          this.props.date.toString(),
          this.abortController
        )
        const promises = InfluxFetcher.request(unthaMeasuresToFetch)
        const promisesResolved = await Promise.all(promises)

        const promisesParsed = promisesResolved.reduce((acc, value, index) => {
          const measureSlug = unthaMeasuresToFetch[index].slug
          const parseFn = unthaMeasuresToFetch[index].parseFn
          const dataParsed = parseFn(value)
          return {
            ...acc,
            [measureSlug]: dataParsed,
          }
        }, {})

        this.setState({
          fetching: true,
          error: false,
        })
        if (this.mounted) {
          this.setState(
            {
              data: Object.assign({}, this.state.data, promisesParsed),
            },
            () => {
              this.constructData()
            }
          )
        }
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  protected abstract renderContent(): JSX.Element
  public render() {
    const { fetching, error } = this.state

    if (fetching) {
      return this.renderLoading()
    }

    if (error) {
      return this.renderError()
    }

    if (!this.state.data) {
      return this.renderNoData()
    }

    return this.renderContent()
  }
}

export default Untha
