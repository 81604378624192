// * -------------------------------- NPM --------------------------------------
import React from 'react'

/**
 * attach a `div` above `Component` to sign the route with `inplant-wrapper`
 * @param Component
 * @returns
 */
const InplantWrapper = <P extends object>(Component: React.ComponentType<P>) => (props: P) => {
  return (
    <div className={'inplant-wrapper'}>
      <Component {...props} />
    </div>
  )
}

/**
 * route is recreated wrapping `component` inside {@link InplantWrapper}
 * @param route
 * @returns route
 */
function attachInplantWrapperTo<T extends { component?: any; children?: T[] }>(route: T): T {
  return {
    ...route,
    component: route.component ? InplantWrapper(route.component) : undefined,
    children: route?.children?.map(attachInplantWrapperTo),
  }
}

export default attachInplantWrapperTo
