const it = {
  user: {
    login: 'Vai al login',
    matchError: {
      uppercaseLetter_singular: 'È richiesta minimo una lettera maiuscola',
      uppercaseLetter_plural: 'Sono richieste minimo {{count}} lettere maiuscole',
      lowercaseLetter_singular: 'È richiesta minimo una lettera minuscola',
      lowercaseLetter_plural: 'Sono richieste minimo {{count}} lettere minuscole',
      numbers_singular: 'È richiesto minimo un numero',
      numbers_plural: 'Sono richiesti minimo {{count}} numeri',
      specialCharacters_singular: 'È richiesto minimo un carattere speciale ( {{special}} )',
      specialCharacters_plural: 'Sono richiesti minimo {{count}} caratteri speciali ( {{special}} )',
      length_singular: 'È richiesto minimo un carattere',
      length_plural: 'Sono richiesti minimo {{count}} caratteri'
    },
    module: 'Utenti',
    navigation: {
      list: 'Lista Utenti',
      new: 'Nuovo Utente',
      edit: 'Modifica Utente',
      changePassword: 'Cambia Password',
    },
    fetching: 'Caricamento',
    index: {
      title: 'Utenti',
      name: 'Nome',
      surname: 'Cognome',
      displayName: 'Nome visualizzato',
      email: 'E-mail',
      username: 'Nome utente',
      roles: 'Ruoli',
      enabled: 'Abilitato',
      disabled: 'Disabilitato',
      table: {
        showAll: 'Tutti',
        page: 'pagina',
        of: 'di',
        rows: 'righe',
        noData: 'Non ci sono dati',
      },
      new: 'Nuovo',
      exportData: 'ESPORTA DATI',
    },
    form: {
      newUser: 'Nuovo Utente',
      newUserCreated: 'Nuovo Utente creato correttamente',
      status: 'Stato',
      select: '- seleziona -',
      create: 'Crea',
      update: 'Modifica',
    },
    errors: {
      required: 'Campo richiesto',
      minLength: 'NON può essere più CORTO di 1 carattere',
      minLength_plular: 'NON può essere più CORTO di {{count}} caratteri',
      maxLength: 'NON può essere più LUNGO di {{count}} caratteri',
      format: 'Deve essere nel formato: {{format}}',
    },
    recoveryPassword: {
      action: 'Invia email di recupero',
      info: 'Inserisci il tuo username, riceverai una mail per recuperare la tua password',
      username: 'Username',
      usernamePlaceholder: 'Inserisci il tuo nome utente...',
      actionSuccess: 'Se l`utente esiste, sarà inviata una mail per il recupero della password',
      actionSuccessTitle: 'Azione completata'
    },
    resetPassword: {
      password: "Password",
      passwordPlaceholder: "Inserisci la password...",
      confirmPasswordPlaceholder: "Conferma la password...",
      confirmPassword: "Conferma password",
      action: "Cambia password",
      info: "Esegui il reset della password",
      successTitle: "Reset password",
      success: "Reset della password completato con successo",
      tokenExpiredTitle: "Errore di validazione",
      tokenExpired: "Il link è scaduto, ti consigliamo di eseguire nuovamente la procedura di recupero password"
    },
    changePassword: {
      info: 'La tua password è scaduta o stai eseguendo il primo login, aggiornala.\n La password che stai inserendo ora sarà valida per {{days}} giorni, al termine dei quali ti verrà richiesto nuovamente di cambiarla',
      title: 'Cambia password',
      button: 'Cambia password',
      username: 'Nome utente',
      usernamePlaceholder: 'Inserisci il nome utente',
      currentPassword: {
        label: 'Password attuale',
        placeholder: 'Inserisci la password attuale',
      },
      'Wrong password': 'Nome utente o password errati',
      'Password cannot match': 'La password coincide con la password precedente',
      'User not found': 'Nome utente o password errati',
      validationError: "Errore di validazione",
      newPassword: {
        label: 'Nuova password',
        labelConfirm: 'Ripeti password',
        placeholder: 'Inserisci una nuova password',
        placeholderConfirm: 'Ripeti la nuova password',
      },
      save: 'Aggiorna password',
      actionSuccess: "La password è stata aggiornata con successo",
      actionSuccessTitle: "Aggiornamento password"
    },
    noMatchConfirmPassword: "Le password non coincidono",
    createUser: {
      title: 'Crea utente',
      updateTitle: 'Aggiorna l\'utente',
      form: {
        update: 'Aggiorna',
        name: 'Nome',
        surname: 'Cognome',
        username: 'Nome utente',
        email: 'Email',
        displayName: 'Nome da visualizzare',
        plants: 'Impianto',
        roles: 'Ruolo',
        passwordConfirm: 'Conferma password',
        save: 'Crea utente',
        enable: 'Utente attivo',
        password: 'Password',
        updateSuccess: 'Utente aggiornato con successo',
        createSuccess: 'Utente create con successo'
      }
    }
  },
}

export default it
