import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface Props {
  title?: string
}

const CommonErrorMessage = (props: WithTranslation & Props) => {
  const { t, title } = props
  return (
    <>
      {title && <h3 className={'w-100'}>{title}</h3>}
      <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
        {t('plantAnalysis.fetchErrors')}
      </div>
    </>
  )
}

export default withTranslation()(CommonErrorMessage)
