import { uniq } from 'lodash'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { hydrateData, hydrateTimeData, parseResponseData } from '../../../../functions/series'
import { simplePopulateSingleData } from '../../../../functions/simplePopulateSingleData'
import { useChartFetching } from '../../../../hook/useChartFetching'
import { useChartState } from '../../../../hook/useChartState'
import { API } from '../../../../redux/actions'
import { PartialRecordDataType } from '../../../../types/helpers'
import { SingleDataValue } from '../../../../types/singleDataValue'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import { GeneralChartProps } from './generalChartProps'
import _PressBaleDetailChart from './_PressBalesDetailChart'
import _ProductionAndConsumptionChart from './_ProductionAndConsumptionChart'
import _RecipeNameWrapper from './_RecipeNameWrapper'
import _RecipeSelection from './_RecipeSelection'
import _RecipeTimeline from './_RecipeTimeline'

const currentRecipeMeasure = '_ACT_REC_NUM' as const

type DataType = 'recipe'

interface CompleteData {
  data: Array<PartialRecordDataType<DataType>>
  uniqueRecipe: string[]
}

const _RecipeWrapper = (props: GeneralChartProps) => {
  const { abortController, endDateTime, plantString, startDateTime, measure } = props
  const { actions, data, isError, isLoading } = useChartState<CompleteData, DataType>({
    keys: [],
    tooltipContentInfo: {},
  })
  const {
    queryEndLastElement: queryEndLimitSection,
    queryEndSection,
    queryStartSection,
    uriRequestStart,
  } = useChartFetching({
    callback: fetchAllData,
    endDateTime,
    startDateTime,
    plantString,
    type: 'withLast',
  })

  function fetchAllData() {
    actions.setLoading()
    const recipeTimelineName = `${measure}${currentRecipeMeasure}`
    const recipeTimelineLastValueRequest = API().request(
      `${uriRequestStart}${queryStartSection}${recipeTimelineName}${queryEndLimitSection}`,
      { signal: abortController.signal }
    )
    const recipeTimelineRequest = API().request(
      `${uriRequestStart}${queryStartSection}${recipeTimelineName}${queryEndSection}`,
      { signal: abortController.signal }
    )

    Promise.all([recipeTimelineLastValueRequest, recipeTimelineRequest])
      .then(([recipeTimelineLastValueResponse, recipeTimelineResponse]) => {
        actions.setData(parseAndPrepareData(recipeTimelineLastValueResponse, recipeTimelineResponse))
      })
      .catch(e => {
        actions.setError()
      })
  }

  function parseAndPrepareData(lastRequestDataBeforeStart: any, requestData: any): CompleteData {
    const lastParsedData = parseResponseData(lastRequestDataBeforeStart)
    const totalParsedData = parseResponseData(requestData)
    const parsedData = [[startDateTime,lastParsedData[0][1]],...totalParsedData]
    const populatedData: Record<DataType, SingleDataValue[]> = { recipe: simplePopulateSingleData(parsedData) }
    const mergedData: any[] = []
    hydrateData(populatedData, mergedData)
    hydrateTimeData(['recipe'], mergedData, { startDate: startDateTime, endDate: endDateTime })
    const uniqueRecipes = uniq(mergedData.map(v => v.recipe.toString()))
    return {
      data: (mergedData as unknown) as Array<PartialRecordDataType<DataType>>,
      uniqueRecipe: uniqueRecipes,
    }
  }

  if (isLoading) {
    return <></>
  }

  if (isError) {
    return <CommonErrorMessage />
  }

  if (data && data.data.length === 0) {
    return <CommonNoDataAvailable />
  }

  return (
    <>
      {data?.uniqueRecipe !== undefined && data.uniqueRecipe.length > 0 && (
        <_RecipeNameWrapper {...props} uniqueRecipeList={data?.uniqueRecipe ?? []} recipes={data.data} />
      )}
      {/* <_RecipeSelection items={[]} onChange={(recipe) => setSelectedRecipe(recipe)} /> */}
      {/* <_RecipeRecapTable {...props} currentSelectedRecipe={selectedRecipe} /> */}
      {/* <_ProductionAndConsumptionChart {...props} currentSelectedRecipe={selectedRecipe} /> */}
      {/* <_PressBaleDetailChart {...props} currentSelectedRecipe={selectedRecipe} /> */}
    </>
  )
}

export default withTranslation()(_RecipeWrapper)
