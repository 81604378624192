import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { COLORS } from '../../../../../constants'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import Column from '../../../../../../inplant-components-fe/ui/components/Grid/Column'
import GraphLineBarEnhanced from '../../GraphLineBar/GraphLineBarEnhanced/GraphLineBarEnhancedView'
import LineBuffer from './LineBuffer'
import { LineBufferProps, LineBufferState } from './_types'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'
import { DEFAULT_STATE, DENSIFIERS_IDS } from './_constants'

class LineBufferView extends LineBuffer<LineBufferProps, LineBufferState> {
  constructor(props: LineBufferProps) {
    super(props, () => DEFAULT_STATE)
  }

  protected renderContent() {
    const { t } = this.props

    return (
      <div className={`col-md-12 col-sm-12 col-lg-12 halts-graphs halts-graphs__vertical`}>
        <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.linesStatusTitle')}</h3>
        <div className={'graph-spacing column-break-before'}>
          <Row>
            <img src={'https://inplant-public.s3.eu-west-1.amazonaws.com/dec-bluair.png'} height={230} />
            <Column md={10} lg={10} sm={10}>
              {DENSIFIERS_IDS.map(number => (
                <>
                  <h5 className={'w-100 mt-3'}>{this.props.t(`plantAnalysis.lines.linesStatus.buffers.buffer${number}`)}</h5>
                  <GraphLineBarEnhanced
                    topMargin={true}
                    entry={`buffer${number}State`}
                    colorsId={'activeStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={`plantAnalysis.lines.linesStatus.buffers.buffer${number}`}
                    lineHeight={30}
                    hideTitle={true}
                    hideTimeline={true}
                  />
                  <GraphLineBarEnhanced
                    topMargin={true}
                    entry={`dec${number}TorqueState`}
                    colorsId={'densifierTorqueStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={`plantAnalysis.lines.linesStatus.dec.dec${number}`}
                    lineHeight={30}
                    hideTitle={true}
                    hideTimeline={true}
                  />
                  <GraphLineBarEnhanced
                    topMargin={true}
                    entry={`buffer${number}Fill`}
                    colorsId={'fillStatus'}
                    filteredData={this.state.filteredData}
                    i18nTitle={`plantAnalysis.lines.linesStatus.buffers.buffer${number}`}
                    lineHeight={30}
                    hideTitle={true}
                    hideTimeline={true}
                  />
                  <GraphLineBarEnhanced
                    topMargin={true}
                    entry={`buffer${number}LoadState`}
                    colorsId={'bufferState'}
                    filteredData={this.state.filteredData}
                    i18nTitle={`plantAnalysis.vecoplant.bufferState.buffers.buffer${number}`}
                    lineHeight={30}
                    hideTitle={true}
                    workshift={this.props.workShift}
                  />
                </>
              ))}
              {this.state.data && (
                <Row spacing={{ vertical: true }}>
                  <Row>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      1.
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.vecoplant.bufferLine.active }}
                      />
                      <span>{t('plantAnalysis.lines.linesStatus.active')}</span>
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.vecoplant.bufferLine.inactive }}
                      />
                      <span>{t('plantAnalysis.lines.linesStatus.inactive')}</span>
                    </span>
                  </Row>
                  <Row>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      2.
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.densifierTorqueStatus[2] }}
                      />
                      <span>{t('plantAnalysis.lines.densifierTorqueStatus.dead')}</span>
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.densifierTorqueStatus[0] }}
                      />
                      <span>{t('plantAnalysis.lines.densifierTorqueStatus.warning')}</span>
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.densifierTorqueStatus[1] }}
                      />
                      <span>{t('plantAnalysis.lines.densifierTorqueStatus.running')}</span>
                    </span>
                  </Row>
                  <Row>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      3.
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.vecoplant.bufferLine.empty }}
                      />
                      <span>{t('plantAnalysis.lines.linesStatus.empty')}</span>
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.vecoplant.bufferLine.full }}
                      />
                      <span>{t('plantAnalysis.lines.linesStatus.full')}</span>
                    </span>
                  </Row>
                  <Row>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      4.
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.vecoplant.bufferState.loadable }}
                      />
                      <span>{t('plantAnalysis.vecoplant.bufferState.loadable')}</span>
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.vecoplant.bufferState.notLoadable }}
                      />
                      <span>{t('plantAnalysis.vecoplant.bufferState.notLoadable')}</span>
                    </span>
                    <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                      <span
                        className="label-color-square"
                        style={{ backgroundColor: COLORS.vecoplant.bufferState.emptying }}
                      />
                      <span>{t('plantAnalysis.vecoplant.bufferState.emptying')}</span>
                    </span>
                  </Row>
                </Row>
              )}
            </Column>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(LineBufferView))
