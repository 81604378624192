// * -------------------------------- NPM --------------------------------------
import React from 'react'
import { useSelector } from 'react-redux'

const VidimWrapper = <P extends object>(Component: React.ComponentType<P>) => (props: P) => {
  const plantType = useSelector((state: any) => state.config.generic.plantType)
  if (!plantType || plantType === 'vidim') {
    return <Component {...props} />
  }
  return (
    <div className={'vidim-wrapper'}>
      <Component {...props} />
    </div>
  )
}

export default VidimWrapper