import { Dispatch } from 'redux'
import { API } from '.'

export const sendRecoveryPassword = (username: string) => async (dispatch: Dispatch): Promise<void> => {
  return API()
    .request(`/auth/forgot`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ username }),
    })
}
