// * -------------------------------- NPM --------------------------------------
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

// * -------------------------------- COMPONENTS --------------------------------------
import { useCircularArray } from '../../../../../mvlabs-components-fe/functions/helpers'
import { uuidRegExp } from '../../../../../mvlabs-components-fe/functions/regExp'
import { Title } from '../../../../../mvlabs-components-fe/ui/components'
import BackTitle from '../../../../../mvlabs-components-fe/ui/components/Layout/BackTitle'

/**
 * Use BreadcrumbsTitle and BreadcrumbsBackTitle when are necessary more information to create a breadcrumbs.
 * For example:
 *  maintenance/list/8456-12345678-12345678-1234
 *  to use the appropriate title for `8456-12345678-12345678-1234` are required the info of the page title
 */

type Uuid = string
type Name = string
type Segment = [Uuid, Name]

const SEGMENTS_STORAGE_KEY = 'segmentsStorageKey'
const MAX_SEGMENTS = 10

interface SegmentsContext {
  segmentsName: { [uuid: string]: string }
  setSegmentName: (segment: Segment) => void
  clean: () => void
}

const SegmentsContext: React.Context<SegmentsContext> = React.createContext<SegmentsContext>({
  segmentsName: {},
  setSegmentName: ({}) => null,
  clean: () => null,
})

export const SegmentsProvider: React.FC<{}> = props => {
  const circularArray = useCircularArray<Segment>([], MAX_SEGMENTS)

  useEffect(() => {
    const storageSegments = localStorage.getItem(SEGMENTS_STORAGE_KEY)
    if (storageSegments) {
      circularArray.set(JSON.parse(storageSegments))
    }
  }, [])

  const handleUpdateSegment = (segment: Segment) => {
    if (!circularArray.array.find(el => el[0] === segment[0])) {
      circularArray.push(segment)
    }
  }

  const cleanCounters = () => {
    localStorage.removeItem(SEGMENTS_STORAGE_KEY)
    circularArray.clear()
  }

  useEffect(() => {
    localStorage.setItem(SEGMENTS_STORAGE_KEY, JSON.stringify(circularArray.array))
  }, [circularArray.array])

  return (
    <SegmentsContext.Provider
      value={{
        segmentsName: circularArray.array.reduce(
          (acc: { [uuid: string]: string }, curr) => ({ ...acc, [curr[0]]: curr[1] }),
          {}
        ),
        setSegmentName: handleUpdateSegment,
        clean: cleanCounters,
      }}
    >
      {props.children}
    </SegmentsContext.Provider>
  )
}

export const useBreadcrumbsTitle = () => {
  const segmentsContext = React.useContext(SegmentsContext)

  return {
    segmentsName: segmentsContext.segmentsName,
  }
}

const useBreadcrumbs = () => {
  const segmentsContext = React.useContext(SegmentsContext)

  return {
    segmentsName: segmentsContext.segmentsName,
    setSegmentsName: segmentsContext.setSegmentName,
  }
}

interface ComponentProps {
  title: string
}

const WithBreadcrumbsProvider = <P extends ComponentProps>(Component: React.ComponentType<P>) => (props: P) => {
  const { title } = props
  const { setSegmentsName } = useBreadcrumbs()
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname.split('/')
    const lastPath = pathname[pathname.length - 1]

    if (lastPath.match(uuidRegExp)) {
      setSegmentsName([lastPath, title])
    }
  }, [])

  return <Component {...props} />
}

export const BreadcrumbsTitle = WithBreadcrumbsProvider(Title)
export const BreadcrumbsBackTitle = WithBreadcrumbsProvider(BackTitle)

export default SegmentsProvider
