import { LineLoadingBeltState } from './_types'

const measuresToFetch = [
  'buffer_f019_from_zero_to_twenty_five_percent',
  'buffer_f028_from_zero_to_twenty_five_percent',
  'buffer_f057_from_zero_to_twenty_five_percent',
  'buffer_f076_from_zero_to_twenty_five_percent',
  'buffer_f086_from_zero_to_twenty_five_percent',
  'buffer_f019_from_twenty_five_to_fifty_percent',
  'buffer_f028_from_twenty_five_to_fifty_percent',
  'buffer_f057_from_twenty_five_to_fifty_percent',
  'buffer_f076_from_twenty_five_to_fifty_percent',
  'buffer_f086_from_twenty_five_to_fifty_percent',
  'buffer_f019_from_fifty_to_seventy_five_percent',
  'buffer_f028_from_fifty_to_seventy_five_percent',
  'buffer_f057_from_fifty_to_seventy_five_percent',
  'buffer_f076_from_fifty_to_seventy_five_percent',
  'buffer_f086_from_fifty_to_seventy_five_percent',
  'buffer_f019_from_seventy_five_to_one_hundred_percent',
  'buffer_f028_from_seventy_five_to_one_hundred_percent',
  'buffer_f057_from_seventy_five_to_one_hundred_percent',
  'buffer_f076_from_seventy_five_to_one_hundred_percent',
  'buffer_f086_from_seventy_five_to_one_hundred_percent',
  'buffer_f019_over_one_hundred_percent',
  'buffer_f028_over_one_hundred_percent',
  'buffer_f057_over_one_hundred_percent',
  'buffer_f076_over_one_hundred_percent',
  'buffer_f086_over_one_hundred_percent',
  'buffer_f019_seconds_at_zero',
  'buffer_f028_seconds_at_zero',
  'buffer_f057_seconds_at_zero',
  'buffer_f076_seconds_at_zero',
  'buffer_f086_seconds_at_zero',
  'buffer_f019_average_torque',
  'buffer_f028_average_torque',
  'buffer_f057_average_torque',
  'buffer_f076_average_torque',
  'buffer_f086_average_torque',
]

const DEFAULT_STATE: LineLoadingBeltState = {
  fetching: true,
  error: false,
  data: null,
  filteredData: null,
}

export { DEFAULT_STATE, measuresToFetch }
