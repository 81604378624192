import { Dispatch } from 'redux'
import { API } from '.'
import { UserFormData } from '../../ui/components/views/New/CreateUserPageView'

export const createUser = (user: UserFormData) => async (dispatch: Dispatch): Promise<void> => {
  return API()
    .request('/manage/users', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
}
