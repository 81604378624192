import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { HaltSeriesShiftZone } from '../../../../../redux/actions/halts'
import { /* EventsPie, */ PieData } from '../../charts/EventsPie/EventsPieView'
import EventsVerticalChart from '../../charts/EventsVerticalChart/EventsVerticalChartView'
import { Workshift } from '../../../../../types/workshift'
import { GeneralData } from '../../../../../types/measure'
import { API } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions'
import {
  newParseResponseSeries,
  parseResponseData,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/series'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/redux/actions'
import { ChartPlaceholder } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import { connect } from 'react-redux'
import PlantHaltsTimeline from '../PlantHaltsTimeline'
import { Zone } from '../../../../../types/hatls'
import EventPieMultiple from '../../charts/EventPieMultiple/EventPieMultipleView'
import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'

export type PlantHaltShitZoneTypes =
  | 'vBulkyStopImpact'
  | 'vBulkyStops'
  | 'vHaltCauses'
  | 'vManualStops'
  | 'vOpenGates'
  | 'vPlantTimeReport'
  | 'vUnjustifiedStops'
  | 'vAssetsCausingClogging'
  | 'vJustifiedBy'

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: PlantHaltShitZoneTypes[]
  showTimeline?: boolean
  workshift: any
  zones: Zone[]
}

interface OwnState {
  halts: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  haltsTimes: Array<Array<string | number>>
  dataClogging: PieData[]
  fetching: boolean
  fetchingClogging: boolean
  error: boolean
  errorClogging: boolean
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  // halts: state.plantAnalysis.halts,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
})

class PlantHaltsShiftView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      halts: {},
      haltsTimes: [],
      dataClogging: [],
      fetching: false,
      fetchingClogging: false,
      error: false,
      errorClogging: false,
    }

    this.getHalts = this.getHalts.bind(this)
    this.getHaltsTimes = this.getHaltsTimes.bind(this)
    this.getSeriesData = this.getSeriesData.bind(this)
    this.getDataClogging = this.getDataClogging.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getHalts(this.props.processing)
    this.getDataClogging(this.props.processing)
    if (this.props.showTimeline) {
      this.getHaltsTimes()
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workshift?.value !== prevProps.workshift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getHalts(this.props.processing)
        this.getDataClogging(this.props.processing)
        if (this.props.showTimeline) {
          this.getHaltsTimes()
        }
      }
    }
  }

  public render() {
    const {
      isDateFilterRange,
      t,
      standardTime,
      days,
      hiddenCharts,
      dateFilterStart,
      dateFilterEnd,
      showTimeline,
    } = this.props
    const { fetching, error, haltsTimes } = this.state
    const pieGraphClass = 'w-100 mb-5 pb-3 graph-spacing page-break-inside-avoid'
    const pieGraphSmallClass = 'w-100 mb-5 pb-3 graph-spacing page-break-inside-avoid'
    // const verticalChartGraphClass = 'col-md-12 col-sm-12 col-lg-6 mb-5 pb-3 graph-spacing column-break-before'
    const verticalChartGraphClass =
      'col-md-12 col-sm-12 col-lg-6 halts-graphs halts-graphs__vertical d-print-block page-break-inside-avoid'

    /***** MOCK *****/
    const colors: Array<{ id: string; color: string }> = [
      {
        id: 'bulky',
        color: '#7986cb',
      },
      {
        id: 'empty',
        color: '#feebae',
      },
      {
        id: 'other',
        color: '#dde9e9',
      },
      {
        id: 'runningTime',
        color: '#c6f5bc',
      },
      { id: 'downStreamLine', color: '#ac3b3b' },
      {
        id: 'morning_delay',
        color: '#720806',
      },
      {
        id: 'pause_delay',
        color: '#fb9606',
      },
    ]
    /***** END MOCK *****/
    return (
      <div>
        <div className="halts-graphs d-flex flex-wrap d-print-block">
          {!fetching && error && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}

          {showTimeline &&
            (!isDateFilterRange ? (
              <PlantHaltsTimeline data={haltsTimes} dateStart={dateFilterStart} dateEnd={dateFilterEnd} />
            ) : (
              <div className="col-12 chart-placeholder mb-5">
                {ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
              </div>
            ))}

          {!error && (
            <>
              {(!hiddenCharts || !hiddenCharts.includes('vPlantTimeReport')) && (
                <div className={`${this.props.zones.length === 1 ? pieGraphSmallClass : pieGraphClass}`}>
                  <h5>{t('plantAnalysis.halts.plantTimeReport.titleShift')}</h5>
                  <EventPieMultiple
                    colors={colors}
                    data={
                      this.props.zones
                        .map(zone => this.getSeriesData(`vPlantTimeReport${zone}`))
                        .filter(p => p !== null) as PieData[]
                    }
                    hideTitle={this.props.zones.length <= 1}
                    days={days}
                    fetching={fetching}
                    totalsLabel={t('plantAnalysis.halts.chartLabels.totalWorkingTime')}
                    totalsReference={
                      standardTime && standardTime !== 0 && days
                        ? {
                            label: t('plantAnalysis.halts.chartLabels.plantStandardWorkingTime'),
                            seconds: standardTime * days,
                          }
                        : undefined
                    }
                  />
                </div>
              )}

              {/* {(!hiddenCharts || !hiddenCharts.includes('vBulkyStopImpact')) && (
              <div className={`${this.props.zones.length === 1 ? pieGraphSmallClass : pieGraphClass}`}>
                <h5>{t('plantAnalysis.halts.bulkyStopImpact.title')}</h5>
                <EventPieMultiple
                  colors={[
                    { id: 'bulky', color: '#7986CB' },
                    { id: 'other', color: '#dde9e9' },
                  ]}
                  data={
                    this.props.zones
                      .map(zone => this.getSeriesData(`vBulkyStopImpact${zone}`))
                      .filter(p => p !== null) as PieData[]
                  }
                  days={days}
                  fetching={this.state.fetching}
                  totalsLabel={t('plantAnalysis.halts.chartLabels.totalHaltsTime')}
                />
              </div>
            )} 
            {(!hiddenCharts || !hiddenCharts.includes('vAssetsCausingClogging')) && (
              <div className={`${this.props.zones.length === 1 ? pieGraphSmallClass : pieGraphClass}`}>
                <h5>{t('plantAnalysis.halts.assetsCausingClogging.title')}</h5>
                <EventPieMultiple
                  colors={colors}
                  data={this.state.dataClogging}
                  days={days}
                  fetching={this.state.fetchingClogging}
                  hideTime={true}
                />
              </div>
            )} */}
              {(!hiddenCharts || !hiddenCharts.includes('vUnjustifiedStops')) && (
                <div className={`${this.props.zones.length === 1 ? pieGraphSmallClass : pieGraphClass}`}>
                  <h5>{t('plantAnalysis.halts.dailyUnjustifiedStops.title')}</h5>
                  <EventPieMultiple
                    data={
                      this.props.zones
                        .map(zone => this.getSeriesData(`vUnjustifiedStops${zone}`))
                        .filter(p => p !== null) as PieData[]
                    }
                    hideTitle={this.props.zones.length <= 1}
                    days={days}
                    colors={[
                      { id: 'unjustified', color: '#fbc106' },
                      { id: 'other', color: '#dde9e9' },
                    ]}
                    fetching={fetching}
                    chartLabels={'dailyUnjustifiedStops.labels'}
                    totalsLabel={t('plantAnalysis.halts.chartLabels.totalHaltsTime')}
                  />
                </div>
              )}
              {(!hiddenCharts || !hiddenCharts.includes('vJustifiedBy')) && (
                <div className={`${this.props.zones.length === 1 ? pieGraphSmallClass : pieGraphClass}`}>
                  <h5>{t('plantAnalysis.halts.dailyJustifiedBy.title')}</h5>
                  <EventPieMultiple
                    chartLabels={'dailyJustifiedBy.labels'}
                    colors={[
                      { id: 'justified_by_users', color: '#c6f5bc' },
                      { id: 'justified_by_system', color: '#720806' },
                    ]}
                    hideTitle={this.props.zones.length <= 1}
                    data={
                      this.props.zones
                        .map(zone => this.getSeriesData(`vJustifiedBy${zone}`))
                        .filter(p => p !== null) as PieData[]
                    }
                    days={days}
                    fetching={fetching}
                    totalsLabel={t('plantAnalysis.halts.chartLabels.totalHaltsTime')}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {this.props.zones.map((zone, index) => (
          <React.Fragment key={index}>
            {this.props.zones.length > 1 && (
              <h5 className="d-print-inline-block">{t(`plantAnalysis.linesTitles.${zone}`)}</h5>
            )}
            <div className="halts-graphs d-flex flex-wrap d-print-block">
              <div key={index} className={verticalChartGraphClass /*  + ' page-break-before-always' */}>
                <h5 className="d-print-inline-block">{t(`plantAnalysis.halts.haltCauses.title`)}</h5>
                <EventsVerticalChart
                  colorBar={'#4DB6AC'}
                  data={this.getSeriesData(`vHaltCauses${zone}`)}
                  fetching={fetching}
                />
              </div>
              {/* 
                {(!hiddenCharts || !hiddenCharts.includes('vBulkyStops')) && (
                  <div className={verticalChartGraphClass}>
                    <h5 className="d-print-inline-block">{t(`plantAnalysis.halts.bulkyStops.titles.${zone}`)}</h5>
                    <EventsVerticalChart
                      colorBar={'#7986CB'}
                      data={this.getSeriesData(`vBulkyStops${zone}`)}
                      fetching={fetching}
                      labelPrefix={t('plantAnalysis.halts.chartLabels.Button') + ' '}
                      />
                  </div>
                )} 
                {(!hiddenCharts || !hiddenCharts.includes('vOpenGates')) && (
                  <div className={verticalChartGraphClass}>
                    <h5 className="d-print-inline-block">{t(`plantAnalysis.halts.openGates.titles.${zone}`)}</h5>
                    <EventsVerticalChart
                      colorBar={'#BA68C8'}
                      data={this.getSeriesData(`vOpenGates${zone}`)}
                      fetching={fetching}
                      hideZero={true}
                    />
                  </div>
                )} 
              */}
              {(!hiddenCharts || !hiddenCharts.includes('vManualStops')) && (
                <div className={verticalChartGraphClass}>
                  <h5 className="d-print-inline-block">{t(`plantAnalysis.halts.manualStops.title`)}</h5>
                  <EventsVerticalChart
                    colorBar={'#64B5F6'}
                    data={this.getSeriesData(`vManualStops${zone}`)}
                    hideZero={false}
                    shortLabels={true}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  }

  private getSeriesData(id: string) {
    return (this.state.halts && this.state.halts[id]) || null
  }

  /**
   * Fetches data for all Halts charts
   * @param processing
   * @private
   */
  private async getHalts(processing?: number) {
    const { plant } = this.props.plant
    const { dateFilterStart, dateFilterEnd, workshift, zones } = this.props
    const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
    if (workshift) {
      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(dateFilterStart.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const startDate =
        workshift?.start && workshift.value !== 99
          ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
          : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(dateFilterEnd.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const endDate =
        workshift?.end && workshift.value !== 99
          ? mvDate.getDateFromString(workshift.end.toString()).toISOString()
          : endOfDay

      const filteredSeries = HaltSeriesShiftZone.filter(
        s => !this.props.hiddenCharts || !this.props.hiddenCharts.includes(s)
      )
      // TODO Change to shift time
      const queryEnd = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startDate}' AND time <= '${endDate}'`
      const queries = filteredSeries
        .map(e => zones.map(z => `${plantQueryString}q=SELECT * FROM ` + `"${e + z}"` + queryEnd))
        .reduce((acc, curr) => acc.concat(curr), [])
      this.setState({
        halts: {},
        fetching: true,
        error: false,
      })

      try {
        Promise.all(queries.map(q => API().request(`/query?${q}`, { signal: this.abortController.signal }))).then(
          results => {
            const out = {}
            const res = results.map(r =>
              typeof r === 'string' ? newParseResponseSeries(r) : newParseResponseSeries(JSON.stringify(r))
            )
            res.forEach(r => {
              if (r) {
                out[r.name] = r
              }
            })

            this.setState({
              halts: out,
              fetching: false,
              error: false,
            })
          }
        )
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          halts: {},
          fetching: false,
          error: true,
        })
      }
    }
  }

  /**
   * Fetches data for timeline
   * @private
   */
  private async getHaltsTimes() {
    const { plant } = this.props.plant
    const { dateFilterStart, dateFilterEnd } = this.props
    const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
    const startDate = mvDate.format(
      mvDate.startOfDay(mvDate.getDateFromString(dateFilterStart.toString())),
      'yyyy-MM-dd HH:mm:ss'
    )
    const endDate = mvDate.format(
      mvDate.endOfDay(mvDate.getDateFromString(dateFilterEnd.toString())),
      'yyyy-MM-dd HH:mm:ss'
    )

    const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
    const query = `${plantQueryString}q=SELECT * from PLANT_STATE_AND_MATERIAL ` + queryEnd

    this.setState({
      haltsTimes: [],
      fetching: true,
      error: false,
    })

    try {
      const dataSrc = await API().request(`/query?${query}`)
      const haltsTimes = parseResponseData(dataSrc)

      this.setState({
        haltsTimes,
        fetching: false,
        error: false,
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        haltsTimes: [],
        fetching: false,
        error: true,
      })
    }
  }

  /**
   * Fetches data for assets causing clogging chart
   * @private
   */
  private async getDataClogging(processing?: number) {
    const { plant } = this.props.plant
    const { dateFilterStart, dateFilterEnd, hiddenCharts, zones } = this.props

    if (!hiddenCharts || !hiddenCharts.includes('vAssetsCausingClogging')) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startDate = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(dateFilterStart.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const endDate = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(dateFilterEnd.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )

      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
      // TODO Da cambiare ?
      const queries = zones.map(zone => `${plantQueryString}q=SELECT * from vAssetsCausingClogging${zone} ` + queryEnd)

      this.setState({
        fetchingClogging: true,
        errorClogging: false,
      })

      try {
        Promise.all(queries.map(q => API().request(`/query?${q}`, { signal: this.abortController.signal }))).then(
          result => {
            if (this.mounted) {
              this.setState({
                dataClogging: result.map(r => {
                  const parsedData = typeof r === 'string' ? JSON.parse(r) : r
                  return PlantHaltsShiftView.prepareCloggingData(parsedData)
                }),
                fetchingClogging: false,
                errorClogging: false,
              })
            }
          }
        )
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetchingClogging: false,
          errorClogging: true,
        })
      }
    }
  }

  /**
   * Prepares data for EventsPie component
   *
   * @summary Needed since EventsPie was designed to get data from InfluxDB series
   * @private
   * @param dataSrc
   */

  private static prepareCloggingData(dataSrc: {
    results?: Array<{
      series?: Array<{
        columns: string[]
        name: string
        values: Array<[string, number, string]>
      }>
    }>
  }) {
    const columns: string[] = []
    const values: number[] = []
    let data: any[] = []
    let parsedData = {}
    const temp = {}
    let sum = 0

    /**
     * dataSrc is from influx, every row contains a stringified json string.
     * We decode it and creates an object with key => value
     */
    if (
      dataSrc.results &&
      dataSrc.results[0] &&
      dataSrc.results[0].series &&
      dataSrc.results[0].series[0] &&
      dataSrc.results[0].series[0].values
    ) {
      data = dataSrc.results[0].series[0].values.map(d => JSON.parse(d[2]))

      if (data) {
        parsedData = data.reduce((acc, val) => {
          if (val && Array.isArray(val)) {
            val.forEach(
              (v: {
                Asset: {
                  Id: string
                  Name: string
                }
                ClogCount: string
                Code: string
              }) => {
                acc[v.Asset.Name] =
                  (v.ClogCount ? parseInt(v.ClogCount, 10) : 0) +
                  (!acc.hasOwnProperty(v.Asset.Name) ? 0 : acc[v.Asset.Name])
              }
            )
          }

          return acc
        }, {})
      }
    }

    /**
     * We create a new object where the key is <measure name>_events and contains clogging data
     * We also sum all values for percentage calculation
     */
    Object.keys(parsedData).forEach(d => {
      temp[d + '_events'] = parsedData[d]
      sum += parsedData[d]
    })

    /**
     * We add <measure name>_perc key, value is from <measure name>_events / summed events
     */
    Object.keys(parsedData).forEach(d => {
      temp[d + '_perc'] = sum === 0 ? 0 : parsedData[d] / sum
    })

    /**
     * Populating columns and values arrays
     */
    Object.keys(temp).forEach(k => {
      columns.push(k)
      values.push(temp[k])
    })

    /**
     * Data formatted for EventsPie chart
     */
    const output: PieData = {
      name: 'assetsCausingClogging',
      columns,
      values: [values],
    }

    return output
  }
}

export default connect(mapStateToProps)(withTranslation()(PlantHaltsShiftView))
