import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Wrapper } from '../../../../../inplant-components-fe'
import useGenericFetchHook from '../../../../../inplant-components-fe/mvfunctions/hooks/useGenericFetchHook'
import { useStateValidity } from '../../../../../inplant-components-fe/mvfunctions/hooks/useGenericInputHook'
import { useComponentsTranslation } from '../../../../../inplant-components-fe/services/translation'
import Alert from '../../../../../inplant-components-fe/ui/components/MVAlert/Alert'
import Button from '../../../../../inplant-components-fe/ui/components/MVButtons/Button'
import Flex, { Direction } from '../../../../../inplant-components-fe/ui/components/MVFlex/Flex'
import Input from '../../../../../inplant-components-fe/ui/components/MVInput/Input'
import { popupNotification } from '../../../../../inplant-coreadapter-fe/functions/notification'
import { getPasswordControlByConfiguration } from '../../../../functions/controlsHelpers'
import { updatePasswordByUsername } from '../../../../redux/actions/changePassword'
import { getResetPassword } from '../../../../redux/actions/resetPassword'
import { PasswordOptionType } from '../../../../types/options'

interface FormData {
  oldPassword: string
  newPassword: string
  confirmPassword: string
  username: string
}

const ChangePasswordLogin = () => {
  const { t } = useComponentsTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const base = 'user.changePassword'

  const [isLoading, setIsLoading] = useState(false)
  const [, addDataValidity, isValid] = useStateValidity({})

  const passwordOption = useGenericFetchHook<PasswordOptionType>(
    getResetPassword
  )

  const [formData, setFormData] = useState<FormData>({
    confirmPassword: '',
    newPassword: '',
    oldPassword: '',
    username: '',
  })

  const [errorToShow, setErrorToShow] = useState<{ error: string | undefined }>({ error: undefined })
  useEffect(() => {
    if (formData.newPassword !== formData.confirmPassword) {
      setErrorToShow({
        error: t(`user.noMatchConfirmPassword`),
      })
    } else {
      setErrorToShow({
        error: undefined,
      })
    }
  }, [formData.newPassword, formData.confirmPassword])

  const updatePassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    updatePasswordByUsername(
      formData.username,
      formData.oldPassword,
      formData.newPassword,
      formData.confirmPassword
    )(dispatch)
      .then(() => {
        history.push('/')
        popupNotification({
          type: 'success',
          content: t(`${base}.actionSuccess`),
          title: t(`${base}.actionSuccessTitle`)
        })
      })
      .catch(e => {
        if(e.name === 'FetchError' && e.statusCode === 422) {
          popupNotification({
            type: 'error',
            content: t(`${base}.${e.message}`),
            title: t(`${base}.validationError`)
          })
          return 
        }
        popupNotification({
          title: t('api.errors.error'),
          content: t(`api.errors.error_${e.statusCode}`),
          type: 'error',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function getDaysFromSecondDuration() {
    if(passwordOption.state.kind === 'success') {
      const seconds = passwordOption.state.data.lifetime
      return seconds !== 0 ? seconds / 86400 : 0
    }
    return
  }


  return (
    <div className="inplant-login">
      <Wrapper customType="loginForm">
        {passwordOption.state.kind === 'success' && (
          <form onSubmit={updatePassword}>
            <img src={'/logo-login.png'} className="img-fluid mb-4" alt={''} />
            <Flex direction={Direction.column} spaceSize={'lg'}>
              <Alert text={t(`${base}.info`, {days: getDaysFromSecondDuration()})} variant={'info'} />
              <Flex direction={Direction.column}>
                <Input
                  label={t(`${base}.username`)}
                  type={'text'}
                  kind={'input'}
                  placeholder={t(`${base}.usernamePlaceholder`)}
                  onChange={value => setFormData(prev => ({ ...prev, username: value }))}
                  required={true}
                  onChangeState={state => addDataValidity('username', state)}
                  grow={1}
                />
                <Input
                  grow={1}
                  kind={'input'}
                  type={'password'}
                  label={t(`${base}.currentPassword.label`)}
                  placeholder={t(`${base}.currentPassword.placeholder`)}
                  initialValue={formData.oldPassword}
                  required={true}
                  onChangeState={state => addDataValidity('oldPassword', state)}
                  onChange={value => setFormData(prev => ({ ...prev, oldPassword: value }))}
                />
                <Input
                  grow={1}
                  kind={'input'}
                  type={'password'}
                  label={t(`${base}.newPassword.label`)}
                  placeholder={t(`${base}.newPassword.placeholder`)}
                  initialValue={formData.newPassword}
                  required={true}
                  controls={getPasswordControlByConfiguration(passwordOption.state.data.controls, t)}
                  onChangeState={state => addDataValidity('newPassord', state)}
                  onChange={value => setFormData(prev => ({ ...prev, newPassword: value }))}
                />
                <Input
                  grow={1}
                  kind={'input'}
                  type={'password'}
                  label={t(`${base}.newPassword.labelConfirm`)}
                  placeholder={t(`${base}.newPassword.placeholderConfirm`)}
                  initialValue={formData.confirmPassword}
                  required={true}
                  onChangeState={state => addDataValidity('newPassworConfirm', state)}
                  onChange={value => setFormData(prev => ({ ...prev, confirmPassword: value }))}
                  error={errorToShow.error}
                />
                <Button
                  isDisable={isLoading || !isValid || errorToShow.error !== undefined}
                  isLoading={isLoading}
                  type={'submit'}
                  semantic={'primary'}
                  label={t(`${base}.save`)}
                />
              </Flex>
            </Flex>
          </form>
        )}
      </Wrapper>
    </div>
  )
}

export default ChangePasswordLogin
