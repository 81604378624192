import { HHHmmssFromMilliseconds, NullPie, RowLegend } from '../../../../../functions/shared'
import * as React from 'react'
import { COLORS } from '../../../../../constants'
import { withTranslation, WithTranslation } from 'react-i18next'
import TableRowWrapper, {
  TableRowFullWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableWrapperUtilities from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'
import TableColumnWrapper from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import PieSmallPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/charts/PieSmall/PieSmallPrint'
import { WithConditionWrapper } from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import BarsPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/charts/Bars/BarsPrint'

interface OwnProps {
  data: any
  days: number
  name: string
  id: string
  currentOverrun: any
  currentLimit: null | string
}

type Props = OwnProps & WithTranslation

class BeltGraphRowItemView extends React.PureComponent<Props> {
  public render() {
    const { days } = this.props
    const dataArrestoMarcia: any = this.props.data.find((e: any) => e.name === 'vBeltStop')
    const dataAvantiIndietro: any = this.props.data.find((e: any) => e.name === 'vBeltForward')

    const dataAuto: any = this.props.data.find((e: any) => e.name === 'vBeltAuto')
    const dataMan: any = this.props.data.find((e: any) => e.name === 'vBeltManual')
    const dataLoc: any = this.props.data.find((e: any) => e.name === 'vBeltLocal')
    const dataSez: any = this.props.data.find((e: any) => e.name === 'vBeltSectioned')

    const currentOverrun = this.props.currentOverrun ? parseInt(this.props.currentOverrun, 10) : null
    const currentLimit = this.props.currentLimit ? parseInt(this.props.currentLimit, 10) : null

    const currentValue =
      currentOverrun && currentLimit && currentOverrun > currentLimit
        ? (((currentOverrun - currentLimit) / currentLimit) * 100).toFixed(2) + '%'
        : 0

    const formatTime = (value: number) => HHHmmssFromMilliseconds(value, false, true, true)

    return (
      <TableRowFullWrapper>
        <TableWrapperUtilities hideHeader={false}>
          <TableRowFullWrapper>
            <h3>{this.props.name}</h3>
          </TableRowFullWrapper>
          <TableRowWrapper>
            <TableColumnWrapper col={currentValue !== 0 ? '1/4' : '1/3'}>
              <WithConditionWrapper condition={dataArrestoMarcia !== undefined}>
                <TableWrapperUtilities hideHeader={false}>
                  <TableRowWrapper>
                    <TableColumnWrapper col={'half'}>
                      <PieSmallPrint
                        removeWidth={true}
                        data={[
                          { name: 'true', value: dataArrestoMarcia?.value / days },
                          { name: 'false', value: 100 - dataArrestoMarcia?.value / days },
                        ]}
                        colors={[
                          { id: 'false', color: COLORS.marciaFermo[1] },
                          { id: 'true', color: COLORS.marciaFermo[0] },
                        ]}
                      />
                    </TableColumnWrapper>
                    <TableColumnWrapper col={1}>
                      <table className={''}>
                        <thead>
                          <tr>
                            <th />
                            <th className="text-right">{this.props.t('plantAnalysis.labels.time')}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <span className="label-color-square" style={{ backgroundColor: COLORS.marciaFermo[0] }} />
                            </td>
                            <td className="text-right">
                              {dataArrestoMarcia?.duration !== undefined && formatTime(dataArrestoMarcia.duration * 1000)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="label-color-square" style={{ backgroundColor: COLORS.marciaFermo[1] }} />
                            </td>
                            <td className="text-right">
                              {dataArrestoMarcia?.remainingTime !== undefined && formatTime(dataArrestoMarcia.remainingTime * 1000)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </TableColumnWrapper>
                  </TableRowWrapper>
                </TableWrapperUtilities>
              </WithConditionWrapper>
              <WithConditionWrapper condition={dataArrestoMarcia === undefined}>
                <NullPie />
              </WithConditionWrapper>
            </TableColumnWrapper>
            <TableColumnWrapper col={currentValue !== 0 ? '1/4' : '1/3'}>
              <WithConditionWrapper condition={dataAvantiIndietro !== undefined}>
                <TableWrapperUtilities hideHeader={false}>
                  <TableRowWrapper>
                    <TableColumnWrapper col={'half'}>
                      <PieSmallPrint
                        removeWidth={true}
                        data={[
                          { name: 'true', value: dataAvantiIndietro?.value / days },
                          { name: 'false', value: 100 - dataAvantiIndietro?.value / days },
                        ]}
                        colors={[
                          { id: 'false', color: COLORS.avind[0] },
                          { id: 'true', color: COLORS.avind[1] },
                        ]}
                      />
                    </TableColumnWrapper>
                    <TableColumnWrapper col={1}>
                      <table className={''}>
                        <thead>
                          <tr>
                            <th />
                            <th className="text-right">{this.props.t('plantAnalysis.labels.time')}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <span className="label-color-square" style={{ backgroundColor: COLORS.avind[0] }} />
                            </td>
                            <td className="text-right">
                              {dataAvantiIndietro?.remainingTime !== undefined && formatTime(dataAvantiIndietro.remainingTime * 1000)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="label-color-square" style={{ backgroundColor: COLORS.avind[1] }} />
                            </td>
                            <td className="text-right">
                              {dataAvantiIndietro?.duration !== undefined && formatTime(dataAvantiIndietro.duration * 1000)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </TableColumnWrapper>
                  </TableRowWrapper>
                </TableWrapperUtilities>
              </WithConditionWrapper>
              <WithConditionWrapper condition={dataAvantiIndietro === undefined}>
                <NullPie />
              </WithConditionWrapper>
            </TableColumnWrapper>
            <TableColumnWrapper col={currentValue !== 0 ? '1/4' : '1/3'}>
              <WithConditionWrapper
                condition={dataAuto !== undefined && dataMan !== undefined && dataLoc !== undefined}
              >
                <TableWrapperUtilities hideHeader={true}>
                  <TableRowFullWrapper>
                    <BarsPrint
                      data={[
                        {
                          man: dataMan?.value ? parseFloat(dataMan.value.toFixed(2)) / days : 0,
                          auto: dataAuto?.value ? parseFloat(dataAuto.value.toFixed(2)) / days : 0,
                          loc: dataLoc?.value ? parseFloat(dataLoc.value.toFixed(2)) / days : 0,
                          sec: dataSez?.value ? parseFloat(dataSez.value.toFixed(2)) / days : 0,
                        },
                      ]}
                      colors={{
                        man: COLORS.manAutoLocSec[0],
                        auto: COLORS.manAutoLocSec[1],
                        loc: COLORS.manAutoLocSec[2],
                        sec: COLORS.manAutoLocSec[3],
                      }}
                    />
                  </TableRowFullWrapper>
                </TableWrapperUtilities>
              </WithConditionWrapper>
            </TableColumnWrapper>
            <WithConditionWrapper condition={currentValue !== 0}>
              <TableColumnWrapper col={'1/4'}>
                {RowLegend(
                  'Sforamento Assorbimento',
                  currentValue,
                  null,
                  <React.Fragment>
                    <strong>{this.props.t('plantAnalysis.labels.mean')}</strong>: {currentOverrun}
                    <br />
                    <strong>{this.props.t('plantAnalysis.labels.limit')}</strong>: {currentLimit}
                  </React.Fragment>
                )}
              </TableColumnWrapper>
            </WithConditionWrapper>
          </TableRowWrapper>
        </TableWrapperUtilities>
      </TableRowFullWrapper>
    )
  }
}

export default withTranslation()(BeltGraphRowItemView)
