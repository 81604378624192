import React, { useMemo } from 'react'
import { COLORS } from '../../../../constants'
import { useChartFetching } from '../../../../hook/useChartFetching'
import { useChartState } from '../../../../hook/useChartState'
import { API } from '../../../../redux/actions'
import { PartialRecordDataType, PartialRecordStringType } from '../../../../types/helpers'
import { GeneralChartProps } from '../Press/generalChartProps'
import { hydrateData, hydrateTimeData, parseResponseData } from '../../../../functions/series'
import { SingleDataValue } from '../../../../types/singleDataValue'
import { simplePopulateSingleData } from '../../../../functions/simplePopulateSingleData'
import { isJSON } from '../../../../functions/shared'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import Row from '../../../../../inplant-components-fe/ui/components/Grid/Row'
import moment from 'moment'
import { Tooltip } from 'react-tippy'
import { ResponsiveContainer, LineChart, XAxis, YAxis, Label, CartesianGrid, Line } from 'recharts'
import CustomLegend from '../Common/CustomLegend'
import CustomTooltip from '../Common/CustomTooltip'

interface MockedData {
  results: Array<{
    series: Array<{
      values: Array<[string, number]>
    }>
  }>
}

type DataType = typeof DATA_TYPE[number]
type DataType350 = typeof DATA_TYPE_350[number]
type DataType357 = typeof DATA_TYPE_357[number]

const itemsColors: PartialRecordStringType<DataType> = {
  U350_airFlow: COLORS.u2Phase2.U350_airFlow,
  U350_powder: COLORS.u2Phase2.U350_powder,
  U352_airPressureIn: COLORS.u2Phase2.U352_airPressureIn,
  U352_airPressureOut: COLORS.u2Phase2.U352_airPressureOut,
  U357_airFlow: COLORS.u2Phase2.U357_airFlow,
  U357_powder: COLORS.u2Phase2.U357_powder,
  U358_airPressure: COLORS.u2Phase2.U358_airPressure,
  U359_airPressure: COLORS.u2Phase2.U359_airPressure,
  U350_airFlowThreshold: COLORS.u2Phase2.U350_airFlowThreshold,
  U357_airFlowThreshold: COLORS.u2Phase2.U357_airFlowThreshold,
} as const

enum BASE_UNIT_OF_MEASURE {
  flow = 'nm^3/h',
  density = 'mg/m^3',
  pressure = 'mbar',
}

const itemsUnitOfMeasure: PartialRecordStringType<DataType> = {
  U350_airFlow: BASE_UNIT_OF_MEASURE.flow,
  U350_powder: BASE_UNIT_OF_MEASURE.density,
  U352_airPressureIn: BASE_UNIT_OF_MEASURE.pressure,
  U352_airPressureOut: BASE_UNIT_OF_MEASURE.pressure,
  U357_airFlow: BASE_UNIT_OF_MEASURE.flow,
  U357_powder: BASE_UNIT_OF_MEASURE.density,
  U358_airPressure: BASE_UNIT_OF_MEASURE.pressure,
  U359_airPressure: BASE_UNIT_OF_MEASURE.pressure,
  U350_airFlowThreshold: BASE_UNIT_OF_MEASURE.flow,
  U357_airFlowThreshold: BASE_UNIT_OF_MEASURE.flow,
} as const

const measureId: PartialRecordStringType<DataType> = {
  U350_airFlow: 'IBREMLBUDQDI8CWL_WS_AIR_ACT_FLOW',
  U350_powder: 'IBREMLBUDQDI8CWL_WS_ACT',
  U352_airPressureIn: 'IBREMLBUDQDI8MTF_WS_IN_ACT',
  U352_airPressureOut: 'IBREMLBUDQDI8MTF_WS_OUT_ACT',
  U357_airFlow: 'IBREMLBUDQDI9RRA_WS_AIR_ACT_FLOW',
  U357_powder: 'IBREMLBUDQDI9RRA_WS_ACT',
  U358_airPressure: 'IBREMLBUDQDI9RRA_U358_WS_ACT',
  U359_airPressure: 'IBREMLBUDQDI9RRA_U359_WS_ACT',
}

interface CompleteDataU350 {
  data: Array<PartialRecordDataType<DataType350>>
}

interface CompleteDataU357 {
  data: Array<PartialRecordDataType<DataType357>>
}

const DATA_TYPE = [
  'U357_airFlow',
  'U357_powder',
  'U358_airPressure',
  'U359_airPressure',
  'U357_airFlowThreshold',
  'U350_airFlowThreshold',
  'U350_airFlow',
  'U350_powder',
  'U352_airPressureIn',
  'U352_airPressureOut',
] as const

const DATA_TYPE_357 = [
  'U357_airFlow',
  'U357_powder',
  'U358_airPressure',
  'U359_airPressure',
  'U357_airFlowThreshold',
] as const

const DATA_TYPE_350 = [
  'U350_airFlow',
  'U350_powder',
  'U352_airPressureIn',
  'U352_airPressureOut',
  'U350_airFlowThreshold',
] as const

const TOOLTIP_PLACEHOLDER = '--'

const _Chart = (props: GeneralChartProps) => {
  const { startDateTime, endDateTime, abortController, plantString, t } = props

  const dataContentLabel: PartialRecordStringType<DataType> = useMemo(
    () =>
      ({
        U350_airFlow: `${t('plantAnalysis.aspirationSystem.U350_airFlow')}`,
        U350_powder: `${t('plantAnalysis.aspirationSystem.U350_powder')}`,
        U352_airPressureIn: `${t('plantAnalysis.aspirationSystem.U352_airPressureIn')}`,
        U350_airFlowThreshold: `${t('plantAnalysis.aspirationSystem.U350_airFlowThreshold')}`,
        U357_airFlowThreshold: `${t('plantAnalysis.aspirationSystem.U357_airFlowThreshold')}`,
        U352_airPressureOut: `${t('plantAnalysis.aspirationSystem.U352_airPressureOut')}`,
        U357_airFlow: `${t('plantAnalysis.aspirationSystem.U357_airFlow')}`,
        U357_powder: `${t('plantAnalysis.aspirationSystem.U357_powder')}`,
        U358_airPressure: `${t('plantAnalysis.aspirationSystem.U358_airPressure')}`,
        U359_airPressure: `${t('plantAnalysis.aspirationSystem.U359_airPressure')}`,
      } as PartialRecordStringType<DataType>),
    []
  )

  const {
    actions: actions350,
    data: data350,
    isError: isError350,
    isLoading: isLoading350,
    tooltip: tooltip350,
  } = useChartState<CompleteDataU350, DataType350>({
    keys: [...DATA_TYPE_350],
    tooltipContentInfo: {
      U350_airFlow_color: itemsColors.U350_airFlow,
      U350_airFlow_label: dataContentLabel.U350_airFlow,
      U350_airFlow_unitOfMeasure: itemsUnitOfMeasure.U350_airFlow,
      U350_powder_color: itemsColors.U350_powder,
      U350_powder_label: dataContentLabel.U350_powder,
      U350_powder_unitOfMeasure: itemsUnitOfMeasure.U350_powder,
      U352_airPressureIn_label: dataContentLabel.U352_airPressureIn,
      U352_airPressureIn_unitOfMeasure: itemsUnitOfMeasure.U352_airPressureIn,
      U352_airPressureOut_color: itemsColors.U352_airPressureOut,
      U352_airPressureOut_label: dataContentLabel.U352_airPressureOut,
      U352_airPressureOut_unitOfMeasure: itemsUnitOfMeasure.U352_airPressureOut,
      U350_airFlowThreshold_color: itemsColors.U350_airFlowThreshold,
      U350_airFlowThreshold_label: dataContentLabel.U350_airFlowThreshold,
      U350_airFlowThreshold_unitOfMeasure: itemsUnitOfMeasure.U350_airFlowThreshold,
    },
  })

  const {
    actions: actions357,
    data: data357,
    isError: isError357,
    isLoading: isLoading357,
    tooltip: tooltip357,
  } = useChartState<CompleteDataU357, DataType357>({
    keys: [...DATA_TYPE_357],
    tooltipContentInfo: {
      U357_airFlow_color: itemsColors.U357_airFlow,
      U357_airFlow_label: dataContentLabel.U357_airFlow,
      U357_airFlow_unitOfMeasure: itemsUnitOfMeasure.U357_airFlow,
      U357_powder_color: itemsColors.U357_powder,
      U357_powder_label: dataContentLabel.U357_powder,
      U357_powder_unitOfMeasure: itemsUnitOfMeasure.U357_powder,
      U358_airPressure_color: itemsColors.U358_airPressure,
      U358_airPressure_label: dataContentLabel.U358_airPressure,
      U358_airPressure_unitOfMeasure: itemsUnitOfMeasure.U358_airPressure,
      U359_airPressure_color: itemsColors.U359_airPressure,
      U359_airPressure_label: dataContentLabel.U359_airPressure,
      U359_airPressure_unitOfMeasure: itemsUnitOfMeasure.U359_airPressure,
      U357_airFlowThreshold_color: itemsColors.U357_airFlowThreshold,
      U357_airFlowThreshold_label: dataContentLabel.U357_airFlowThreshold,
      U357_airFlowThreshold_unitOfMeasure: itemsUnitOfMeasure.U357_airFlowThreshold,
    },
  })

  const { queryEndSection, queryStartSection, uriRequestStart } = useChartFetching({
    callback: fetchAllData,
    endDateTime,
    startDateTime,
    plantString,
    type: 'normal',
  })

  function fetchAllData() {
    fetchAllData350()
    fetchAllData357()
  }

  function fetchAllData350() {
    actions350.setLoading()
    const U350AirFlowRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureId.U350_airFlow}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )
    const U350PowderRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureId.U350_powder}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )
    const U352AirPressureInRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureId.U352_airPressureIn}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )
    const U352AirPressureOutRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureId.U352_airPressureOut}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )

    Promise.all([U350AirFlowRequest, U350PowderRequest, U352AirPressureInRequest, U352AirPressureOutRequest])
      .then(([U350AirFlowResponse, U350PowderResponse, U352AirPressureInResponse, U352AirPressureOutResponse]) => {
        const mockedDataThreshold: MockedData = {
          results: [
            {
              series: [
                {
                  values: [
                    [startDateTime, 60000],
                    [endDateTime, 60000],
                  ],
                },
              ],
            },
          ],
        }
        actions350.setData({
          data: parseAndPrepareData({
            U350_airFlowThreshold: JSON.stringify(mockedDataThreshold),
            U350_airFlow: U350AirFlowResponse,
            U350_powder: U350PowderResponse,
            U352_airPressureIn: U352AirPressureInResponse,
            U352_airPressureOut: U352AirPressureOutResponse,
          }),
        })
      })
      .catch(_ => {
        actions350.setError()
      })
  }

  function fetchAllData357() {
    actions357.setLoading()
    const U357AirFlowRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureId.U357_airFlow}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )
    const U357PowderRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureId.U357_powder}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )
    const U358AirPressureRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureId.U358_airPressure}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )
    const U359AirPressureRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureId.U359_airPressure}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )

    Promise.all([U357AirFlowRequest, U357PowderRequest, U358AirPressureRequest, U359AirPressureRequest])
      .then(([U357AirFlowResponse, U357PowderResponse, U358AirPressureResponse, U359AirPressureResponse]) => {
        const mockedDataThreshold: MockedData = {
          results: [
            {
              series: [
                {
                  values: [
                    [startDateTime, 100000],
                    [endDateTime, 100000],
                  ],
                },
              ],
            },
          ],
        }
        actions357.setData({
          data: parseAndPrepareData({
            U357_airFlowThreshold: JSON.stringify(mockedDataThreshold),
            U357_airFlow: U357AirFlowResponse,
            U357_powder: U357PowderResponse,
            U358_airPressure: U358AirPressureResponse,
            U359_airPressure: U359AirPressureResponse,
          }),
        })
      })
      .catch(_ => {
        actions357.setError()
      })
  }

  function parseAndPrepareData(requestDataSet: Record<DataType350, any>): Array<PartialRecordDataType<DataType350>>
  function parseAndPrepareData(requestDataSet: Record<DataType357, any>): Array<PartialRecordDataType<DataType357>>
  function parseAndPrepareData(
    requestDataSet: Record<DataType350, any> | Record<DataType357, any>
  ): Array<PartialRecordDataType<DataType350>> | Array<PartialRecordDataType<DataType357>> {
    const populatedData: Record<DataType, SingleDataValue[]> = Object.entries(requestDataSet).reduce(
      (acc, [currKey, currValue]) => {
        const dataToParse = isJSON(currValue)
          ? parseResponseData(currValue)
          : parseResponseData(JSON.stringify(currValue))
        return {
          ...acc,
          [currKey]: simplePopulateSingleData(dataToParse),
        }
      },
      {}
    ) as Record<DataType, SingleDataValue[]>
    const mergedData: any[] = []
    hydrateData(populatedData, mergedData)
    hydrateTimeData([...DATA_TYPE], mergedData, { startDate: startDateTime, endDate: endDateTime })
    return (mergedData as unknown) as Array<PartialRecordDataType<DataType>>
  }

  function handleMouseEnter(type: '350' | '357') {
    switch (type) {
      case '350': {
        actions350.setTooltipContent({})
        break
      }
      case '357': {
        actions357.setTooltipContent({})
        break
      }
    }
  }

  function handleMouseMove(args: any, type: '350' | '357') {
    switch (type) {
      case '350': {
        if (args && args.activeLabel) {
          const currentData = data350?.data.find((e: any) => e.time === args.activeLabel)
          // tslint:disable-next-line: variable-name
          const U350_airFlow_data = currentData?.U350_airFlow ?? TOOLTIP_PLACEHOLDER
          // tslint:disable-next-line: variable-name
          const U350_powder_data = currentData?.U350_powder ?? TOOLTIP_PLACEHOLDER
          // tslint:disable-next-line: variable-name
          const U352_airPressureIn_data = currentData?.U352_airPressureIn ?? TOOLTIP_PLACEHOLDER
          // tslint:disable-next-line: variable-name
          const U352_airPressureOut_data = currentData?.U352_airPressureOut ?? TOOLTIP_PLACEHOLDER
          // tslint:disable-next-line: variable-name
          const U350_airFlowThreshold_data = currentData?.U350_airFlowThreshold ?? TOOLTIP_PLACEHOLDER
          actions350.setTooltipContent({
            time: currentData?.time,
            U350_airFlow_data,
            U350_powder_data,
            U352_airPressureIn_data,
            U352_airPressureOut_data,
            U350_airFlowThreshold_data
          })
        }
        break
      }
      case '357': {
        if (args && args.activeLabel) {
          const currentData = data357?.data.find((e: any) => e.time === args.activeLabel)
          // tslint:disable-next-line: variable-name
          const U357_airFlow_data = currentData?.U357_airFlow ?? TOOLTIP_PLACEHOLDER
          // tslint:disable-next-line: variable-name
          const U357_powder_data = currentData?.U357_powder ?? TOOLTIP_PLACEHOLDER
          // tslint:disable-next-line: variable-name
          const U358_airPressure_data = currentData?.U358_airPressure ?? TOOLTIP_PLACEHOLDER
          // tslint:disable-next-line: variable-name
          const U359_airPressure_data = currentData?.U359_airPressure ?? TOOLTIP_PLACEHOLDER
          // tslint:disable-next-line: variable-name
          const U357_airFlowThreshold_data = currentData?.U357_airFlowThreshold ?? TOOLTIP_PLACEHOLDER
          actions357.setTooltipContent({
            time: currentData?.time,
            U357_airFlow_data,
            U357_powder_data,
            U358_airPressure_data,
            U359_airPressure_data,
            U357_airFlowThreshold_data
          })
        }
        break
      }
    }
  }
  function handleMouseLeave(type: '350' | '357') {
    switch (type) {
      case '350': {
        actions350.hideTooltip()
        break
      }
      case '357': {
        actions357.hideTooltip()
        break
      }
    }
  }

  function nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  function render350() {
    if (isLoading350) {
      return <></>
    }

    if (isError350) {
      return <CommonErrorMessage />
    }

    if (data350 && data350.data.length === 0) {
      return <CommonNoDataAvailable />
    }

    return (
      <React.Fragment>
        <Row>
          <div className="col-md-10">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                width={700}
                height={400}
                data={data350?.data}
                margin={{ top: 40, right: 0, bottom: 0, left: 0 }}
                onMouseEnter={() => handleMouseEnter('350')}
                onMouseMove={arg => handleMouseMove(arg, '350')}
                onMouseLeave={() => handleMouseLeave('350')}
                barGap={0}
                barCategoryGap={0}
              >
                <XAxis
                  key={'line1'}
                  hide={false}
                  dataKey="time"
                  label={''}
                  height={30}
                  axisLine={true}
                  tickLine={false}
                  tick={true}
                  interval={'preserveStartEnd'}
                  minTickGap={50}
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                  type={'category'}
                />

                <YAxis
                  domain={[0, 'auto']}
                  axisLine={false}
                  yAxisId={BASE_UNIT_OF_MEASURE.density}
                  orientation={'right'}
                >
                  <Label value={BASE_UNIT_OF_MEASURE.density} position={'top'} offset={10} />
                </YAxis>
                <YAxis domain={[0, 'auto']} axisLine={false} yAxisId={BASE_UNIT_OF_MEASURE.flow} orientation={'left'}>
                  <Label value={BASE_UNIT_OF_MEASURE.flow} position={'top'} offset={10} />
                </YAxis>
                <YAxis
                  domain={[0, 'auto']}
                  axisLine={false}
                  yAxisId={BASE_UNIT_OF_MEASURE.pressure}
                  orientation={'left'}
                >
                  <Label value={BASE_UNIT_OF_MEASURE.pressure} position={'top'} offset={10} />
                </YAxis>
                <CartesianGrid stroke="#f5f5f5" />
                <Tooltip content={nullTooltipContent()} />
                <Line
                  dataKey={'U350_airFlow'}
                  stroke={itemsColors.U350_airFlow}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U350_airFlow}
                />
                <Line
                  dataKey={'U350_powder'}
                  stroke={itemsColors.U350_powder}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U350_powder}
                />
                <Line
                  dataKey={'U352_airPressureIn'}
                  stroke={itemsColors.U352_airPressureIn}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U352_airPressureIn}
                />
                <Line
                  dataKey={'U352_airPressureOut'}
                  stroke={itemsColors.U352_airPressureOut}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U352_airPressureOut}
                />
                <Line
                  dataKey={'U350_airFlowThreshold'}
                  stroke={itemsColors.U350_airFlowThreshold}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U350_airFlowThreshold}
                />
              </LineChart>
            </ResponsiveContainer>
            <CustomLegend
              dataToShow={DATA_TYPE_350.map(dataType => ({
                label: dataContentLabel[dataType] ?? '',
                color: itemsColors[dataType] ?? '',
                unitOfMeasure: itemsUnitOfMeasure[dataType] ?? '',
              }))}
            />
          </div>
          <CustomTooltip tooltip={tooltip350} />
        </Row>
      </React.Fragment>
    )
  }

  function render357() {
    if (isLoading357) {
      return <></>
    }

    if (isError357) {
      return <CommonErrorMessage />
    }

    if (data357 && data357.data.length === 0) {
      return <CommonNoDataAvailable />
    }

    return (
      <React.Fragment>
        <Row>
          <div className="col-md-10">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                width={700}
                height={400}
                data={data357?.data}
                margin={{ top: 40, right: 0, bottom: 0, left: 0 }}
                onMouseEnter={() => handleMouseEnter('357')}
                onMouseMove={arg => handleMouseMove(arg, '357')}
                onMouseLeave={() => handleMouseLeave('357')}
                barGap={0}
                barCategoryGap={0}
              >
                <XAxis
                  key={'line1'}
                  hide={false}
                  dataKey="time"
                  label={''}
                  height={30}
                  axisLine={true}
                  tickLine={false}
                  tick={true}
                  interval={'preserveStartEnd'}
                  minTickGap={50}
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                  type={'category'}
                />

                <YAxis
                  domain={[0, 'auto']}
                  axisLine={false}
                  yAxisId={BASE_UNIT_OF_MEASURE.density}
                  orientation={'right'}
                >
                  <Label value={BASE_UNIT_OF_MEASURE.density} position={'top'} offset={10} />
                </YAxis>
                <YAxis domain={[0, 'auto']} axisLine={false} yAxisId={BASE_UNIT_OF_MEASURE.flow} orientation={'left'}>
                  <Label value={BASE_UNIT_OF_MEASURE.flow} position={'top'} offset={10} />
                </YAxis>
                <YAxis
                  domain={[0, 'auto']}
                  axisLine={false}
                  yAxisId={BASE_UNIT_OF_MEASURE.pressure}
                  orientation={'left'}
                >
                  <Label value={BASE_UNIT_OF_MEASURE.pressure} position={'top'} offset={10} />
                </YAxis>
                <CartesianGrid stroke="#f5f5f5" />
                <Tooltip content={nullTooltipContent()} />
                <Line
                  dataKey={'U357_airFlow'}
                  stroke={itemsColors.U357_airFlow}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U357_airFlow}
                />
                <Line
                  dataKey={'U357_powder'}
                  stroke={itemsColors.U357_powder}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U357_powder}
                />
                <Line
                  dataKey={'U357_powder'}
                  stroke={itemsColors.U357_powder}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U357_powder}
                />
                <Line
                  dataKey={'U358_airPressure'}
                  stroke={itemsColors.U358_airPressure}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U358_airPressure}
                />
                <Line
                  dataKey={'U359_airPressure'}
                  stroke={itemsColors.U359_airPressure}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U359_airPressure}
                />
                <Line
                  dataKey={'U357_airFlowThreshold'}
                  stroke={itemsColors.U357_airFlowThreshold}
                  dot={false}
                  strokeWidth={2}
                  type="step"
                  isAnimationActive={false}
                  yAxisId={itemsUnitOfMeasure.U357_airFlowThreshold}
                />
              </LineChart>
            </ResponsiveContainer>
            <CustomLegend
              dataToShow={DATA_TYPE_357.map(dataType => ({
                label: dataContentLabel[dataType] ?? '',
                color: itemsColors[dataType] ?? '',
                unitOfMeasure: itemsUnitOfMeasure[dataType] ?? '',
              }))}
            />
          </div>
          <CustomTooltip tooltip={tooltip357} />
        </Row>
      </React.Fragment>
    )
  }

  return (
    <>
      <h3 className={'w-100'}>{t('plantAnalysis.aspirationSystem.e2Title')}</h3>
      {render357()}
      <h3 className={'w-100'}>{t('plantAnalysis.aspirationSystem.e1Title')}</h3>
      {render350()}
    </>
  )
}

export default _Chart
