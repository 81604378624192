import {
  InfluxResponseToParse,
  parseInfluxResponse,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx'
import { IInfluxResponse, InfluxMetricQueryParams } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/influx'


const unthaMeasuresToFetch: Array<InfluxMetricQueryParams & InfluxResponseToParse> = [
  {
    type: 'standard',
    id: 'IBSGN5PNUV27G9GF_ROT_ACT_SPD',
    aggregate: true,
    slug: "speedPercent",
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'standard' }),
  },
  {
    type: 'summary',
    id: 'vUntha',
    slug: "recapData",
    parseFn: (res: IInfluxResponse) => parseInfluxResponse(res, { measureType: 'summary' }),
  },
]

export { unthaMeasuresToFetch }
