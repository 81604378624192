import * as React from 'react'
import { connect } from 'react-redux'
import { loginUser, logoutUser } from '../../../../redux/actions/auth'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { Button, Input, Wrapper } from '@mv-submodules/inplant-components-fe'
import routes from '../../../../../inplant-config/config/routes'
import { LinkableText } from '../../../../../inplant-components-fe/ui/components/MVText/Text'

interface StateProps {
  fetching: boolean
  error: Error | null
  loggedIn: boolean
  config: { legacyLogin: (username: string, password: string) => Promise<void> } | undefined
}

interface DispatchProps {
  loginUser: (username: string, password: string) => Function
  logoutUser: () => Function
}

interface OwnProps {
  successPath: string
  image?: string
  // t: TranslationFunction
}

interface OwnState {
  username: string
  password: string
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation & RouteComponentProps

const mapStateToProps = (state: any): StateProps => ({
  error: state.auth.error,
  fetching: state.auth.fetching,
  loggedIn: state.auth.loggedIn,
  config: state.config.auth || undefined,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    loginUser: (email, password) => dispatch(loginUser(email, password)),
    logoutUser: () => dispatch(logoutUser()),
  }
}

class Login extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      username: '',
      password: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    // clean session before logging in
    this.props.logoutUser()
  }

  private handleChange(name: string, value: string | number) {
    this.setState(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.props.loginUser(this.state.username, this.state.password)
    if (this.props.config && this.props.config.legacyLogin) {
      try {
        await this.props.config.legacyLogin(this.state.username, this.state.password)
      } catch (error) {
        console.error(error) // tslint:disable-line
      }
    }
  }

  public render() {
    const {
      location: { search },
    } = this.props

    const redirect = new URLSearchParams(search).get('redirect')

    if (this.props.loggedIn) {
      return <Redirect to={redirect ?? this.props.successPath} />
    }
    return (
      <div className="inplant-login">
        <Wrapper customType="loginForm">
          <form onSubmit={this.handleSubmit}>
            {this.props.image ? <img src={this.props.image} className="img-fluid mb-4" alt={''} /> : undefined}
            <Input
              label={this.props.t('auth.usernameLabel')}
              type="text"
              id="username"
              name="username"
              aria-describedby="usernameHelp"
              placeholder={this.props.t('auth.usernamePlaceholder')}
              value={this.state.username}
              onChange={value => this.handleChange('username', value)}
              required={true}
            />
            <Input
              label={this.props.t('auth.passwordLabel')}
              type="password"
              id="password"
              name="password"
              placeholder={this.props.t('auth.passwordPlaceholder')}
              value={this.state.password}
              onChange={value => this.handleChange('password', value)}
              required={true}
            />
            <Button
              variant="primary-alternate"
              isLoading={this.props.fetching}
              type="submit"
              label={this.props.t('auth.submitText')}
            />
            {routes([]).find(r => r.children?.find(c => c.path === '/user/password-recovery')) && (
              <LinkableText
                text={this.props.t('auth.passwordRecovery')}
                to={loc => ({ ...loc, pathname: '/user/password-recovery' })}
              />
            )}
          </form>
        </Wrapper>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Login)))
