import { EngineSpeedDensifierState } from "./_types"


const rifColumnName = 'rif_min_time'

const measuresToFetch = [
  'dec_f019_speed_over_torque_threshold_percent',
  'dec_f028_speed_over_torque_threshold_percent',
  'dec_f057_speed_over_torque_threshold_percent',
  'dec_f076_speed_over_torque_threshold_percent',
  'dec_f086_speed_over_torque_threshold_percent',
  'dec_f019_over_threshold_count',
  'dec_f028_over_threshold_count',
  'dec_f057_over_threshold_count',
  'dec_f076_over_threshold_count',
  'dec_f086_over_threshold_count',
  'dec_f019_seconds_under_zero_threshold',
  'dec_f028_seconds_under_zero_threshold',
  'dec_f057_seconds_under_zero_threshold',
  'dec_f076_seconds_under_zero_threshold',
  'dec_f086_seconds_under_zero_threshold',
]

const DEFAULT_STATE: EngineSpeedDensifierState = {
  fetching: true,
  error: false,
  errorDensifier: false,
  dataDensifier: null,
  data: null,
  filteredData: [],
  mergedData: [],
  keyCollection: [],
  highlightInfoData: {},
  warnAreas: {
    zeroThrs: {
      line1: [],
      line2: [],
      line3: [],
      line4: [],
      line5: [],
    },
    fromZeroThrsToPlcThrs: {
      line1: [],
      line2: [],
      line3: [],
      line4: [],
      line5: [],
    },
  },
}

export { DEFAULT_STATE, rifColumnName, measuresToFetch }
