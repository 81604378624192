import { EngineTorqueDensifierState } from './_types'

const measuresToFetch = [
  'dec_f019_from_one_point_five_to_sixty_eight_percent',
  'dec_f028_from_one_point_five_to_sixty_eight_percent',
  'dec_f057_from_one_point_five_to_sixty_eight_percent',
  'dec_f076_from_one_point_five_to_sixty_eight_percent',
  'dec_f086_from_one_point_five_to_sixty_eight_percent',
  'dec_f019_from_sixty_eight_to_seventy_eight_percent',
  'dec_f028_from_sixty_eight_to_seventy_eight_percent',
  'dec_f057_from_sixty_eight_to_seventy_eight_percent',
  'dec_f076_from_sixty_eight_to_seventy_eight_percent',
  'dec_f086_from_sixty_eight_to_seventy_eight_percent',
  'dec_f019_from_seventy_eight_to_eighty_eight_percent',
  'dec_f028_from_seventy_eight_to_eighty_eight_percent',
  'dec_f057_from_seventy_eight_to_eighty_eight_percent',
  'dec_f076_from_seventy_eight_to_eighty_eight_percent',
  'dec_f086_from_seventy_eight_to_eighty_eight_percent',
  'dec_f019_from_eighty_eight_to_hundred_percent',
  'dec_f028_from_eighty_eight_to_hundred_percent',
  'dec_f057_from_eighty_eight_to_hundred_percent',
  'dec_f076_from_eighty_eight_to_hundred_percent',
  'dec_f086_from_eighty_eight_to_hundred_percent',
  'dec_f019_average_torque',
  'dec_f028_average_torque',
  'dec_f057_average_torque',
  'dec_f076_average_torque',
  'dec_f086_average_torque',
  'dec_f019_seconds_under_zero_threshold',
  'dec_f028_seconds_under_zero_threshold',
  'dec_f057_seconds_under_zero_threshold',
  'dec_f076_seconds_under_zero_threshold',
  'dec_f086_seconds_under_zero_threshold',
  'dec_f019_seconds_over_zero_threshold',
  'dec_f028_seconds_over_zero_threshold',
  'dec_f057_seconds_over_zero_threshold',
  'dec_f076_seconds_over_zero_threshold',
  'dec_f086_seconds_over_zero_threshold',
]

const ENGINE_TORQUE_DENSIFIER_GRAPH_KEYS_TO_IGNORE = [
  'seconds_over_zero_threshold',
  'seconds_under_zero_threshold',
  'average_torque',
]

const DEFAULT_STATE: EngineTorqueDensifierState = {
  fetching: true,
  error: false,
  data: null,
  filteredData: [],
}

export { measuresToFetch, DEFAULT_STATE, ENGINE_TORQUE_DENSIFIER_GRAPH_KEYS_TO_IGNORE }
