import { consoleLog } from "@mv-submodules/mvlabs-components-fe/functions/logs"
import { TFunction } from "i18next"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { ResponsiveContainer, ComposedChart, YAxis, XAxis } from "recharts"
import { COLORS } from "../_constants"
import { getAreaSections, reduceWarnAreas } from "@mv-submodules/inplant-plantanalysis-fe-iblu/functions/sharedV2"

export interface OwnProps {
  filteredData: any
  t: TFunction
  topMargin?: boolean
  entry: string
  paddingRight?: number
  lineHeight?: number
}

const i18nTitle = 'plantAnalysis.labels'

type Props = WithTranslation & OwnProps

class GenericTimeBarGraph extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    try {
      const dataPoints = getAreaSections(this.props.filteredData, this.props.entry)
      const dataAreas = reduceWarnAreas(dataPoints, {colorMap: COLORS[this.props.entry]} )

      return (
        <div className={'row row-bar-chart row-bar-chart' + (this.props.topMargin ? '-under' : '')}>
          <div className="col-md-10" style={this.props.paddingRight ? { paddingRight: this.props.paddingRight } : {}}>
            <h5>{this.props.t(`${i18nTitle}.${this.props.entry}`)}</h5>
            <ResponsiveContainer width="100%" height={this.props.lineHeight ? this.props.lineHeight : 20}>
              <ComposedChart width={700} height={180} data={this.props.filteredData} barGap={0} barCategoryGap={0}>
                <YAxis label={''} domain={[0, 100]} tick={false} axisLine={false} tickLine={false} />
                <XAxis dataKey="time" hide={true} domain={['dataMin', 'dataMax']} type={'number'} interval={0} />
                {dataAreas}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    } catch (error) {
      consoleLog('error', error)
    }
  }
}

export default withTranslation()(GenericTimeBarGraph)
