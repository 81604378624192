import { IInfluxResponse } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/influx'
import moment from 'moment'

interface ParseOptions {
  resultToParse?: number
  serieToParse?: number
  measureType?: 'standard' | 'single_column_state_serie' | 'multi_column_state_serie' | 'summary'
}

interface ParsedSerieDatum {
  x: number
  y: number
}

interface ParsedStateData {
  [key: string]: number
}

export type ParsedData = ParsedSerieDatum[] | ParsedStateData

export interface InfluxResponseToParse {
  id: string
  slug: string
  parseFn: (res: IInfluxResponse) => ParsedData
}

function parseInfluxResponse(response: IInfluxResponse, options?: ParseOptions): ParsedSerieDatum[]
function parseInfluxResponse(response: IInfluxResponse, options?: { measureType: 'summary' }): ParsedStateData

function parseInfluxResponse(
  response: IInfluxResponse,
  options: ParseOptions = {}
): ParsedSerieDatum[] | ParsedStateData {
  const { resultToParse = 0, serieToParse = 0, measureType = 'standard' } = options
  const selectedResult = response.results[resultToParse]

  if (!selectedResult.series) {
    return []
  }

  const selectedSerie = selectedResult.series[serieToParse]

  if (!selectedSerie) {
    throw new Error('Selected series not found in the response')
  }

  const { values, columns } = selectedSerie

  switch (measureType) {
    case 'standard':
      return values.map((data: any) => ({
        x: moment(data[0]).unix(),
        y: data[1],
      }))

    case 'single_column_state_serie': {
      // Takes values always from the first column that is not 'time' or 'shift'
      const timeColumnIndex = columns.findIndex(col => col === 'time')
      const valueColumnIndex = columns.findIndex(col => col !== 'time' && col !== 'shift')
      return values.map((data: any) => ({
        x: moment((data[timeColumnIndex] as any) as string).unix(),
        y: +((data[valueColumnIndex] as any) as boolean),
      }))
    }
    case 'multi_column_state_serie': {
      const timeColumnIndex = columns.findIndex(col => col === 'time')
      const shiftColumnIndex = columns.findIndex(col => col === 'shift')
      const indicesToIgnore = [timeColumnIndex, shiftColumnIndex]
      return values.map((data: any) => {
        const datumIndex = data.findIndex((element: any) => element === true)
        let offset = 0
        indicesToIgnore.forEach(indexToIgnore => {
          if (indexToIgnore < datumIndex) {
            offset = offset + 1
          }
        })
        return {
          x: moment(data[0] as string).unix(),
          y: datumIndex - offset,
        }
      })
    }
    case 'summary':
      const stateData = columns.reduce((acc: any, col: string, index: number) => {
        if (col !== 'time' && col !== 'shift') {
          const colValue = (values as any)[0][index]
          acc[col] = colValue >= 0 ? colValue : 0
        }
        return acc
      }, {})
      return stateData

    default:
      return []
  }
}

export { parseInfluxResponse }
