import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AreaSection } from '../../../../types/measure'
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../constants'
import moment from 'moment'
import { getAreaPauses, getAreaSections, HHHmmssFromMilliseconds, reduceWarnAreas } from '../../../../functions/shared'
import { TableRowFullWrapper } from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import { UnthaProps, UnthaState } from './_types'
import { DEFAULT_STATE } from './_constants'
import Untha from './_Untha'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'

class UnthaPrint extends Untha<UnthaProps, UnthaState> {
  constructor(props: UnthaProps) {
    super(props, () => DEFAULT_STATE)
  }

  public renderContent() {
    const { t, pauses } = this.props

    const warnPoints: AreaSection[] | undefined =
      ([{}] as [{}]) && getAreaSections(this.state.filteredData, 'warnArea').filter(e => e.value === 100)
    const warnPauses = pauses && reduceWarnAreas(getAreaPauses(pauses), undefined, COLORS.pauses)
    const warnAreas = reduceWarnAreas(warnPoints)
    return (
      <>
        <TableRowFullWrapper>
          <h3 className={'w-100'}>{this.props.t(this.chartTranslationKeyTitle)}</h3>
        </TableRowFullWrapper>
        <TableRowFullWrapper>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={this.state.filteredData}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              barCategoryGap={0}
            >
              <XAxis
                key={'line1'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />
              <CartesianGrid stroke="#f5f5f5" />

              {warnAreas}
              {warnPauses}
              <YAxis yAxisId={'percentage'} domain={[0, 100]} axisLine={false} tickFormatter={val => parseInt(val, 10)}>
                <Label value={'%'} position={'insideTopLeft'} />
              </YAxis>
              <Line
                dataKey="speedPercent"
                stroke={COLORS.vecoplant.generalGraph.speedPercent}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'percentage'}
              />
            </LineChart>
          </ResponsiveContainer>
          <div className="d-none d-print-inline-flex">
            <span style={{ whiteSpace: 'nowrap' }}>
              <span
                className="label-color-square mx-3"
                style={{ backgroundColor: COLORS.vecoplant.generalGraph.speedPercent }}
              />
              <span>{t('plantAnalysis.labels.speedPercent')}</span>
            </span>
          </div>
          <div>
            <span className="mx-5">
              <b>{this.props.t('plantAnalysis.vecoplant.vecoplanTable.number') + ': '}</b>
              {this.state.data && this.state.data.recapData && this.state.data.recapData.stops_count}
            </span>
            <span>
              <b>{this.props.t('plantAnalysis.vecoplant.vecoplanTable.time') + ': '}</b>
              {this.state.data &&
                this.state.data.recapData &&
                HHHmmssFromMilliseconds((this.state.data.recapData.stop_seconds as number) * 1000, false, true, false)}
            </span>
            <span className="mx-5">
              <b>{this.props.t('plantAnalysis.vecoplant.vecoplanTable.averageSpeed') + ': '}</b>
              {this.state.data &&
                this.state.data.recapData &&
                Number(this.state.data.recapData.average_speed).toFixed(2)}
            </span>
          </div>
        </TableRowFullWrapper>
      </>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(UnthaPrint))
