// import { maintenanceReducer as maintenance, MaintenanceRouter } from '@mv-submodules/inplant-maintenance-fe'
import { userReducer as user } from '@mv-submodules/inplant-user-fe'
import { authReducer } from '../../inplant-coreadapter-fe/auth'
// import { reducers as mcsReducer } from '@mv-submodules/inplant-mcs-fe-iblu'
// import { checklistReducer } from '@mv-submodules/inplant-checklist-fe-iblu'
import { reducers as chronoReducer } from '@mv-submodules/inplant-chronoframe-fe'
import { reducers as plantHaltsReducer } from '@mv-submodules/inplant-fermiimpianto-fe-iblu'
import { reducers as titech } from '@mv-submodules/inplant-titech-fe-iblu'
import { reducers as plantAnalysis } from '@mv-submodules/inplant-plantanalysis-fe-iblu'
import { reducers as plantStatus } from '@mv-submodules/inplant-plantstatus-fe'
import { plantChartConfig } from './charts';
import { plantSelector } from '../../inplant-coreadapter-fe/ui/components/widgets/PlantSelector/reducers'
import { reducers as assetManagereReducer } from '@mv-submodules/inplant-asset-manager-fe'
import componentsReducers from '../../mvlabs-components-fe/redux/reducers'
import coreControlReducers from '../../inplant-coreadapter-fe/redux/reducers'


/*
interface MaintenancePage {
  name: string
  i18nkey: string
  description?: string
  pagePath: string
  pageType: 'calendar' | 'plan' | 'setup'
  slug: string
  component: any
  hiddenCols: null | string[]
  enableNewButton: boolean
}

const pages: MaintenancePage[] = [
  {
    name: 'Calendario',
    i18nkey: 'maintenance.navigation.calendar',
    pagePath: 'calendar',
    pageType: 'calendar',
    slug: 'calendar',
    component: MaintenanceRouter,
    hiddenCols: null,
    enableNewButton: true,
  },
  {
    name: 'Da pianificare',
    i18nkey: 'maintenance.navigation.toBePlanned',
    pagePath: 'calendarize',
    pageType: 'plan',
    slug: 'calendarize',
    component: MaintenanceRouter,
    hiddenCols: ['macroarea', 'component', 'type'],
    enableNewButton: true,
  },
  {
    name: 'In ritardo',
    i18nkey: 'maintenance.navigation.expiredList',
    pagePath: 'overdue-anomalies',
    pageType: 'plan',
    slug: 'overdueAnomalies',
    component: MaintenanceRouter,
    hiddenCols: ['macroarea', 'component', 'type'],
    enableNewButton: true,
  },
  {
    name: 'In essere',
    i18nkey: 'maintenance.navigation.anomalies',
    pagePath: 'anomalies',
    pageType: 'plan',
    slug: 'anomaliesPlan',
    component: MaintenanceRouter,
    hiddenCols: ['macroarea', 'component', 'type'],
    enableNewButton: true,
  },
  {
    name: 'Archivio',
    i18nkey: 'maintenance.navigation.archive',
    pagePath: 'archive',
    pageType: 'plan',
    slug: 'archive',
    component: MaintenanceRouter,
    hiddenCols: ['macroarea', 'component', 'type'],
    enableNewButton: false,
  },
]
*/


export const config = {
  generic: {
    loginSuccessPath: '/plant-analysis/list',
    plantType: 'inplant',
  },
  /*maintenance: {
    additionalLevels: [],
    enablePlanPrint: false,
    enableScheduledJobRejectAction: true,
    enableScheduledJobConstAndTime: false,
    enableAcknowledgment: true,
    showTargetNameOnNullTarget: true,
    scheduledJobsHiddenCols: ['type'],
    pages,
  },*/
  plantHalts: {
    enabledActions: (window as any).HALTS_ENABLED_ACTIONS || process.env.REACT_APP_HALTS_ENABLED_ACTIONS || false,
  },
  plantSelector: {
    isMultiPlant: (window as any).MULTI_PLANT || process.env.REACT_APP_MULTI_PLANT || false,
    multiPlantAcl: (window as any).MULTI_PLANT_ACL || process.env.REACT_APP_MULTI_PLANT_ACL || false,
  },
  plantAnalysis: {
    recipeTables: (window as any).PA_RECIPE_TABLES || process.env.REACT_APP_PA_RECIPE_TABLES || false,
    recipeConfig: (window as any).PA_RECIPE_CONFIG ||  process.env.REACT_APP_PA_RECIPE_CONFIG || false,
    rppConfig: (window as any).PA_RPP_CONFIG ||  process.env.REACT_APP_PA_RPP_CONFIG || false,
    plantConfigurations: (window as any).PA_PLANT_CONFIGUTATIONS || process.env.REACT_APP_PA_PLANT_CONFIGUTATIONS || false,
    chartConfigurations: plantChartConfig,
    overviewConfig: (window as any).PA_OVERVIEW_LAYOUTS || process.env.REACT_APP_PA_OVERVIEW_LAYOUTS || false,
    detailsConfiguration: (window as any).PA_DETAILS_CONFIGURATION || process.env.REACT_APP_PA_DETAILS_CONFIGURATION || false,
  }
}

export default {
  auth: authReducer,
  assetManager: assetManagereReducer,
  // checklist: checklistReducer,
  chronoframe: chronoReducer,
  // maintenance,
  // mcs: mcsReducer,
  plantHalts: plantHaltsReducer,
  user,
  titech,
  plantSelector,
  plantAnalysis,
  plantStatus,
  config: () => config,
  core: coreControlReducers,
  component: componentsReducers
}
