import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { getHaltsData, HHHmmssFromMilliseconds, Loader } from '../../../../../functions/shared'

export interface PieData {
  name: string
  columns: string[]
  values: any[]
}

interface PieDataColor {
  id: string
  color: string
}

interface OwnProps {
  data: PieData[]
  days: number
  colors: PieDataColor[]
  hideTime?: boolean
  fetching?: boolean
  totalsLabel?: string
  totalsReference?: {
    seconds?: number
    label: string
  }
  chartLabels?: string
  hideTitle?: boolean
}

const fallBackColors: string[] = [
  '#ff9a91',
  '#fff37b',
  '#4aa130',
  '#7dff9b',
  '#2d4cc2',
  '#00b0ff',
  '#ff60ff',
  '#9beafb',
  '#ffdb79',
  '#095aaa',
  '#0aa285',
  '#6dd673',
  '#ac3b3b',
  '#c4dd8b',
  '#0382fc',
  '#87294d',
  '#941d3c',
  '#fff474',
  '#c2815c',
]

type Props = OwnProps & WithTranslation

class EventsPieMultiple extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  private static getColor(id: string, colors: PieDataColor[], index?: number) {
    const color = colors.find(c => c.id === id)
    return (color && color.color) || (index !== undefined && fallBackColors[index]) || undefined
  }

  public render() {
    const { fetching, t, data, colors, totalsLabel, totalsReference, days, hideTime } = this.props

    if (!fetching && (!data || !data[0])) {
      return (
        <div style={{ paddingRight: '15px' }}>
          <div className="alert alert-warning w-100">{t('plantAnalysis.noDataAvailable')}</div>
        </div>
      )
    }

    const values =
      data && data.length > 0
        ? data.map(d => ({ [d.name]: getHaltsData(d, days) })).reduce((acc, curr) => ({ ...acc, ...curr }), {})
        : {}

    const totalTime = Object.entries(values).map(([key, value]) =>
      value.reduce((acc, current) => acc + (typeof current.duration === 'number' ? current.duration : 0), 0)
    )

    const chartLabels = this.props.chartLabels ? this.props.chartLabels : 'chartLabels'

    let tableData = Object.entries(values)
      .map(([key, val]) => val.map(v => v.key))
      .reduce((acc, curr) => curr.reduce((a, c) => ({ ...a, [c]: [] }), {}), {})

    const keys = Object.keys(tableData)

    Object.entries(values).forEach(([key, val]) => {
      keys.forEach(k => {
        const r = val.find(v => v.key === k)
        const valueRes = [...tableData[k], { ...r, parentKey: key }]
        tableData = {
          ...tableData,
          [k]: {
            ...valueRes,
          },
        }
      })
    })

    if (this.props.fetching) {
      return (
        <div className="alert alert-secondary w-100 col-sm-6 mx-auto rpp-graph-fetch-loading alert-local">
          {this.props.t('plantAnalysis.loading')}
          <Loader />
        </div>
      )
    }

    const isDataAvailable = Object.entries(values).every(([, val]) => val.every(v => v.duration === 0))

    if (isDataAvailable) {
      return (
        <div className="row chart-events-pie mb-3">
          <div style={{ paddingLeft: '15px', paddingRight: '15px' }} className={'w-100'}>
            <div className="alert alert-warning w-100  d-print-block">{t('plantAnalysis.noDataAvailable')}</div>
          </div>
        </div>
      )
    }

    const dataLengthToContainerClassName = (length: number) => {
      if(length > 3){
        return 'col-md-2 col-lg-2 col-sm-2'
      }
      if(length > 1){
        return 'col-md-4 col-lg-4 col-sm-4'
      }
      return 'd-flex col-md-12 col-lg-12 col-sm-12'
    }

    return (
      <>
        <div className="row chart-events-pie mb-3">
          {Object.entries(values).map(([key, v], indexValue) => {
            if (v.length === 0) {
              return (
                <div key={indexValue} style={{ paddingLeft: '15px', paddingRight: '15px' }} className={'w-100'}>
                  <div className="alert alert-warning w-100  d-print-block">{t('plantAnalysis.noDataAvailable')}</div>
                </div>
              )
            }
            return (
              <div key={key} className={dataLengthToContainerClassName(data.length)}>
                <div key={`${indexValue}-chart`} className={`col-md-12 ${data.length === 1 ? 'col-xl-4' : 'col-xl-12 mx-1'} col-sm-12 chart-events-pie--chart`}>
                  {!this.props.hideTitle && (
                    <h3 className={'w-100'}>{this.props.t(`plantAnalysis.halts.pie.${key}`)}</h3>
                  )}
                  <ResponsiveContainer width={150} height={150}>
                    <PieChart width={150} height={150}>
                      <Pie
                        data={v}
                        labelLine={false}
                        outerRadius={70}
                        fill="#8884d8"
                        dataKey="perc"
                        cx={75}
                        cy={75}
                        isAnimationActive={false}
                      >
                        {v.map((entry, index) => {
                          const color = EventsPieMultiple.getColor(entry.key, colors)
                          return <Cell key={`cell-${index}`} fill={color} />
                        })}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className={`col-md-12 ${data.length === 1 ? 'col-xl-8' : 'col-xl-12'} col-sm-12 mt-5`} key={`${indexValue}-table`}>
                  <table>
                    <thead>
                      <tr>
                        <React.Fragment key={key + 'table'}>
                          {indexValue < 1 && <th />}
                          <th className="text-right">
                            <span /* style={{ whiteSpace: 'nowrap' }} */>%</span>
                          </th>
                          <th className="text-right">{t('plantAnalysis.halts.labels.events')}</th>
                          <th />
                          <th />
                          {!hideTime && <th className="text-right">{t('plantAnalysis.halts.labels.time')}</th>}
                        </React.Fragment>
                      </tr>
                    </thead>
                    <tbody>
                      {v.map((entry, index) => {
                        if (
                          Object.entries(values).every(([entriesKey, entriesVal]) => entriesVal[index].duration === 0)
                        ) {
                          return <></>
                        }
                        return (
                          <tr key={`${index}-${entry.key}`}>
                            {indexValue < 1 && (
                              <td>
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                  className={`${Object.entries(values).length === 1 ? 'mr-3' : ''}`}
                                >
                                  <span
                                    title={t(`plantAnalysis.halts.${chartLabels}.${entry.key}`, { defaultValue: entry.key })}
                                    className="label-color-square mr-2"
                                    style={{
                                      backgroundColor: EventsPieMultiple.getColor(entry.key, colors),
                                    }}
                                  />
                                  {Object.entries(values).length === 1 && t(`plantAnalysis.halts.${chartLabels}.${entry.key}`, { defaultValue: entry.key })}
                                </span>
                              </td>
                            )}
                            <td className="text-right">{entry && entry.perc ? entry.perc.toFixed(2) : 0}</td>
                            <td className="text-right mr-1">{(entry && entry.events) || ''}</td>
                            <td />
                            <td />
                            {!hideTime && (
                              <>
                                <td className="text-right">
                                  {HHHmmssFromMilliseconds((v ? entry.duration : 0) * 1000, false, true) || ''}
                                </td>
                                <td />
                              </>
                            )}
                          </tr>
                        )
                      })}
                      {totalsLabel && (
                        <tr key={'total'} className="font-bold">
                          {!hideTime && (
                            <>
                              {indexValue < 1 && <td />}
                              <td />
                              <td />
                              <td />
                              <td />
                              <td className="text-right">
                                {HHHmmssFromMilliseconds(totalTime[indexValue] * 1000, false, true) || '--'}
                              </td>
                              <td>
                                {totalsReference && totalsReference.seconds && (
                                  <span
                                    className="text-warning"
                                    data-total={totalTime}
                                    data-ref={totalsReference.seconds}
                                  >
                                    {HHHmmssFromMilliseconds(
                                      totalTime[indexValue] * 1000 - totalsReference.seconds * 1000,
                                      false,
                                      true
                                    ) || '--'}
                                  </span>
                                )}
                              </td>
                              <td />
                              <td />
                              <td />
                            </>
                          )}
                        </tr>
                      )}
                      {totalsReference && (
                        <tr key={'total-reference'}>
                          <td>
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <span className="label-color-square" style={{ backgroundColor: 'transparent' }} />
                              <span>{totalsReference.label || '--'}</span>
                            </span>
                          </td>
                          {Object.entries(values).map(([valueKey], index) => (
                            <React.Fragment key={valueKey + 'total-reference'}>
                              <td />
                              <td />
                              {!hideTime && (
                                <>
                                  <td className="text-right">
                                    {totalsReference.seconds
                                      ? HHHmmssFromMilliseconds(totalsReference.seconds * 1000, false, true) || '--'
                                      : ''}
                                  </td>
                                  <td />
                                </>
                              )}
                              <td />
                              <td />
                              <td />
                            </React.Fragment>
                          ))}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          })}
        </div>
        {Object.entries(values).length !== 1 && 
          <div className='d-inline-block'>
            {Object.entries(values).map(([key, v], indexValue) => {
              if (indexValue > 0) {
                return <></>
              }
              return v.map((entry, index) => {
                if (Object.entries(values).every(([entriesKey, entriesVal]) => entriesVal[index].duration === 0)) {
                  return <></>
                }
                return (
                  <span
                    key={index}
                    className={'mr-2'}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <span
                      className="label-color-square"
                      style={{
                        backgroundColor: EventsPieMultiple.getColor(entry.key, colors),
                      }}
                    />
                    <span
                      title={`${t(`plantAnalysis.halts.${chartLabels}.${entry.key}`, {
                        defaultValue: entry.key,
                      })}`}
                    >
                      {t(`plantAnalysis.halts.${chartLabels}.${entry.key}`, { defaultValue: entry.key })}
                    </span>
                  </span>
                )
              })
            })}
          </div>
        }
        {/* <div className="row chart-events-pie">
          <div className="col-md-12 col-lg-12 col-sm-12 chart-events-pie--data">
            <table className={'table table-borderless table-sm w-100 mw-400 font-size-12 table-first-column-color'}>
              <thead>
                <tr>
                  {Object.entries(values).map(([key, value]) => {
                    if (value.length === 0) {
                      return <React.Fragment key={key + 'table'} />
                    }
                    return (
                      <React.Fragment key={key + 'table'}>
                        <th />
                        <th className="text-right">
                          <span style={{ whiteSpace: 'nowrap' }}>%</span>
                        </th>
                        <th className="text-right">{t('plantAnalysis.halts.labels.events')}</th>
                        {!hideTime && <th className="text-right">{t('plantAnalysis.halts.labels.time')}</th>}
                        <th />
                        <th />
                        <th />
                      </React.Fragment>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.entries(tableData).map(
                  ([key, val], index) =>
                    (val as any[]).some(v => v.duration > 0) && (
                      <tr key={index.toString() + key}>
                        <td>
                          <span
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <span
                              className="label-color-square"
                              style={{ backgroundColor: EventsPieMultiple.getColor(key, colors, index) }}
                            />
                            <span>{t(`plantAnalysis.halts.${chartLabels}.${key}`, { defaultValue: key })}</span>
                          </span>
                        </td>
                        {(val as any[]).map((v, indexVal) => (
                          <React.Fragment key={indexVal.toString() + 'values'}>
                            <td className="text-right">{v && v.perc ? v.perc.toFixed(2) : 0}</td>
                            <td className="text-right">{(v && v.events) || ''}</td>
                            {!hideTime && (
                              <>
                                <td className="text-right">
                                  {HHHmmssFromMilliseconds((v ? v.duration : 0) * 1000, false, true) || ''}
                                </td>
                                <td />
                              </>
                            )}
                            <td />
                            <td />
                            <td />
                          </React.Fragment>
                        ))}
                      </tr>
                    )
                )}
                {totalsLabel && (
                  <tr key={'total'} className="font-bold">
                    <td>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span className="label-color-square" style={{ backgroundColor: 'transparent' }} />
                        <span>{totalsLabel}</span>
                      </span>
                    </td>
                    {!hideTime &&
                      Object.entries(values).map(([key, value], index) => (
                        <React.Fragment key={key + 'totalsLabel'}>
                          <td />
                          <td />
                          <td className="text-right">
                            {HHHmmssFromMilliseconds(totalTime[index] * 1000, false, true) || '--'}
                          </td>
                          <td>
                            {totalsReference && totalsReference.seconds && (
                              <span className="text-warning" data-total={totalTime} data-ref={totalsReference.seconds}>
                                {HHHmmssFromMilliseconds(
                                  totalTime[index] * 1000 - totalsReference.seconds * 1000,
                                  false,
                                  true
                                ) || '--'}
                              </span>
                            )}
                          </td>
                          <td />
                          <td />
                          <td />
                        </React.Fragment>
                      ))}
                  </tr>
                )}
                {totalsReference && (
                  <tr key={'total-reference'}>
                    <td>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span className="label-color-square" style={{ backgroundColor: 'transparent' }} />
                        <span>{totalsReference.label || '--'}</span>
                      </span>
                    </td>
                    {Object.entries(values).map(([key, value], index) => (
                      <React.Fragment key={key + 'total-reference'}>
                        <td />
                        <td />
                        {!hideTime && (
                          <>
                            <td className="text-right">
                              {totalsReference.seconds
                                ? HHHmmssFromMilliseconds(totalsReference.seconds * 1000, false, true) || '--'
                                : ''}
                            </td>
                            <td />
                          </>
                        )}
                        <td />
                        <td />
                        <td />
                      </React.Fragment>
                    ))}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div> */}
      </>
    )
  }
}

export default withTranslation()(EventsPieMultiple)
