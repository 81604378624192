// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { Button, LinkButton } from '../../../../../mvlabs-components-fe/ui/components'

// * -------------------------------- MODULE --------------------------------------
import { User } from '../../../../types'
import { useAppSelector } from '../../../../redux/reducers'

interface Props {
  aclRequired?: string | string[]
}

const canDo = (user: User, aclRequired?: string | string[]) => {
  if (user.uuid) {
    return user.can(aclRequired)
  }
}

export const useCanUserDo = (aclRequired?: string | string[]) => {
  const user: User = useAppSelector(state => new User(state.auth?.user))
  return canDo(user, aclRequired)
}

export const useCanUserDoMultiple = (
  multipleAclRequired?: Array<{ aclRequired?: string | string[]; slug: string }>
): Record<string, boolean> => {
  const user: User = useAppSelector(state => new User(state.auth?.user))
  return (
    multipleAclRequired?.reduce((acc, curr) => {
      return { ...acc, [curr.slug]: canDo(user, curr.aclRequired) }
    }, {}) || {}
  )
}

const WithAcl = <P extends object>(Component: React.ComponentType<P>) => (props: P & Props) => {
  const { aclRequired, ...componentProps } = props
  const can = useCanUserDo(aclRequired)
  return (can && <Component {...(componentProps as P)} />) || null
}

export const AclButton = WithAcl(Button)
export const AclLinkButton = WithAcl(LinkButton)

export default WithAcl
