// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Flex from '../Flex/Flex'
import Title from '../Text/Title'
import { ButtonVariants } from '../Buttons/types'
import { LinkButton } from '../Buttons/Button'
import { IsLoading } from '../../../types/base'

interface BackTitleProps extends IsLoading {
  title: string
}

const BackTitle = (props: BackTitleProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <Flex>
      <LinkButton
        icon={'chevron-left'}
        semantic={'secondary'}
        variant={ButtonVariants.flat}
        to={location => {
          const path = location.pathname.replace(location.pathname.substring(location.pathname.lastIndexOf('/')), '')
          return { ...location, pathname: path }
        }}
      />
      <Title level={1} title={props.title} isLoading={props.isLoading} />
    </Flex>
  )
}

export default BackTitle
