import { Controls } from '../../inplant-components-fe/mvfunctions/hooks/useGenericInputHook'
import { ControlOptionType } from '../types/options'

export function getPasswordControlByConfiguration(o: ControlOptionType, t: Function, required: boolean = true, currentValue?: string | undefined): Controls<string> | undefined {
  if(!required && (!currentValue || currentValue?.length === 0)) {
    return []
  }
  return Object.entries(o).map(([key, valueI]) => {
    switch (key) {
      case 'uppercase-letter':
        return {
          control: value => {
            const count = []
            for (const iterator of value.matchAll(/[A-Z]/g)) {
              count.push(iterator)
            }
            return count.length >= valueI.min
          },
          error: valueI.min === 1 ? `${t('user.matchError.uppercaseLetter_singular', { count: valueI.min})}` : `${t('user.matchError.uppercaseLetter_plural', { count: valueI.min})}`
        }
      case 'lowercase-letter':
        return {
          control: value => {
            const count = []
            for (const iterator of value.matchAll(/[a-z]/g)) {
              count.push(iterator)
            }
            return count.length >= valueI.min
          },
          error: valueI.min === 1 ? `${t('user.matchError.lowercaseLetter_singular', { count: valueI.min})}` : `${t('user.matchError.lowercaseLetter_plural', { count: valueI.min})}`
        }
      case 'number':
        return {
          control: value => {
            const count = []
            for (const iterator of value.matchAll(/[0-9]/g)) {
              count.push(iterator)
            }
            return count.length >= valueI.min
          },
          error: valueI.min === 1 ? `${t('user.matchError.numbers_singular', { count: valueI.min})}` : `${t('user.matchError.numbers_plural', { count: valueI.min})}`
        }
      case 'special':
        return {
          control: value => {
            const count = []
            for (const iterator of value.matchAll(/.?[@#$%?!].?/g)) {
              count.push(iterator)
            }
            return count.length >= valueI.min
          },
          error: valueI.min === 1 ? `${t('user.matchError.specialCharacters_singular', { count: valueI.min, special: valueI.characters})}` : `${t('user.matchError.specialCharacters_plural', { count: valueI.min, special: valueI.characters})}`
        }
      case 'length':
        return {
          control: value => {
            return value.length >= valueI.min
          },
            error: valueI.min === 1 ? `${t('user.matchError.length_singular', { count: valueI.min})}` : `${t('user.matchError.length_plural', { count: valueI.min})}`,
        }
      default:
        return {
          control: value => {
            return true
          },
          error: '',
        }
    }
  })
}