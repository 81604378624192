import ChangePassword from './components/views/ChangePassword/ChangePassword'
import ChangePasswordLogin from './components/views/ChangePassword/ChangePasswordLogin'
import Details from './components/views/Details/DetailsPageView'
import { RouteProps } from '../../inplant-coreadapter-fe/types/routes'
import RecoveryPassword from './components/views/RecoveryPassword/RecoveryPassword'
import ResetPassword from './components/views/ResetPassword/ResetPassword'
import UserList from './components/views/List/ListPageView'
import CreateUserPageView from './components/views/New/CreateUserPageView'
import ManageUserPageView from './components/views/Edit/ManageUserPageView'

const routes: RouteProps = {
  path: '/user/list',
  i18nkey: 'user',
  visible: false,
  aclActionKey: 'user.user.create',
  children: [
    {
      path: '/user/list',
      exact: true,
      i18nkey: 'user.navigation.list',
      component: UserList,
      visible: true,
      visibleInUserProfile: true,
      aclActionKey: 'user.user.create',
    },
    {
      path: '/user/new',
      exact: true,
      i18nkey: 'user.navigation.new',
      component: CreateUserPageView,
      visible: false,
      aclActionKey: 'user.user.create',
    },    
    {
      path: '/user/details/:id',
      component: Details,
      visible: false,
      aclActionKey: 'user.user.create',
    },
    {
      path: '/user/edit/:id',
      component: ManageUserPageView,
      visible: false,
      aclActionKey: 'user.user.create',
    },
    {
      path: '/user/change-password',
      i18nkey: 'user.navigation.changePassword',
      component: ChangePassword,
      visible: false,
      aclActionKey: 'user.user.change-password',
      visibleInUserProfile: true,
    },
    {
      path: '/user/password-recovery',
      i18nkey: 'user.navigation.passwordRecovery',
      component: RecoveryPassword,
      visible: false,
      exact: true,
      // aclActionKey: 'user.user.passwordRecovery',
      visibleInUserProfile: false,
      unAuthorizedRoute: true
    },
    {
      path: '/user/reset-password/:token',
      i18nkey: 'user.navigation.resetPassword',
      component: ResetPassword,
      visible: false,
      exact: true,
      // aclActionKey: 'user.user.passwordRecovery',
      visibleInUserProfile: false,
      unAuthorizedRoute: true
    },
    {
      path: '/user/update-password',
      i18nkey: 'user.navigation.updatePassword',
      component: ChangePasswordLogin,
      visible: false,
      unAuthorizedRoute: true
    }
  ],
}

export default routes
