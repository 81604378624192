import { DerivedWordMeasures } from './_bynaryMappings'

/**
 * Functions to calculate measures derived from word
 */

const getCurrentCommand = (
  timedData: Required<{ time: number; manual: number; automatic: number; local: number; sectioned: number }>
): number | undefined => {
  let commandData
  if (timedData.manual === 1) {
    commandData = 0
  }
  if (timedData.automatic === 1) {
    commandData = 1
  }
  if (timedData.local === 1) {
    commandData = 2
  }
  if (timedData.sectioned === 1) {
    commandData = 3
  }
  return commandData
}

const getCurrentDirection = (
  timedData: Required<{ time: number; forward: number; backward: number }>
): number | undefined => {
  let direction
  if (timedData.backward === 1) {
    direction = 0
  }
  if (timedData.forward === 1) {
    direction = 1
  }
  if (timedData.forward === 0 && timedData.backward === 0) {
    direction = 2
  }
  return direction
}

const getCurrentValvePosition = (
  timedData: Required<{ time: number; valveOpenPos: number; valveClosedPos: number }>
): number | undefined => {
  let valvePosition
  if (timedData.valveClosedPos === 1) {
    valvePosition = 0
  }
  if (timedData.valveOpenPos === 1) {
    valvePosition = 1
  }
  return valvePosition
}

const getDerivedWordMeasures = (supportedDerivedMeasures: DerivedWordMeasures[], timedData: any) => {
  const derivedMeasures: any = {}
  supportedDerivedMeasures.map(measure => {
    if (measure === 'command') {
      const commandData = getCurrentCommand(timedData)
      derivedMeasures.command = commandData
    }
    if (measure === 'direction') {
      const directionData = getCurrentDirection(timedData)
      derivedMeasures.direction = directionData
    }
    if (measure === 'valvePosition') {
      const valvePositionData = getCurrentValvePosition(timedData)
      derivedMeasures.valvePosition = valvePositionData
    }
  })
  return derivedMeasures
}

export { getDerivedWordMeasures }
