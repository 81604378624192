import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Pie, PieChart, ResponsiveContainer, Cell } from 'recharts'
import { COLORS } from '../../../../../constants'
import { renderCustomizedLabel } from '../../../../../functions/renderCustomizedLabelPie'
import TableColumnWrapper from '../../PrintUtilities/TableColumnWrapper'
import TableRowWrapper, { TableRowFullWrapper } from '../../PrintUtilities/TableRowWrapper'
import TorqueBelt from './_EngineTorqueDensifier'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'
import { DEFAULT_STATE, ENGINE_TORQUE_DENSIFIER_GRAPH_KEYS_TO_IGNORE } from './_constants'
import { EngineTorqueDensifierProps, EngineTorqueDensifierState } from './_types'
import { HHHmmssFromMilliseconds } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'

class EngineTorqueDensifierPrint extends TorqueBelt<EngineTorqueDensifierProps, EngineTorqueDensifierState> {
  constructor(props: EngineTorqueDensifierProps) {
    super(props, () => DEFAULT_STATE)
  }

  public renderContent(): JSX.Element {
    const { t } = this.props

    return (
      <>
        <TableRowFullWrapper>
          <h3 className={'w-100'}>{this.props.t(this.chartTranslationKeyTitle)}</h3>
        </TableRowFullWrapper>
        {this.state.data &&
          Object.entries(this.state.data)
            .reduce<any[][]>((acc, item, index) => {
              // Subdivide graphs in rows of 3 elements
              const chunkIndex = Math.floor(index / 3)
              if (!acc[chunkIndex]) {
                acc[chunkIndex] = []
              }
              acc[chunkIndex].push(item)
              return acc
            }, [])
            .map((arr, arrIndex) => {
              return (
                <TableRowWrapper key={`arr_${arrIndex}`}>
                  {arr.map(([key, value], index) => {
                    const filteredData = (this.state.filteredData[key] as Array<{
                      name: string
                      value: number
                    }>).filter(v => !ENGINE_TORQUE_DENSIFIER_GRAPH_KEYS_TO_IGNORE.includes(v.name))
                    const secondsAtZero = this.state.data[key].seconds_under_zero_threshold
                    const secondsNotAtZero = this.state.data[key].seconds_over_zero_threshold
                    const averageTorque = this.state.data[key].average_torque
                    const secondsAtZeroPercentFromTotalWorkshift = this.getSecondsPercentFromTotalWorkshift(
                      secondsAtZero
                    )
                    const secondsNotAtZeroPercentFromTotalWorkshift = this.getSecondsPercentFromTotalWorkshift(
                      secondsNotAtZero
                    )

                    const isDataCorrect = Math.round(filteredData.reduce((sum, obj) => sum + obj.value, 0)) === 100

                    if (!isDataCorrect) {
                      return (
                        <TableColumnWrapper key={key} col={'1/3'} className="mx-2">
                          <h3>{this.props.t(`plantAnalysis.lines.engineTorqueDensifier.engines.${key}`)}</h3>
                          <div className="alert alert-warning w-100">{t('plantAnalysis.noDataAvailable')}</div>
                        </TableColumnWrapper>
                      )
                    }

                    return (
                      <TableColumnWrapper key={key} col={'1/3'} className="mx-2">
                        <h3>{this.props.t(`plantAnalysis.lines.engineTorqueDensifier.engines.${key}`)}</h3>
                        <ResponsiveContainer className={'mx-4'} key={index} width="100%" height={250}>
                          <PieChart /* width={350} */ height={250}>
                            <Pie
                              nameKey={'key'}
                              dataKey={'value'}
                              data={filteredData as any[]}
                              cx={130}
                              cy={125}
                              isAnimationActive={false}
                              outerRadius={100}
                              labelLine={false}
                              label={renderCustomizedLabel}
                            >
                              {Object.entries(value as Record<string, Record<string, number>>).map(
                                ([vKey, _vValue]) => {
                                  return (
                                    <Cell textRendering="auto" key={vKey} fill={COLORS.lines.torque[vKey] || 'gray'} />
                                  )
                                }
                              )}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                        <div>
                          <span>
                            <b>{this.props.t('plantAnalysis.lines.engineTorqueDensifier.secondsAtZero') + ': '}</b>
                            {`${HHHmmssFromMilliseconds(
                              secondsAtZero * 1000,
                              false,
                              true,
                              false
                            )} (${secondsAtZeroPercentFromTotalWorkshift?.toFixed(0) ?? ''}%)`}
                          </span>
                        </div>
                        {secondsNotAtZero !== null && (
                          <div>
                            <span>
                              <b>{this.props.t('plantAnalysis.lines.engineTorqueDensifier.secondsNotAtZero') + ': '}</b>
                              {`${HHHmmssFromMilliseconds(
                                secondsNotAtZero * 1000,
                                false,
                                true,
                                false
                              )} (${secondsNotAtZeroPercentFromTotalWorkshift?.toFixed(0) ?? ''}%)`}
                            </span>
                          </div>
                        )}
                        {secondsNotAtZero !== null && (
                          <div>
                            <span>
                              <b>{this.props.t('plantAnalysis.lines.engineTorqueDensifier.totalSeconds') + ': '}</b>
                              {HHHmmssFromMilliseconds((secondsNotAtZero + secondsAtZero) * 1000, false, true, false)}
                            </span>
                          </div>
                        )}
                        <div>
                          <span>
                            <b>{this.props.t('plantAnalysis.lines.engineTorqueDensifier.averageTorque') + ': '}</b>
                            {parseFloat(averageTorque).toFixed(2)}
                          </span>
                        </div>
                      </TableColumnWrapper>
                    )
                  })}
                </TableRowWrapper>
              )
            })}
        <TableRowFullWrapper>
          <span className="mr-3" style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square mr-3"
              style={{ backgroundColor: COLORS.lines.torque.from_one_point_five_to_sixty_eight_percent }}
            />
            <span>{this.props.t(`plantAnalysis.lines.engineTorqueDensifier.rangeUnder68`)}</span>
          </span>
          <span className="mr-3" style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square"
              style={{
                backgroundColor: COLORS.lines.torque.from_sixty_eight_to_seventy_eight_percent,
              }}
            />
            <span>{this.props.t(`plantAnalysis.lines.engineTorqueDensifier.rangeFrom68To78`)}</span>
          </span>
          <span className="mr-3" style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square mr-3"
              style={{ backgroundColor: COLORS.lines.torque.from_seventy_eight_to_eighty_eight_percent }}
            />
            <span>{this.props.t(`plantAnalysis.lines.engineTorqueDensifier.rangeFrom78to88`)}</span>
            <span>{this.props.t(`plantAnalysis.lines.engineTorqueDensifier.rangeOver88`)}</span>
          </span>
          <span style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square"
              style={{ backgroundColor: COLORS.lines.torque.from_eighty_eight_to_hundred_percent }}
            />
            <span>{this.props.t(`plantAnalysis.lines.engineTorqueDensifier.rangeOver88`)}</span>
          </span>
        </TableRowFullWrapper>
      </>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(EngineTorqueDensifierPrint))
