import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Pie, PieChart, ResponsiveContainer, Cell } from 'recharts'
import { COLORS } from '../../../../../constants'
import { HHHmmssFromMilliseconds } from '../../../../../functions/shared'
import { renderCustomizedLabel } from '../../../../../functions/renderCustomizedLabelPie'
import TableRowWrapper, {
  TableRowFullWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableColumnWrapper'
import LineLoadingBelt from './_LoadingBelt'
import { LineLoadingBeltProps, LineLoadingBeltState } from './_types'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'
import { DEFAULT_STATE } from './_costants'

class LineLoadingBeltPrint extends LineLoadingBelt<LineLoadingBeltProps, LineLoadingBeltState> {
  constructor(props: LineLoadingBeltProps) {
    super(props, () => DEFAULT_STATE)
  }

  public renderContent(): JSX.Element {
    const { t } = this.props
    return (
      <>
        <TableRowFullWrapper>
          <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.loadingBeltTitle')}</h3>
        </TableRowFullWrapper>
        {this.state.data &&
          Object.entries(this.state.data)
            .reduce<any[][]>((acc, item, index) => {
              // Subdivide graphs in rows of 3 elements
              const chunkIndex = Math.floor(index / 3)
              if (!acc[chunkIndex]) {
                acc[chunkIndex] = []
              }
              acc[chunkIndex].push(item)
              return acc
            }, [])
            .map((arr, arrIndex) => {
              return (
                <TableRowWrapper key={`arr_${arrIndex}`} className="mb-4">
                  {arr.map(([key, value], index) => {
                    const filteredData = (this.state.filteredData[key] as Array<{
                      name: string
                      value: number
                    }>).filter(v => v.name !== 'seconds_at_zero' && v.name !== 'average_torque')
                    const secondsAtZero = this.state.data[key].seconds_at_zero
                    const averageTorque = this.state.data[key].average_torque

                    const isDataCorrect = Math.round(filteredData.reduce((sum, obj) => sum + obj.value, 0)) === 100

                    if (!isDataCorrect) {
                      return (
                        <TableColumnWrapper key={key} col={'1/3'} className="mx-2">
                          <h3>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.${key}`)}</h3>
                          <div className="alert alert-warning w-100">{t('plantAnalysis.noDataAvailable')}</div>
                        </TableColumnWrapper>
                      )
                    }

                    return (
                      <TableColumnWrapper key={key} col={'1/3'} className="mx-2">
                        <h3>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.${key}`)}</h3>
                        <ResponsiveContainer className={'mx-4'} key={index} width="100%" height={250}>
                          <PieChart /* width={350} */ height={250}>
                            <Pie
                              nameKey={'key'}
                              dataKey={'value'}
                              data={filteredData as any[]}
                              cx={130}
                              cy={125}
                              isAnimationActive={false}
                              outerRadius={100}
                              labelLine={false}
                              label={renderCustomizedLabel}
                            >
                              {Object.entries(value as Record<string, Record<string, number>>).map(
                                ([vKey, _vValue]) => {
                                  return (
                                    <Cell textRendering="auto" key={vKey} fill={COLORS.lines.loading[vKey] || 'gray'} />
                                  )
                                }
                              )}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                        <div>
                          <span>
                            <b>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.timeAtZero`)}: </b>
                            {HHHmmssFromMilliseconds(secondsAtZero * 1000, false, true, false)}
                          </span>
                        </div>
                        <div>
                          <span>
                            <b>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.averageTorque`)}: </b>
                            {parseFloat(averageTorque).toFixed(2)}
                          </span>
                        </div>
                      </TableColumnWrapper>
                    )
                  })}
                </TableRowWrapper>
              )
            })}
        <TableRowFullWrapper>
          <span style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square mx-3"
              style={{
                whiteSpace: 'nowrap',
                backgroundColor: COLORS.lines.loading.from_zero_to_twenty_five_percent,
              }}
            />
            <span>{`< 25% ${this.props.t(`plantAnalysis.lines.loadingBelt.excludeZero`)}`}</span>
          </span>
          <span style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square mx-3"
              style={{
                whiteSpace: 'nowrap',
                backgroundColor: COLORS.lines.loading.from_twenty_five_to_fifty_percent,
              }}
            />
            <span>25 - 50%</span>
          </span>
          <span style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square mx-3"
              style={{
                whiteSpace: 'nowrap',
                backgroundColor: COLORS.lines.loading.from_fifty_to_seventy_five_percent,
              }}
            />
            <span>50 - 75%</span>
          </span>
          <span style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square mx-3"
              style={{
                whiteSpace: 'nowrap',
                backgroundColor: COLORS.lines.loading.from_seventy_five_to_one_hundred_percent,
              }}
            />
            <span>75 - 100 %</span>
          </span>
          <span style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square mx-3"
              style={{ backgroundColor: COLORS.lines.loading.over_one_hundred_percent }}
            />
            <span>+ 100 %</span>
          </span>
        </TableRowFullWrapper>
      </>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(LineLoadingBeltPrint))
