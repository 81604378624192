const en = {
  general: {
    mv4iot: 'MV4IoT',
    userLogout: 'Logout',
  },
  plantSelectorWidget: {
    options: {
      'disabled': 'Module is disabled for this plant',
      'null' : 'Select plant',
      'cd': 'Codroipo',
      'cm': 'Cairo Montenotte',
      'go': 'Godega',
      'plant-name':'plant-name',
      're': 'Reggio Emilia',
      'ri': 'Rive d\'Arcano',
      'ro': 'Rovigo',
      'sg': 'San Giorgio di Nogaro',
      'visor': 'visor'
    }
  }
}

export default en
