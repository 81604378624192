import { i18next as i18n, i18nextInit } from '@mv-submodules/inplant-core-fe/i18n/i18n'
import core from '@mv-submodules/inplant-core-fe/i18n'
// import checklist from '@mv-submodules/inplant-checklist-fe-iblu/i18n'
import chronoframe from '@mv-submodules/inplant-chronoframe-fe/i18n'
import director from '../i18n'
import fermiimpianto from '@mv-submodules/inplant-fermiimpianto-fe-iblu/i18n'
// import fetchWrapper from '@mv-submodules/inplant-core-fe/fetch-wrapper/i18n'
import titech from '@mv-submodules/inplant-titech-fe-iblu/i18n'
// import maintenance from '@mv-submodules/inplant-maintenance-fe/i18n'
// import mcs from '@mv-submodules/inplant-mcs-fe-iblu/i18n'
import user from '@mv-submodules/inplant-user-fe/i18n'
import plantAnalysis from '@mv-submodules/inplant-plantanalysis-fe-iblu/i18n'
import plantStatus from '@mv-submodules/inplant-plantstatus-fe/i18n'
import assetManager from '@mv-submodules/inplant-asset-manager-fe/i18n'
import components from '@mv-submodules/inplant-components-fe/i18n'
import coreAdapter from '../../inplant-coreadapter-fe/i18n'

i18nextInit({
  resources: {
    en: {
      translation: {
        ...director.en,
        ...core.en,
        //  ...fetchWrapper.en,
        ...fermiimpianto.en,
        ...plantAnalysis.en,
        ...chronoframe.en,
        ...user.en,
        ...plantStatus.en,
        ...titech.en,
        ...assetManager.en,
        ...components.en,
        ...coreAdapter.en,
      },
    },
    it: {
      translation: {
        ...director.it,
        ...core.it,
        //   ...fetchWrapper.it,
        ...fermiimpianto.it,
        ...plantAnalysis.it,
        ...chronoframe.it,
        ...user.it,
        ...plantStatus.it,
        ...titech.it,
        ...assetManager.it,
        ...components.it,
        ...coreAdapter.it,
      },
    },
  },
})

export default i18n
