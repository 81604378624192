import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { COLORS } from '../../../../../constants'
import { HHHmmssFromMilliseconds } from '../../../../../functions/shared'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import Column from '../../../../../../inplant-components-fe/ui/components/Grid/Column'
import { renderCustomizedLabel } from '../../../../../functions/renderCustomizedLabelPie'
import LineLoadingBelt from './_LoadingBelt'
import { LineLoadingBeltProps, LineLoadingBeltState } from './_types'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'
import { DEFAULT_STATE } from './_costants'

interface OwnState extends LineLoadingBeltState {
  showTooltip: boolean
  tooltipData: any
}

function propsToOwnState(props: LineLoadingBeltProps): OwnState {
  return {
    ...DEFAULT_STATE,
    showTooltip: true,
    tooltipData: null,
  }
}

class LineLoadingBeltView extends LineLoadingBelt<LineLoadingBeltProps, OwnState> {
  constructor(props: LineLoadingBeltProps) {
    super(props, propsToOwnState)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  private handleMouseEnter() {
    this.setState({
      showTooltip: true,
    })
  }

  private handleMouseLeave() {
    this.setState({
      showTooltip: false,
      tooltipData: null,
    })
  }

  private handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const curThrustPresser = curData.thrustPresser !== undefined ? curData.thrustPresser : '--'
        const curAbsorption = curData.absorption !== undefined ? curData.absorption : '--'
        const curSpeedPercent = curData.speedPercent !== undefined ? curData.speedPercent : '--'
        this.setState({
          showTooltip: true,
          tooltipData: {
            time: curData.time,
            thrustPresser: curThrustPresser,
            absorption: curAbsorption,
            speedPercent: curSpeedPercent,
            thrustPresserColor: COLORS.vecoplant.generalGraph.thrustPresser,
            absorptionColor: COLORS.vecoplant.generalGraph.absorption,
            speedPercentColor: COLORS.vecoplant.generalGraph.speedPercent,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  public renderContent(): JSX.Element {
    const { t } = this.props

    return (
      <div className={`col-md-12 col-sm-12 col-lg-12 halts-graphs halts-graphs__vertical`}>
        <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.loadingBeltTitle')}</h3>
        <div className={'graph-spacing column-break-before'}>
          {Object.entries(this.state.data)
            .reduce<any[][]>((acc, item, index) => {
              // Subdivide graphs in rows of 3 elements
              const chunkIndex = Math.floor(index / 3)
              if (!acc[chunkIndex]) {
                acc[chunkIndex] = []
              }
              acc[chunkIndex].push(item)
              return acc
            }, [])
            .map((arr, arrIndex) => {
              return (
                <Row key={`arr_${arrIndex}`}>
                  {arr.map(([key, value], index) => {
                    const filteredData = (this.state.filteredData[key] as Array<{
                      name: string
                      value: number
                    }>).filter(v => v.name !== 'seconds_at_zero' && v.name !== 'average_torque')
                    const secondsAtZero = this.state.data[key].seconds_at_zero
                    const averageTorque = this.state.data[key].average_torque

                    const isDataCorrect = Math.round(filteredData.reduce((sum, obj) => sum + obj.value, 0)) === 100

                    if (!isDataCorrect) {
                      return (
                        <Column key={index} sm={4} md={4} lg={4}>
                          <h3>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.${key}`)}</h3>
                          <div className="alert alert-warning w-100">{t('plantAnalysis.noDataAvailable')}</div>
                        </Column>
                      )
                    }

                    return (
                      <Column key={index} sm={4} md={4} lg={4}>
                        <h3>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.${key}`)}</h3>
                        <ResponsiveContainer className={'mx-2'} key={index} width="100%" height={250}>
                          <PieChart /* width={350} */ height={250}>
                            <Pie
                              nameKey={'key'}
                              dataKey={'value'}
                              data={filteredData}
                              cx={130}
                              cy={125}
                              isAnimationActive={false}
                              outerRadius={100}
                              labelLine={false}
                              label={renderCustomizedLabel}
                            >
                              {Object.entries(value as Record<string, Record<string, number>>).map(
                                ([vKey, _vValue]) => {
                                  return (
                                    <Cell textRendering="auto" key={vKey} fill={COLORS.lines.loading[vKey] || 'gray'} />
                                  )
                                }
                              )}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                        <div>
                          <span>
                            <b>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.timeAtZero`)}: </b>
                            {HHHmmssFromMilliseconds(secondsAtZero * 1000, false, true, false)}
                          </span>
                        </div>
                        <div>
                          <span>
                            <b>{this.props.t(`plantAnalysis.lines.loadingBelt.belts.averageTorque`)}: </b>
                            {parseFloat(averageTorque).toFixed(2)}
                          </span>
                        </div>
                      </Column>
                    )
                  })}
                </Row>
              )
            })}
          <Row>
            <div className="d-flex d-print-inline-flex">
              <Row verticalAlignment="center">
                <span style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className="label-color-square"
                    style={{
                      whiteSpace: 'nowrap',
                      backgroundColor: COLORS.lines.loading.from_zero_to_twenty_five_percent,
                    }}
                  />
                  <span>{`< 25% ${this.props.t(`plantAnalysis.lines.loadingBelt.excludeZero`)}`}</span>
                </span>
              </Row>
              <Row verticalAlignment="center">
                <span style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className="label-color-square"
                    style={{
                      whiteSpace: 'nowrap',
                      backgroundColor: COLORS.lines.loading.from_twenty_five_to_fifty_percent,
                    }}
                  />
                  <span>25 - 50%</span>
                </span>
              </Row>
              <Row verticalAlignment="center">
                <span style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className="label-color-square"
                    style={{
                      whiteSpace: 'nowrap',
                      backgroundColor: COLORS.lines.loading.from_fifty_to_seventy_five_percent,
                    }}
                  />
                  <span>50 - 75%</span>
                </span>
              </Row>
              <Row verticalAlignment="center">
                <span style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className="label-color-square"
                    style={{
                      whiteSpace: 'nowrap',
                      backgroundColor: COLORS.lines.loading.from_seventy_five_to_one_hundred_percent,
                    }}
                  />
                  <span>75 - 100 %</span>
                </span>
              </Row>
              <Row verticalAlignment="center">
                <span style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className="label-color-square"
                    style={{ backgroundColor: COLORS.lines.loading.over_one_hundred_percent }}
                  />
                  <span>+ 100 %</span>
                </span>
              </Row>
            </div>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(LineLoadingBeltView))
