import { MillsState } from "./_types"

export const MILLS = [
  {
    id: 'mill1',
    assetId: 'f024',
  },
  {
    id: 'mill2',
    assetId: 'f033',
  },
  {
    id: 'mill3',
    assetId: 'f083',
  },
  {
    id: 'mill4',
    assetId: 'f093',
  },
] as const

export const DEFAULT_STATE: MillsState = {
  mills: {},
  fetching: true,
  error: false,
  dataMills: null,
  data: null,
  filteredData: [],
  mergedData: [],
}