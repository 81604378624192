import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface Props {
  title?: string
}

const CommonNoDataAvailable = (props: Props & WithTranslation) => {
  const { t, title } = props
  return (
    <>
      {title && <h3 className={'w-100'}>{title}</h3>}
      <div className="alert alert-warning w-100 col-sm-6 mx-auto">{t('plantAnalysis.noDataAvailable')}</div>
    </>
  )
}

export default withTranslation()(CommonNoDataAvailable)
