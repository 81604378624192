import moment from 'moment'
import React, { useMemo } from 'react'
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Line, LineChart, Tooltip, Label } from 'recharts'
import Row from '../../../../../inplant-components-fe/ui/components/Grid/Row'
import { COLORS } from '../../../../constants'
import { hydrateData, hydrateTimeData, parseResponseData } from '../../../../functions/series'
import { isJSON } from '../../../../functions/shared'
import { simplePopulateSingleData } from '../../../../functions/simplePopulateSingleData'
import { useChartFetching } from '../../../../hook/useChartFetching'
import { useChartState } from '../../../../hook/useChartState'
import { API } from '../../../../redux/actions'
import { PartialRecordDataType, PartialRecordStringType } from '../../../../types/helpers'
import { SingleDataValue } from '../../../../types/singleDataValue'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import CustomLegend from '../Common/CustomLegend'
import CustomTooltip from '../Common/CustomTooltip'
import { GeneralChartProps } from './generalChartProps'

type DataType = typeof DATA_TYPE[number]

const itemsColors: PartialRecordStringType<DataType> = {
  absorption: COLORS.press.absorption,
  tons: COLORS.press.tons,
}

const itemsUnitOfMeasure: PartialRecordStringType<DataType> = {
  absorption: 'KW/H',
  tons: 'T/h',
}

interface CompleteData {
  data: Array<PartialRecordDataType<DataType>>
}

const DATA_TYPE = ['absorption', 'tons'] as const
const measureMiddleSection = '_PROD_'
const absorptionMeasure = '_KWH_DISP' as const
const tonsMeasure = '_TON_H' as const

interface Props {
  currentSelectedRecipe: string
}

const _ProductionAndConsumptionChart = (props: GeneralChartProps & Props) => {
  const { startDateTime, endDateTime, abortController, measure, plantString, t, currentSelectedRecipe } = props

  const dataContentLabel: PartialRecordStringType<DataType> = useMemo(
    () => ({
      absorption: `${t('plantAnalysis.pressDetail.absorption')}`,
      tons: `${t('plantAnalysis.pressDetail.tons')}`,
    }),
    []
  )

  const { actions, data, isError, isLoading, tooltip } = useChartState<CompleteData, DataType>({
    keys: [...DATA_TYPE],
    tooltipContentInfo: {
      absorption_color: itemsColors.absorption,
      absorption_label: dataContentLabel.absorption,
      absorption_unitOfMeasure: itemsUnitOfMeasure.absorption,
      tons_color: itemsColors.tons,
      tons_label: dataContentLabel.tons,
      tons_unitOfMeasure: itemsUnitOfMeasure.tons,
    },
  })

  const { queryEndSection, queryStartSection, uriRequestStart } = useChartFetching({
    callback: fetchAllData,
    endDateTime,
    startDateTime,
    plantString,
    type: 'normal',
    deps: [currentSelectedRecipe],
  })

  function fetchAllData() {
    if (currentSelectedRecipe.length === 0) {
      return
    }
    actions.setLoading()
    const baseStartMeasure = `${measure}${measureMiddleSection}`
    const tonsMeasureName = `${baseStartMeasure}${currentSelectedRecipe}${tonsMeasure}`
    const absorptionMeasureName = `${baseStartMeasure}${currentSelectedRecipe}${absorptionMeasure}`

    const tonsRequest = API().request(`${uriRequestStart}${queryStartSection}${tonsMeasureName}${queryEndSection}`, {
      signal: abortController.signal,
    })

    const absorptionRequest = API().request(
      `${uriRequestStart}${queryStartSection}${absorptionMeasureName}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )

    Promise.all([tonsRequest, absorptionRequest]).then(([tonsResult, absorptionResult]) => {
      actions.setData({
        data: parseAndPrepareData({
          absorption: absorptionResult,
          tons: tonsResult,
        }),
      })
    })
  }

  function parseAndPrepareData(requestDataSet: Record<DataType, any>): Array<PartialRecordDataType<DataType>> {
    const populatedData: Record<DataType, SingleDataValue[]> = Object.entries(requestDataSet).reduce(
      (acc, [currKey, currValue]) => {
        const dataToParse = isJSON(currValue) ? currValue : JSON.stringify(currValue)
        return { ...acc, [currKey]: simplePopulateSingleData(parseResponseData(dataToParse)) }
      },
      {}
    ) as Record<DataType, SingleDataValue[]>
    const mergedData: any[] = []
    hydrateData(populatedData, mergedData)
    hydrateTimeData([...DATA_TYPE], mergedData, { startDate: startDateTime, endDate: endDateTime })
    return (mergedData as unknown) as Array<PartialRecordDataType<DataType>>
  }

  function handleMouseEnter() {
    actions.setTooltipContent({})
  }

  function handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      const currentData = data?.data.find((e: any) => e.time === args.activeLabel)
      // tslint:disable-next-line: variable-name
      const absorption_data = currentData?.absorption ?? '--'
      // tslint:disable-next-line: variable-name
      const tons_data = currentData?.tons ?? '--'
      actions.setTooltipContent({
        absorption_data,
        tons_data,
        time: currentData?.time,
      })
    }
  }

  function handleMouseLeave() {
    actions.hideTooltip()
  }

  function nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  if (isLoading) {
    return <></>
  }

  if (isError) {
    return <CommonErrorMessage />
  }

  if (data && data.data.length === 0) {
    return <CommonNoDataAvailable />
  }

  return (
    <React.Fragment>
      <Row>
        <div className="col-md-10">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={700}
              height={400}
              data={data?.data}
              margin={{ top: 40, right: 0, bottom: 0, left: 0 }}
              // syncId={this.id}
              onMouseEnter={handleMouseEnter}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                key={'line1'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />

              <YAxis domain={[0, 'auto']} axisLine={false} yAxisId={'tons'} orientation={'left'}>
                <Label value={itemsUnitOfMeasure.tons} position={'top'} offset={20} />
              </YAxis>
              <YAxis domain={[0, 'auto']} axisLine={true} yAxisId={'absorption'} orientation={'right'}>
                <Label value={itemsUnitOfMeasure.absorption} position={'top'} offset={20}  />
              </YAxis>
              <CartesianGrid stroke="#f5f5f5" />
              <Tooltip content={nullTooltipContent()} />
              <Line
                dataKey="absorption"
                stroke={itemsColors.absorption}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'absorption'}
              />
              <Line
                dataKey="tons"
                stroke={itemsColors.tons}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'tons'}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <CustomTooltip tooltip={tooltip} />
      </Row>
      <CustomLegend
        dataToShow={DATA_TYPE.map(dataType => ({
          label: dataContentLabel[dataType] ?? '',
          color: itemsColors[dataType] ?? '',
          unitOfMeasure: itemsUnitOfMeasure[dataType] ?? '',
        }))}
      />
    </React.Fragment>
  )
}

export default _ProductionAndConsumptionChart
