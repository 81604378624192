import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Select } from '../../../../../inplant-components-fe'

interface Props {
  selectedRecipe: string
  items: Record<string, string>
  onChange: (recipeSelected: string) => void
}

const _RecipeSelection = (props: Props & WithTranslation) => {
  const { onChange, items, selectedRecipe, t } = props

  return (
    <div className={'row justify-content-start'}>
      <div className={'d-flex flex-column'}>
        <Select
          label={t('plantAnalysis.recipe')}
          value={selectedRecipe}
          onChange={onChange}
          options={{
            items: [
              { value: '', label: '', disabled: true },
              ...Object.entries(items).map(([key, value]) => ({ label: `${key}: ${value}`, value: key })),
            ],
          }}
        />
        {t('plantAnalysis.pressDetail.recipeInformation')}
      </div>
    </div>
  )
}

export default withTranslation()(_RecipeSelection)
