import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import {
  extractResponseData,
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  populateManAutoLocSec,
} from '../../../../../functions/series'
import moment from 'moment'
import { logger } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import { API } from '../../../../../redux/actions'
import FetchError from '../../../../../../inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { MillsProps, MillsState } from './_types'
import { ChartFetch } from '../../ChartRenderUtilities'

abstract class Mills<Props extends MillsProps, State extends MillsState> extends ChartFetch<Props, State> {
  private mounted = false
  private abortController: AbortController = new AbortController()
  protected chartTranslationKeyTitle: string = 'plantAnalysis.lines.millsTitle'
  
  constructor(props: Props, generateState: (props: Props) => State) {
    super(props)
    this.state = generateState(props)
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private constructData() {
    try {
      let mill1Thr1: any = []
      let mill1Thr2: any = []
      let mill1Thr3: any = []
      let mill1Absorption: any = []
      let mill2Thr1: any = []
      let mill2Thr2: any = []
      let mill2Thr3: any = []
      let mill2Absorption: any = []
      let mill3Thr1: any = []
      let mill3Thr2: any = []
      let mill3Thr3: any = []
      let mill3Absorption: any = []
      let mill4Thr1: any = []
      let mill4Thr2: any = []
      let mill4Thr3: any = []
      let mill4Absorption: any = []
      const mergedData: any[] = []
      if (this.state.data) {
        mill1Thr1 = this.populateSingleData('mill1Thr1')
        mill1Thr2 = this.populateSingleData('mill1Thr2')
        mill1Thr3 = this.populateSingleData('mill1Thr3')
        mill1Absorption = this.populateSingleData('mill1Absorption')
        mill2Thr1 = this.populateSingleData('mill2Thr1')
        mill2Thr2 = this.populateSingleData('mill2Thr2')
        mill2Thr3 = this.populateSingleData('mill2Thr3')
        mill2Absorption = this.populateSingleData('mill2Absorption')
        mill3Thr1 = this.populateSingleData('mill3Thr1')
        mill3Thr2 = this.populateSingleData('mill3Thr2')
        mill3Thr3 = this.populateSingleData('mill3Thr3')
        mill3Absorption = this.populateSingleData('mill3Absorption')
        mill4Thr1 = this.populateSingleData('mill4Thr1')
        mill4Thr2 = this.populateSingleData('mill4Thr2')
        mill4Thr3 = this.populateSingleData('mill4Thr3')
        mill4Absorption = this.populateSingleData('mill4Absorption')
      }
      hydrateData(
        {
          mill1Thr1,
          mill1Thr2,
          mill1Thr3,
          mill1Absorption,
          mill2Thr1,
          mill2Thr2,
          mill2Thr3,
          mill2Absorption,
          mill3Thr1,
          mill3Thr2,
          mill3Thr3,
          mill3Absorption,
          mill4Thr1,
          mill4Thr2,
          mill4Thr3,
          mill4Absorption,
        },
        mergedData,
        undefined,
        0
      )
      mergedData.sort((a, b) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
      })
      hydrateTimeData(
        [
          'mill1Thr1',
          'mill1Thr2',
          'mill1Thr3',
          'mill1Absorption',
          'mill2Thr1',
          'mill2Thr2',
          'mill2Thr3',
          'mill2Absorption',
          'mill3Thr1',
          'mill3Thr2',
          'mill3Thr3',
          'mill3Absorption',
          'mill4Thr1',
          'mill4Thr2',
          'mill4Thr3',
          'mill4Absorption',
        ],
        mergedData,
        this.state
      )
      populateManAutoLocSec(mergedData)
      fillWarnArea(mergedData, '', 0)

      if (this.mounted) {
        this.setState({
          filteredData: mergedData,
          fetching: false,
          error: false,
        })
      }
    } catch (error) {
      logger('MillsView.tsx', 'constructData', `Error calculation:  ${error}`, 'server')
      if (this.mounted) {
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  private formatData(data: any) {
    const resultData = data.columns
    try {
      const result = resultData
        .map((value: any, index: number) => {
          if (value !== 'time' && value !== 'shift') {
            return {
              [value]: data.values[0][index] >= 0 ? data.values[0][index] : 0,
              // [value]: data.values[0][index]
            }
          }
        })
        .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {})
      return [result]
    } catch (error) {
      return []
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workShift

    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const startDate = workshift?.start
        ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
        : startOfDay

      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const endDate = workshift?.end ? mvDate.getDateFromString(workshift.end.toString()).toISOString() : endOfDay

      const queryStart = `SELECT * FROM `
      const queryEnd = ` WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryStartGrouped = `SELECT PERCENTILE(*, 95) FROM `
      const queryEndGrouped = ` WHERE time >= '${startDate}' AND time <= '${endDate}' GROUP BY time(1m) fill(linear)`

      const queryEndShiftTime = ` WHERE time <= '${startDate}' ORDER BY desc LIMIT 1`
      const queryEndShift = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`

      try {
        const mill1ThrLabel = 'IBSGN5PHL2L16TOG'
        const mill1AbsorptionLable = 'IBSGN5PHL2L16TOG'

        const mill2ThrLabel = 'IBSGN5PHL2LHTFBK'
        const mill2AbsorptionLable = 'IBSGN5PHL2LHTFBK-1'

        const mill3ThrLabel = 'IBSGN5PP16CFV2LR'
        const mill3AbsorptionLable = 'IBSGN5PP16CFV2LR'

        const mill4ThrLabel = 'IBSGN5PP16CV0DUN'
        const mill4AbsorptionLable = 'IBSGN5PP16CV0DUN'

        const millsLabel = 'vMills'

        const mill1Thr1Values = queryStart + `"${mill1ThrLabel}_CUR_1ST_SET"` + queryEnd
        const mill1Thr1ValuesBeforeShift = queryStart + `"${mill1ThrLabel}_CUR_1ST_SET"` + queryEndShiftTime
        const mill1Thr2Values = queryStart + `"${mill1ThrLabel}_CUR_2ND_SET"` + queryEnd
        const mill1Thr2ValuesBeforeShift = queryStart + `"${mill1ThrLabel}_CUR_2ND_SET"` + queryEndShiftTime
        const mill1Thr3Values = queryStart + `"${mill1ThrLabel}_CUR_3RD_SET"` + queryEnd
        const mill1Thr3ValuesBeforeShift = queryStart + `"${mill1ThrLabel}_CUR_3RD_SET"` + queryEndShiftTime
        const mill1AbsorptionValues = queryStartGrouped + `"${mill1AbsorptionLable}_ACT_CUR_calc"` + queryEndGrouped

        const mill2Thr1Values = queryStart + `"${mill2ThrLabel}_CUR_1ST_SET"` + queryEnd
        const mill2Thr1ValuesBeforeShift = queryStart + `"${mill2ThrLabel}_CUR_1ST_SET"` + queryEndShiftTime
        const mill2Thr2Values = queryStart + `"${mill2ThrLabel}_CUR_2ND_SET"` + queryEnd
        const mill2Thr2ValuesBeforeShift = queryStart + `"${mill2ThrLabel}_CUR_2ND_SET"` + queryEndShiftTime
        const mill2Thr3Values = queryStart + `"${mill2ThrLabel}_CUR_3RD_SET"` + queryEnd
        const mill2Thr3ValuesBeforeShift = queryStart + `"${mill2ThrLabel}_CUR_3RD_SET"` + queryEndShiftTime
        const mill2AbsorptionValues = queryStartGrouped + `"${mill2AbsorptionLable}_ACT_CUR_calc"` + queryEndGrouped

        const mill3Thr1Values = queryStart + `"${mill3ThrLabel}_CUR_1ST_SET"` + queryEnd
        const mill3Thr1ValuesBeforeShift = queryStart + `"${mill3ThrLabel}_CUR_1ST_SET"` + queryEndShiftTime
        const mill3Thr2Values = queryStart + `"${mill3ThrLabel}_CUR_2ND_SET"` + queryEnd
        const mill3Thr2ValuesBeforeShift = queryStart + `"${mill3ThrLabel}_CUR_2ND_SET"` + queryEndShiftTime
        const mill3Thr3Values = queryStart + `"${mill3ThrLabel}_CUR_3RD_SET"` + queryEnd
        const mill3Thr3ValuesBeforeShift = queryStart + `"${mill3ThrLabel}_CUR_3RD_SET"` + queryEndShiftTime
        const mill3AbsorptionValues = queryStartGrouped + `"${mill3AbsorptionLable}_ACT_CUR_calc"` + queryEndGrouped

        const mill4Thr1Values = queryStart + `"${mill4ThrLabel}_CUR_1ST_SET"` + queryEnd
        const mill4Thr1ValuesBeforeShift = queryStart + `"${mill4ThrLabel}_CUR_1ST_SET"` + queryEndShiftTime
        const mill4Thr2Values = queryStart + `"${mill4ThrLabel}_CUR_2ND_SET"` + queryEnd
        const mill4Thr2ValuesBeforeShift = queryStart + `"${mill4ThrLabel}_CUR_2ND_SET"` + queryEndShiftTime
        const mill4Thr3Values = queryStart + `"${mill4ThrLabel}_CUR_3RD_SET"` + queryEnd
        const mill4Thr3ValuesBeforeShift = queryStart + `"${mill4ThrLabel}_CUR_3RD_SET"` + queryEndShiftTime
        const mill4AbsorptionValues = queryStartGrouped + `"${mill4AbsorptionLable}_ACT_CUR_calc"` + queryEndGrouped

        const millsValue = queryStart + `${millsLabel}` + queryEndShift

        const dataMill1Thr1Values = API().request(`/query?${plantQueryString}q=` + mill1Thr1Values, {
          signal: this.abortController.signal,
        })
        const dataMill1Thr2Values = API().request(`/query?${plantQueryString}q=` + mill1Thr2Values, {
          signal: this.abortController.signal,
        })
        const dataMill1Thr3Values = API().request(`/query?${plantQueryString}q=` + mill1Thr3Values, {
          signal: this.abortController.signal,
        })
        const dataMill1AbsorptionValues = API().request(`/query?${plantQueryString}q=` + mill1AbsorptionValues, {
          signal: this.abortController.signal,
        })

        const dataMill2Thr1Values = API().request(`/query?${plantQueryString}q=` + mill2Thr1Values, {
          signal: this.abortController.signal,
        })
        const dataMill2Thr2Values = API().request(`/query?${plantQueryString}q=` + mill2Thr2Values, {
          signal: this.abortController.signal,
        })
        const dataMill2Thr3Values = API().request(`/query?${plantQueryString}q=` + mill2Thr3Values, {
          signal: this.abortController.signal,
        })
        const dataMill2AbsorptionValues = API().request(`/query?${plantQueryString}q=` + mill2AbsorptionValues, {
          signal: this.abortController.signal,
        })

        const dataMill3Thr1Values = API().request(`/query?${plantQueryString}q=` + mill3Thr1Values, {
          signal: this.abortController.signal,
        })
        const dataMill3Thr2Values = API().request(`/query?${plantQueryString}q=` + mill3Thr2Values, {
          signal: this.abortController.signal,
        })
        const dataMill3Thr3Values = API().request(`/query?${plantQueryString}q=` + mill3Thr3Values, {
          signal: this.abortController.signal,
        })
        const dataMill3AbsorptionValues = API().request(`/query?${plantQueryString}q=` + mill3AbsorptionValues, {
          signal: this.abortController.signal,
        })

        const dataMill4Thr1Values = API().request(`/query?${plantQueryString}q=` + mill4Thr1Values, {
          signal: this.abortController.signal,
        })
        const dataMill4Thr2Values = API().request(`/query?${plantQueryString}q=` + mill4Thr2Values, {
          signal: this.abortController.signal,
        })
        const dataMill4Thr3Values = API().request(`/query?${plantQueryString}q=` + mill4Thr3Values, {
          signal: this.abortController.signal,
        })
        const dataMill4AbsorptionValues = API().request(`/query?${plantQueryString}q=` + mill4AbsorptionValues, {
          signal: this.abortController.signal,
        })

        const dataMill1Thr1ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill1Thr1ValuesBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataMill1Thr2ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill1Thr2ValuesBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataMill1Thr3ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill1Thr3ValuesBeforeShift,
          { signal: this.abortController.signal }
        )

        const dataMill2Thr1ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill2Thr1ValuesBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataMill2Thr2ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill2Thr2ValuesBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataMill2Thr3ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill2Thr3ValuesBeforeShift,
          { signal: this.abortController.signal }
        )

        const dataMill3Thr1ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill3Thr1ValuesBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataMill3Thr2ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill3Thr2ValuesBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataMill3Thr3ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill3Thr3ValuesBeforeShift,
          { signal: this.abortController.signal }
        )

        const dataMill4Thr1ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill4Thr1ValuesBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataMill4Thr2ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill4Thr2ValuesBeforeShift,
          { signal: this.abortController.signal }
        )
        const dataMill4Thr3ValuesBeforeShift = API().request(
          `/query?${plantQueryString}q=` + mill4Thr3ValuesBeforeShift,
          { signal: this.abortController.signal }
        )

        const dataMillsValues = API().request(`/query?${plantQueryString}q=` + millsValue, {
          signal: this.abortController.signal,
        })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([
          dataMill1Thr1Values,
          dataMill1Thr2Values,
          dataMill1Thr3Values,
          dataMill1Thr1ValuesBeforeShift,
          dataMill1Thr2ValuesBeforeShift,
          dataMill1Thr3ValuesBeforeShift,
          dataMill1AbsorptionValues,
          dataMill2Thr1Values,
          dataMill2Thr2Values,
          dataMill2Thr3Values,
          dataMill2Thr1ValuesBeforeShift,
          dataMill2Thr2ValuesBeforeShift,
          dataMill2Thr3ValuesBeforeShift,
          dataMill2AbsorptionValues,
          dataMill3Thr1Values,
          dataMill3Thr2Values,
          dataMill3Thr3Values,
          dataMill3Thr1ValuesBeforeShift,
          dataMill3Thr2ValuesBeforeShift,
          dataMill3Thr3ValuesBeforeShift,
          dataMill3AbsorptionValues,
          dataMill4Thr1Values,
          dataMill4Thr2Values,
          dataMill4Thr3Values,
          dataMill4Thr1ValuesBeforeShift,
          dataMill4Thr2ValuesBeforeShift,
          dataMill4Thr3ValuesBeforeShift,
          dataMill4AbsorptionValues,
          dataMillsValues,
        ])
          .then(
            ([
              dataMill1Thr1ValuesResult,
              dataMill1Thr2ValuesResult,
              dataMill1Thr3ValuesResult,
              dataMill1Thr1ValuesBeforeShiftResult,
              dataMill1Thr2ValuesBeforeShiftResult,
              dataMill1Thr3ValuesBeforeShiftResult,
              dataMill1AbsorptionValuesResult,
              dataMill2Thr1ValuesResult,
              dataMill2Thr2ValuesResult,
              dataMill2Thr3ValuesResult,
              dataMill2Thr1ValuesBeforeShiftResult,
              dataMill2Thr2ValuesBeforeShiftResult,
              dataMill2Thr3ValuesBeforeShiftResult,
              dataMill2AbsorptionValuesResult,
              dataMill3Thr1ValuesResult,
              dataMill3Thr2ValuesResult,
              dataMill3Thr3ValuesResult,
              dataMill3Thr1ValuesBeforeShiftResult,
              dataMill3Thr2ValuesBeforeShiftResult,
              dataMill3Thr3ValuesBeforeShiftResult,
              dataMill3AbsorptionValuesResult,
              dataMill4Thr1ValuesResult,
              dataMill4Thr2ValuesResult,
              dataMill4Thr3ValuesResult,
              dataMill4Thr1ValuesBeforeShiftResult,
              dataMill4Thr2ValuesBeforeShiftResult,
              dataMill4Thr3ValuesBeforeShiftResult,
              dataMill4AbsorptionValuesResult,
              dataMillsValuesResult,
            ]) => {
              if (this.mounted) {
                try {
                  const mill1Thr1 = [
                    [
                      startDate,
                      extractResponseData(dataMill1Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill1Thr1ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill1Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill1Thr2 = [
                    [
                      startDate,
                      extractResponseData(dataMill1Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill1Thr2ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill1Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill1Thr3 = [
                    [
                      startDate,
                      extractResponseData(dataMill1Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill1Thr3ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill1Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill1Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill2Thr1 = [
                    [
                      startDate,
                      extractResponseData(dataMill2Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill2Thr1ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill2Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill2Thr2 = [
                    [
                      startDate,
                      extractResponseData(dataMill2Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill2Thr2ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill2Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill2Thr3 = [
                    [
                      startDate,
                      extractResponseData(dataMill2Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill2Thr3ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill2Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill2Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill3Thr1 = [
                    [
                      startDate,
                      extractResponseData(dataMill3Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill3Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill3Thr1ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill3Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill3Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill3Thr2 = [
                    [
                      startDate,
                      extractResponseData(dataMill3Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill3Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill3Thr2ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill3Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill3Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill3Thr3 = [
                    [
                      startDate,
                      extractResponseData(dataMill3Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill3Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill3Thr3ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill3Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill3Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill4Thr1 = [
                    [
                      startDate,
                      extractResponseData(dataMill4Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill4Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill4Thr1ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill4Thr1ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill4Thr1ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill4Thr2 = [
                    [
                      startDate,
                      extractResponseData(dataMill4Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill4Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill4Thr2ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill4Thr2ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill4Thr2ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  const mill4Thr3 = [
                    [
                      startDate,
                      extractResponseData(dataMill4Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill4Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                    ...extractResponseData(dataMill4Thr3ValuesResult),
                    [
                      endDate,
                      extractResponseData(dataMill4Thr3ValuesBeforeShiftResult).length > 0
                        ? extractResponseData(dataMill4Thr3ValuesBeforeShiftResult)[0][1]
                        : 0,
                    ],
                  ]
                  this.setState(
                    {
                      data: Object.assign({}, this.state.data, {
                        mill1Thr1: { data: mill1Thr1 },
                        mill1Thr2: { data: mill1Thr2 },
                        mill1Thr3: { data: mill1Thr3 },
                        mill1Absorption: { data: extractResponseData(dataMill1AbsorptionValuesResult) },
                        mill2Thr1: { data: mill2Thr1 },
                        mill2Thr2: { data: mill2Thr2 },
                        mill2Thr3: { data: mill2Thr3 },
                        mill2Absorption: { data: extractResponseData(dataMill2AbsorptionValuesResult) },
                        mill3Thr1: { data: mill3Thr1 },
                        mill3Thr2: { data: mill3Thr2 },
                        mill3Thr3: { data: mill3Thr3 },
                        mill3Absorption: { data: extractResponseData(dataMill3AbsorptionValuesResult) },
                        mill4Thr1: { data: mill4Thr1 },
                        mill4Thr2: { data: mill4Thr2 },
                        mill4Thr3: { data: mill4Thr3 },
                        mill4Absorption: { data: extractResponseData(dataMill4AbsorptionValuesResult) },
                        mills: this.formatData(JSON.parse(dataMillsValuesResult).results[0].series[0]),
                      }),
                    },
                    () => {
                      this.constructData()
                    }
                  )
                } catch (error) {
                  logger('MillsView.tsx', 'getData parsingResult', `Error on parsing result: ${error}`, 'server')
                  this.setState({
                    data: null,
                    mills: {},
                    fetching: false,
                    error: false,
                  })
                }
              }
            }
          )
          .catch((error: FetchError) => {
            logger(
              'MillsView.tsx',
              'getData',
              `Error on fetch: ${error.message}, StatusCode: ${error.statusCode}, Errors:${error.errors}`,
              'server'
            )
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              mills: {},
              fetching: false,
              error: true,
            })
          })
      } catch (error: any) {
        logger('MillsView.tsx', 'getData', `Error on try catch line:  ${error}`, 'server')
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          mills: {},
          fetching: false,
          error: true,
        })
      }
    }
  }

  protected abstract renderContent(): JSX.Element
  public render() {
    const { fetching, error } = this.state

    if (fetching) {
      return this.renderLoading()
    }

    if (error) {
      return this.renderError()
    }

    if (!this.state.data) {
      return this.renderNoData()
    }

    return this.renderContent()
  }
}

export default Mills
