import { useEffect, useMemo } from 'react'

interface GeneralProps {
  callback: () => void
  startDateTime: string
  endDateTime: string
  plantString: string
  deps?: React.DependencyList
}

interface PropsNormalType extends GeneralProps {
  type: 'normal'
}

interface PropsWithLastType extends GeneralProps {
  type: 'withLast'
}

interface PropsOnlyLastElementType extends GeneralProps {
  type: 'onlyLastElement'
}

type AggregateProps = PropsWithLastType | PropsNormalType | PropsOnlyLastElementType

interface BaseReturnType {
  queryEndSection: string
  queryStartSection: string
  uriRequestStart: string
  queryStartSectionMax: string
}

interface ReturnTypeWithLast extends BaseReturnType {
  queryEndLastElement: string
}

interface ReturnTypeOnlyLastElement extends BaseReturnType {
  queryOnlyLastElementSection: string
}

type ReturnTypeNormal = BaseReturnType

type GeneralReturnType = ReturnTypeWithLast | ReturnTypeNormal | ReturnTypeOnlyLastElement

function useChartFetching(props: PropsNormalType): ReturnTypeNormal
function useChartFetching(props: PropsWithLastType): ReturnTypeWithLast
function useChartFetching(props: PropsOnlyLastElementType): ReturnTypeOnlyLastElement
function useChartFetching(props: AggregateProps): GeneralReturnType {
  const { callback, endDateTime, startDateTime, type, plantString, deps } = props
  const queryStartSection = `SELECT "measure" FROM "`
  const queryStartSectionMax = `SELECT MAX(*) FROM "`

  const uriRequestStart = useMemo(() => `/query?plant=${plantString}&q=`, [plantString])

  const queryEndSection = useMemo(() => {
    return `" WHERE time >= '${startDateTime}' AND time <= '${endDateTime}'`
  }, [startDateTime, endDateTime])

  const queryEndLimitSection = useMemo(() => {
    return `" WHERE time <= '${startDateTime}' ORDER BY time desc LIMIT 1`
  }, [startDateTime])

  const queryOnlyLastElement = useMemo(() => {
    return `" ORDER BY time desc LIMIT 1`
  },[])

  useEffect(() => {
    callback()
  }, [queryEndSection, ...(deps ?? []) ])

  const baseReturn: BaseReturnType = useMemo(
    () => ({
      queryEndSection,
      queryStartSection,
      uriRequestStart,
      queryStartSectionMax,
    }),
    [queryEndSection, queryStartSection, uriRequestStart, queryStartSectionMax]
  )
  
  if(type === 'onlyLastElement') {
    return {
      ...baseReturn,
      queryOnlyLastElementSection: queryOnlyLastElement
    }
  }

  if (type === 'withLast') {
    return {
      ...baseReturn,
      queryEndLastElement: queryEndLimitSection
    }
  }

  return {
    ...baseReturn
  }
}

export { useChartFetching }
