import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import { Loader } from '../../../../functions/shared'
import React from 'react'
import { WithTranslation } from 'react-i18next'

abstract class ChartFetch<Props extends WithTranslation, State = {}> extends React.Component<Props,State> {
  protected abstract chartTranslationKeyTitle: string

  private renderChartTitle() {
    return <h3 className={'w-100'}>{this.props.t(this.chartTranslationKeyTitle)}</h3>
  }
  protected renderLoading() {
    return (
      <Row>
        {this.renderChartTitle()}
        <Loader />
      </Row>
    )
  }
  protected renderError() {
    return (
      <>
        {this.renderChartTitle()}
        <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
          {this.props.t('plantAnalysis.fetchErrors')}
        </div>
      </>
    )
  }

  protected renderNoData() {
    return (
      <>
        {this.renderChartTitle()}
        <div className="alert alert-warning w-100 col-sm-6 mx-auto">{this.props.t('plantAnalysis.noDataAvailable')}</div>
      </>
    )
  }
}

export default ChartFetch
