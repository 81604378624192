import moment from "moment"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"

export type Tooltip<T extends string> = { 
  show: false, 
  keys: T[], 
  tooltipContentInfo: 
    TooltipLabel<T> & 
    TooltipColor<T>
  } | { 
  show: true, 
  keys: T[], 
  tooltipContentInfo: 
    TooltipLabel<T> & 
    TooltipColor<T> &
    TooltipUnitOfMeasure<T>
  tooltipContentData: TooltipData<T>
}

export type TooltipData<T extends string> = { [keyof in `${T}_data` | 'time']?: string | number }
export type TooltipLabel<T extends string> = { [keyof in `${T}_label`]?:string }
export type TooltipColor<T extends string> = { [keyof in `${T}_color`]?: string }
export type TooltipUnitOfMeasure<T extends string> = { [keyof in `${T}_unitOfMeasure`]?: string }

interface Props<T extends string> {
  tooltip: Tooltip<T>
}

const CustomTooltip = <T extends string,>(props: Props<T>  & WithTranslation) => {
  const { tooltip, t } = props
  return <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
    {tooltip.show && (
      <ul className="side-tooltip">
        {tooltip.tooltipContentData?.time && (<li>
          <strong>{t('plantAnalysis.labels.time')}</strong>
          <br />
          <span>{moment.unix(tooltip.tooltipContentData?.time as number).format('HH:mm')}</span>
        </li>)}
        {tooltip.keys.map((key, index) => (
          tooltip.tooltipContentData?.[`${key}_data`] !== undefined && <li key={index}>
          <strong>{tooltip.tooltipContentInfo[`${key}_label`]}</strong>
          <br />
          <span
            className="label-color label-color-line"
            style={{ backgroundColor: `${tooltip.tooltipContentInfo[`${key}_color`]}` }}
          />
          <span>{tooltip.tooltipContentData?.[`${key}_data`]} {tooltip.tooltipContentInfo[`${key}_unitOfMeasure`]}</span>
        </li>
        ))}
      </ul>
    )}
  </div>
}

export default withTranslation()(CustomTooltip)