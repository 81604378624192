import { IfOverflowType, ReferenceArea } from 'recharts'
import { AreaSection } from '../types/measure'
import React from 'react'
import moment from 'moment'

interface IWarnAreaOptions {
  color?: string
  colorMap?: Record<number | string, string>
  opacity?: number
  overflow?: IfOverflowType
  xAxisId?: string
  yAxisId?: string
}

export const WAREA_EMPTY_COLOR = '#EEE'

const reduceWarnAreas = (warnPoints: AreaSection[], options?: IWarnAreaOptions) => {
  if (warnPoints && warnPoints.length > 0) {
    return warnPoints.map((e: any, i) => {
      const color = options?.colorMap?.[e.value] || options?.color || WAREA_EMPTY_COLOR
      return (
        <ReferenceArea
          ifOverflow={options?.overflow || 'visible'}
          key={i + e.x1}
          x1={e.x1}
          x2={e.x2}
          stroke={color}
          fill={color}
          strokeWidth={1}
          strokeLinejoin={'miter'}
          fillOpacity={options?.opacity ?? 1}
          strokeOpacity={options?.opacity ?? 1}
          yAxisId={options?.yAxisId}
          xAxisId={options?.xAxisId}
        />
      )
    })
  }

  return null
}

const getAreaSections = (dataSrc: any, key: string) => {
  const out: AreaSection[] = []

  let index = 0
  while (index < dataSrc.length) {
    const current = dataSrc[index]
    for (let i = index; i < dataSrc.length; i++) {
      const next = dataSrc[i]
      if (i === dataSrc.length - 1) {
        out.push({
          x1: current.time,
          x2: next.time,
          y1: 0,
          y2: 100,
          value: current[key],
        })
        index = i
      }
      if (current[key] !== next[key] && next[key] !== undefined && next[key] !== null) {
        out.push({
          x1: current.time,
          x2: next.time,
          y1: 0,
          y2: 100,
          value: current[key],
        })
        index = i - 1
        break
      }
    }
    index++
  }

  return out
}

const joinSubsequentAreaSections = (list: AreaSection[]): AreaSection[] => {
  const joinedList: AreaSection[] = []
  for (let i = 0; i < list.length; i++) {
    const el = list[i]
    while (el.x2 === list[i + 1]?.x1 && i < list.length) {
      el.x2 = list[i + 1].x2
      i++
    }
    joinedList.push(el)
  }
  return joinedList
}

/**
 * Parses a duration string and returns the total seconds.
 * @param {string} durationString - The duration string formatted as "XXh YYm".
 * @returns {number} - The total seconds.
 */
function parseDuration(durationString: string): moment.Duration {
  // Regular expression to extract hours and minutes
  const regex = /(\d{1,2})h (\d{1,2})m/
  const match = durationString.match(regex)

  if (match) {
    const hours = parseInt(match[1], 10)
    const minutes = parseInt(match[2], 10)

    // Use moment to create a duration and get total seconds
    const duration = moment.duration({ hours, minutes })
    return duration
  } else {
    throw new Error('Invalid duration format')
  }
}

export { getAreaSections, reduceWarnAreas, joinSubsequentAreaSections, parseDuration }
