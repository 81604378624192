import * as React from 'react'
import * as moment from 'moment'
import { Moment } from 'moment'
import { Cell, Legend, LegendProps, Line, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import { GeneralStore } from '../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Workshift } from '../../../../types/workshift'
import { Loader } from '../../../../functions/shared'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { COLORS } from '../../../../constants'
import BottomLegend from '../BottomLegend/BottomLegend/BottomLegendView'
import {
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  interpolateTime,
  parseResponseData,
  parseResponseSingleData,
  populateManAutoLocSec,
} from '../../../../functions/series'
import BagGraphMain from './BagGraphMain/BagGraphMainView'
import GraphLineBarTime from '../GraphLineBar/GraphLineBarTime/GraphLineBarTimeView'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import GraphBrush from '../GraphBrush/GraphBrush'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { debounce } from '../../../../../inplant-components-fe/mvfunctions/helpers'
import { consoleLog } from '../../../../../mvlabs-components-fe/functions/logs'

interface WindowDetails {
  width: number
  height: number
}

interface OwnState {
  isLoading: boolean
  fetchErrors: boolean
  data: any
  last: any
  lastAfter: any
  dataAspirato: any
  dataRicetta: any
  dataRompisacco: any
  isCollapsed: boolean
  dateFilterStart: null | Moment
  workshift: null | Workshift
  minAspirato: number
  minRicetta: number
  minRompisacco: number
  maxAspirato: number
  maxRicetta: number
  maxRompisacco: number
  brush1Start: number
  brush1End: number
  brush1StartInd: number
  brush1EndInd: number
  mergedData: undefined | any[]
  filteredData: any[]
  window: WindowDetails
  startDate: number | undefined
  endDate: number | undefined
}

interface OwnProps {
  data: any
  toggleCollapse: (id: string) => void
  active: string
  component: any
}

interface StateProps {
  aspirato: GeneralStore
  ricetta: GeneralStore
  rompisacco: GeneralStore
  dateFilterStart: Moment
  workshift: Workshift
  plant: string | null
}

const mapStateToProps = (state: any): StateProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  ricetta: state.plantAnalysis.general.ricetta,
  rompisacco: state.plantAnalysis.general.rompisacco,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  workshift: state.plantAnalysis.common.workshift,
  plant: state.plantSelector.plant,
})

const cleanState = {
  isLoading: false,
  fetchErrors: false,
  data: null,
  last: null,
  lastAfter: null,
  isCollapsed: false,
  dataAspirato: null,
  dataRicetta: null,
  dataRompisacco: null,
  dateFilterStart: null,
  workshift: null,
  minAspirato: 0,
  minRicetta: 0,
  minRompisacco: 0,
  maxAspirato: 0,
  maxRicetta: 0,
  maxRompisacco: 0,
  brush1Start: 0,
  brush1End: 0,
  brush1StartInd: 0,
  brush1EndInd: 0,
  mergedData: undefined,
  filteredData: [],
  window: {
    width: innerWidth,
    height: innerHeight,
  },
  startDate: undefined,
  endDate: undefined,
}

type Props = StateProps & OwnProps & WithTranslation

class BagGraphs extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = cleanState

    this.exportData = this.exportData.bind(this)
    this.handleBrush1 = debounce( this.handleBrush1.bind(this),10)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const date = moment.isMoment(nextProps.dateFilterStart)
      ? nextProps.dateFilterStart
      : moment(nextProps.dateFilterStart)

    if (
      (this.props.dateFilterStart !== nextProps.dateFilterStart ||
        this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.component.id) &&
      nextProps.dateFilterStart &&
      moment.isMoment(date) &&
      nextProps.workshift
    ) {
      this.getData(nextProps)
    } else {
      this.setState(cleanState)
    }
  }

  public componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    const date = moment.isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    if (this.props.dateFilterStart && moment.isMoment(date) && this.props.workshift) {
      this.getData(this.props)
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    this.setState(cleanState)
  }

  public render() {
    const { workshift } = this.props
    const isReady = this.state.mergedData !== undefined && this.state.mergedData.length > 0 && !this.state.isLoading
    const noData = this.state.mergedData !== undefined && this.state.mergedData.length === 0 && !this.state.isLoading

    const renderLegend = (props: LegendProps & { contentType: string }) => {
      const { payload, contentType } = props

      if (payload) {
        const percents: any[] = []
        payload.map((entry: any) => {
          return (percents[entry.value] = parseFloat(String(entry.payload.percent * 100)).toFixed(2))
        })

        return (
          <ul className='pie-legend'>
            {payload.map((entry: any, index) => (
              <li key={`item-${index}`}>
                <span className='color-dot' style={{ borderColor: entry.color }} />
                <strong>
                  {(contentType === 'manAutoLocSec' ? this.props.t('plantAnalysis.labels.manAutoLocSec') + ' ' : '') +
                  (entry.value && entry.value !== 'null'
                    ? this.props.t('plantAnalysis.legend.manAutoLocSec.' + entry.value)
                    : 'undefined')}
                </strong>
                <br />
                {percents[entry.value]}%
              </li>
            ))}
          </ul>
        )
      }

      return undefined
    }

    let recipeData = this.state.mergedData ? this.countDistinct(this.state.mergedData, 'manAutoLocSec') : []
    if (recipeData.length === 1 && recipeData[0].name === 'undefined') {
      recipeData = []
    }
    consoleLog({ manAutoLocSec: this.state.mergedData, recipeData })

    return (
      <React.Fragment>
        <Row>
          {noData && (
            <Row horizontalAlignment={'center'}>
              <Column sm={6}>
                <div className={'alert alert-warning w-100'}>
                  {this.props.t('plantAnalysis.noDataAvailable')}
                </div>

              </Column>
            </Row>

          )}
          {this.state.isLoading && (
            <Row horizontalAlignment={'center'}>
              <Column sm={6}>
                <div className='alert alert-secondary w-100'>
                  {this.props.t('plantAnalysis.loading')}
                  <Loader />
                </div>
              </Column>

            </Row>

          )}
          {this.state.fetchErrors && (
            <Row horizontalAlignment={'center'}>
              <Column sm={6}>
                <div className='alert alert-danger w-100 bag-graph-fetch-errors'>
                  {this.props.t('plantAnalysis.fetchErrors')}
                </div>
              </Column>

            </Row>

          )}
          {isReady && (
            <BagGraphMain
              data={this.state.mergedData}
              filteredData={this.state.filteredData}
              pauses={(workshift && workshift.pauses) || []}
            />
          )}
        </Row>
        {isReady && (
          <React.Fragment>
            <GraphLineBarTime
              filteredData={this.state.filteredData}
              colorsId={'manAutoLocSec'}
              entry={'manAutoLocSec'}
              i18nTitle={'plantAnalysis.labels.manAutoLocSec'}
              topMargin={true}
            />

            <GraphTimelineView data={this.state.filteredData} xAxisNumber={true} />
            <BottomLegend labels={['manAutoLocSec']} />
            <GraphBrush data={this.state.mergedData} brushOnChange={this.handleBrush1}>
              <Line dataKey='performance' type='step' stroke='#A19FF9' dot={false} isAnimationActive={false} />
            </GraphBrush>
            <GraphTimelineView data={this.state.mergedData} xAxisNumber={false} />

            {recipeData && recipeData.length > 0 && (
              <Row>
                <Column md={10}>
                  <Row>
                    <Column md={6}>
                      <div className={'pie-chart-container'}>
                        <h3>{this.props.t('plantAnalysis.graph1.percManAutoLocSec')}</h3>
                        <ResponsiveContainer width='100%' height={this.state.window.width > 1200 ? 250 : 400}>
                          <PieChart width={300} height={this.state.window.width > 1200 ? 250 : 400}>
                            <Pie
                              nameKey={'name'}
                              dataKey={'value'}
                              data={recipeData}
                              cx={this.state.window.width > 1200 ? 130 : 150}
                              cy={125}
                              innerRadius={70}
                              outerRadius={120}
                            >
                              {recipeData.map((entry, index) => (
                                <Cell key={index} fill={COLORS.manAutoLocSec[entry.name] || 'gray'} />
                              ))}
                            </Pie>
                            <g>
                              <text
                                x={this.state.window.width > 1200 ? 130 : 150}
                                y={125}
                                dy={0}
                                textAnchor='middle'
                                fill={'#000000'}
                              >
                                {this.props.t('plantAnalysis.labels.total')}
                              </text>
                              <text
                                x={this.state.window.width > 1200 ? 130 : 150}
                                y={125}
                                dy={16}
                                textAnchor='middle'
                                fill={'#4E505C'}
                              >
                                8h
                              </text>
                            </g>
                            <Legend
                              layout={this.state.window.width > 1200 ? 'vertical' : 'horizontal'}
                              align={this.state.window.width > 1200 ? 'right' : 'left'}
                              verticalAlign={this.state.window.width > 1200 ? 'middle' : 'bottom'}
                              // @ts-ignore
                              content={renderLegend}
                              wrapperStyle={this.state.window.width > 1200 ? { left: 330 } : { left: 0 }}
                              {...{ contentType: 'ricetta' }}
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </Column>
                  </Row>
                </Column>
              </Row>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  private updateWindowDimensions() {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  private async getData(props: Props) {
    try {
      this.setState({ isLoading: true })

      const startDateObj = moment(props.workshift.start)
      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDateObj = moment(props.workshift.end)
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

      const measureLabel = props.component.id

      /*
      const reg = new RegExp(/\[(.*)\] (.*)/);
      const measureDetails = props.component.data.label.match(reg);

      const measureLabel = measureDetails[2] + ' ' + measureDetails[1];
      */

      const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

      const queryStart = `SELECT "measure" FROM "`
      const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
      const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`
      /*const queryLastAfterStart = ` SELECT first("measure") AS "last_measure" FROM "`
      const queryLastAfterEnd = `" WHERE time > '${endDate}' LIMIT 1`*/

      // const queryManuale = queryStart + measureLabel + '_BS_MAN' + queryEnd
      const queryAutomatico = queryStart + measureLabel + '_BS_AUTO' + queryEnd
      const queryServ = queryStart + measureLabel + '_BS_SERV' + queryEnd
      const queryLoc = queryStart + measureLabel + '_BS_LOC' + queryEnd
      const queryCyc = queryStart + measureLabel + '_WS_CYC_ACT' + queryEnd
      const queryWrk = queryStart + measureLabel + '_WS_WRK_MODE' + queryEnd
      const queryPerf = queryStart + measureLabel + '_WS_PERF_NUM' + queryEnd
      const queryMarcia = queryStart + 'PLANT_IS_RUNNING' + queryEnd
      // const queryLastManuale = queryLastStart + measureLabel + '_BS_MAN' + queryLastEnd
      const queryLastAutomatico = queryLastStart + measureLabel + '_BS_AUTO' + queryLastEnd
      const queryLastServ = queryLastStart + measureLabel + '_BS_SERV' + queryLastEnd
      const queryLastLoc = queryLastStart + measureLabel + '_BS_LOC' + queryLastEnd
      const queryLastCyc = queryLastStart + measureLabel + '_WS_CYC_ACT' + queryLastEnd
      const queryLastWrk = queryLastStart + measureLabel + '_WS_WRK_MODE' + queryLastEnd
      const queryLastPerf = queryLastStart + measureLabel + '_WS_PERF_NUM' + queryLastEnd
      const queryLastMarcia = queryLastStart + 'PLANT_IS_RUNNING' + queryLastEnd
      // const queryLastAfterManuale = queryLastAfterStart + measureLabel + '_BS_MAN' + queryLastAfterEnd
      /*const queryLastAfterAutomatico = queryLastAfterStart + measureLabel + '_BS_AUTO' + queryLastAfterEnd
      const queryLastAfterServ = queryLastAfterStart + measureLabel + '_BS_SERV' + queryLastAfterEnd
      const queryLastAfterLoc = queryLastAfterStart + measureLabel + '_BS_LOC' + queryLastAfterEnd
      const queryLastAfterCyc = queryLastAfterStart + measureLabel + '_WS_CYC_ACT' + queryLastAfterEnd
      const queryLastAfterWrk = queryLastAfterStart + measureLabel + '_WS_WRK_MODE' + queryLastAfterEnd
      const queryLastAfterPerf = queryLastAfterStart + measureLabel + '_WS_PERF_NUM' + queryLastAfterEnd
      const queryLastAfterMarcia = queryLastAfterStart + 'PLANT_IS_RUNNING' + queryLastAfterEnd*/

      // const dataManualeSrc = await API().request(`/query?q=` + queryManuale)
      const dataAutomaticoSrc = await API().request(`/query?${plantQuery}&q=` + queryAutomatico) // @todo from redux
      const dataServSrc = await API().request(`/query?${plantQuery}&q=` + queryServ)
      const dataLocSrc = await API().request(`/query?${plantQuery}&q=` + queryLoc)
      const dataCycSrc = await API().request(`/query?${plantQuery}&q=` + queryCyc)
      const dataWrkSrc = await API().request(`/query?${plantQuery}&q=` + queryWrk)
      const dataPerfSrc = await API().request(`/query?${plantQuery}&q=` + queryPerf)
      const dataMarciaSrc = await API().request(`/query?${plantQuery}&q=` + queryMarcia)
      // const dataLastManualeSrc = await API().request(`/query?${plantQuery}&q=` + queryLastManuale)
      const dataLastAutomaticoSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAutomatico)
      const dataLastServSrc = await API().request(`/query?${plantQuery}&q=` + queryLastServ)
      const dataLastLocSrc = await API().request(`/query?${plantQuery}&q=` + queryLastLoc)
      const dataLastCycSrc = await API().request(`/query?${plantQuery}&q=` + queryLastCyc)
      const dataLastWrkSrc = await API().request(`/query?${plantQuery}&q=` + queryLastWrk)
      const dataLastPerfSrc = await API().request(`/query?${plantQuery}&q=` + queryLastPerf)
      const dataLastMarciaSrc = await API().request(`/query?${plantQuery}&q=` + queryLastMarcia)
      // const dataLastAfterManualeSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAfterManuale)
      /*const dataLastAfterAutomaticoSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAfterAutomatico)
      const dataLastAfterServSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAfterServ)
      const dataLastAfterLocSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAfterLoc)
      const dataLastAfterCycSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAfterCyc)
      const dataLastAfterWrkSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAfterWrk)
      const dataLastAfterPerfSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAfterPerf)
      const dataLastAfterMarciaSrc = await API().request(`/query?${plantQuery}&q=` + queryLastAfterMarcia)*/

      const startDateS = startDateObj.unix()
      const endDateS = endDateObj.unix()

      Promise.all([
        // dataManualeSrc,
        dataAutomaticoSrc,
        dataServSrc,
        dataLocSrc,
        dataCycSrc,
        dataWrkSrc,
        dataPerfSrc,
        dataMarciaSrc,
        // dataLastManualeSrc,
        dataLastAutomaticoSrc,
        dataLastServSrc,
        dataLastLocSrc,
        dataLastCycSrc,
        dataLastWrkSrc,
        dataLastPerfSrc,
        dataLastMarciaSrc,
        // dataLastAfterManualeSrc,
        /*dataLastAfterAutomaticoSrc,
        dataLastAfterServSrc,
        dataLastAfterLocSrc,
        dataLastAfterCycSrc,
        dataLastAfterWrkSrc,
        dataLastAfterPerfSrc,
        dataLastAfterMarciaSrc,*/
      ]).then(() => {
        this.setState({
          data: Object.assign({}, this.state.data, {
            // manuale: { data: parseResponseData(dataManualeSrc) },
            automatico: { data: parseResponseData(dataAutomaticoSrc) },
            servizio: { data: parseResponseData(dataServSrc) },
            localeSezionato: { data: parseResponseData(dataLocSrc) },
            ricetta: { data: parseResponseData(dataWrkSrc) },
            performance: { data: parseResponseData(dataPerfSrc) },
            marcia: { data: parseResponseData(dataMarciaSrc) },
          }),
          last: Object.assign({}, this.state.data, {
            // manuale: parseResponseSingleData(dataLastManualeSrc),
            automatico: parseResponseSingleData(dataLastAutomaticoSrc),
            servizio: parseResponseSingleData(dataLastServSrc),
            localeSezionato: parseResponseSingleData(dataLastLocSrc),
            ricetta: parseResponseSingleData(dataLastWrkSrc),
            performance: parseResponseSingleData(dataLastPerfSrc),
            marcia: parseResponseSingleData(dataLastMarciaSrc),
          }),
          /*lastAfter: Object.assign({}, this.state.data, {
            // manuale: parseResponseSingleData(dataLastAfterManualeSrc),
            automatico: parseResponseSingleData(dataLastAfterAutomaticoSrc),
            servizio: parseResponseSingleData(dataLastAfterServSrc),
            localeSezionato: parseResponseSingleData(dataLastAfterLocSrc),
            ricetta: parseResponseSingleData(dataLastAfterWrkSrc),
            performance: parseResponseSingleData(dataLastAfterPerfSrc),
            marcia: parseResponseSingleData(dataLastAfterMarciaSrc),
          }),*/
          startDate: startDateS,
          endDate: endDateS,
          isLoading: false,
          fetchErrors: false,
        })

        this.prepareData()
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      console.log(error) // tslint:disable-line
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private populateSingleData(key: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private prepareData() {
    try {
      let automatico: any = []
      let servizio: any = []
      let localeSezionato: any = []
      let ricetta: any = []
      let performance: any = []
      let marcia: any = []
      const mergedData: any[] = []

      if (this.state.data) {
        // manuale = this.populateSingleData('manuale', true)
        automatico = this.populateSingleData('automatico', true)
        servizio = this.populateSingleData('servizio', true)
        localeSezionato = this.populateSingleData('localeSezionato', true)
        ricetta = this.populateSingleData('ricetta')
        performance = this.populateSingleData('performance')
        marcia = this.populateSingleData('marcia', true)

        hydrateData(
          {
            // manuale,
            automatico,
            servizio,
            localeSezionato,
            ricetta,
            performance,
            marcia,
          },
          mergedData,
          'marcia',
          0,
        )

        mergedData.sort((a, b) => {
          if (a.time < b.time) {
            return -1
          }
          if (a.time > b.time) {
            return 1
          }
          return 0
        })

        hydrateTimeData(
          [
            'automatico',
            // 'manuale',
            'servizio',
            'ricetta',
            'performance',
            'localeSezionato',
            'marcia',
            'warnArea',
          ],
          mergedData,
          this.state,
        )

        populateManAutoLocSec(mergedData, true)
        fillWarnArea(mergedData, 'marcia', 0)

        interpolateTime(mergedData, moment(this.props.workshift.start).unix(), moment(this.props.workshift.end).unix())

        this.setState({
          // brush1Start: mergedData[0] ? mergedData[0].time : 0,
          // brush1End: mergedData[mergedData.length - 1] ? mergedData[mergedData.length - 1].time : 0,
          brush1StartInd: 0,
          brush1EndInd: mergedData.length - 1,
          mergedData,
          filteredData: mergedData.slice(0, mergedData.length - 1),
        })
      }
    } catch (err) {
      console.log(err) // tslint:disable-line
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private exportData(e: React.MouseEvent) {
    e.preventDefault()
  }

  private countDistinct(data: any, key: string) {
    let out: any[] = []

    data.map((e: any) => {
      if (e.hasOwnProperty(key)) {
        out[e[key]] = out[e[key]] ? out[e[key]] + 1 : 1
      }
    })
    out = Object.keys(out).map(e => ({ name: e, value: out[e] }))

    return out
  }

  private handleBrush1(args: any) {
    this.setState({
      brush1StartInd: args.startIndex,
      brush1EndInd: args.endIndex,
      filteredData: this.state.mergedData ? this.state.mergedData.slice(args.startIndex, args.endIndex) : [],
    })
  }
}

export default withRouter<any, any>(connect(mapStateToProps, undefined)(withTranslation()(BagGraphs)))
