import React, { useMemo } from 'react'
import GenericBinarySrcGraph from '../../GeneralBinarySrcGraph/GenericBinarySrcGraph'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Flex } from '@mv-submodules/mvlabs-components-fe/ui/components'
import { Direction } from '@mv-submodules/mvlabs-components-fe/ui/components/Flex/Flex'

interface Props extends WithTranslation {
  id: string
}

const COLORS = {
  buffers: {
    1: '#00B0F0',
    2: '#FF99CC',
    3: '#7B17D9',
    4: '#FED283',
    5: '#87C287',
  },
  thresholds: { 1: '#FF0000' },
}

const BUFFERS_ASSET_IDS = {
  1: 'IBSGN5PHK0LR8VII',
  2: 'IBSGN5PHK0N72MZJ',
  3: 'IDF074',
  4: 'IDF084',
  5: 'IBSGN5PK9QL0FEJQ',
}

const BufferFillGraph = (props: Props) => {
  const { id, t } = props

  const BufferGraphs = useMemo(() => {
    return Object.entries(BUFFERS_ASSET_IDS).map(([n, assetId]) => {
      return (
        <GenericBinarySrcGraph
          t={t}
          key={assetId}
          id={assetId}
          title={t(`plantAnalysis.vecoplant.buffer${n}FillTitle`)}
          measures={[
            {
              name: `buffer${n}VAlues`,
              slug: 'MAT_ACT_LEV_calc',
              representation: 'line',
              color: COLORS.buffers[n],
              unitOfMeasure: 'm',
              label: t('plantAnalysis.labels.bufferVAlues'),
            },
            {
              name: `buffer${n}Threshold`,
              slug: 'MAT_LEV_INT_STA',
              representation: 'line',
              color: COLORS.thresholds[1],
              unitOfMeasure: 'm',
              label: t('plantAnalysis.labels.bufferThreshold'),
            },
          ]}
        />
      )
    })
  }, [])

  return (
    <Flex direction={Direction.column} spaceSize={'lg'}>
      <GenericBinarySrcGraph
        t={t}
        id={id}
        title={t('plantAnalysis.buffer.bufferLoadingTitle')}
        measures={[
          { name: 'bufferLoadedWithF015', slug: 'BUF_BOX_SEL_F015', representation: 'time-bar' },
          { name: 'bufferLoadedWithF070', slug: 'BUF_BOX_SEL_F070', representation: 'time-bar' },
        ]}
      />
      {BufferGraphs}
    </Flex>
  )
}

export default withTranslation()(BufferFillGraph)
