import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import moment from 'moment'
import { HHHmmssFromMilliseconds } from '../../../../../functions/shared'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import Mills from './_Mills'
import { MillsProps, MillsState } from './_types'
import { DEFAULT_STATE, MILLS } from './_constants'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'

interface MillsViewState extends MillsState {
  tooltipData: any
  showTooltipMill1: boolean
  showTooltipMill2: boolean
  showTooltipMill3: boolean
  showTooltipMill4: boolean
}

function propsToOwnState(props: MillsProps): MillsViewState {
  return {
    ...DEFAULT_STATE,
    showTooltipMill1: true,
    showTooltipMill2: true,
    showTooltipMill3: true,
    showTooltipMill4: true,
    tooltipData: null,
  }
}

class MillsView extends Mills<MillsProps, MillsViewState> {
  constructor(props: MillsProps) {
    super(props, propsToOwnState)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  private handleMouseEnter(graph: 'mill1' | 'mill2' | 'mill3' | 'mill4') {
    switch (graph) {
      case 'mill1':
        this.setState({
          showTooltipMill1: true,
        })
      case 'mill2':
        this.setState({
          showTooltipMill2: false,
        })
      case 'mill3':
        this.setState({
          showTooltipMill2: false,
        })
      case 'mill4':
        this.setState({
          showTooltipMill2: false,
        })
      default:
        return
    }
  }

  private handleMouseLeave(graph: 'mill1' | 'mill2' | 'mill3' | 'mill4') {
    switch (graph) {
      case 'mill1':
        this.setState({
          showTooltipMill1: false,
          tooltipData: null,
        })
      case 'mill2':
        this.setState({
          showTooltipMill2: false,
          tooltipData: null,
        })
      case 'mill3':
        this.setState({
          showTooltipMill3: false,
          tooltipData: null,
        })
      case 'mill4':
        this.setState({
          showTooltipMill4: false,
          tooltipData: null,
        })
      default:
        return
    }
  }

  private handleMouseMove(args: any, type: 'mill1' | 'mill2' | 'mill3' | 'mill4') {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const currMill1Thr1 = curData.mill1Thr1 !== undefined ? curData.mill1Thr1 : '--'
        const currMill1Thr2 = curData.mill1Thr2 !== undefined ? curData.mill1Thr2 : '--'
        const currMill1Thr3 = curData.mill1Thr3 !== undefined ? curData.mill1Thr3 : '--'
        const currMill1Absorption = curData.mill1Absorption !== undefined ? curData.mill1Absorption : '--'
        const currMill2Thr1 = curData.mill2Thr1 !== undefined ? curData.mill2Thr1 : '--'
        const currMill2Thr2 = curData.mill2Thr2 !== undefined ? curData.mill2Thr2 : '--'
        const currMill2Thr3 = curData.mill2Thr3 !== undefined ? curData.mill2Thr3 : '--'
        const currMill2Absorption = curData.mill2Absorption !== undefined ? curData.mill2Absorption : '--'
        const currMill3Thr1 = curData.mill3Thr1 !== undefined ? curData.mill3Thr1 : '--'
        const currMill3Thr2 = curData.mill3Thr2 !== undefined ? curData.mill3Thr2 : '--'
        const currMill3Thr3 = curData.mill3Thr3 !== undefined ? curData.mill3Thr3 : '--'
        const currMill3Absorption = curData.mill3Absorption !== undefined ? curData.mill3Absorption : '--'
        const currMill4Thr1 = curData.mill4Thr1 !== undefined ? curData.mill4Thr1 : '--'
        const currMill4Thr2 = curData.mill4Thr2 !== undefined ? curData.mill4Thr2 : '--'
        const currMill4Thr3 = curData.mill4Thr3 !== undefined ? curData.mill4Thr3 : '--'
        const currMill4Absorption = curData.mill4Absorption !== undefined ? curData.mill4Absorption : '--'

        this.setState({
          showTooltipMill1: type === 'mill1',
          showTooltipMill2: type === 'mill2',
          showTooltipMill3: type === 'mill3',
          showTooltipMill4: type === 'mill4',
          tooltipData: {
            time: curData.time,
            mill1Thr1: currMill1Thr1,
            mill1Thr2: currMill1Thr2,
            mill1Thr3: currMill1Thr3,
            mill2Thr1: currMill2Thr1,
            mill2Thr2: currMill2Thr2,
            mill2Thr3: currMill2Thr3,
            mill3Thr1: currMill3Thr1,
            mill3Thr2: currMill3Thr2,
            mill3Thr3: currMill3Thr3,
            mill4Thr1: currMill4Thr1,
            mill4Thr2: currMill4Thr2,
            mill4Thr3: currMill4Thr3,
            mill1Absorption: currMill1Absorption,
            mill1AbsorptionColor: COLORS.lines.mills.mill,
            mill2Absorption: currMill2Absorption,
            mill2AbsorptionColor: COLORS.lines.mills.mill,
            mill3Absorption: currMill3Absorption,
            mill3AbsorptionColor: COLORS.lines.mills.mill,
            mill4Absorption: currMill4Absorption,
            mill4AbsorptionColor: COLORS.lines.mills.mill,
            mill1ThrColor: COLORS.lines.mills.thr,
            mill2ThrColor: COLORS.lines.mills.thr,
            mill3ThrColor: COLORS.lines.mills.thr,
            mill4ThrColor: COLORS.lines.mills.thr,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  public renderContent(): JSX.Element {
    const { t } = this.props
    return (
      <div className="col-md-12 col-sm-12 col-lg-12">
        <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.millsTitle')}</h3>
        {MILLS.map(mill => {
          return (
            <Row>
              <div className={`col-md-10 col-sm-10 col-xxl-11`}>
                <Row>
                  <div className="mv-col col-md-10 col-sm-10 col-xl-11 print-full-width">
                    <h3 className={'w-100'}>{this.props.t(`plantAnalysis.lines.mills.${mill.id}`)}</h3>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart
                        height={400}
                        data={this.state.filteredData}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        onMouseEnter={() => this.handleMouseEnter(mill.id)}
                        onMouseMove={data => this.handleMouseMove(data, mill.id)}
                        onMouseLeave={() => this.handleMouseLeave(mill.id)}
                        barGap={0}
                        barCategoryGap={0}
                      >
                        <XAxis
                          key={'line1'}
                          hide={false}
                          dataKey="time"
                          label={''}
                          height={30}
                          axisLine={true}
                          tickLine={false}
                          tick={true}
                          interval={'preserveStartEnd'}
                          minTickGap={50}
                          domain={['dataMin', 'dataMax']}
                          tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                          type={'category'}
                        />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Tooltip content={MillsView.nullTooltipContent} />
                        <YAxis
                          orientation={'left'}
                          yAxisId={'A'}
                          axisLine={false}
                          tickFormatter={val => parseInt(val, 10)}
                        />
                        <Line
                          dataKey={`${mill.id}Thr1`}
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type="step"
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey={`${mill.id}Thr2`}
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type="step"
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey={`${mill.id}Thr3`}
                          stroke={COLORS.lines.mills.thr}
                          dot={false}
                          strokeWidth={2}
                          type="step"
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                        <Line
                          dataKey={`${mill.id}Absorption`}
                          stroke={COLORS.lines.mills.mill}
                          dot={false}
                          strokeWidth={2}
                          type="step"
                          isAnimationActive={false}
                          yAxisId={'A'}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                  <div className={`mv-col col-md-2 col-sm-2 col-xl-1 d-print-none`}>
                    {this.state[`showTooltip${mill.id[0].toUpperCase() + mill.id.slice(1)}`] &&
                      this.state.tooltipData !== null && (
                        <ul className="side-tooltip">
                          <li>
                            <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                            <br />
                            <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                          </li>

                          {this.state.tooltipData[`${mill.id}Thr1`] !== null && (
                            <li>
                              <strong>{this.props.t(`plantAnalysis.lines.mills.label.${mill.id}Thr1`)}</strong>
                              <br />
                              <span
                                className="label-color label-color-line"
                                style={{ backgroundColor: this.state.tooltipData[`${mill.id}ThrColor`] }}
                              />
                              <span>{parseFloat(this.state.tooltipData[`${mill.id}Thr1`]).toFixed(2)} A</span>
                            </li>
                          )}

                          {this.state.tooltipData[`${mill.id}Thr2`] !== null && (
                            <li>
                              <strong>{this.props.t(`plantAnalysis.lines.mills.label.${mill.id}Thr2`)}</strong>
                              <br />
                              <span
                                className="label-color label-color-line"
                                style={{ backgroundColor: this.state.tooltipData[`${mill.id}ThrColor`] }}
                              />
                              <span>{parseFloat(this.state.tooltipData[`${mill.id}Thr2`]).toFixed(2)} A</span>
                            </li>
                          )}

                          {this.state.tooltipData[`${mill.id}Thr3`] !== null && (
                            <li>
                              <strong>{this.props.t(`plantAnalysis.lines.mills.label.${mill.id}Thr3`)}</strong>
                              <br />
                              <span
                                className="label-color label-color-line"
                                style={{ backgroundColor: this.state.tooltipData[`${mill.id}ThrColor`] }}
                              />
                              <span>{parseFloat(this.state.tooltipData[`${mill.id}Thr3`]).toFixed(2)} A</span>
                            </li>
                          )}

                          {this.state.tooltipData[`${mill.id}Absorption`] !== null && (
                            <li>
                              <strong>{this.props.t(`plantAnalysis.lines.mills.label.${mill.id}Absorption`)}</strong>
                              <br />
                              <span
                                className="label-color label-color-line"
                                style={{
                                  backgroundColor: this.state.tooltipData[`${mill.id}AbsorptionColor`],
                                }}
                              />
                              <span>{parseFloat(this.state.tooltipData[`${mill.id}Absorption`]).toFixed(2)} A</span>
                            </li>
                          )}
                        </ul>
                      )}
                  </div>
                </Row>
              </div>
              <div className={`col-md-2 col-sm-2 col-xxl-1 chart-events-pie--data`}>
                <table className={'table table-borderless table-sm w-50 mw-300 font-size-12 table-first-column-color'}>
                  <thead>
                    <tr>
                      <th />
                      <th className="text-right">{t('plantAnalysis.lines.mills.table.overValue')}</th>
                      <th className="text-right">{t('plantAnalysis.lines.mills.table.overTimeValue')}</th>
                    </tr>
                  </thead>
                  {this.state.data?.mills && (
                    <tbody>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr1')}</td>
                        <td className="text-right">
                          {parseInt(this.state.data?.mills[0]?.[`mill_${mill.assetId}_count_over_first_threshold`], 10)}
                        </td>
                        <td className="text-right">
                          {HHHmmssFromMilliseconds(
                            this.state.data?.mills[0]?.[`mill_${mill.assetId}_seconds_over_first_threshold`] * 1000,
                            false,
                            true,
                            false
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr2')}</td>
                        <td className="text-right">
                          {parseInt(
                            this.state.data?.mills[0]?.[`mill_${mill.assetId}_count_over_second_threshold`],
                            10
                          )}
                        </td>
                        <td className="text-right">
                          {HHHmmssFromMilliseconds(
                            this.state.data?.mills[0]?.[`mill_${mill.assetId}_seconds_over_second_threshold`] * 1000, // tslint:disable-line
                            false,
                            true,
                            false
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ whiteSpace: 'nowrap' }}>{this.props.t('plantAnalysis.lines.mills.table.thr3')}</td>
                        <td className="text-right">
                          {parseInt(this.state.data?.mills[0]?.[`mill_${mill.assetId}_count_over_third_threshold`], 10)}
                        </td>
                        <td className="text-right">
                          {HHHmmssFromMilliseconds(
                            this.state.data?.mills[0]?.[`mill_${mill.assetId}_seconds_over_third_threshold`] * 1000,
                            false,
                            true,
                            false
                          )}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </Row>
          )
        })}
      </div>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(MillsView))
