import React, { useMemo } from 'react'
import { useChartFetching } from '../../../../hook/useChartFetching'
import { useChartState } from '../../../../hook/useChartState'
import { API } from '../../../../redux/actions'
import { PartialRecordDataType, PartialRecordStringType } from '../../../../types/helpers'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import { GeneralChartProps } from './generalChartProps'

type DataType = typeof DATA_TYPE[number]

interface CompleteData {
  data: PartialRecordDataType<DataType>
}

const DATA_TYPE = ['totalProduction', 'totalTonsProduced'] as const
const measureMiddleSection = '_PROD_'
const totalNumberOfBalesProduced = '_NUM_BALES' as const
const totalTonsProduced = '_TOT_TON' as const

const MEASURE_TYPE = [totalNumberOfBalesProduced, totalTonsProduced] as const
type measureType = typeof MEASURE_TYPE[number]

const mapperMeasureToDataType: Record<measureType, DataType> = {
  _NUM_BALES: 'totalProduction',
  _TOT_TON: 'totalTonsProduced',
}

interface Props {
  currentSelectedRecipe: string
}

const _RecipeRecapTable = (props: GeneralChartProps & Props) => {
  const { startDateTime, endDateTime, abortController, measure, plantString, t, currentSelectedRecipe } = props

  const baseStartMeasure = useMemo(() => {
    return `${measure}${measureMiddleSection}`
  }, [measure, measureMiddleSection])
  const totalNumberOfBalesProducedMeasureName = useMemo(() => {
    return `${baseStartMeasure}${currentSelectedRecipe}${totalNumberOfBalesProduced}`
  }, [baseStartMeasure, currentSelectedRecipe, totalNumberOfBalesProduced])
  const totalTonsProducedMeasureName = useMemo(() => {
    return `${baseStartMeasure}${currentSelectedRecipe}${totalTonsProduced}`
  }, [baseStartMeasure, currentSelectedRecipe, totalTonsProduced])

  const dataContentLabel: PartialRecordStringType<DataType> = useMemo(
    () => ({
      totalProduction: `${t('plantAnalysis.pressDetail.totalProduction')}`,
      totalTonsProduced: `${t('plantAnalysis.pressDetail.totalTonsProduced')}`,
    }),
    []
  )

  const { actions, data, isError, isLoading } = useChartState<CompleteData, DataType>({
    keys: [...DATA_TYPE],
    tooltipContentInfo: {
      totalProduction_color: '',
      totalProduction_label: dataContentLabel.totalProduction,
      totalProduction_unitOfMeasure: '',
      totalTonsProduced_color: '',
      totalTonsProduced_label: dataContentLabel.totalTonsProduced,
      totalTonsProduced_unitOfMeasure: '',
    },
  })

  const { uriRequestStart, queryEndSection, queryStartSection, queryEndLastElement } = useChartFetching({
    callback: fetchAllData,
    endDateTime,
    startDateTime,
    plantString,
    type: 'withLast',
    deps: [currentSelectedRecipe]
  })

  function fetchAllData() {
    actions.setLoading()

    const measureNameList = [totalNumberOfBalesProducedMeasureName, totalTonsProducedMeasureName].join('", "')

    const productionRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureNameList}${queryEndSection}`,
      {
        signal: abortController.signal,
      }
    )

    const productionLastRequest = API().request(
      `${uriRequestStart}${queryStartSection}${measureNameList}${queryEndLastElement}`,
      {
        signal: abortController.signal,
      }
    )

    Promise.all([productionRequest, productionLastRequest]).then(([productionResult, productionLastResult]) => {
      actions.setData({
        data: parseAndPrepareData(productionResult, productionLastResult),
      })
    })
  }

  function getValueFromSeries(responseData: any) {
    const dataParsed = JSON.parse(responseData)
    const recipeNameRecord: Record<string, string[]> = {}
    if (dataParsed.results[0]) {
      const resultDataParsed = dataParsed.results[0]
      MEASURE_TYPE.forEach(name => {
        if (resultDataParsed?.series) {
          const recipeFound = resultDataParsed.series.find((s: any) => s.name.includes(name))
          recipeNameRecord[name] = []
          if (recipeFound && recipeFound.values.length > 0) {
            recipeNameRecord[name] = recipeFound.values.map((v: any) => v[1])
          }
        }
      })
    }
    return recipeNameRecord
  }

  function parseAndPrepareData(responseData: any, lastResponseData: any): PartialRecordDataType<DataType> {
    const getValueDataResult = getValueFromSeries(responseData)
    const getValueLastDataResult = getValueFromSeries(lastResponseData)
    const result: Record<DataType, number> = MEASURE_TYPE.reduce(
      (acc, curr) => {
        const baseArray = [...(getValueLastDataResult[curr] ?? []), ...(getValueDataResult[curr] ?? [])]

        const res = baseArray.reduceRight((difference, currentValue, index, array) => {
          if (array[index - 1] === undefined) {
            return difference
          }
          if (parseFloat(currentValue) - parseFloat(array[index - 1]) <= 0) {
            return difference
          }
          return difference + (parseFloat(currentValue) - parseFloat(array[index - 1]))
        }, 0)

        return { ...acc, [mapperMeasureToDataType[curr]]: res }
      },
      {
        totalTonsProduced: 0,
        totalProduction: 0,
      }
    )
    return result
  }

  if (isLoading) {
    return <></>
  }

  if (isError) {
    return <CommonErrorMessage />
  }

  if ((data && !data.data)) {
    return <CommonNoDataAvailable />
  }

  return (
    <div className={'d-flex flex-column'}>
      <table className={'table table-bordered w-25'}>
        <tbody>
          <tr>
            <td scope="col">{dataContentLabel.totalProduction}</td>
            <td scope="col"><b>{data?.data.totalProduction ?? 0}</b></td>
          </tr>
          <tr>
            <td scope="col">{dataContentLabel.totalTonsProduced}</td>
            <td scope="col"><b>{data?.data.totalTonsProduced ?? 0}</b></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default _RecipeRecapTable
