import React from 'react'
import Row from '../../../../../inplant-components-fe/ui/components/Grid/Row'

interface DataToShowProps {
  label: string
  color: string
  unitOfMeasure: string 
}

interface Props {
  dataToShow: DataToShowProps[]
  stayCenter?: boolean
  showUnitOfMeasure?: boolean
}

const CustomLegend = ({ stayCenter = true, showUnitOfMeasure = false, ...props }: Props) => {
  const { dataToShow } = props
  return (
    <div className={`d-flex ${stayCenter ? 'justify-content-center' : ''} flex-wrap`}>
      {dataToShow.map((data, index) => (
        <Row key={index} verticalAlignment="center">
          <span style={{ whiteSpace: 'nowrap' }}>
            <span
              className="label-color-square"
              style={{ backgroundColor: data.color }}
            />
            <span>{data.label}  {showUnitOfMeasure ? data.unitOfMeasure : ''}</span>
          </span>
        </Row>
      ))}
    </div>
  )
}

export default CustomLegend
