import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../inplant-core-fe/redux/actions'
import { APIPlantAnalysis } from '../../../../redux/actions'
import { GeneralData } from '../../../../types/measure'
import { Workshift } from '../../../../types/workshift'
import { PieData } from '../charts/EventsPie/EventsPieView'
import { COLORS } from '../../../../constants'
import { HHHmmssFromMilliseconds, Loader } from '../../../../functions/shared'
import { consoleLog } from '../../../../../inplant-components-fe/mvfunctions/logs'
import GraphLineBarWithTimeline from '../GraphLineBar/GraphLineBarWithTimeline/GraphLineBarWithTimelineView'
import SimpleTable from '../../../../../mvlabs-components-fe/ui/components/Table/SimpleTable'
import { generateSpace, SpaceType } from '../../../../../mvlabs-components-fe/functions/bootstrapUtility'
import {
  WithConditionWrapper,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import {
  TableRowFullWrapper,
  TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableWrapperUtilities
  from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'

export type ScrubberStatusType = 'scrubberStatus'

interface RecipeTableData {
  scheduledRecipe: string
  scheduledRecipeId: string
  executedRecipe: string
  executedRecipeId: string
  start: string
  stop: string
  percentage: number
  time: number
  comment: string
  isConnected: boolean
  isScheduledHalt: boolean
  isExecutedHalt: boolean
}

interface RecipeList {
  name: string
  id: string
}

interface OwnProps {
  isDateFilterRange?: boolean
  standardTime: number
  processing?: number
  hiddenCharts?: ScrubberStatusType[]
  showTimeline?: boolean
  pauses?: Array<{ start: number; end: number }>
  date: string | number
  workShift: any
  installationSlug: string
}

interface OwnState {
  scrubberState: { [k: string]: null | { name: string; columns: string[]; values: Array<Array<string | number>> } }
  dataScrubberState: PieData | null
  fetching: boolean
  error: boolean
  data: any
  filteredData: any[]
  mergedData: any[]
  keyCollection: string[]
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
  workshift: any
}

type Props = StateProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
  workshift: state.plantAnalysis.common.workshift,
})

class RecipeRecapView extends React.Component<Props, OwnState> {
  private mounted = false
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)

    this.state = {
      keyCollection: [],
      scrubberState: {},
      fetching: false,
      error: false,
      dataScrubberState: null,
      data: null,
      filteredData: [],
      mergedData: [],
    }
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    if (this.props.workShift) {
      this.getData(this.props.processing)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  public render() {
    const { t, hiddenCharts } = this.props
    const { fetching, error } = this.state

    return (
      <TableRowFullWrapper>
        <TableWrapperUtilities>
          <WithConditionWrapper condition={!fetching && error}>
            <div className='alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local'>
              {t('plantAnalysis.fetchErrors')}
            </div>
          </WithConditionWrapper>
          <WithConditionWrapper
            condition={!fetching && !error && ((this.state.data && this.state.data.length === 0) || !this.state.data)}>
            <div className='alert alert-warning w-100 col-sm-6 mx-auto'>{t('plantAnalysis.noDataAvailable')}</div>
          </WithConditionWrapper>
          <WithConditionWrapper condition={!error}>
            {(!hiddenCharts || !hiddenCharts.includes('scrubberStatus')) &&
              <>
                <WithConditionWrapper condition={this.state.fetching}>
                  <Loader />
                </WithConditionWrapper>
                <WithConditionWrapper condition={this.state.data}>
                  <TableRowFullWrapperWithCondition
                    condition={!(!this.state.data?.programmedTimeline || this.state.data?.programmedTimeline.length === 0)}>
                    <GraphLineBarWithTimeline
                      topMargin={true}
                      entry={'execution'}
                      colorsId={'recipeRecap'}
                      filteredData={this.state.data?.programmedTimeline}
                      i18nTitle={'plantAnalysis.recipeRecap.programmedTimeline'}
                      forcedColors={this.generateForcedColor(this.state.data?.recipeList)}
                      lineHeight={40}
                    />
                  </TableRowFullWrapperWithCondition>
                  <TableRowFullWrapperWithCondition
                    condition={!(!this.state.data?.actualTimeline || this.state.data?.actualTimeline.length === 0)}
                  >
                    <GraphLineBarWithTimeline
                      topMargin={true}
                      entry={'execution'}
                      colorsId={'recipeRecap'}
                      filteredData={this.state.data?.actualTimeline}
                      i18nTitle={'plantAnalysis.recipeRecap.actual'}
                      forcedColors={this.generateForcedColor(this.state.data?.recipeList)}
                      lineHeight={40}
                    />
                  </TableRowFullWrapperWithCondition>
                  <TableRowFullWrapper>
                    {this.state.data?.recipeList &&
                      this.state.data.recipeList.map((recipe: any, index: number) => (
                        <span style={{ whiteSpace: 'nowrap', marginInline: '10px' }}>
                          <span
                            className='label-color-square'
                            style={{
                              backgroundColor: COLORS.recipeRecap[recipe.id]
                                ? COLORS.recipeRecap[recipe.id]
                                : COLORS.recipeRecap[recipe.id % 13],
                            }}
                          />
                          <span>{recipe.name.length > 0 ? `${recipe.id}: ${recipe.name}` : recipe.id}</span>
                        </span>
                      ))}
                    <span style={{ whiteSpace: 'nowrap', marginInline: '10px' }}>
                      <span
                        className='label-color-square'
                        style={{ backgroundColor: COLORS.recipeRecap.noConnection }}
                      />
                      <span>{t('plantAnalysis.recipeRecap.noConnectionLabel')}</span>
                    </span>
                    <span style={{ whiteSpace: 'nowrap', marginInline: '10px' }}>
                      <span
                        className='label-color-square'
                        style={{ backgroundColor: COLORS.recipeRecap.halt }}
                      />
                      <span>{t('plantAnalysis.recipeRecap.isHalt')}</span>
                    </span>
                    <span style={{ whiteSpace: 'nowrap', marginInline: '10px' }}>
                      <span
                        className='label-color-square'
                        style={{ backgroundColor: COLORS.recipeRecap.null }}
                      />
                      <span>{t('plantAnalysis.recipeRecap.noScheduledActivity')}</span>
                    </span>
                  </TableRowFullWrapper>
                  <TableRowFullWrapperWithCondition
                    columnClassName={'py-3'}
                    condition={!(!this.state.data?.tableData || this.state.data?.tableData.length === 0)}
                    onError={<div className='alert alert-warning w-100 col-sm-6 mx-auto'>
                      {t('plantAnalysis.noDataAvailable')}
                    </div>}>
                    <SimpleTable<RecipeTableData>
                      columns={[
                        {
                          title: t('plantAnalysis.recipeRecap.table.expectedRecipe'),
                          cell: data =>
                            `${
                              data.scheduledRecipe
                                ? `${data.scheduledRecipeId ? `${data.scheduledRecipeId}:` : ''} ${data.scheduledRecipe}`
                                : data.isScheduledHalt
                                  ? t('plantAnalysis.recipeRecap.isHalt')
                                  : t('plantAnalysis.recipeRecap.noScheduledActivity')
                            }`,
                          className: this.getClassName,
                        },
                        {
                          title: t('plantAnalysis.recipeRecap.table.actualRecipe'),
                          cell: data =>
                            `${
                              data.executedRecipe
                                ? `${data.executedRecipeId ? `${data.executedRecipeId}:` : ''} ${data.executedRecipe}`
                                : data.isExecutedHalt
                                  ? t('plantAnalysis.recipeRecap.isHalt')
                                  : !data.isConnected
                                    ? t('plantAnalysis.recipeRecap.noConnection')
                                    : t('plantAnalysis.recipeRecap.noExecutedActivity')
                            }`,
                          className: this.getClassName,
                        },
                        {
                          title: t('plantAnalysis.recipeRecap.table.startEndTime'),
                          cell: data =>
                            `${mvDate.getDateFromStringWithFormatting(
                              data.start,
                              'HH:mm',
                            )} - ${mvDate.getDateFromStringWithFormatting(data.stop, 'HH:mm')}`,
                          className: this.getClassName,
                        },
                        {
                          title: t('plantAnalysis.recipeRecap.table.percentage'),
                          cell: data => `${data.percentage}`,
                          className: this.getClassName,
                        },
                        {
                          title: t('plantAnalysis.recipeRecap.table.duration'),
                          cell: data => HHHmmssFromMilliseconds(data.time * 1000 || 0, false, true, false),
                          className: this.getClassName,
                        },
                        {
                          title: t('plantAnalysis.recipeRecap.table.comment'),
                          cell: data => `${data.comment}`,
                          className: this.getClassName,
                          width: '300px',
                        },
                        {
                          title: t('plantAnalysis.recipeRecap.table.connected'),
                          cell: data =>
                            t(
                              `plantAnalysis.recipeRecap.connection.${
                                data.isConnected ? 'isConnected' : 'isNotConnected'
                              }`,
                            ),
                          className: this.getClassName,
                        },
                      ]}
                      data={this.state.data?.tableData}
                    />
                  </TableRowFullWrapperWithCondition>
                </WithConditionWrapper>
              </>}
          </WithConditionWrapper>
        </TableWrapperUtilities>
      </TableRowFullWrapper>
    )
  }

  private generateForcedColor(recipeList: RecipeList[]) {
    if (!recipeList) {
      return {}
    }
    return {
      ['noConnection']: COLORS.recipeRecap.noConnection,
      ['halt']: COLORS.recipeRecap.halt,
      ...recipeList.reduce(
        (acc: Record<string, string>, curr: RecipeList) => ({
          ...acc,
          [curr.id.toString()]: COLORS.recipeRecap[curr.id]
            ? COLORS.recipeRecap[curr.id]
            : COLORS.recipeRecap[parseInt(curr.id, 10) % 13],
        }),
        {},
      ),
    }
  }

  private getClassName(data: RecipeTableData) {
    return `${generateSpace(SpaceType.padding, { right: 3 })} ${
      data.executedRecipe === data.scheduledRecipe && data.executedRecipe !== null
        ? ''
        : data.isConnected
          ? 'table-striped-mismatch'
          : 'table-striped-connection'
    }`
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift
    if (workshift && this.props.date) {
      try {
        const graphData = APIPlantAnalysis().request(`/recipe-chart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Content-Language': 'it-IT',
          },
          body: JSON.stringify({ date: this.props.date, plantName: plant, shift: workshift.value, installationSlug: this.props.installationSlug }),
          signal: this.abortController.signal,
        })
        const tableData = APIPlantAnalysis().request(`/recipe-table`, {
          headers: {
            'Content-Type': 'application/json',
            'Content-Language': 'it-IT',
          },
          method: 'POST',
          body: JSON.stringify({ date: this.props.date, plantName: plant, shift: workshift.value, installationSlug: this.props.installationSlug }),
          signal: this.abortController.signal,
        })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([graphData, tableData])
          .then(([graphDataResult, tableDataResult]) => {
            if (this.mounted) {
              try {
                const graphDataResultParsed =
                  typeof graphDataResult === 'string' ? JSON.parse(graphDataResult) : graphDataResult
                this.setState({
                  data: Object.assign({}, this.state.data, {
                    recipeList: graphDataResultParsed.recipesInformation,
                    actualTimeline: graphDataResultParsed.actualExecutions,
                    programmedTimeline: graphDataResultParsed.scheduledExecutions,
                    tableData: typeof tableDataResult === 'string' ? JSON.parse(tableDataResult) : tableDataResult,
                  }),
                  fetching: false,
                  error: false,
                })
              } catch (error) {
                this.setState({
                  fetching: false,
                  data: null,
                })
              }
            }
          })
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              fetching: false,
              error: true,
            })
          })
      } catch (error: any) {
        consoleLog('error', error)
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(RecipeRecapView))
