import * as React from 'react'
import * as moment from 'moment-timezone'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { DateRangeProps, Workshift } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/workshift'
import { Configurations } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/reducers/configurations'
import { GeneralData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/measure'
import {
  ChartPlaceholder,
  HHHmmssFromMilliseconds,
  HoursFromMilliseconds,
  isJSON,
  momentRoundTime,
  parseInfluxResponseData,
  timeToSeconds,
  toggleArrayItem,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import {
  PlantAnalysisGeneralSettings,
  PlantAnalysisGeneralsSettings,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/settings'
import { generalFetchData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/general'
import { modelFetchData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/model'
import { configurationSaveData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/configuration'
import {
  updateDateFilter,
  updateWorkshiftFilter,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/common'
import { flatData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/model'
import WorkshiftSelector from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/WorkShiftSelector/WorkshiftSelector'
import GeneralGraphsPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/GeneralGraphs/GeneralGraphsPrint'
import { API } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions'
import {
  fillWarnArea,
  hydrateData,
  hydrateTimeData,
  parseResponseData,
  parseResponseSingleData,
  populateManAutoLocSec,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/series'
import genericMeasureFetch from '../../../../redux/actions/genericMeasureFetch'
import TableRowWrapper, {
  TableRowFullWrapper,
  TableRowFullWrapperWithCondition,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableRowWrapper'
import TableColumnWrapper from '../../widgets/PrintUtilities/TableColumnWrapper'
import { WithConditionWrapper } from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/WithConditionHOC'
import GraphWrapperPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/GraphWrapper/GraphWrapperPrint'
import RecipeRecapPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/RecipeRecap/RecipeRecapPrint'
import NamedRecipeGraphPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/RecipeGraphs/NamedRecipeGraphPrint'
import RppGraphMainPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/RppGraphMain/RppGraphMain/RppGraphMainPrint'
import RppGraphMainSG2Print from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/RppGraphMain/RppGraphMainSG2/RppGraphMainSG2Print'
import PlantStatusPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PlantState/PlantStatePrint'
import PlantHaltsShiftPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PlantHalts/PlantHaltsShift/PlantHaltsShiftPrint'
import LineBufferPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/Lines/LineBuffer/LineBufferPrint'
import LoadingBeltPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/Lines/LoadingBelt/LoadingBeltPrint'
import EngineTorqueDensifierPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/Lines/EngineTorqueDensifier/EngineTorqueDensifierPrint'
import MillsPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/Lines/Mills/MillsPrint'
import VecoplanPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/Vecoplan/VecoplanPrint'
import ScrubberStatusPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/Scrubber/ScrubberStatus/ScrubberStatusPrint'
import LiterPerHourTrendPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/Scrubber/LiterPerHourTrend/LiterPerHourTrendPrint'
import TitechPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/Titech/TitechPrint'
import BearingsGraphMainPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BearingsGraphMain/BearingsGraphMainPrint'
import BagSpeedDistributionGeneralPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BagSpeedDistributionGeneral/BagSpeedDistributionGeneral/BagSpeedDistributionGeneralPrint'
import BagGraphMainPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BagGraph/BagGraphMain/BagGraphMainPrint'
import PlantHaltsPrint, {
  PlantHaltTypes,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PlantHalts/PlantHalts/PlantHaltsPrint'
import MotorAbsorptionPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/MotorAbsorption/MotorAbsorptionPrint'
import AspiratedAreaGraphRowPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/AspiratedAreaGraphRow/AspiratedAreaGraphRow/AspiratedAreaGraphRowPrint'
import AspiratedAreaGraphRowCustomPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/AspiratedAreaGraphRow/AspiratedGraphRowCustom/AspiratedGraphRowCustomPrint'
import FanGraphRowPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/FanGraph/FanGraphRow/FanGraphRowPrint'
import { SectionNodeTypes } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/chronograph'
import BeltGraphRowPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BeltGraph/BeltGraphRow/BeltGraphRowPrint'
import BunkerGraphHeaderPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BunkerGraph/BunkerGraphHeader/BunkerGraphHeaderPrint'
import BunkerGraphRowPrint from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BunkerGraph/BunkerGraphRow/BunkerGraphRowPrint'
import { get12TableHeadColumns } from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/PrintUtilities/TableWrapperUtilities'
import EngineSpeedDensifierPrint from '../../widgets/Lines/EngineSpeedDensifier/EngineSpeedDensifierPrint'
import UnthaPrint from '../../widgets/Untha/UnthaPrint'

library.add(faCircleNotch, faInfoCircle, faEyeDropper, faHourglassHalf)

interface OwnState {
  puppeteerSectionsEnable?: string[]
  brush1EndInd: Record<string, number>
  brush1StartInd: Record<string, number>
  currentGraph: string[]
  data: any
  dataBoolElapsed: any
  dataBunker: any
  dataProcessedMaterial: string[]
  dataCurrent: any
  dataElapsed: any
  dataIntElapsed: any
  endDate: string | number
  fetchErrors: boolean
  fetchErrorsBunker: boolean
  fetchErrorsProcessedMaterial: boolean
  fetchErrorsCurrent: boolean
  fetchErrorsElapsed: boolean
  filteredData: Record<string, any[]>
  isLoading: boolean
  isLoadingBunker: boolean
  isLoadingProcessedMaterial: boolean
  isLoadingCurrent: boolean
  isLoadingElapsed: boolean
  last: any
  lastAfter: any
  lastScrollY: number
  mergedData: undefined | Record<string, any[]>
  modalIsVisible: boolean
  plant: null | string
  selectedDate: string
  selectedWorkshift: Workshift | null
  startDate: string | number
  window: WindowDetails
}

interface WindowDetails {
  width: number
  height: number
}

interface StateProps {
  configurations: null | Configurations
  fullDay: null | Workshift
  lavorazioneAttuale: null | GeneralData
  model: null | GeneralData
  overviewConfig?: string[][]
  plant: any | null
  plantHaltsConfiguration: {
    processings?: number[]
    hiddenCharts?: PlantHaltTypes[]
    hiddenGeneralCharts?: PlantHaltTypes[]
  } | null
  plantConfigurations: any | null
  ricettaAttuale: null | GeneralData
  isWithGateway: boolean
  rppConfiguration: {
    [k: string]: [
      {
        widgetName: string
        tableName: string
        hideSummaryTable?: boolean
        hideOnPrint: boolean
      }
    ]
  } | null
  workshift: null | Workshift
  workshiftsValidity: null | GeneralData
}

const mapStateToProps = (state: any): StateProps & DateRangeProps => ({
  configurations: state.plantAnalysis.configurations,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  days: state.plantAnalysis.common.days,
  isDateFilterRange: state.plantAnalysis.common.isDateFilterRange,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  lavorazioneAttuale: state.plantAnalysis.general.lavorazione,
  model: state.plantAnalysis.model,
  plant: state.plantSelector || null,
  isWithGateway: (state.config && state.config.plantAnalysis && state.config.plantAnalysis.isWithGateway) ?? false,
  plantConfigurations:
    state.config &&
    state.config.plantAnalysis &&
    state.config.plantAnalysis.plantConfigurations &&
    isJSON(state.config.plantAnalysis.plantConfigurations)
      ? JSON.parse(state.config.plantAnalysis.plantConfigurations)
      : null,
  plantHaltsConfiguration:
    state.config &&
    state.config.plantAnalysis &&
    state.config.plantAnalysis.plantHaltsConfiguration &&
    isJSON(state.config.plantAnalysis.plantHaltsConfiguration)
      ? JSON.parse(state.config.plantAnalysis.plantHaltsConfiguration)
      : null,
  overviewConfig:
    state.config &&
    state.config.plantAnalysis &&
    state.config.plantAnalysis.overviewConfig &&
    isJSON(state.config.plantAnalysis.overviewConfig)
      ? JSON.parse(state.config.plantAnalysis.overviewConfig)
      : null,
  rppConfiguration:
    state.config?.plantAnalysis?.rppConfig && isJSON(state.config.plantAnalysis.rppConfig)
      ? JSON.parse(state.config.plantAnalysis.rppConfig)
      : null,
  ricettaAttuale: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
  workshiftsValidity: state.plantAnalysis.workshifts.workshiftsValidity,
})

interface DispatchProps {
  generalFetchData: (workshift: Workshift) => void
  modelFetchData: (plant?: string) => Promise<{ model: any; plant?: string }>
  saveConfiguration: (settings: PlantAnalysisGeneralsSettings) => Promise<PlantAnalysisGeneralsSettings>
  updateWorkshiftFilter: (workshift: number, workshifts: GeneralData | null) => void
  updateDateFilter: (dateStart: moment.Moment, dateEnd: moment.Moment, isManual?: boolean) => void
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    generalFetchData: (workshift: Workshift) => dispatch(generalFetchData(workshift)),
    modelFetchData: (plant?: string) => dispatch(modelFetchData(plant)),
    saveConfiguration: settings => dispatch(configurationSaveData(settings)),
    updateWorkshiftFilter: (workshift: number, workshifts: GeneralData | null) =>
      dispatch(updateWorkshiftFilter(workshift, workshifts)),
    updateDateFilter: (dateStart, dateEnd, isManual) => dispatch(updateDateFilter(dateStart, dateEnd, isManual)),
  }
}

enum DataToFetch {
  PLANT_DATA_BRT = 'PLANT_DATA_BRT',
  U3081 = 'U308-1',
  U3082 = 'U308-2',
}

const dataToFetch = {
  [DataToFetch.PLANT_DATA_BRT]: {
    measureType: '_WS_PERF_NUM',
    propsToSeach: 'nodeType',
  },
  [DataToFetch.U3081]: {
    measureType: '_WS_SET_SPD',
    propsToSeach: 'code',
  },
  [DataToFetch.U3082]: {
    measureType: '_WS_SET_SPD',
    propsToSeach: 'code',
  },
}

export type Props = WithTranslation & RouteComponentProps<any> & DispatchProps & StateProps & DateRangeProps

class PlantAnalysisGeneralPagePrint extends React.Component<Props, OwnState> {
  // @ts-ignore
  private selectDateText: string
  private abortController: AbortController = new AbortController()
  // @ts-ignore
  private mounted: boolean
  private layoutConfig: false | undefined | string[]

  constructor(props: Props) {
    super(props)

    this.selectDateText = this.props.t('plantAnalysis.actions.selectDateText')

    const { search } = props.location
    const query = new URLSearchParams(search)

    let puppeteerSectionsEnable: string[] | undefined
    let tempPuppeteerSectionsEnable: string | undefined

    const queryParamsPuppeteer = 'puppeteerSectionsEnable' as const

    if (query.get(queryParamsPuppeteer)) {
      // ! DON'T DO THIS
      tempPuppeteerSectionsEnable =
        query.get(queryParamsPuppeteer) !== null ? ((query.get(queryParamsPuppeteer) as any) as string) : undefined
      const content: string | undefined =
        tempPuppeteerSectionsEnable && tempPuppeteerSectionsEnable.replace('[', '').replace(']', '')
      puppeteerSectionsEnable = content ? content.split(',') : undefined
    }

    this.state = {
      puppeteerSectionsEnable,
      isLoading: false,
      isLoadingElapsed: false,
      isLoadingCurrent: false,
      isLoadingBunker: false,
      isLoadingProcessedMaterial: false,
      fetchErrors: false,
      fetchErrorsElapsed: false,
      fetchErrorsCurrent: false,
      fetchErrorsBunker: false,
      fetchErrorsProcessedMaterial: false,
      selectedDate: moment(this.props.dateFilterStart).format('DD/MM/YYYY'),
      selectedWorkshift: this.props.fullDay,
      startDate: 'auto',
      endDate: 'auto',
      currentGraph: [
        'aprisacchi',
        'brt3081',
        'brt3082',
        'aspiratedArea',
        'aspiratori',
        'bearings',
        'bunker',
        'general',
        'halts',
        'halts-filtered',
        'motorAbsorption',
        'named-recipes',
        'nastri',
        'rpp',
        'rpp-0',
        'rpp-1',
        'rpp-2',
        'titech',
        'plantState',
        'vecoplant',
        'scrubber',
        'lines',
        'lines-1',
        'lines-2',
        'lines-3',
        'ventilator',
        'recipe-recap',
        'recipe-recap-plant',
        'recipe-recap-u2-phase2',
      ],
      lastScrollY: 0,
      window: {
        width: innerWidth,
        height: innerHeight,
      },
      mergedData: {},
      filteredData: {},
      data: {
        performance: {
          data: [],
          min: 0,
          max: 0,
        },
        marcia: {
          data: [],
          min: 0,
          max: 0,
        },
      },
      dataElapsed: {},
      dataBunker: {},
      dataProcessedMaterial: [],
      dataCurrent: {},
      dataIntElapsed: {},
      dataBoolElapsed: {},
      last: null,
      lastAfter: null,
      plant: null,
      brush1StartInd: {},
      brush1EndInd: {},
      modalIsVisible: false,
    }

    this.getAllData = this.getAllData.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.handleCloseSettingsModal = this.handleCloseSettingsModal.bind(this)
    this.handleOpenSettingsModal = this.handleOpenSettingsModal.bind(this)
    this.handleSaveSettingsModal = this.handleSaveSettingsModal.bind(this)
    this.getData = this.getData.bind(this)
    this.getDataElapsed = this.getDataElapsed.bind(this)
    this.getDataMeanCurrent = this.getDataMeanCurrent.bind(this)
    this.getDataBunker = this.getDataBunker.bind(this)
    this.getDataHaltsProcessedMaterial = this.getDataHaltsProcessedMaterial.bind(this)
    this.getActiveNodes = this.getActiveNodes.bind(this)
    this.getConfiguration = this.getConfiguration.bind(this)
    this.updateLayoutConfig = this.updateLayoutConfig.bind(this)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const condition =
      ((nextProps.plant && (!this.props.plant || nextProps.plant.plant !== this.props.plant.plant)) ||
        (nextProps.fullDay && nextProps.fullDay !== this.props.fullDay) ||
        (nextProps.configurations &&
          (!this.props.configurations || nextProps.configurations.data !== this.props.configurations.data))) &&
      !(nextProps.model && nextProps.model.fetching)
    if (condition) {
      this.updateLayoutConfig(nextProps.plant.plant)
      this.props
        .modelFetchData(nextProps.plant.plant)
        .then(({ model, plant }) => {
          this.getAllData()
        })
        .catch(e => console.log) // tslint:disable-line
    }
    if (!condition && nextProps.plant.plant !== this.props.plant.plant) {
      this.updateLayoutConfig(nextProps.plant.plant)
      this.props
        .modelFetchData(nextProps.plant.plant)
        .then(({ model, plant }) => {
          this.getAllData()
        })
        .catch(e => console.log) // tslint:disable-line
    }
  }

  public componentWillUnmount(): void {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidMount() {
    this.mounted = true
    const url = new URL(window.location.href)
    const manualDate = url.searchParams.get('manualDate')
    const manualWorkShift = url.searchParams.get('manualWorkshift')

    this.updateLayoutConfig()

    if (this.props.plantConfigurations) {
      const localConfigurations = Object.assign({}, (this.props.configurations && this.props.configurations.data) || {})
      Object.keys(this.props.plantConfigurations).map(plant => {
        if (!localConfigurations.hasOwnProperty(plant)) {
          localConfigurations[plant] = this.props.plantConfigurations[plant]
        }
      })
      this.props.saveConfiguration(localConfigurations)
    }

    if (manualDate && moment(manualDate).isValid()) {
      this.props.updateDateFilter(moment(manualDate), moment(manualDate), true)
    }
    if (manualWorkShift) {
      this.props.updateWorkshiftFilter(parseInt(manualWorkShift, 10), this.props.workshiftsValidity)
    }
  }

  public render() {
    const {
      days,
      fullDay,
      isDateFilterRange,
      model,
      plant,
      /*plantHaltsConfiguration,*/
      rppConfiguration,
      t,
      workshiftsValidity,
    } = this.props
    const {
      currentGraph,
      dataElapsed,
      dataCurrent,
      fetchErrorsElapsed,
      isLoadingElapsed,
      lastScrollY,
      mergedData,
    } = this.state
    let nodesIds
    let areNodesVisible
    let plantStandardTime = 0
    let workshiftUniqueSlots: any
    let repeatSlots: number[] = []
    let workShiftSlotJSX: React.ReactNode[] = []
    let workshiftSlotsTotalTimeStr: string = ''
    let plantStandardTimeStr: string = ''
    let totalWorkshiftDelta: string = ''
    let nodes: any[] = []
    let wTotal = 0

    const configuration = this.getConfiguration(plant.plant, this.props)
    const cutOffHour = model && model.data && model.data.model.content.data.cutOffHour

    const modelData = model && !model.fetching && !model.error && model.data && model.data.model
    const shiftsBudget = modelData ? modelData.content.data.shiftsDuration : []

    const roundToMinutes = (seconds: number, multiplier = 1): number => {
      return Math.round(seconds / (60 * multiplier)) * 60 * multiplier
    }

    if (this.isSectionEnabled('assets')) {
      nodesIds = (configuration && configuration.nodes && Object.keys(configuration.nodes)) || []
      areNodesVisible = nodesIds.length > 0
      nodes = model && !model.fetching && !model.error && model.data ? flatData(modelData.content) : []
    }

    if (this.isSectionEnabled('halts')) {
      plantStandardTime =
        modelData && shiftsBudget
          ? shiftsBudget.reduce((acc: number, cur: string) => {
              return acc + timeToSeconds(cur)
            }, 0)
          : 0
    }

    if (this.isSectionEnabled('overview')) {
      const slots: Array<{ id: number; start: number; end: number }> = (fullDay && fullDay.slots && fullDay.slots) || []
      const slotsNumber: number[] = []

      workshiftUniqueSlots =
        slots &&
        (slots.reduce((acc: number[], curr) => {
          if (!acc.includes(curr.id)) {
            acc.push(curr.id)
            slotsNumber[curr.id] = 1
          } else {
            slotsNumber[curr.id] = slotsNumber[curr.id] + 1
          }

          return acc
        }, []) as number[])

      // @todo calc only if necessary
      const workShiftValidity = (slotId: number): null | boolean => {
        if (workshiftUniqueSlots && workshiftsValidity && workshiftsValidity.data) {
          const validSlot = workshiftsValidity.data.find(
            (e: { name: string; status: boolean }) => e.name === `PLANT_SHIFT${String(slotId).padStart(2, '0')}_ENAB`
          )
          if (validSlot && validSlot !== -1) {
            return validSlot.status
          }
        }
        return null
      }

      const maxSlots =
        slotsNumber &&
        slotsNumber.reduce((acc: number, curr: number) => {
          if (curr > acc) {
            acc = curr
          }
          return acc
        }, 0)

      repeatSlots = []

      for (let i = 0; i < maxSlots; i++) {
        repeatSlots.push(i)
      }

      if (fullDay && fullDay.totals) {
        wTotal = Object.keys(fullDay.totals)
          .filter(k => k && parseInt(k, 10) !== 0)
          .reduce((acc: number, curr: number | string) => {
            // @ts-ignore
            acc += parseInt(fullDay.totals[curr], 10)
            return acc
          }, 0)
      }

      workShiftSlotJSX =
        shiftsBudget &&
        workshiftUniqueSlots &&
        workshiftUniqueSlots.map((slot: number, id: number) => {
          return (
            <tr key={id}>
              <td className={'slot-name'} key={'slot'}>
                {slot}° {t('plantAnalysis.dailyTable.workshift')}
              </td>
              {repeatSlots.map((i: number, slotId: number) => {
                const slotData = slots && slots.filter(s => s.id === slot)

                if (slotData && slotData[slotId]) {
                  return (
                    !isDateFilterRange && (
                      <td
                        className={'slot-data'}
                        key={slotId}
                        data-start={slotData[slotId].start}
                        data-end={slotData[slotId].end}
                      >
                        {moment.unix(roundToMinutes(slotData[slotId].start)).format('HH:mm')} -{' '}
                        {moment.unix(roundToMinutes(slotData[slotId].end)).format('HH:mm')}
                      </td>
                    )
                  )
                }
                return !isDateFilterRange && <td className={'slot-data-ext'} key={slotId} />
              })}
              <td className={'slot-data-total'} key={'slotTotal'}>
                {// @ts-ignore
                (fullDay.totals && HHHmmssFromMilliseconds(roundToMinutes(fullDay.totals[slot], 1000))) || ''}
              </td>
              <td className={'slot-data-budget'} key={'slotBudget'}>
                {HHHmmssFromMilliseconds(roundToMinutes(timeToSeconds(shiftsBudget[id])) * 1000 * days)}
              </td>
              <td className={'slot-data-diff'} key={'slotDiff'}>
                {HHHmmssFromMilliseconds(
                  // @ts-ignore
                  ((fullDay.totals && roundToMinutes(fullDay.totals[slot], 1000)) || 0) -
                    roundToMinutes(timeToSeconds(shiftsBudget[id])) * 1000 * days
                )}
              </td>
              {!isDateFilterRange && (
                <td className={'slot-data-validity'} key={'slotValidity'}>
                  {workshiftsValidity &&
                  workshiftsValidity.data &&
                  !(workshiftsValidity.error || workshiftsValidity.fetching)
                    ? workShiftValidity(slot) === null
                      ? '--'
                      : workShiftValidity(slot)
                      ? 'OK'
                      : 'KO'
                    : '--'}
                </td>
              )}
            </tr>
          )
        })

      workshiftSlotsTotalTimeStr = HHHmmssFromMilliseconds(momentRoundTime(wTotal, 'minute').valueOf())

      totalWorkshiftDelta =
        (roundToMinutes(wTotal, 1000) / 1000 - plantStandardTime * days >= 0 ? '' : '-') +
        HHHmmssFromMilliseconds(
          moment
            .utc(
              moment
                .duration(Math.abs(roundToMinutes(wTotal, 1000) / 1000 - plantStandardTime * days), 's')
                .as('milliseconds')
            )
            .valueOf()
        )

      plantStandardTimeStr = HHHmmssFromMilliseconds(
        momentRoundTime(plantStandardTime * 1000 * days, 'minute').valueOf()
      )
    }

    const rppWidgetsConfig:
      | [
          {
            widgetName: string
            tableName: string
            hideSummaryTable?: boolean
            hideOnPrint: boolean
          }
        ]
      | undefined =
      this.isSectionEnabled('report-press') || this.isSectionEnabled('report-press-sg2')
        ? rppConfiguration && rppConfiguration.hasOwnProperty(plant.plant)
          ? rppConfiguration[plant.plant]
          : [
              {
                widgetName: 'REPORT PRESSA',
                tableName: 'reportPressa',
                hideOnPrint: false,
              },
            ]
        : undefined

    const isDayWorkshift = this.props.workshift?.value === 99
    return (
      <div className={'mv4iot-fe-plant-analysis plant-analysis-general ' + (lastScrollY > 48 ? 'is-scrolling' : '')}>
        <table className={'page-size'}>
          {get12TableHeadColumns()}
          <tbody>
            <TableRowWrapper>
              <TableColumnWrapper col={8}>
                <WithConditionWrapper condition={this.isSectionEnabled('plant-info')}>
                  <div className={'d-none d-print-block'}>
                    <h1 className="title mb-3">{t(`plantAnalysis.titles.${plant.plant}`)}</h1>
                  </div>
                </WithConditionWrapper>
                <WithConditionWrapper condition={this.isSectionEnabled('overview')}>
                  <>
                    <h1 className="title mb-3">{this.props.t('plantAnalysis.navigation.plantAnalysis')}</h1>

                    <ul className="top-details text-left row-orari">
                      <li>&nbsp;</li>
                      <li>
                        <strong>
                          {!isDateFilterRange &&
                            this.props.t(
                              'plantAnalysis.options.workshift.' + ((fullDay && fullDay.value) || 'undefined')
                            )}
                        </strong>
                      </li>
                    </ul>

                    <div className="daily-time-table">
                      <table className="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th className={'slot-header-name'} key={'h-1'} />
                            {!isDateFilterRange &&
                              repeatSlots.map((slot, i: number) => (
                                <th className={'slot-header-data'} key={i}>
                                  {i === 0 ? t('plantAnalysis.dailyTable.time') : ''}
                                </th>
                              ))}
                            <th className={'slot-header-real'} key={'h-2'}>
                              {t('plantAnalysis.dailyTable.realTime')}
                            </th>
                            <th className={'slot-header-budget'} key={'h-3'}>
                              {t('plantAnalysis.dailyTable.budgetTime')}
                            </th>
                            <th className={'slot-header-delta'} key={'h-4'}>
                              {t('plantAnalysis.dailyTable.deltaTime')}
                            </th>
                            {!isDateFilterRange && (
                              <th className={'slot-header-status'} key={'h-5'}>
                                {t('plantAnalysis.dailyTable.status')}
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {workShiftSlotJSX}
                          <tr key={'slot-bottom-totals'}>
                            <td className={'slot-total-name'} key={'w-1'} />
                            {!isDateFilterRange &&
                              repeatSlots.map((slot, n: number) => <td className={'slot-total-data'} key={n} />)}
                            <td className={'slot-header-real'} key={'w-2'}>
                              {workshiftSlotsTotalTimeStr}
                            </td>
                            <td className={'slot-header-budget'} key={'w-3'}>
                              {plantStandardTimeStr}
                            </td>
                            <td className={'slot-header-delta'} key={'w-4'}>
                              {totalWorkshiftDelta}
                            </td>
                            {!isDateFilterRange && <td className={'slot-header-status'} key={'w-5'} />}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                </WithConditionWrapper>
              </TableColumnWrapper>
              <TableColumnWrapper col={2}>
                <WithConditionWrapper condition={this.isSectionEnabled('assets')}>
                  <button className="btn btn-light d-print-none" onClick={this.handleOpenSettingsModal}>
                    <>
                      {!areNodesVisible && (
                        <div
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          style={{ transform: 'translate(-5px, -2px)' }}
                        >
                          <span className="sr-only">Select</span>
                        </div>
                      )}
                      {this.props.t('plantAnalysis.modal.showViewSettings')}
                    </>
                  </button>
                </WithConditionWrapper>
              </TableColumnWrapper>
              <TableColumnWrapper col={2}>
                <WorkshiftSelector
                  isRenderVisible={this.isSectionEnabled('overview') || this.isSectionEnabled('plant-info')}
                  hideWorkshiftSelector={this.props.plant.plant !== 'ric-sg'}
                  isRangeDatePicker={this.props.plant.plant !== 'ric-sg'}
                />

                <button
                  className={'btn btn-secondary mt-3 float-right action-print'}
                  onClick={PlantAnalysisGeneralPagePrint.printPage}
                >
                  {t('plantAnalysis.actions.print')}
                </button>
              </TableColumnWrapper>
            </TableRowWrapper>
            {
              <TableRowFullWrapperWithCondition condition={!this.layoutConfig}>
                <div className={'alert alert-warning w-100 alert-local text-center'}>
                  {t('plantAnalysis.noOverviewLayout')}
                </div>
              </TableRowFullWrapperWithCondition>
            }
            {this.isSectionEnabled('overview') && (
              <>
                <GeneralGraphsPrint
                  active={'general'}
                  component={{ id: 'general', label: 'general' }}
                  isNamedRecipesPlant={this.isSectionEnabled('named-recipes')}
                  showFullDay={true}
                  toggleCollapse={this.toggleCollapse}
                />
              </>
            )}
            <WithConditionWrapper condition={this.isSectionEnabled('recipe-recap-plant')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: this.props.t('plantAnalysis.recipeSlug.plant'), id: 'recipe-recap-plant' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                <RecipeRecapPrint
                  date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                  standardTime={plantStandardTime}
                  workShift={this.props.workshift}
                  installationSlug={'plant'}
                />
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('recipe-recap-u2-phase2')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: this.props.t('plantAnalysis.recipeSlug.u2-phase2'), id: 'recipe-recap-u2-phase2' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                <RecipeRecapPrint
                  date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                  standardTime={plantStandardTime}
                  workShift={this.props.workshift}
                  installationSlug={'u2-phase2'}
                />
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('named-recipes')}>
              <div className="mb-5" style={{ minHeight: 170 }}>
                <NamedRecipeGraphPrint
                  active={currentGraph}
                  component={{ id: 'named-recipes', label: 'RICETTE' }}
                  dateEnd={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T23:59:59.00Z`}
                  dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                  showFullDay={true}
                  toggleCollapse={this.toggleCollapse}
                />
              </div>
            </WithConditionWrapper>
            <WithConditionWrapper
              condition={
                (this.isSectionEnabled('report-press') && rppWidgetsConfig && Array.isArray(rppWidgetsConfig)) || false
              }
            >
              {rppWidgetsConfig?.map((rppCfg, i) => {
                /*const styleClass = `graph-wrapper page-break-inside-avoid ${rppCfg.hideOnPrint && 'graph-wrapper-hide'} ${firstElementInserted ? 'page-break-before-always' : ''}`*/
                return (
                  <>
                    <GraphWrapperPrint
                      active={currentGraph}
                      key={i}
                      component={{
                        label: rppCfg.widgetName,
                        id: `rpp`,
                      }} // @todo separate widget collapse, currentActive
                      toggleCollapse={this.toggleCollapse}
                      zeroPadding={true}
                    >
                      <WithConditionWrapper
                        condition={!isDateFilterRange && this.props.dateFilterStart !== null}
                        onError={ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
                      >
                        <RppGraphMainPrint
                          config={rppCfg}
                          dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                          dateEnd={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T23:59:59.00Z`}
                          plant={this.props.plant}
                          slotHours={HoursFromMilliseconds(wTotal)}
                        />
                      </WithConditionWrapper>
                    </GraphWrapperPrint>
                  </>
                )
              })}
            </WithConditionWrapper>
            <WithConditionWrapper
              condition={
                (this.isSectionEnabled('report-press-sg2') && rppWidgetsConfig && Array.isArray(rppWidgetsConfig)) ||
                false
              }
            >
              {rppWidgetsConfig?.map((rppCfg, i) => (
                <>
                  <GraphWrapperPrint
                    key={i}
                    active={currentGraph}
                    component={{
                      label: rppCfg.widgetName,
                      id: `rpp-${i}`,
                    }} // @todo separate widget collapse, currentActive
                    toggleCollapse={this.toggleCollapse}
                    zeroPadding={true}
                  >
                    {!isDateFilterRange && this.props.dateFilterStart ? (
                      <RppGraphMainSG2Print
                        config={rppCfg}
                        dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                        dateEnd={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T23:59:59.00Z`}
                        plant={this.props.plant}
                        slotHours={24} // @TODO temporary, get from real slot
                      />
                    ) : (
                      ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))
                    )}
                  </GraphWrapperPrint>
                </>
              ))}
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('halts')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'FERMI', id: 'halts' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                <PlantHaltsPrint standardTime={plantStandardTime} />
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('plantState')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'STATO IMPIANTO', id: 'plantState' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                <PlantStatusPrint
                  date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                  standardTime={plantStandardTime}
                  workShift={this.props.workshift}
                />
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('vecoplant')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'CARICAMENTO', id: 'vecoplant' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                <PlantHaltsShiftPrint
                  standardTime={plantStandardTime}
                  workshift={this.props.workshift}
                  zones={['Load']}
                />
                <VecoplanPrint
                  date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                  standardTime={plantStandardTime}
                  workShift={this.props.workshift}
                />
                <UnthaPrint
                  date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                  standardTime={plantStandardTime}
                  workShift={this.props.workshift}
                />
                {/* <AverageDistribution
                    date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                    standardTime={plantStandardTime}
                    workShift={this.props.workshift}
                  /> */}
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('lines')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'LINEE', id: 'lines' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                <WithConditionWrapper condition={this.isSectionEnabled('lines-1')}>
                  <PlantHaltsShiftPrint
                    standardTime={plantStandardTime}
                    workshift={this.props.workshift}
                    zones={['Line01', 'Line02', 'Line05', 'Line03', 'Line04',]}
                  />
                </WithConditionWrapper>
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('lines-2')}>
              <LineBufferPrint
                date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                standardTime={plantStandardTime}
                workShift={this.props.workshift}
              />
              {/* <WithConditionWrapper condition={isDayWorkshift}>
                <BufferStatePrint
                  date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                  standardTime={plantStandardTime}
                  workShift={this.props.workshift}
                />
              </WithConditionWrapper> */}
              <LoadingBeltPrint
                date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                standardTime={plantStandardTime}
                workShift={this.props.workshift}
              />
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('lines-3')}>
              <EngineTorqueDensifierPrint
                date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                standardTime={plantStandardTime}
                workShift={this.props.workshift}
              />
              <EngineSpeedDensifierPrint 
                date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                standardTime={plantStandardTime}
                workShift={this.props.workshift}
              />
              <MillsPrint
                date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                standardTime={plantStandardTime}
                workShift={this.props.workshift}
              />
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('scrubber')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'SCRUBBER', id: 'scrubber' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                <ScrubberStatusPrint
                  date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                  standardTime={plantStandardTime}
                  workShift={this.props.workshift}
                />
                <WithConditionWrapper condition={isDayWorkshift}>
                  <LiterPerHourTrendPrint
                    date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                    standardTime={plantStandardTime}
                    workShift={this.props.workshift}
                  />
                </WithConditionWrapper>
              </GraphWrapperPrint>
              {/*<WithConditionWrapper condition={this.isSectionEnabled('halts-filtered')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'FERMI', id: 'halts-filtered' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                 TODO Cambia in print
                <TabbedWrapper
                  loading={this.state.isLoadingProcessedMaterial}
                  changedKey={
                    this.props.dateFilterStart.toString() + this.props.dateFilterEnd.toString() + this.props.plant
                  }
                  tabs={[
                    {
                      label: this.props.t('plantAnalysis.halts.processing.general'),
                      forceEnabled: true,
                      component: (
                        <IdealPlantHalts
                          isDateFilterRange={isDateFilterRange}
                          showTimeline={true}
                          standardTime={plantStandardTime}
                          hiddenCharts={
                            (plantHaltsConfiguration && plantHaltsConfiguration.hiddenGeneralCharts) || []
                          }
                        />
                      ),
                    },
                  ].concat(
                    plantHaltsConfiguration?.processings?.filter(p => this.state.dataProcessedMaterial.includes(p.toString())).map(p => ({
                      label: this.props.t('plantAnalysis.halts.processing.' + p, {
                        defaultValue: 'Lavorazione ' + p,
                      }),
                      forceEnabled: false,
                      component: (
                        <IdealPlantHalts
                          isDateFilterRange={isDateFilterRange}
                          standardTime={plantStandardTime}
                          processing={p}
                          hiddenCharts={(plantHaltsConfiguration && plantHaltsConfiguration.hiddenCharts) || []} />
                      ),
                    })) ?? [],
                  )}
                />
              </GraphWrapperPrint>
              {(firstElementInserted = true)}
            </WithConditionWrapper>*/}
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('titech')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'TITECH', id: 'titech' }}
                toggleCollapse={this.toggleCollapse}
                isMulti={true}
                zeroPadding={true}
              >
                <WithConditionWrapper
                  condition={!isDateFilterRange}
                  onError={ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
                >
                  <TitechPrint plant={this.props.plant} />
                </WithConditionWrapper>
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('brt')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'APRISACCHI', id: 'aprisacchi' }}
                toggleCollapse={this.toggleCollapse}
              >
                {!isDateFilterRange && mergedData && !!mergedData[DataToFetch.PLANT_DATA_BRT] ? (
                  <BagGraphMainPrint
                    data={mergedData?.[DataToFetch.PLANT_DATA_BRT]}
                    filteredData={this.state.filteredData[DataToFetch.PLANT_DATA_BRT]}
                  />
                ) : (
                  ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))
                )}
                <WithConditionWrapper condition={this.props.dateFilterStart && cutOffHour}>
                  <BagSpeedDistributionGeneralPrint
                    measure="vDailyBrt"
                    dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T${cutOffHour}:00Z`}
                    dateEnd={`${moment(this.props.dateFilterEnd).format('YYYY-MM-DD')}T${cutOffHour}:00Z`}
                    plant={this.props.plant}
                    days={days}
                  />
                </WithConditionWrapper>
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('brt3081')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'U308-1', id: 'brt3081' }}
                toggleCollapse={this.toggleCollapse}
              >
                {!isDateFilterRange && mergedData && !!mergedData[DataToFetch.U3081] ? (
                  <BagGraphMainPrint
                    data={mergedData?.[DataToFetch.U3081]}
                    filteredData={this.state.filteredData[DataToFetch.U3081]}
                  />
                ) : (
                  ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))
                )}
                <WithConditionWrapper condition={this.props.dateFilterStart && cutOffHour}>
                  <BagSpeedDistributionGeneralPrint
                    measure="vDailyBrt3081"
                    dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T${cutOffHour}:00Z`}
                    dateEnd={`${moment(this.props.dateFilterEnd).format('YYYY-MM-DD')}T${cutOffHour}:00Z`}
                    plant={this.props.plant}
                    days={days}
                  />
                </WithConditionWrapper>
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('brt3082')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'U308-2', id: 'brt3082' }}
                toggleCollapse={this.toggleCollapse}
              >
                {!isDateFilterRange && mergedData && !!mergedData[DataToFetch.U3082] ? (
                  <BagGraphMainPrint
                    data={mergedData?.[DataToFetch.U3082]}
                    filteredData={this.state.filteredData[DataToFetch.U3082]}
                  />
                ) : (
                  ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))
                )}
                <WithConditionWrapper condition={this.props.dateFilterStart && cutOffHour}>
                  {/* TODO Cambia in print  */}
                  <BagSpeedDistributionGeneralPrint
                    measure="vDailyBrt3082"
                    dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T${cutOffHour}:00Z`}
                    dateEnd={`${moment(this.props.dateFilterEnd).format('YYYY-MM-DD')}T${cutOffHour}:00Z`}
                    plant={this.props.plant}
                    days={days}
                  />
                </WithConditionWrapper>
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('bearings')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: t('plantAnalysis.bearings.title'), id: 'bearings' }}
                toggleCollapse={this.toggleCollapse}
              >
                <WithConditionWrapper
                  onError={ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
                  condition={!isDateFilterRange}
                >
                  <BearingsGraphMainPrint
                    dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                    dateEnd={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T23:59:59.00Z`}
                    plant={this.props.plant}
                    days={days}
                  />
                </WithConditionWrapper>
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('motorAbsorption')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'ASSORBIMENTO MOTORI', id: 'motorAbsorption' }}
                toggleCollapse={this.toggleCollapse}
              >
                <WithConditionWrapper condition={this.props.dateFilterStart && cutOffHour}>
                  <MotorAbsorptionPrint
                    plant={this.props.plant}
                    dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T${cutOffHour}:00Z`}
                    dateEnd={`${moment(this.props.dateFilterEnd).format('YYYY-MM-DD')}T${cutOffHour}:00Z`}
                  />
                </WithConditionWrapper>
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('aspiratedArea')}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'ZONA ASPIRATO', id: 'aspiratedArea' }}
                toggleCollapse={this.toggleCollapse}
              >
                <WithConditionWrapper
                  condition={!isDateFilterRange}
                  onError={ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
                >
                  <AspiratedAreaGraphRowPrint
                    dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                    dateEnd={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T23:59:59.00Z`}
                    plant={this.props.plant}
                    days={days}
                  />
                </WithConditionWrapper>
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('ventilator') && this.props.workshift?.value === 99}>
              <GraphWrapperPrint
                active={currentGraph}
                component={{ label: 'VENTILATORI', id: 'ventilator' }}
                toggleCollapse={this.toggleCollapse}
              >
                <WithConditionWrapper
                  condition={!isDateFilterRange}
                  onError={ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
                >
                  <AspiratedAreaGraphRowCustomPrint
                    // date={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                    dateStart={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T00:00:00.00Z`}
                    dateEnd={`${moment(this.props.dateFilterStart).format('YYYY-MM-DD')}T23:59:59.00Z`}
                    plant={this.props.plant}
                    workShift={this.props.workshift}
                    days={days}
                  />
                </WithConditionWrapper>
                *
              </GraphWrapperPrint>
            </WithConditionWrapper>
            <WithConditionWrapper condition={this.isSectionEnabled('assets')}>
              <WithConditionWrapper
                condition={areNodesVisible !== undefined && areNodesVisible}
                onError={
                  <TableRowFullWrapper>
                    <div className="alert alert-warning w-100 text-center">
                      {this.props.t('plantAnalysis.noNodesSelected')}
                    </div>
                  </TableRowFullWrapper>
                }
              >
                <WithConditionWrapper
                  condition={
                    nodes?.filter(
                      (e: any) => SectionNodeTypes?.aspiratori.includes(e?.nodeType) && configuration?.nodes?.[e?.code]
                    ).length > 0
                  }
                >
                  <GraphWrapperPrint
                    active={currentGraph}
                    component={{ label: 'VENTILATORI/ASPIRATORI', id: 'aspiratori' }}
                    toggleCollapse={this.toggleCollapse}
                    isMulti={true}
                  >
                    <FanGraphRowPrint
                      t={true}
                      measures={['']}
                      component={{}}
                      data={dataElapsed}
                      dataCurrent={dataCurrent}
                      isFetching={isLoadingElapsed}
                      fetchError={fetchErrorsElapsed}
                      configuration={configuration}
                      days={days}
                    />
                  </GraphWrapperPrint>
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={
                    nodes.filter(
                      (e: any) => SectionNodeTypes?.nastri.includes(e?.nodeType) && configuration?.nodes?.[e?.code]
                    ).length > 0
                  }
                >
                  <GraphWrapperPrint
                    active={currentGraph}
                    component={{ label: 'NASTRI', id: 'nastri' }}
                    toggleCollapse={this.toggleCollapse}
                    isMulti={true}
                  >
                    <BeltGraphRowPrint
                      t={true}
                      measures={['']}
                      component={{}}
                      data={dataElapsed}
                      dataCurrent={dataCurrent}
                      isFetching={isLoadingElapsed}
                      fetchErrors={fetchErrorsElapsed}
                      configuration={configuration}
                      days={days}
                    />
                  </GraphWrapperPrint>
                </WithConditionWrapper>
                <WithConditionWrapper
                  condition={
                    nodes?.filter(
                      (e: any) => SectionNodeTypes?.bunker.includes(e?.nodeType) && configuration?.nodes?.[e?.code]
                    ).length > 0
                  }
                >
                  <GraphWrapperPrint
                    active={currentGraph}
                    component={{ label: 'BUNKER', id: 'bunker' }}
                    toggleCollapse={this.toggleCollapse}
                    isMulti={true}
                  >
                    <WithConditionWrapper
                      condition={!isDateFilterRange}
                      onError={ChartPlaceholder(this.props.t('plantAnalysis.invalidDateRangeForThisChart'))}
                    >
                      <BunkerGraphHeaderPrint data={this.state.dataBunker} />
                    </WithConditionWrapper>
                    <BunkerGraphRowPrint
                      t={true}
                      days={days}
                      measures={['']}
                      component={{}}
                      data={dataElapsed}
                      isFetching={isLoadingElapsed}
                      fetchErrors={fetchErrorsElapsed}
                      configuration={configuration}
                    />
                  </GraphWrapperPrint>
                </WithConditionWrapper>
              </WithConditionWrapper>
            </WithConditionWrapper>
          </tbody>
        </table>
      </div>
    )
  }

  private updateLayoutConfig(plant?: string): void {
    this.layoutConfig =
      this.props.overviewConfig &&
      this.props.plant &&
      this.props.plant.plant &&
      this.props.overviewConfig[plant || this.props.plant.plant]
  }

  private isSectionEnabled(
    section:
      | 'overview'
      | 'report-press'
      | 'report-press-sg2'
      | 'halts'
      | 'halts-filtered'
      | 'titech'
      | 'brt'
      | 'brt3081'
      | 'brt3082'
      | 'assets'
      | 'motorAbsorption'
      | 'bearings'
      | 'aspiratedArea'
      | 'named-recipes'
      | 'vecoplant'
      | 'ventilator'
      | 'lines'
      | 'scrubber'
      | 'plant-info'
      | 'plantState'
      | 'lines-1'
      | 'lines-2'
      | 'recipe-recap'
      | 'recipe-recap-plant'
      | 'recipe-recap-u2-phase2'
      | 'lines-3'
  ): boolean {
    const isInLayoutConfig = !!(this.layoutConfig && this.layoutConfig.includes(section))

    if (this.state.puppeteerSectionsEnable) {
      const condition = Array.isArray(this.state.puppeteerSectionsEnable)
        ? this.state.puppeteerSectionsEnable.find(v => v === section)
        : this.state.puppeteerSectionsEnable === section
      return isInLayoutConfig && condition ? true : false
    }
    return isInLayoutConfig
  }

  private getAllData() {
    const localProps = this.props
    const localState = this.state

    if (this.mounted && localProps.model && !localProps.model.fetching && !localProps.model.error) {
      if (this.isSectionEnabled('overview') && localProps.fullDay) {
        this.props.generalFetchData(localProps.fullDay)
      }

      if (this.isSectionEnabled('assets')) {
        if (!localState.isLoading && !localProps.isDateFilterRange) {
          this.getData(localProps)
        }

        if (!localState.isLoadingElapsed) {
          this.getDataElapsed(localProps)
        }

        if (!localState.isLoadingBunker) {
          this.getDataBunker(localProps)
        }

        if (!localState.isLoadingCurrent) {
          this.getDataMeanCurrent(localProps)
        }
      }

      if (this.isSectionEnabled('halts-filtered')) {
        this.getDataHaltsProcessedMaterial(localProps)
      }
    }
  }

  private getConfiguration(plant?: string, props?: Props) {
    const localProps = props || this.props

    return localProps.configurations &&
      localProps.configurations.data &&
      localProps.configurations.data[plant || localProps.plant.plant]
      ? localProps.configurations.data[plant || localProps.plant.plant]
      : {} // defaultPlantAnalysisGeneralSettings
  }

  private handleCloseSettingsModal() {
    this.setState({
      modalIsVisible: false,
    })
  }

  private handleSaveSettingsModal(configuration: PlantAnalysisGeneralSettings) {
    this.setState({
      modalIsVisible: false,
    })
  }

  private handleOpenSettingsModal() {
    this.setState({
      modalIsVisible: true,
    })
  }

  private toggleCollapse(id: string) {
    /* @todo tutti apribili */
    const state = { ...this.state }

    toggleArrayItem(id, state.currentGraph)

    this.setState({
      currentGraph: state.currentGraph,
    })
  }

  private static printPage() {
    window.print()
  }

  private async getData(props: Props) {
    if (!this.state.isLoading && props && props.fullDay) {
      this.setState({ isLoading: true, fetchErrors: false })
      try {
        const startDateObj = moment(props.fullDay.start)
        const startDate = startDateObj
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()
        const endDateObj = moment(props.fullDay.end)
        const endDate = endDateObj
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()

        Object.entries(dataToFetch).map(async ([key, value]) => {
          if (props.model && props.model.data) {
            const aprisacco = flatData(props.model.data.model.content).filter(n => n[value.propsToSeach] === key)
            // const aprisacco = flatData(props.model.data.model.content).filter(n => n.id === 'IBREMLBUDQDI1LXY_WS_SET_SPD')
            if (aprisacco && aprisacco.length === 1) {
              const measureLabel = aprisacco[0].id
              const plantQuery = props.plant && props.plant.plant ? 'plant=' + props.plant.plant + '&' : ''

              const queryStart = `SELECT "measure" FROM "`
              const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`

              const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
              const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`

              const queryPerf = queryStart + measureLabel + `${value.measureType}` + queryEnd
              const queryMarcia = queryStart + 'PLANT_IS_RUNNING' + queryEnd
              const queryLastPerf = queryLastStart + measureLabel + `${value.measureType}` + queryLastEnd
              const queryLastMarcia = queryLastStart + 'PLANT_IS_RUNNING' + queryLastEnd

              const dataPerfSrc = await API().request(`/query?${plantQuery}q=` + queryPerf, {
                signal: this.abortController.signal,
              })
              const dataMarciaSrc = await API().request(`/query?${plantQuery}q=` + queryMarcia, {
                signal: this.abortController.signal,
              })
              const dataLastPerfSrc = await API().request(`/query?${plantQuery}q=` + queryLastPerf, {
                signal: this.abortController.signal,
              })
              const dataLastMarciaSrc = await API().request(`/query?${plantQuery}q=` + queryLastMarcia, {
                signal: this.abortController.signal,
              })

              Promise.all([dataPerfSrc, dataMarciaSrc, dataLastPerfSrc, dataLastMarciaSrc])
                .then(() => {
                  if (this.mounted) {
                    this.setState(prev => ({
                      data: Object.assign({}, this.state.data, {
                        ...prev.data,
                        [key]: {
                          performance: { data: parseResponseData(dataPerfSrc) },
                          marcia: { data: parseResponseData(dataMarciaSrc) },
                        },
                      }),
                      last: Object.assign({}, this.state.data, {
                        ...prev.last,
                        [key]: {
                          performance: parseResponseSingleData(dataLastPerfSrc),
                          marcia: parseResponseSingleData(dataLastMarciaSrc),
                        },
                      }),
                      startDate: startDateObj.unix(),
                      endDate: endDateObj.unix(),
                    }))

                    this.prepareData(key)
                  }
                })
                .catch((error: any) => {
                  if (error.name === 'FetchError' && error.statusCode === 401) {
                    logoutUser()
                  }
                  if (this.mounted) {
                    this.setState({
                      isLoading: false,
                      fetchErrors: true,
                    })
                  }
                })
            } else {
              this.setState({
                isLoading: false,
                fetchErrors: false,
                data: {},
                last: {},
              })
            }
          } else {
            this.setState({
              isLoading: false,
              fetchErrors: false,
            })
          }
        })
      } catch (error:any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        if (this.mounted) {
          this.setState({
            isLoading: false,
            fetchErrors: true,
          })
        }
      }
    }
  }

  private populateSingleData(key: string, keySlug: string, isBoolean: boolean = false) {
    const stateData = { ...this.state.data }

    if (stateData && stateData[keySlug][key] && stateData[keySlug][key].data) {
      return stateData[keySlug][key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[keySlug][key].min === 0 || time < stateData[keySlug][key].min) {
          stateData[keySlug][key].min = time
        }
        if (time > stateData[keySlug][key].max) {
          stateData[keySlug][key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : datum[1],
          h100: 100,
        }
      })
    }

    return {}
  }

  private prepareData(slug: string) {
    try {
      let performance: any = []
      let marcia: any = []
      const mergedData: any[] = []

      if (this.state.data) {
        performance = this.populateSingleData('performance', slug)
        marcia = this.populateSingleData('marcia', slug, true)

        hydrateData(
          {
            performance,
            marcia,
          },
          mergedData,
          'marcia',
          0
        )

        mergedData.sort((a, b) => {
          if (a.time < b.time) {
            return -1
          }
          if (a.time > b.time) {
            return 1
          }
          return 0
        })

        hydrateTimeData(['performance', 'marcia', 'warnArea'], mergedData, this.state)
        populateManAutoLocSec(mergedData)
        fillWarnArea(mergedData, 'marcia', 0)

        if (this.mounted) {
          this.setState(prev => ({
            isLoading: false,
            fetchErrors: false,
            brush1StartInd: {
              ...prev.brush1StartInd,
              [slug]: 0,
            },
            brush1EndInd: {
              ...prev.brush1EndInd,
              [slug]: mergedData.length - 1,
            },
            mergedData: {
              ...prev.mergedData,
              [slug]: mergedData,
            },
            filteredData: {
              ...prev.filteredData,
              [slug]: mergedData.slice(0, mergedData.length - 1),
            },
          }))
        }
      }
    } catch (err) {
      if (this.mounted) {
        this.setState({
          isLoading: false,
          fetchErrors: true,
        })
      }
    }
  }

  private getActiveNodes(props: Props) {
    const localConfiguration = this.getConfiguration(props.plant.plant, props)
    return localConfiguration && localConfiguration.nodes
      ? Object.keys(localConfiguration.nodes).filter(e => localConfiguration.nodes[e])
      : []
  }

  private async getDataElapsed(props: Props) {
    const dateStart = moment.isMoment(props.dateFilterStart) ? props.dateFilterStart : moment(props.dateFilterStart)
    const dateEnd = moment.isMoment(props.dateFilterEnd) ? props.dateFilterEnd : moment(props.dateFilterEnd)
    // const dateFilterStart1 = date.clone()
    // const dateFilterEnd1 = date.clone()

    this.setState({
      isLoadingElapsed: true,
      fetchErrorsElapsed: false,
    })

    const startDate1 = dateStart
      .set({ h: 0, m: 0 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()
    const endDate1 = dateEnd
      .set({ h: 23, m: 59 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()

    const activeNodes = this.getActiveNodes(props)

    const nodeTypeMetrics = {
      PLANT_DATA_ASP: [
        'vAspiratorAuto',
        'vAspiratorManual',
        'vAspiratorLocal',
        'vAspiratorSectioned',
        'vAspiratorStop',
        'vAspiratorBypass',
      ],
      PLANT_DATA_ASP_INVERTER: [
        'vAspiratorAuto',
        'vAspiratorManual',
        'vAspiratorLocal',
        'vAspiratorSectioned',
        'vAspiratorStop',
        'vAspiratorBypass',
      ],
      PLANT_DATA_BLT: ['vBeltForward', 'vBeltAuto', 'vBeltLocal', 'vBeltManual', 'vBeltSectioned', 'vBeltStop'],
      PLANT_DATA_BELT: ['vBeltForward', 'vBeltAuto', 'vBeltLocal', 'vBeltManual', 'vBeltSectioned', 'vBeltStop'],
      PLANT_DATA_ROT_SCR: ['vBeltForward', 'vBeltAuto', 'vBeltLocal', 'vBeltManual', 'vBeltSectioned', 'vBeltStop'],
      PLANT_DATA_BELT_INVERTER: [
        'vBeltForward',
        'vBeltAuto',
        'vBeltLocal',
        'vBeltManual',
        'vBeltSectioned',
        'vBeltStop',
      ],
      PLANT_DATA_ROT_SCR_INVERTER: [
        'vBeltForward',
        'vBeltAuto',
        'vBeltLocal',
        'vBeltManual',
        'vBeltSectioned',
        'vBeltStop',
      ],
      PLANT_DATA_BNK_INVERTER: [
        'vBunkerAuto',
        'vBunkerManual',
        'vBunkerLocal',
        'vBunkerSectioned',
        'vBunkerFull',
        'vBunkerBypass',
      ],
      PLANT_DATA_BNK: [
        'vBunkerAuto',
        'vBunkerManual',
        'vBunkerLocal',
        'vBunkerSectioned',
        'vBunkerFull',
        'vBunkerBypass',
      ],
    }

    const tables: string[] = [
      'vAspiratorAuto',
      'vAspiratorManual',
      'vAspiratorLocal',
      'vAspiratorSectioned',
      'vAspiratorStop',
      'vAspiratorBypass',
      'vBeltForward',
      'vBeltAuto',
      'vBeltLocal',
      'vBeltManual',
      'vBeltSectioned',
      'vBeltStop',
      'vBunkerAuto',
      'vBunkerManual',
      'vBunkerLocal',
      'vBunkerSectioned',
      'vBunkerFull',
      'vBunkerBypass',
    ]

    const nodes =
      props.model && activeNodes
        ? flatData(props.model.data.model.content).filter(n => activeNodes && activeNodes.includes(n.code))
        : []

    const queryStartFiltered = (type: string): string | boolean => {
      const validNodeTypes = nodes
        .filter(n => {
          return nodeTypeMetrics[n.nodeType] && nodeTypeMetrics[n.nodeType].includes(type)
        })
        .map(n => n.code)

      return validNodeTypes.length > 0
        ? `SELECT "${validNodeTypes
            .map(n => [n, `${n}_duration`, `${n}_remaining_time`])
            .reduce((acc, curr) => [...acc, ...curr], [])
            .join('","')}" FROM `
        : false
    }

    const queryEnd = ` WHERE time >= '${startDate1}' AND time <= '${endDate1}' `
    const plantQuery = props.plant && props.plant.plant ? 'plant=' + props.plant.plant + '&' : ''

    const queries: string[] = []
    let dataElapsed: any = null

    try {
      tables.forEach(table => {
        const queryStart = queryStartFiltered(table)
        if (queryStart !== false) {
          queries.push(`/query?${plantQuery}q=` + queryStartFiltered(table) + ' ' + table + ' ' + queryEnd)
        }
      })
      dataElapsed = await Promise.all(
        queries.map(async query => {
          const data = await API().request(query, { signal: this.abortController.signal })
          return parseInfluxResponseData(data)
        })
      )
      if (this.mounted) {
        this.setState({
          dataElapsed,
          isLoadingElapsed: false,
          fetchErrorsElapsed: false,
        })
      }
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }

      console.log(error) // tslint:disable-line
      this.setState({
        isLoadingElapsed: false,
        fetchErrorsElapsed: true,
      })
    }
  }

  private async getDataMeanCurrent(props: Props) {
    const dateStart = moment.isMoment(props.dateFilterStart) ? props.dateFilterStart : moment(props.dateFilterStart)
    const dateEnd = moment.isMoment(props.dateFilterEnd) ? props.dateFilterEnd : moment(props.dateFilterEnd)

    this.setState({
      isLoadingCurrent: true,
      fetchErrorsCurrent: false,
    })

    const startDate1 = dateStart
      .set({ h: 0, m: 0 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()
    const endDate1 = dateEnd
      .set({ h: 23, m: 59 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()

    const queryStart = `SELECT mean(measure) as current FROM `
    const queryEnd = ` WHERE time >= '${startDate1}' AND time <= '${endDate1}' `

    try {
      const plantQuery = props.plant && props.plant.plant ? 'plant=' + props.plant.plant + '&' : ''
      const data = await API().request(
        `/query?${plantQuery}q=` + queryStart + ' /(' + this.getActiveNodes(props).join('|') + ')/' + queryEnd,
        { signal: this.abortController.signal }
      )

      if (this.mounted) {
        this.setState({
          dataCurrent: parseInfluxResponseData(data),
          isLoadingCurrent: false,
          fetchErrorsCurrent: false,
        })
      }
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoadingCurrent: false,
        fetchErrorsCurrent: true,
      })
    }
  }

  private async getDataBunker(props: Props) {
    const dateEnd = moment.isMoment(props.dateFilterEnd) ? props.dateFilterEnd : moment(props.dateFilterEnd)

    this.setState({
      isLoadingBunker: true,
      fetchErrorsBunker: false,
    })

    const endDate1 = dateEnd
      .set({ h: 23, m: 59 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()

    // const queryGateway =

    // if(this.props.isWithGateway) {
    //   data = await genericMeasureFetch(chartName, startDate1, endDate1, props.plant.plant, 'influxResponse')
    //   dataProcessedMaterialSrc = [data]
    // }

    const queryStart = `SELECT last(measure) as measure FROM `
    const queryEnd = ` WHERE time <= '${endDate1}' LIMIT 1`
    const plantQuery = props.plant && props.plant.plant ? 'plant=' + props.plant.plant + '&' : ''

    const tables: string[] = [
      'LAST_BUK_CLEAN1',
      'LAST_BUK_CLEAN2',
      'LAST_BUK_CLEAN3',
      'LAST_BUK_GATE_OPN1',
      'LAST_BUK_GATE_OPN2',
      'LAST_BUK_GATE_OPN3',
    ]
    const queries: string[] = []
    let dataBunker: any = null

    try {
      tables.forEach(table => {
        queries.push(`/query?${plantQuery}q=` + queryStart + ' ' + table + ' ' + queryEnd)
      })

      // genericMeasureFetch(chartName, props.plant.plant, 'influxResponse' startDate1, endDate1 )

      dataBunker = await Promise.all(
        queries.map(async query => {
          const data = await API().request(query, { signal: this.abortController.signal })
          return parseInfluxResponseData(data)
        })
      )

      if (this.mounted) {
        this.setState({
          dataBunker,
          isLoadingBunker: false,
          fetchErrorsBunker: false,
        })
      }
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoadingBunker: false,
        fetchErrorsBunker: true,
      })
    }
  }

  private async getDataHaltsProcessedMaterial(props: Props) {
    const dateStart = moment.isMoment(props.dateFilterStart) ? props.dateFilterStart : moment(props.dateFilterStart)
    const dateEnd = moment.isMoment(props.dateFilterEnd) ? props.dateFilterEnd : moment(props.dateFilterEnd)

    const startDate1 = dateStart
      .set({ h: 0, m: 0 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()
    const endDate1 = dateEnd
      .set({ h: 23, m: 59 })
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()

    const queryStart = `SELECT measure FROM `
    const queryEnd = ` WHERE time >= '${startDate1}' AND time <= '${endDate1}' `
    const plantQuery = props.plant && props.plant.plant ? 'plant=' + props.plant.plant + '&' : ''

    const dataProcessedMaterial: string[] = []
    let dataProcessedMaterialSrc:
      | null
      | undefined
      | Array<{ columns: string[]; name: string; values: string[][] }> = null

    try {
      if (
        this.props.plantHaltsConfiguration &&
        this.props.plantHaltsConfiguration.processings &&
        this.props.plantHaltsConfiguration.processings.length > 0
      ) {
        this.setState({
          isLoadingProcessedMaterial: true,
          fetchErrorsProcessedMaterial: false,
        })

        let data: any

        const chartName = 'vDailyProcessedMaterial'

        if (this.props.isWithGateway) {
          data = await genericMeasureFetch(chartName, props.plant.plant, 'influxResponse', startDate1, endDate1)
          dataProcessedMaterialSrc = [data]
        } else {
          data = await API().request(`/query?${plantQuery}q=` + queryStart + ` ${chartName} ` + queryEnd, {
            signal: this.abortController.signal,
          })
          dataProcessedMaterialSrc = parseInfluxResponseData(data)
        }

        if (dataProcessedMaterialSrc && dataProcessedMaterialSrc.length > 0) {
          dataProcessedMaterialSrc[0].values.forEach(v => {
            if (v[1]) {
              v[1].split(',').forEach((processing: string) => {
                if (
                  !dataProcessedMaterial.includes(processing) &&
                  this.props.plantHaltsConfiguration?.processings?.includes(parseInt(processing, 10))
                ) {
                  dataProcessedMaterial.push(processing)
                }
              })
            }
          })
        }

        if (this.mounted) {
          this.setState({
            dataProcessedMaterial,
            isLoadingProcessedMaterial: false,
            fetchErrorsProcessedMaterial: false,
          })
        }
      }
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoadingBunker: false,
        fetchErrorsBunker: true,
      })
    }
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PlantAnalysisGeneralPagePrint))
)
