import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useGenericFetchHook from '../../../../../inplant-components-fe/mvfunctions/hooks/useGenericFetchHook'
import { useStateValidity } from '../../../../../inplant-components-fe/mvfunctions/hooks/useGenericInputHook'
import { useComponentsTranslation } from '../../../../../inplant-components-fe/services/translation'
import Wrapper from '../../../../../inplant-components-fe/ui/components/Layout/Wrapper'
import Alert from '../../../../../inplant-components-fe/ui/components/MVAlert/Alert'
import Button from '../../../../../inplant-components-fe/ui/components/MVButtons/Button'
import Flex, { Direction } from '../../../../../inplant-components-fe/ui/components/MVFlex/Flex'
import Input from '../../../../../inplant-components-fe/ui/components/MVInput/Input'
import { LinkableText } from '../../../../../inplant-components-fe/ui/components/MVText/Text'
// import Text from '../../../../../inplant-components-fe/ui/components/MVText/Text'
import { popupNotification } from '../../../../../inplant-coreadapter-fe/functions/notification'
import { getSlugByIndex } from '../../../../../mvlabs-components-fe/functions/getSlug'
import { getPasswordControlByConfiguration } from '../../../../functions/controlsHelpers'
import { getResetPassword, sendResetPassword } from '../../../../redux/actions/resetPassword'
import { PasswordOptionType } from '../../../../types/options'

interface FormData {
  password: string
  confirmPassword: string
}

const ResetPassword = () => {
  const { t } = useComponentsTranslation()
  const [formData, setFormData] = useState<FormData>({ password: '', confirmPassword: '' })
  const [fetching, setFetching] = useState<boolean>(false)

  const base = 'user.resetPassword'
  const history = useHistory()
  const dispatch = useDispatch()
  const [, addDataValidity, isValid] = useStateValidity({})

  const passwordOption = useGenericFetchHook<PasswordOptionType>(
    getResetPassword
  )

  const [errorToShow, setErrorToShow] = useState<{ error: string | undefined }>({ error: undefined })
  useEffect(() => {
    if (formData.password !== formData.confirmPassword) {
      setErrorToShow({
        error: t(`user.noMatchConfirmPassword`),
      })
    } else {
      setErrorToShow({
        error: undefined,
      })
    }
  }, [formData.password, formData.confirmPassword])

  async function handleResetPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setFetching(true)
    sendResetPassword(
      getSlugByIndex(3),
      formData
    )(dispatch)
      .then(result => {
        popupNotification({
          type: 'success',
          title: t(`${base}.successTitle`),
          content: t(`${base}.success`),
        })
        history.push('/')
      })
      .catch(error => {
        if (error.name === 'FetchError' && error.statusCode === 404) {
          popupNotification({
            type: 'error',
            title: t(`${base}.tokenExpiredTitle`),
            content: t(`${base}.tokenExpired`),
          })
        }
      })
      .finally(() => setFetching(false))
  }

  return (
    <div className="inplant-login">
      <Wrapper customType="loginForm">
        {passwordOption.state.kind === 'success' && (
          <form onSubmit={handleResetPassword}>
            <img src={'/logo-login.png'} className="img-fluid mb-4" alt={''} />
            <Flex direction={Direction.column} spaceSize={'lg'}>
              <Alert text={t(`${base}.info`)} variant={'info'} />
              <Flex direction={Direction.column}>
                <Input
                  label={t(`${base}.password`)}
                  type={'password'}
                  kind={'input'}
                  placeholder={t(`${base}.passwordPlaceholder`)}
                  onChange={value => setFormData(prev => ({ ...prev, password: value }))}
                  required={true}
                  min={formData.confirmPassword}
                  controls={getPasswordControlByConfiguration(passwordOption.state.data.controls, t)}
                  onChangeState={state => addDataValidity('newPassord', state)}
                />
                <Input
                  label={t(`${base}.confirmPassword`)}
                  type={'password'}
                  kind={'input'}
                  placeholder={t(`${base}.confirmPasswordPlaceholder`)}
                  onChange={value => setFormData(prev => ({ ...prev, confirmPassword: value }))}
                  required={true}
                  min={formData.password}
                  // controls={getPasswordControlByConfiguration(passwordOption.state.data, t)}
                  onChangeState={state => addDataValidity('newPassworConfirm', state)}
                  error={errorToShow.error}
                />
                <Button
                  isDisable={!isValid || errorToShow.error !== undefined}
                  type={'submit'}
                  semantic={'primary'}
                  isLoading={fetching}
                  label={t(`${base}.action`)}
                />
                <LinkableText
                  text={t('user.login')}
                  to={loc => ({ ...loc, pathname: '/' })}
                />
              </Flex>
            </Flex>
          </form>
        )}
      </Wrapper>
    </div>
  )
}

export default ResetPassword
