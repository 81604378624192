import { mvDate } from '../../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { API } from '../../../../../redux/actions'
import { measuresToFetch } from './_constants'
import { EngineTorqueDensifierProps, EngineTorqueDensifierState } from './_types'
import { ChartFetch } from '../../ChartRenderUtilities'
import { parseDuration } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/sharedV2'

abstract class TorqueBelt<Props extends EngineTorqueDensifierProps, State extends EngineTorqueDensifierState> extends ChartFetch<
  Props,
  State
> {
  private mounted = false
  private abortController: AbortController = new AbortController()
  protected chartTranslationKeyTitle: string = 'plantAnalysis.lines.engineTorqueDensifierTitle'

  constructor(props: Props, generateState: (props: Props) => State) {
    super(props)
    this.state = generateState(props)
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  protected getSecondsPercentFromTotalWorkshift(lineSecondsAtZero: number): number | undefined {
    const workShiftTotalSeconds = this.getWorkshiftTotalSeconds()
    if(workShiftTotalSeconds){
      return (lineSecondsAtZero / workShiftTotalSeconds) * 100
    }
    return undefined
  }

  protected getWorkshiftTotalSeconds(){
    const { workshift } = this.props
    if (workshift) {
      if(workshift.value === 99){
        return parseDuration("24h 00m").asSeconds()
      }else{
        return parseDuration(workshift.h).asSeconds()
      }
    }
    return undefined
  }

  public componentDidUpdate(prevProps: Readonly<EngineTorqueDensifierProps>) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private jsonifyInfluxData(data: any) {
    return data.columns
    .map((value: any, index: number) => {
      if (value !== 'time' && value !== 'shift') {
        return {
          [value]: data.values[0][index],
        }
      }
    })
    .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {})
  }

  private formatData(data: any) {
    try {
      const jsonizedData = this.jsonifyInfluxData(data)
      const dataGrouped = Object.entries(jsonizedData).reduce((acc, [key, value]) => {
        const match = key.match(/(dec_f\d+)_([\w_]+)/);
        if (match) {
          const [ , bufferKey, metricKey ] = match;
          (acc[bufferKey] ??= {})[metricKey] = value;
        }
        return acc;
      }, {});
      
      const filteredDataGrouped =  Object.entries(jsonizedData).reduce((acc, [key, value]) => {
        const match = key.match(/(dec_f\d+)_([\w_]+)/);
        if (match) {
          const [ , bufferKey, metricKey ] = match;
          (acc[bufferKey] ??= []).push({name: metricKey, value});
        }
        return acc;
      }, {});

      this.setState({
        data: dataGrouped,
        filteredData: filteredDataGrouped
      })
    } catch (error) {
      this.setState({
        data: null,
        fetching: false,
        error: true,
      })
    }
  }

  protected getKeyFromLine(line: 'line1' | 'line2' | 'line3' | 'line4' | 'line5') {
    switch (line) {
      case 'line1':
        return 'dec_f019'
      case 'line2':
        return 'dec_f028'
      case 'line3':
        return 'dec_f076'
      case 'line4':
        return 'dec_f086'
      case 'line5':
        return 'dec_f057'
      default:
        return ''
    }
  }

  private async getData(processing?: number) {
    const { plant } = this.props.plant
    const workshift = this.props.workshift

    if (workshift) {
      const plantQueryString = plant && plant !== '' ? 'plant=' + plant + '&' : ''
      const startOfDay = mvDate.format(
        mvDate.startOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )
      const endOfDay = mvDate.format(
        mvDate.endOfDay(mvDate.getDateFromString(this.props.date.toString())),
        'yyyy-MM-dd HH:mm:ss'
      )

      const queryEndShift = ` WHERE shift = ${
        workshift.value !== 99 ? workshift.value : 0
      } AND time >= '${startOfDay}' AND time <= '${endOfDay}'`

      try {
        const customQueryStart = `SELECT ${measuresToFetch.map((m, index) => `"${m}"`)} FROM `
        const query = customQueryStart + `vDecSpeedTorque` + queryEndShift

        const dataTorqueBelt = API().request(`/query?${plantQueryString}q=` + query, {
          signal: this.abortController.signal,
        })

        this.setState({
          fetching: true,
          error: false,
        })

        Promise.all([dataTorqueBelt])
          .then(([dataTorqueBeltResult]) => {
            const dataTorqueBeltResultValue =
              typeof dataTorqueBeltResult === 'string' ? JSON.parse(dataTorqueBeltResult) : dataTorqueBeltResult
            if (this.mounted) {
              try {
                this.formatData(dataTorqueBeltResultValue.results[0].series[0])
                this.setState({
                  fetching: false,
                  error: false,
                })
              } catch (error) {
                this.setState({
                  fetching: false,
                  error: false,
                  data: null,
                })
              }
            }
            if (dataTorqueBeltResultValue.results[0].error) {
              this.setState({
                data: null,
                fetching: false,
                error: true,
              })
            }
          })
          .catch(error => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            this.setState({
              fetching: false,
              error: true,
            })
          })
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  protected abstract renderContent(): JSX.Element
  public render() {
    const { fetching, error } = this.state

    if (fetching) {
      return this.renderLoading()
    }

    if (error) {
      return this.renderError()
    }

    if (!this.state.data) {
      return this.renderNoData()
    }

    return this.renderContent()
  }
}

export default TorqueBelt
