import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Input, Modal } from '@mv-submodules/inplant-components-fe'
import { ButtonVariants } from '@mv-submodules/inplant-components-fe/ui/components/Button/types'

interface OwnState {
  machineCode: string
}

interface StateProps {
  onSubmit: (text: string) => void
  closeModal: () => void
  visible: boolean
  isSubmitting: boolean
  machineCode: string
}

type Props = StateProps & WithTranslation

class ModalUpdateMachineCodeAsset extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      machineCode: this.props.machineCode,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  public componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    if (this.props && nextProps && this.props.visible !== nextProps.visible) {
      this.setState({ machineCode: '' })
    }
  }

  public render() {
    const { visible, t, isSubmitting } = this.props
    const { machineCode } = this.state
    return (
      <form onSubmit={this.onSubmit}>
        <Modal
          visible={visible}
          closeLabel={t('assetManager.actions.cancel')}
          onClose={this.props.closeModal}
          title={t('assetManager.modal.title.updateMachineCode')}
          width={50}
          additionalFooterButtons={[{
            type: 'submit',
            variant: 'primary' as ButtonVariants,
            isLoading: isSubmitting,
            label: t('assetManager.actions.save'),
          }]}
        >
          <Input
            label={t('assetManager.forms.machineCode')}
            value={machineCode}
            type={'text'}
            onChange={value => this.handleInputChange({ value: String(value) })}
          />
        </Modal>
      </form>
    )
  }

  private onSubmit(event: React.FormEvent) {
    event.preventDefault()
    this.props.onSubmit(this.state.machineCode)
  }

  private handleInputChange(state: { value: string }) {
    const text = state.value as string
    this.setState({ machineCode: text })
  }
}

export default withTranslation()(ModalUpdateMachineCodeAsset)
