import * as React from 'react'
import { Line } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'

import { GeneralStore } from '../../../../types/Store'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { DateRangeProps, Workshift } from '../../../../types/workshift'
import { GeneralData, Measure } from '../../../../types/measure'
import { API } from '../../../../redux/actions'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Loader } from '../../../../functions/shared'
import BottomLegend from '../BottomLegend/BottomLegend/BottomLegendView'
import {
  fillWarnArea,
  getValueFromBinaryStatus,
  hydrateData,
  hydrateTimeData,
  interpolateTime,
  parseResponseData,
  parseResponseSingleData,
  populateForwardBackward,
  populateManAutoLocSec,
} from '../../../../functions/series'
import BeltGraphMain, { AdditionalChartLineData } from './BeltGraphMain'
import GraphLineBarTime from '../GraphLineBar/GraphLineBarTime/GraphLineBarTimeView'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import GraphBrush from '../GraphBrush/GraphBrush'
import {
  BinaryMappingConfiguration,
  PlantAnalysisDetailsConfiguration,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/settings'
import { isJSON } from '@mv-submodules/inplant-coreadapter-fe/functions'
import { debounce } from '../../../../../inplant-components-fe/mvfunctions/helpers'
import GenericLineGraph from './GenericLineGraph'

const nodeSubtypeWithRunning = ['MDIR']
const nodeSubtypeWithInverter = ['INV']

export const waterFlowAdditionalLine: AdditionalChartLineData = {
  label: 'waterFlow',
  measure: 'waterFlow',
  type: 'value',
  unit: 'l/h',
}

export const materialTemperatureAdditionalLine: AdditionalChartLineData = {
  label: 'materialTemperature',
  measure: 'materialTemperature',
  type: 'value',
  unit: '°C',
}

interface SingleDataValue {
  x: number
  y: number | undefined
  h100: number
}

interface OwnState {
  brush1End: number
  brush1EndInd: number
  brush1Start: number
  brush1StartInd: number
  data: { [key: string]: Measure }
  dayEnd: string | null
  dayStart: string | null
  endDate: string | number
  fetchErrors: boolean
  filteredData: any[]
  isCollapsed: boolean
  isLoading: boolean
  last: any
  lastAfter: any
  mergedData: undefined | any[]
  startDate: string | number
}

interface OwnProps {
  binaryConfiguration: BinaryMappingConfiguration
  subnodeType: string
  active: string
  additionalChartLines?: AdditionalChartLineData[]
  externalChartLines?: AdditionalChartLineData[]
  component: any
  hideAvind?: boolean
  hideSpeed?: boolean
  inverter: boolean
  waterFlow: boolean
  measureActCurrentName?: string
  mono?: boolean
  toggleCollapse: (id: string) => void
}

interface StateProps {
  aspirato: GeneralStore
  detailsConfiguration: PlantAnalysisDetailsConfiguration | null
  fullDay: null | Workshift
  model: null | GeneralData
  plant: string | null
  ricetta: GeneralStore
  workshift: Workshift
}

const mapStateToProps = (state: any): StateProps & DateRangeProps => ({
  aspirato: state.plantAnalysis.general.aspirato,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  days: state.plantAnalysis.common.days,
  detailsConfiguration:
    state.config?.plantAnalysis?.detailsConfiguration && isJSON(state.config.plantAnalysis.detailsConfiguration)
      ? JSON.parse(state.config.plantAnalysis.detailsConfiguration)
      : undefined,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  isDateFilterRange: state.plantAnalysis.common.isDateFilterRange,
  model: state.plantAnalysis.model,
  plant: state.plantSelector.plant,
  ricetta: state.plantAnalysis.general.ricetta,
  workshift: state.plantAnalysis.common.workshift,
})

type Props = StateProps & OwnProps & WithTranslation & DateRangeProps

const cleanState = {
  brush1End: 0,
  brush1EndInd: 0,
  brush1Start: 0,
  brush1StartInd: 0,
  data: {
    running: {
      data: [],
      min: 0,
      max: 0,
    },
    aspirato: {
      data: [],
      min: 0,
      max: 0,
    },
    ricetta: {
      data: [],
      min: 0,
      max: 0,
    },
    assorbimento: {
      data: [],
      min: 0,
      max: 0,
    },
    avantiIndietro: {
      data: [],
      min: 0,
      max: 0,
    },
    forward: {
      data: [],
      min: 0,
      max: 0,
    },
    backward: {
      data: [],
      min: 0,
      max: 0,
    },
    inverterDiretta: {
      data: [],
      min: 0,
      max: 0,
    },
    manuale: {
      data: [],
      min: 0,
      max: 0,
    },
    automatico: {
      data: [],
      min: 0,
      max: 0,
    },
    setVelocita: {
      data: [],
      min: 0,
      max: 0,
    },
    status: {
      data: [],
      min: 0,
      max: 0,
    },
    localeSezionato: {
      data: [],
      min: 0,
      max: 0,
    },
    waterFlow: {
      data: [],
      min: 0,
      max: 0,
    },
  },
  dayEnd: null,
  dayStart: null,
  endDate: 'auto',
  fetchErrors: false,
  filteredData: [],
  isCollapsed: false,
  isLoading: false,
  last: null,
  lastAfter: null,
  mergedData: [],
  startDate: 'auto',
}

class BeltBinarySrcGraphs extends React.Component<Props, OwnState> {
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)
    this.state = cleanState

    this.exportData = this.exportData.bind(this)
    this.handleBrush1 = debounce(this.handleBrush1.bind(this), 10)
  }

  public componentDidMount() {
    const date = moment.isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    if (this.props.dateFilterStart && moment.isMoment(date) && this.props.workshift) {
      this.getData(this.props)
    }
  }

  public componentWillUnmount() {
    this.setState(cleanState)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const date = moment.isMoment(nextProps.dateFilterStart)
      ? nextProps.dateFilterStart
      : moment(nextProps.dateFilterStart)
    if (
      (this.props.dateFilterStart !== nextProps.dateFilterStart ||
        this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.component.id) &&
      nextProps.dateFilterStart &&
      moment.isMoment(date) &&
      nextProps.workshift
    ) {
      this.getData(nextProps)
    } else {
      if (this.state.mergedData && this.state.mergedData?.length > 0) {
        return
      }
      const cleanStateWithouFetch = (({ isLoading, fetchErrors, ...others }) => ({ ...others }))(cleanState)
      this.setState(cleanStateWithouFetch)
    }
  }

  public render() {
    const { additionalChartLines, hideAvind, hideSpeed, workshift, externalChartLines } = this.props
    const isReady = this.state.mergedData !== undefined && this.state.mergedData.length > 0 && !this.state.isLoading
    const noData = this.state.mergedData !== undefined && this.state.mergedData.length === 0 && !this.state.isLoading
    const showRunning = nodeSubtypeWithRunning.includes(this.props.subnodeType)
    const showInverter = nodeSubtypeWithInverter.includes(this.props.subnodeType)
    const isExternalGraphPresent = externalChartLines && externalChartLines.length > 0

    return (
      <React.Fragment>
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.state.isLoading && (
            <div className="alert alert-secondary w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.loading')}
              <Loader />
            </div>
          )}
          {this.state.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto belt-graph-fetch-errors">
              {this.props.t('plantAnalysis.fetchErrors')}
            </div>
          )}
          {isReady && (this.props.inverter || showInverter) && (
            <BeltGraphMain
              additionalChartLines={additionalChartLines}
              filteredData={this.state.filteredData}
              data={this.state.mergedData}
              pauses={(workshift && workshift.pauses) || []}
              hideSpeed={hideSpeed}
            />
          )}
        </div>

          {isReady && isExternalGraphPresent && (
            <div className="row">
              <GenericLineGraph 
                additionalChartLines={externalChartLines}
                filteredData={this.state.filteredData}
                data={this.state.mergedData}
              />
            </div>
          )}

        {isReady && (
          <GraphLineBarTime
            topMargin={true}
            entry={'manAutoLocSec'}
            colorsId={'manAutoLocSec'}
            filteredData={this.state.filteredData}
            i18nTitle={'plantAnalysis.labels.manAutoLocSec'}
            paddingRight={70}
          />
        )}

        {isReady && !hideAvind && !showRunning && (
          <GraphLineBarTime
            topMargin={true}
            entry={'avantiIndietro'}
            colorsId={'avind'}
            filteredData={this.state.filteredData}
            i18nTitle={'plantAnalysis.labels.avantiIndietro'}
            paddingRight={70}
          />
        )}

        {isReady && showRunning && (
          <GraphLineBarTime
            topMargin={true}
            entry={'running'}
            colorsId={'running'}
            filteredData={this.state.filteredData}
            i18nTitle={'plantAnalysis.labels.running'}
            paddingRight={70}
          />
        )}

        {isReady && <GraphTimelineView data={this.state.filteredData} xAxisNumber={true} paddingRight={60} />}

        {isReady && (
          <BottomLegend
            labels={['manAutoLocSec', 'localeSezionato']
              .concat(hideAvind || showRunning ? [] : ['avind'])
              .concat(showRunning ? ['running'] : [])}
          />
        )}

        {isReady && (this.props.inverter || showInverter || isExternalGraphPresent) && (
          <GraphBrush data={this.state.mergedData} brushOnChange={this.handleBrush1} paddingRight={70}>
            <Line dataKey="assorbimento" type="step" stroke="#A19FF9" dot={false} isAnimationActive={false} />
          </GraphBrush>
        )}

        {isReady && this.props.inverter && (
          <GraphTimelineView data={this.state.mergedData} xAxisNumber={false} paddingRight={60} />
        )}
      </React.Fragment>
    )
  }

  private populateSingleData(key: string, isBoolean: boolean = false, multiplier?: number): SingleDataValue[] {
    const stateData = { ...this.state.data }

    if (stateData && stateData[key] && stateData[key].data) {
      return stateData[key].data.map((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }

        return {
          x: time,
          y: isBoolean ? (datum[1] ? 1 : 0) : multiplier ? datum[1] * multiplier : datum[1],
          h100: 100,
        }
      })
    }

    return []
  }

  private async getData(props: Props) {
    const { measureActCurrentName } = props
    try {
      this.setState({ isLoading: true })

      const startDateObj = moment(props.workshift.start)
      const startDate = startDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
      const endDateObj = moment(props.workshift.end)
      const endDate = endDateObj
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()

      const measureLabel = props.component.id

      const plantQuery = props.plant ? 'plant=' + props.plant + '&' : ''

      const queryStart = `SELECT "measure" FROM "`
      const queryEnd = `" WHERE time >= '${startDate}' AND time <= '${endDate}'`
      const queryLastStart = ` SELECT last("measure") AS "last_measure" FROM "`
      const queryLastEnd = `" WHERE time < '${startDate}' LIMIT 1`

      const queryStatus = queryStart + measureLabel + '_WORD01_INFO' + queryEnd
      const queryLastStatus = queryLastStart + measureLabel + '_WORD01_INFO' + queryLastEnd

      const requestDataStatusSrc = API().request(`/query?${plantQuery}q=` + queryStatus)
      const requestDataLastStatusSrc = API().request(`/query?${plantQuery}q=` + queryLastStatus)

      const speedSubstring = `_SPD_ACT`
      const currentSubstring = `_ACT_CUR`
      const waterFlowSubstring = `_H2O_ACT_FLW`
      const materialTemperatureSubstring = `_MAT_ACT_TMP`

      const queryAssortment = queryStart + measureLabel + (measureActCurrentName || currentSubstring) + queryEnd
      const querySetSpeed = queryStart + measureLabel + speedSubstring + queryEnd
      const queryWaterFlow = queryStart + measureLabel + waterFlowSubstring + queryEnd
      const queryMaterialTemperature = queryStart + measureLabel + materialTemperatureSubstring + queryEnd
      const queryLastAssortment =
        queryLastStart + measureLabel + (measureActCurrentName || currentSubstring) + queryLastEnd
      const queryLastSetSpeed = queryLastStart + measureLabel + speedSubstring + queryLastEnd
      const queryLastWaterFlow = queryLastStart + measureLabel + waterFlowSubstring + queryLastEnd
      const queryLastMaterialTemperature = queryLastStart + measureLabel + materialTemperatureSubstring + queryLastEnd

      const requestDataAssortmentSrc = API().request(`/query?${plantQuery}&q=` + queryAssortment, {
        signal: this.abortController.signal,
      })
      const requestDataSetSpeedSrc = API().request(`/query?${plantQuery}&q=` + querySetSpeed, {
        signal: this.abortController.signal,
      })
      const requestDataWaterFlowSrc = API().request(`/query?${plantQuery}&q=` + queryWaterFlow, {
        signal: this.abortController.signal,
      })
      const requestDataMaterialTemperatureSrc = API().request(`/query?${plantQuery}&q=` + queryMaterialTemperature, {
        signal: this.abortController.signal,
      })
      const requestDataLastAssortmentSrc = API().request(`/query?${plantQuery}&q=` + queryLastAssortment, {
        signal: this.abortController.signal,
      })
      const requestDataLastSetSpeedSrc = API().request(`/query?${plantQuery}&q=` + queryLastSetSpeed, {
        signal: this.abortController.signal,
      })
      const requestDataLastWaterFlowSrc = API().request(`/query?${plantQuery}&q=` + queryLastWaterFlow, {
        signal: this.abortController.signal,
      })
      const requestDataLastMaterialTemperatureSrc = API().request(
        `/query?${plantQuery}&q=` + queryLastMaterialTemperature
      )

      Promise.all([
        requestDataStatusSrc,
        requestDataLastStatusSrc,
        requestDataAssortmentSrc,
        requestDataSetSpeedSrc,
        requestDataLastAssortmentSrc,
        requestDataLastSetSpeedSrc,
        requestDataWaterFlowSrc,
        requestDataLastWaterFlowSrc,
        requestDataMaterialTemperatureSrc,
        requestDataLastMaterialTemperatureSrc,
      ]).then(
        ([
          requestDataStatusSrcResult,
          requestDataLastStatusSrcResult,
          requestDataAssortmentSrcResult,
          requestDataSetSpeedSrcResult,
          requestDataLastAssortmentSrcResult,
          requestDataLastSetSpeedSrcResult,
          requestDataWaterFlowSrcResult,
          requestDataLastWaterFlowSrcResult,
          requestDataMaterialTemperatureSrcResult,
          requestDataLastMaterialTemperatureSrcResult,
        ]) => {
          this.setState(
            {
              data: Object.assign({}, this.state.data, {
                assorbimento: { data: parseResponseData(requestDataAssortmentSrcResult) },
                status: { data: parseResponseData(requestDataStatusSrcResult) },
                setVelocita: { data: parseResponseData(requestDataSetSpeedSrcResult) },
                waterFlow: { data: parseResponseData(requestDataWaterFlowSrcResult) },
                materialTemperature: { data: parseResponseData(requestDataMaterialTemperatureSrcResult) },
              }),
              last: Object.assign({}, this.state.data, {
                assorbimento: parseResponseSingleData(requestDataLastAssortmentSrcResult),
                status: parseResponseSingleData(requestDataLastStatusSrcResult),
                setVelocita: parseResponseSingleData(requestDataLastSetSpeedSrcResult),
                waterFlow: parseResponseSingleData(requestDataLastWaterFlowSrcResult),
                materialTemperature: parseResponseSingleData(requestDataLastMaterialTemperatureSrcResult),
              }),
              startDate: startDateObj.unix(),
              endDate: endDateObj.unix(),
              isLoading: false,
              fetchErrors: false,
            },
            () => {
              this.prepareData()
            }
          )
        }
      )
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private getBinaryMapping() {
    const { binaryConfiguration } = this.props
    return binaryConfiguration || undefined
  }

  private populateSingleDataFromBinaryStatus(key: string): SingleDataValue[] {
    const stateData = { ...this.state.data }
    const stateDataLast = { ...this.state.last }
    const detailsConfiguration = this.getBinaryMapping()

    const result: SingleDataValue[] = []

    if (stateDataLast?.status && detailsConfiguration) {
      const time = moment(this.props.workshift.start).unix()

      result.push({
        x: time,
        y: getValueFromBinaryStatus(key, stateDataLast?.status[1], detailsConfiguration),
        h100: 100,
      })
    }

    if (stateData?.status?.data && detailsConfiguration) {
      stateData.status.data.forEach((datum: any) => {
        const time = moment(datum[0]).unix()

        if (stateData[key].min === 0 || time < stateData[key].min) {
          stateData[key].min = time
        }
        if (time > stateData[key].max) {
          stateData[key].max = time
        }
        result.push({
          x: time,
          y: getValueFromBinaryStatus(key, datum[1], detailsConfiguration),
          h100: 100,
        })
      })
      return result
    }

    // TODO Controlla che sia corretto => [{}]
    return result
  }

  private prepareData() {
    try {
      const mergedData: any[] = []

      if (this.state.data) {
        // let aspirato: any = []
        // let ricetta: any = []
        let assorbimento: SingleDataValue[] = []
        let running: SingleDataValue[] = []
        let backward: SingleDataValue[] = []
        let forward: SingleDataValue[] = []
        let inverterDiretta: SingleDataValue[] = []
        let manuale: SingleDataValue[] = []
        let automatico: SingleDataValue[] = []
        let setVelocita: SingleDataValue[] = []
        let status: SingleDataValue[] = []
        let localeSezionato: SingleDataValue[] = []
        let waterFlow: SingleDataValue[] = []
        let materialTemperature: SingleDataValue[] = []

        // needed by populateSingleDataFromBinaryStatus
        status = this.populateSingleData('status', false)
        assorbimento = this.populateSingleData('assorbimento', false)
        setVelocita = this.populateSingleData('setVelocita')
        waterFlow = this.populateSingleData('waterFlow')
        materialTemperature = this.populateSingleData('materialTemperature')

        // aspirato = this.populateSingleData('aspirato')
        // ricetta = this.populateSingleData('ricetta')

        running = this.populateSingleDataFromBinaryStatus('running')
        backward = this.populateSingleDataFromBinaryStatus('backward')
        forward = this.populateSingleDataFromBinaryStatus('forward')
        inverterDiretta = this.populateSingleDataFromBinaryStatus('inverterDiretta')
        manuale = this.populateSingleDataFromBinaryStatus('manuale')
        automatico = this.populateSingleDataFromBinaryStatus('automatico')
        localeSezionato = this.populateSingleDataFromBinaryStatus('localeSezionato')

        if (
          !(
            status.length === 0 &&
            assorbimento.length === 0 &&
            setVelocita.length === 0 &&
            forward.length === 0 &&
            backward.length === 0 &&
            inverterDiretta.length === 0 &&
            manuale.length === 0 &&
            automatico.length === 0 &&
            localeSezionato.length === 0 &&
            running.length === 0 &&
            materialTemperature.length === 0 &&
            waterFlow.length === 0
          )
        ) {
          hydrateData(
            {
              // aspirato,
              // ricetta,
              assorbimento,
              forward,
              backward,
              inverterDiretta,
              manuale,
              automatico,
              setVelocita,
              localeSezionato,
              status,
              running,
              waterFlow,
              materialTemperature,
            },
            mergedData,
            'assorbimento',
            0
          )
          mergedData.sort((a, b) => {
            if (a.time < b.time) {
              return -1
            }
            if (a.time > b.time) {
              return 1
            }
            return 0
          })

          hydrateTimeData(
            [
              // 'aspirato',
              // 'ricetta',
              'assorbimento',
              'forward',
              'backward',
              'inverterDiretta',
              'manuale',
              'automatico',
              'setVelocita',
              'localeSezionato',
              'warnArea',
              'status',
              'running',
              'waterFlow',
              'materialTemperature',
            ],
            mergedData,
            this.state,
            undefined,
            undefined,
            undefined,
            this.getBinaryMapping()
          )

          populateManAutoLocSec(mergedData)
          populateForwardBackward(mergedData)
          fillWarnArea(mergedData, 'assorbimento', 0)
          interpolateTime(
            mergedData,
            moment(this.props.workshift.start).unix(),
            moment(this.props.workshift.end).unix()
          )
        }
        this.setState({
          brush1StartInd: 0,
          brush1EndInd: mergedData.length,
          mergedData,
          filteredData: mergedData,
        })
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private handleBrush1(args: any) {
    this.setState({
      brush1StartInd: args.startIndex,
      brush1EndInd: args.endIndex,
      filteredData: this.state.mergedData ? this.state.mergedData.slice(args.startIndex, args.endIndex + 1) : [],
    })
  }

  private exportData(e: React.MouseEvent) {
    e.preventDefault()
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(BeltBinarySrcGraphs)))
