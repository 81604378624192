import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { GeneralData } from '../../../../types/measure'
import { Workshift } from '../../../../types/workshift'
import { connect } from 'react-redux'
import Row from '../../../../../inplant-components-fe/ui/components/Grid/Row'
import { Loader } from '../../../../../inplant-components-fe'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers'
import Column from '../../../../../inplant-components-fe/ui/components/Grid/Column'
import _Chart from './_Charts'

interface OwnProps {
  measureId: string
  workShift: any
}

interface OwnState {
  isFetching: boolean
  isError: boolean
  startDateTime: string | null
  endDateTime: string | null
  startOfDay: string | null
  endOfDay: string | null
  isAllDay: boolean
  plant: string | null
}

interface StateProps {
  days: number
  fullDay: null | Workshift
  plant: any | null
  workshifts: null | GeneralData
  dateFilterStart: string
  dateFilterEnd: string
  model: null | GeneralData
  workshift: Workshift
}

const mapStateToProps = (state: any): StateProps => ({
  days: state.plantAnalysis.common.days,
  fullDay: state.plantAnalysis.workshifts.fullDay,
  plant: state.plantSelector || null,
  workshifts: state.plantAnalysis.workshifts.workshifts,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  model: state.plantAnalysis.model,
  workshift: state.plantAnalysis.common.workshift,
})

type Props = OwnProps & WithTranslation & StateProps

class AspirationSystem extends React.Component<Props, OwnState> {
  private abortController: AbortController = new AbortController()
  constructor(props: Props) {
    super(props)
    this.state = {
      isFetching: false,
      isError: false,
      endDateTime: this.generateEndDateTime(),
      startDateTime: this.generateStartDateTime(),
      endOfDay: this.generateEndOfDay(),
      startOfDay: this.generateStartOfDay(),
      isAllDay: this.getIsAllDay(),
      plant: this.getPlant(),
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      this.setState({
        endDateTime: this.generateEndDateTime(),
        startDateTime: this.generateStartDateTime(),
        endOfDay: this.generateEndOfDay(),
        startOfDay: this.generateStartOfDay(),
        isAllDay: this.getIsAllDay(),
        plant: this.getPlant(),
      })
    }
  }

  public componentWillUnmount(): void {
    this.abortController.abort()
  }

  public render() {
    const { isFetching, isError } = this.state
    if (isFetching) {
      return this.renderLoadingElement()
    }

    if (isError) {
      return this.renderErrorElement()
    }

    return (
      <Column>
        <_Chart
          {...this.props}
          plantString={this.state.plant ?? ''}
          abortController={this.abortController}
          endDateTime={this.state.endDateTime ?? ''}
          startDateTime={this.state.startDateTime ?? ''}
          measure={this.props.measureId}
        />
      </Column>
    )
  }

  private renderLoadingElement() {
    return (
      <Row>
        <Loader />
      </Row>
    )
  }

  private renderErrorElement() {
    const { t } = this.props
    return (
      <>
        <h3 className={'w-100'}>{this.props.t('plantAnalysis.vecoplant.bufferFillTitle')}</h3>
        <div className="alert alert-danger w-100 col-sm-6 mx-auto bunker-graph-row-fetch-errors alert-local">
          {t('plantAnalysis.fetchErrors')}
        </div>
      </>
    )
  }

  // * Logic

  private getPlant(): string | null {
    if (!this.props.plant) {
      return null
    }
    return this.props.plant.plant
  }

  private getIsAllDay(): boolean {
    if (!this.props.workshift) {
      return false
    }
    return this.props.workshift.value === 99
  }

  private generateStartOfDay(): string | null {
    if (!this.props.workshift) {
      return null
    }
    return mvDate.format(
      mvDate.startOfDay(mvDate.getDateFromString(this.props.workshift.start.toString())),
      'yyyy-MM-dd HH:mm:ss'
    )
  }

  private generateEndOfDay(): string | null {
    if (!this.props.workshift) {
      return null
    }
    return mvDate.format(
      mvDate.endOfDay(mvDate.getDateFromString(this.props.workshift.end.toString())),
      'yyyy-MM-dd HH:mm:ss'
    )
  }

  private generateStartDateTime(): string | null {
    if (!this.props.workshift) {
      return null
    }
    return this.props.workshift.start
      ? mvDate.getDateFromString(this.props.workshift.start.toString()).toISOString()
      : this.generateStartOfDay()
  }

  private generateEndDateTime(): string | null {
    if (!this.props.workshift) {
      return null
    }
    return this.props.workshift.end
      ? mvDate.getDateFromString(this.props.workshift.end.toString()).toISOString()
      : this.generateEndOfDay()
  }
}

export default connect(mapStateToProps)(withTranslation()(AspirationSystem))
