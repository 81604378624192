import { TFunction } from 'i18next'
export const FULL_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss"

export const convertSecondsToString = (seconds: number, t: TFunction) => {
  const padNum = (n: number) => (n < 10 ? '0' : '') + n
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  const mm = padNum(minutes)
  const ss = padNum(Math.floor(seconds - hours * 3600 - minutes * 60))

  return [(hours > 0 ? t('plantAnalysis.formats.timeHH', { hh: hours }): ''),
    (mm !==  '00' ? t('plantAnalysis.formats.timeMM', { mm }): ''),
    (ss !== '00' ? t('plantAnalysis.formats.timeSS', { ss }): '')].join(' ')
}
