import { SupportedWordsSlugs } from "./_bynaryMappings"

/**
 * Measure that `GenericBinarySearchGraph` can read from a binary word
 */
const wordSupportedMeasures = 
    [
        "running", 
        "command", 
        "direction", 
        "sync", 
        'valvePosition',
        'key1Inserted',
        'key2Inserted',
        'key3Inserted',
        'key4Inserted',
        'key5Inserted',
        'key6Inserted',
        'key7Inserted',
        'key8Inserted',
        'key9Inserted',
        'key10Inserted',
        'key11Inserted',
        'key12Inserted',
        'key13Inserted',
        'key14Inserted',
    ] as const
type WordSupportedMeasures = typeof wordSupportedMeasures[number]

/**
 * Measure that `GenericBinarySearchGraph` can read from influx that are not encoded in a word (every other polling measure)
 */

const additionalSupportedMeasures = [
    "amperage",
    "rotorSpeed",
    "waterFlow",
    "materialTemperature",
    "pressorThrust",
    "rotorSpeed",
    "program",
    "bufferLoadedWithF015",
    "bufferLoadedWithF070"
] as const
type AdditionalSupportedMeasures = typeof additionalSupportedMeasures[number]


/**
 * All measures that can be supported by `GenericBinarySearchGraph`
 */
type SupportedMeasures = WordSupportedMeasures | AdditionalSupportedMeasures

/**
 * Mapping between the word supplied to "GenericBinarySearchGraph" and the measure that can be derived from it
 */

const wordSupportedMeasuresFromBinaryWordSlug: Record<SupportedWordsSlugs, WordSupportedMeasures[]> = {
    MDIR : ["command", "running"],
    INV: ["command", "direction"],
    SYNC: ["sync"],
    KH: ["valvePosition"],
    PKEY: [
        "key1Inserted",
        "key2Inserted",
        "key3Inserted",
        "key4Inserted",
        "key5Inserted",
        "key6Inserted",
        "key7Inserted",
        "key8Inserted",
        "key9Inserted",
        "key10Inserted",
        "key11Inserted",
        "key12Inserted",
        "key13Inserted",
        "key14Inserted",
    ]
}

export type {WordSupportedMeasures, AdditionalSupportedMeasures, SupportedMeasures}
export {wordSupportedMeasuresFromBinaryWordSlug as supportedMeasuresByWord}