import moment from 'moment'
import React, { useMemo } from 'react'
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Line, LineChart, Tooltip, Label } from 'recharts'
import Row from '../../../../../inplant-components-fe/ui/components/Grid/Row'
import { COLORS } from '../../../../constants'
import { parseResponseData, hydrateData, hydrateTimeData } from '../../../../functions/series'
import { isJSON } from '../../../../functions/shared'
import { simplePopulateSingleData } from '../../../../functions/simplePopulateSingleData'
import { useChartFetching } from '../../../../hook/useChartFetching'
import { useChartState } from '../../../../hook/useChartState'
import { API } from '../../../../redux/actions'
import { PartialRecordDataType, PartialRecordStringType } from '../../../../types/helpers'
import { SingleDataValue } from '../../../../types/singleDataValue'
import CommonErrorMessage from '../Common/CommonErrorMessage'
import CommonNoDataAvailable from '../Common/CommonNoDataAvailable'
import CustomLegend from '../Common/CustomLegend'
import CustomTooltip from '../Common/CustomTooltip'
import { GeneralChartProps } from './generalChartProps'

type DataType = typeof DATA_TYPE[number]

const itemsColors: PartialRecordStringType<DataType> = {
  balesLength: COLORS.press.generalCartPressure,
  numberOfBaleHourly: COLORS.press.generalChannelPressure,
  unitWeight: COLORS.press.generalOilTemp,
} as const

const itemsUnitOfMeasure: PartialRecordStringType<DataType> = {
  balesLength: 'm',
  numberOfBaleHourly: 'bales/h',
  unitWeight: 'Kg',
} as const

interface CompleteData {
  data: Array<PartialRecordDataType<DataType>>
}

const DATA_TYPE = ['unitWeight', 'balesLength', 'numberOfBaleHourly'] as const
const measureMiddleSection = '_PROD_'
const unitWeightMeasure = '_WEIGHT' as const
const balesLengthMeasure = '_BALE_LEN' as const
const numberOFBaleHourlyMeasure = '_BALES_H' as const

interface Props {
  currentSelectedRecipe: string
}

const _PressBaleDetailChart = (props: GeneralChartProps & Props) => {
  const { startDateTime, endDateTime, abortController, measure, plantString, t, currentSelectedRecipe } = props
  const dataContentLabel: PartialRecordStringType<DataType> = useMemo(
    () => ({
      balesLength: `${t('plantAnalysis.pressDetail.balesLength')}`,
      numberOfBaleHourly: `${t('plantAnalysis.pressDetail.numberOfBaleHourly')}`,
      unitWeight: `${t('plantAnalysis.pressDetail.unitWeight')}`,
    }),
    []
  )
  const { actions, data, isError, isLoading, tooltip } = useChartState<CompleteData, DataType>({
    keys: [...DATA_TYPE],
    tooltipContentInfo: {
      balesLength_color: itemsColors.balesLength,
      balesLength_label: dataContentLabel.balesLength,
      balesLength_unitOfMeasure: itemsUnitOfMeasure.balesLength,
      numberOfBaleHourly_color: itemsColors.numberOfBaleHourly,
      numberOfBaleHourly_label: dataContentLabel.numberOfBaleHourly,
      numberOfBaleHourly_unitOfMeasure: itemsUnitOfMeasure.numberOfBaleHourly,
      unitWeight_color: itemsColors.unitWeight,
      unitWeight_label: dataContentLabel.unitWeight,
      unitWeight_unitOfMeasure: itemsUnitOfMeasure.unitWeight,
    },
  })
  const { queryEndSection, queryStartSection, uriRequestStart } = useChartFetching({
    callback: fetchAllData,
    endDateTime,
    startDateTime,
    plantString,
    type: 'normal',
    deps: [currentSelectedRecipe],
  })

  function fetchAllData() {
    if (currentSelectedRecipe.length === 0) {
      return
    }
    actions.setLoading()
    const baseStartMeasure = `${measure}${measureMiddleSection}`
    const unitWeightMeasureName = `${baseStartMeasure}${currentSelectedRecipe}${unitWeightMeasure}`
    const numberOfBaleHourlyMeasureName = `${baseStartMeasure}${currentSelectedRecipe}${numberOFBaleHourlyMeasure}`
    const balesLengthMeasureName = `${baseStartMeasure}${currentSelectedRecipe}${balesLengthMeasure}`

    const unitWeightRequest = API().request(
      `${uriRequestStart}${queryStartSection}${unitWeightMeasureName}${queryEndSection}`,
      { signal: abortController.signal }
    )
    const numberOfBaleHourlyRequest = API().request(
      `${uriRequestStart}${queryStartSection}${numberOfBaleHourlyMeasureName}${queryEndSection}`,
      { signal: abortController.signal }
    )
    const balesLengthRequest = API().request(
      `${uriRequestStart}${queryStartSection}${balesLengthMeasureName}${queryEndSection}`,
      { signal: abortController.signal }
    )
    Promise.all([unitWeightRequest, numberOfBaleHourlyRequest, balesLengthRequest])
      .then(([unitWeightResponse, numberOfBaleHourlyResponse, balesLengthResponse]) => {
        actions.setData({
          data: parseAndPrepareData({
            unitWeight: unitWeightResponse,
            numberOfBaleHourly: numberOfBaleHourlyResponse,
            balesLength: balesLengthResponse,
          }),
        })
      })
      .catch(e => {
        actions.setError()
      })
  }

  function parseAndPrepareData(requestDataSet: Record<DataType, any>): Array<PartialRecordDataType<DataType>> {
    const populatedData: Record<DataType, SingleDataValue[]> = Object.entries(requestDataSet).reduce(
      (acc, [currKey, currValue]) => {
        const dataToParse = isJSON(currValue) ? currValue : JSON.stringify(currValue)
        return {
          ...acc,
          [currKey]: simplePopulateSingleData(parseResponseData(dataToParse)),
        }
      },
      {}
    ) as Record<DataType, SingleDataValue[]>
    const mergedData: any[] = []
    hydrateData(populatedData, mergedData)
    hydrateTimeData([...DATA_TYPE], mergedData, { startDate: startDateTime, endDate: endDateTime })
    return (mergedData as unknown) as Array<PartialRecordDataType<DataType>>
  }

  function handleMouseEnter() {
    actions.setTooltipContent({})
  }

  function handleMouseMove(args: any) {
    if (args && args.activeLabel) {
      const currentData = data?.data.find((e: any) => e.time === args.activeLabel)
      // tslint:disable-next-line: variable-name
      const balesLength_data = currentData?.balesLength ?? '--'
      // tslint:disable-next-line: variable-name
      const numberOfBaleHourly_data = currentData?.numberOfBaleHourly ?? '--'
      // tslint:disable-next-line: variable-name
      const unitWeight_data = currentData?.unitWeight ?? '--'
      actions.setTooltipContent({
        balesLength_data,
        numberOfBaleHourly_data,
        unitWeight_data,
        time: currentData?.time,
      })
    }
  }

  function handleMouseLeave() {
    actions.hideTooltip()
  }

  function nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  if (isLoading) {
    return <></>
  }

  if (isError) {
    return <CommonErrorMessage />
  }

  if (data && data.data.length === 0) {
    return <CommonNoDataAvailable />
  }

  return (
    <div className={'d-flex flex-column'}>
      <h3 className={'w-100'}>{t('plantAnalysis.pressDetail.baleDetail').toUpperCase()}</h3>
      <Row>
        <div className="col-md-10">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={700}
              height={400}
              data={data?.data}
              margin={{ top: 40, right: 0, bottom: 0, left: 0 }}
              // syncId={this.id}
              onMouseEnter={handleMouseEnter}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              barGap={0}
              barCategoryGap={0}
            >
              <XAxis
                key={'line1'}
                hide={false}
                dataKey="time"
                label={''}
                height={30}
                axisLine={true}
                tickLine={false}
                tick={true}
                interval={'preserveStartEnd'}
                minTickGap={50}
                domain={['dataMin', 'dataMax']}
                tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                type={'category'}
              />

              <YAxis domain={[0, 'auto']} tick={true} axisLine={true} yAxisId={'balesHourly'} orientation={'left'}>
                <Label value={itemsUnitOfMeasure.numberOfBaleHourly} position={'top'} offset={20}  />
              </YAxis>
              <YAxis domain={[0, 'auto']} tick={true} axisLine={true} yAxisId={'meter'} orientation={'right'}>
                <Label value={itemsUnitOfMeasure.balesLength} position={'top'} offset={20}  />
              </YAxis>
              <YAxis domain={[0, 'auto']} tick={true} axisLine={false} yAxisId={'weight'} orientation={'left'}>
                <Label value={itemsUnitOfMeasure.unitWeight} position={'top'} offset={20} />
              </YAxis>
              <CartesianGrid stroke="#f5f5f5" />
              <Tooltip content={nullTooltipContent()} />
              <Line
                dataKey="balesLength"
                stroke={itemsColors.balesLength}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'meter'}
              />
              <Line
                dataKey="numberOfBaleHourly"
                stroke={itemsColors.numberOfBaleHourly}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'balesHourly'}
              />
              <Line
                dataKey="unitWeight"
                stroke={itemsColors.unitWeight}
                dot={false}
                strokeWidth={2}
                type="step"
                isAnimationActive={false}
                yAxisId={'weight'}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <CustomTooltip tooltip={tooltip} />
      </Row>
      <CustomLegend
        dataToShow={DATA_TYPE.map(dataType => ({
          label: dataContentLabel[dataType] ?? '',
          color: itemsColors[dataType] ?? '',
          unitOfMeasure: itemsUnitOfMeasure[dataType] ?? '',
        }))}
      />
    </div>
  )
}

export default _PressBaleDetailChart
